<div class="page-title-area item-bg1">
  <div class="container">
    <div class="page-title-content">
      <ul>
        <li>
          <a href="index.html">{{ 'HOME' | translate }}</a>
        </li>
        <li>{{ 'PACKS' | translate }}</li>
      </ul>
      <h2>{{ 'PACK' | translate }}</h2>
    </div>
  </div>
</div>
<section class="product-details my-5">
  <div class="container" style="min-height: 300px">
    <div class="row" *ngIf="pack && pack.id">
      <!-- pack image(s) -->
      <div class="col-md-5 text-center mb-3">
        <ng-container *appIfBrowserPlatform>
          <ng-container *ngIf="pack.medias && pack.medias.length > 0; else mainImage">
            <owl-carousel-o [options]="productImageSlides" *ngIf="pack.medias">
              <ng-template carouselSlide *ngFor="let media of pack.medias">
                <img
                  class="product-image"
                  [alt]="media.alt ?? pack.label"
                  style="min-width: 100%; max-height: 500px"
                  [src]="media.url ? media.publicUrl : getImage(media.id, null)" />
              </ng-template>
            </owl-carousel-o>
          </ng-container>
        </ng-container>
        <ng-container *appIfServerPlatform>
          <img
            *ngIf="pack.id && pack.medias.length"
            style="max-height: 500px"
            [src]="pack.medias[0].url ? pack.medias[0].publicUrl : getImage(pack.medias[0].id, 'PDP')"
            [alt]="pack.medias[0].alt ?? pack.label"
            class="product-image mw-100"
            style="max-height: 500px" />
        </ng-container>
        <ng-template #mainImage>
          <img
            class="product-image mw-100"
            [alt]="pack.media?.alt ?? pack.label"
            *ngIf="pack.media && pack.media.id"
            style="min-width: 100%; max-height: 500px"
            [src]="pack.media.url ? pack.media.publicUrl : getImage(pack.media.id, null)" />
          <img
            class="product-image mw-100"
            [alt]="pack?.medias[0]?.alt ?? pack.label"
            *ngIf="pack.medias && pack.medias?.length && pack.medias[0] && pack.medias[0]?.id"
            style="min-width: 100%; max-height: 500px"
            [src]="pack.medias[0].url ? pack.medias[0].publicUrl : getImage(pack.medias[0].id, null)" />
          <img
            *ngIf="!pack.media && !pack?.medias?.length && pack.id"
            class="product-image"
            style="max-height: 500px"
            src="assets/img/not-found.png"
            [alt]="pack.label" />
        </ng-template>
      </div>
      <!-- pack infos -->
      <div class="col-md-7 position-relative">
        <div class="row">
          <div class="col-8 col-lg-9">
            <h2 class="mt-2 theme-color-main">{{ pack.label }}</h2>
            <i *ngIf="pack.categoryName" class="bx bx-folder-open mr-2" style="font-size: 25px; color: #cfcfcf"></i>
            <a *ngIf="pack.categoryName" style="font-size: 23px">{{ pack.categoryName.localizedValues[this.currentLocale] }}</a>
          </div>
          <div class="col-4 col-lg-3" *ngIf="pack.sellerId">
            <img
              class="seller-image shadow-sm"
              [src]="getImage(pack.sellerId, null)"
              onerror="this.src='assets/img/not-found.png'"
              alt="image" />
          </div>
        </div>

        <!-- start pricing -->
        <div [class.position-absolute]="isOnLargeScreen" style="bottom: 0">
          <ng-container *ngIf="isAuthenticatedAsDRHOrBeneficiary">
            <div>
              <div class="row mt-2">
                <div class="col-sm-6 col-lg-5 col-xl-4">
                  <span class="theme-color-optional mr-3 h5">{{ 'MEMBER_PRICE' | translate }}</span>
                </div>
                <div class="col-sm-6 col-lg-7 col-xl-8">
                  <span class="h5"
                    >{{
                      pack?.priceWithPromotion?.amount
                        | currency
                          : pack?.priceWithPromotion?.currency.isoCode
                          : ''
                          : '0.' + pack?.priceWithPromotion?.currency.numberOfDigistAfterDecimalsign + '-3'
                    }}
                    {{ pack?.priceWithPromotion?.currency.isoCode }}</span
                  >
                </div>
              </div>
              <div class="row d-none">
                <div class="col-sm-6 col-md-5 col-lg-3">
                  <span class="theme-color-optional mr-3 h5">{{ 'PUBLIC_PRICE' | translate }}</span>
                </div>
                <div class="col-sm-6 col-md-7 col-lg-9">
                  <span class="h5"
                    >{{
                      pack?.price?.amount
                        | currency : pack?.price?.currency.isoCode : '' : '0.' + pack?.price?.currency.numberOfDigistAfterDecimalsign + '-3'
                    }}
                    {{ pack?.price?.currency.isoCode }}</span
                  >
                </div>
              </div>
            </div>
            <a style="color: #ffffff" (click)="addToCart()" class="default-btn ml-0 cursor-pointer"
              ><i class="bx bx-cart icon-arrow before"></i><span class="label">{{ 'BUY' | translate }}</span
              ><i class="bx bx-cart icon-arrow after"></i
            ></a>
          </ng-container>
          <ng-container *ngIf="!authenticatedUser">
            <a style="color: #ffffff" class="default-btn ml-0 cursor-pointer"
              ><i class="bx bx-log-in-circle icon-arrow before"></i><span class="label"> {{ 'BECOME_A_MEMBER' | translate }} </span
              ><i class="bx bx-log-in-circle icon-arrow after"></i
            ></a>
          </ng-container>
        </div>

        <!-- end pricing -->
      </div>
      <div class="col-12">
        <!-- pack description start -->
        <app-read-wysiwyg *ngIf="pack.description" [value]="pack.description"></app-read-wysiwyg>
        <!-- pack description start -->
      </div>
      <div class="col-12">
        <div class="products-details-tab">
          <ngx-tabset>
            <ngx-tab tabTitle="PRODUCTS">
              <div class="products-details-tab-content">
                <app-pack-entries *ngIf="pack && pack.packEntries" [packEntries]="pack.packEntries"></app-pack-entries>
              </div>
            </ngx-tab>

            <ngx-tab tabTitle="Reviews">
              <app-product-reviews [product]="pack"></app-product-reviews>
            </ngx-tab>
          </ngx-tabset>
        </div>
      </div>
    </div>
  </div>
</section>
