import { Component, Input, OnInit } from '@angular/core';
import { CorporateSellerService } from '../corporate-seller.service';
import { AppState } from '../../store/app.reducer';
import { Store } from '@ngrx/store';
import { LOCALE_ID, LOCALE_STATE } from '../../shared/constants';

@Component({
  selector: 'hro-corporate-seller-block',
  templateUrl: './corporate-seller-block.component.html',
  styleUrls: ['./corporate-seller-block.component.scss'],
})
export class CorporateSellerBlockComponent implements OnInit {
  @Input()
  corporateSeller;
  @Input() corporateSellerDetailsUrlPattern: string;
  @Input() currentLocaleId: string;

  constructor(private corporateSellerService: CorporateSellerService, private store: Store<AppState>) {}

  ngOnInit(): void {
    this.store.select(LOCALE_STATE, LOCALE_ID).subscribe(localeId => (this.currentLocaleId = localeId));
  }
  getImage(image: any, format: string | null): any {
    if (!image) {
      return;
    }
    return this.corporateSellerService.downloadMedia(image, format);
  }
}
