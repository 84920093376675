<div [ngStyle]="{ 'background-image': 'url(assets/img/page-title/title1.webp)' }" class="page-title-area item-bg1">
  <div class="container">
    <div class="page-title-content">
      <ul>
        <li>
          <a href="index.html">{{ 'HOME' | translate }}</a>
        </li>
        <li>{{ 'COACH_DETAILS' | translate }}</li>
      </ul>
      <h2 style="color: white">{{ coach?.firstName }} {{ coach?.lastName }}</h2>
    </div>
  </div>
</div>
<div class="my-dashboard-area ptb-100">
  <div class="container">
    <div class="myDashboard-content">
      <div style="text-align: right">
        <a
          [routerLink]="
            coachDetailsPageUrlPattern && coach ? (coachDetailsPageUrlPattern | seoNameUrl : coach.seoData : currentLocale) : null
          "
          class="default-btn">
          <i class="bx bx-arrow-back icon-arrow before"></i><span class="label">{{ 'BACK' | translate }}</span>
          <i class="bx bx-arrow-back icon-arrow after"></i
        ></a>
      </div>

      <ul class="legend">
        <li><span class="approved"></span> {{ 'VALIDATED' | translate }}</li>
        <li><span class="reported"></span> {{ 'REPORTED' | translate }}</li>
        <li><span class="pending"></span> {{ 'PENDING' | translate }}</li>
        <li><span class="refuse"></span> {{ 'REFUSED' | translate }}</li>
        <li><span class="not-available"></span> {{ 'NOT_AVAILABE' | translate }}</li>
        <li style="float: right">{{ 'SHOW_ALL_DAYS' | translate }}<input type="checkbox" style="margin: 5px" (change)="btnClick()" /></li>
      </ul>
      <br />
      <div class="content-wrapper position-relative schedule-wrapper">
        <ngx-spinner bdColor="rgba(51,51,51,0.8)" color="#fff" size="medium" type="line-scale" [fullScreen]="false">
          <p class="text-white">
            {{ 'LOADING_COACH_AVAILIBILITY' | translate }}
          </p>
        </ngx-spinner>
        <ejs-schedule
          width="100%"
          height="510px"
          #scheduleObj
          (cellClick)="displaySelectedDate($event)"
          [selectedDate]="selectedDate"
          [allowDragAndDrop]="false"
          [locale]="local"
          (popupOpen)="onPopupOpen($event)"
          (eventRendered)="onEventRendered($event)"
          [eventSettings]="eventSettings"
          [(currentView)]="currentView"
          (actionComplete)="onActionComplete($event)"
          [(workHours)]="workHours"
          [startHour]="startHour"
          [endHour]="endHour"
          [workDays]="workDays"
          [showWeekend]="showWeekend"
          [timezone]="timezone"
          [firstDayOfWeek]="1"
          (currentViewChange)="changeCurrentView()">
          <e-views>
            <e-view option="Month"></e-view>
            <e-view option="Week"></e-view>
            <e-view option="Day"></e-view>
          </e-views>
          <ng-template #eventSettingsTemplate let-data>
            <div class="template-wrap">
              <div class="subject">{{ data.Subject | translate }}</div>
            </div>
          </ng-template>
          <ng-template #editorTemplate let-data>
            <table *ngIf="data !== undefined" class="custom-event-editor" width="100%" cellpadding="5">
              <tbody>
                <tr>
                  <td class="e-text">{{ 'LABEL' | translate }}</td>
                  <td colspan="4">
                    <div class="control-section">
                      <div class="content-wrapper">
                        <div id="icon">
                          <div class="content" style="width: 100%">
                            <ejs-dropdownlist
                              id="Subject"
                              data-name="Subject"
                              (select)="changeSubject($event, data)"
                              [value]="selectedSubject"
                              locale="fr-CH"
                              #Subject
                              [dataSource]="labelTypeAppointment"
                              [placeholder]="placeholder | translate"
                              [itemTemplate]="itemTemplate"
                              [valueTemplate]="valueTemplate"
                              [enabled]="appointmentStatus?.id !== 'APPROVED_APPOINTMENT'">
                              <ng-template #itemTemplate="" let-data="">
                                <!--set the value to itemTemplate property-->
                                <span
                                  ><span class="name"> {{ data | translate }}</span></span
                                >
                              </ng-template>
                              <ng-template #valueTemplate="" let-data="">
                                <!--set the value to valueTemplate property-->
                                <span class="value">{{ data | translate }}</span>
                              </ng-template>
                            </ejs-dropdownlist>
                          </div>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>

                <tr>
                  <td class="e-textlabel">
                    <span>{{ 'STARTTIME' | translate }}</span>
                  </td>
                  <td colspan="4">
                    <ejs-datetimepicker
                      id="StartTime"
                      class="e-field"
                      data-name="StartTime"
                      locale="fr-CH"
                      [value]="data.StartTime"
                      [enabled]="appointmentStatus?.id !== 'APPROVED_APPOINTMENT'"
                      [min]="minDate"
                      [max]="maxDate">
                    </ejs-datetimepicker>
                  </td>
                </tr>

                <tr>
                  <td class="e-textlabel">{{ 'ENDTIME' | translate }}</td>
                  <td colspan="4">
                    <ejs-datetimepicker
                      id="EndTime"
                      class="e-field"
                      data-name="EndTime"
                      locale="fr-CH"
                      [value]="data.EndTime"
                      [enabled]="appointmentStatus?.id !== 'APPROVED_APPOINTMENT'"
                      [min]="minDate"
                      [max]="maxDate"></ejs-datetimepicker>
                  </td>
                </tr>
                <tr *ngIf="!appointmentStatus && !appointmentStatus?.name && !data.Id">
                  <td class="e-textlabel">{{ 'VOUCHER' | translate }} {{ appointmentStatus?.name }}</td>
                  <td colspan="4">
                    <input
                      #voucher
                      id="voucherCode"
                      class="e-field e-input"
                      type="text"
                      [(ngModel)]="voucherCode"
                      value="{{ voucherCode }}"
                      name="voucherCode"
                      style="width: 100%" />
                  </td>
                </tr>
                <tr *ngIf="appointmentStatus && appointmentStatus?.name">
                  <td class="e-textlabel">{{ 'STATUS' | translate }}</td>
                  <td>
                    {{ appointmentStatus?.name.localizedValues[currentLocale] }}
                  </td>
                </tr>
              </tbody>
            </table>
          </ng-template>
        </ejs-schedule>
      </div>
    </div>
  </div>
</div>
