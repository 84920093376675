<div [ngStyle]="{ 'background-image': 'url(assets/img/page-title/title1.webp)' }" class="page-title-area item-bg1">
  <div class="container">
    <div class="page-title-content">
      <ul>
        <li>
          <a href="index.html">{{ 'HOME' | translate }}</a>
        </li>
        <li>{{ 'PRODUCTS' | translate }}</li>
      </ul>
      <h2>{{ 'PRODUCTS' | translate }}</h2>
    </div>
  </div>
</div>
<div class="courses-area ptb-100">
  <div class="container" style="min-height: 150px">
    <div class="text-center" *ngIf="isFetchingProducts">
      <mat-spinner class="mx-auto mt-5" diameter="25"></mat-spinner>
    </div>
    <div class="row" *ngIf="products && products.length > 0 && !isFetchingProducts">
      <hro-product-card
        class="col-lg-4 col-md-6"
        *ngFor="let product of products"
        [product]="product"
        [detailsPageUrlPattern]="
          product.productType === PRODUCT_PRODUCT_TYPE ? productDetailsPageUrlPattern : packDetailsPageUrlPattern
        "></hro-product-card>
      <div class="col-lg-12 col-md-12 col-sm-12">
        <div class="pagination-area text-center">
          <mat-paginator
            (page)="handlePageEvent($event)"
            [length]="totalItems"
            [pageSize]="size"
            [showFirstLastButtons]="false"
            [pageIndex]="page">
          </mat-paginator>
        </div>
      </div>
    </div>
    <div class="no-data" *ngIf="!isFetchingProducts && (!products || !products.length)">
      {{ 'NO_PRODUCT_FOUND' | translate }}
    </div>
  </div>
</div>
