<div class="page-title-area item-bg1">
  <div class="container">
    <div class="page-title-content">
      <ul>
        <li>
          <a href="index.html">{{ 'HOME' | translate }}</a>
        </li>
        <li>{{ 'PROFIL' | translate }}</li>
      </ul>
      <h2>{{ 'PROFIL' | translate }}</h2>
    </div>
  </div>
</div>

<div class="checkout-area">
  <div class="container" style="min-height: 300px">
    <form>
      <div class="row">
        <div class="col-lg-12 col-md-12">
          <div class="billing-details" style="margin-top: 30px" *ngIf="beneficiaryProfil">
            <h3 class="title" style="margin-bottom: 20px">{{ 'MY_RESUME_PASSEXCELLENCE' | translate }}</h3>
            <div class="row">
              <div class="col-lg-4 col-md-4">
                <div class="form-group">
                  <label>{{ 'FIRSTNAME' | translate }}</label>
                  <h4>{{ beneficiaryProfil.firstName }}</h4>
                </div>
              </div>
              <div class="col-lg-4 col-md-4">
                <div class="form-group">
                  <label> {{ 'LASTNAME' | translate }}</label>
                  <h4>{{ beneficiaryProfil.lastName }}</h4>
                </div>
              </div>
              <div class="col-lg-4 col-md-4" *ngIf="beneficiaryProfil?.badgeWinning?.length">
                <div class="form-group">
                <div style="display: flex; align-items: center">
                  <div *ngFor="let badge of beneficiaryProfil?.badgeWinning">
                    <label>{{ badge?.badgeName}}</label>
                    <div
                            class="badge-icon"
                            data-toggle="tooltip"
                            data-placement="top"
                            [title]="badge?.badgeName">
                      <img
                              style="width: 70px; height: auto;"
                              [title]="badge?.badgeName"
                              [src]="getImage(badge?.badgeMediaId, null)"
                              onerror="this.src='assets/img/not-found.png'"
                              [alt]="badge?.badgeName" />
                    </div>
                  </div>
                </div>
                </div>
              </div>
            </div>
            <h3 class="title" style="margin-bottom: 20px">{{ 'ACCOMPLISHMENTS' | translate }}</h3>
          </div>
        </div>
      </div>
    </form>
    <div class="mb-4" *ngFor="let axis of beneficiaryDevelopmentAxises; trackBy: identifyer">
      <mat-accordion>
        <mat-expansion-panel expanded="true" hideToggle="true">
          <mat-expansion-panel-header class="h-auto py-2 py-sm-3">
            <mat-panel-title>
              <div class="w-100">
                <div class="row">
                  <div class="col-sm-8 col-md-10">
                    <div class="row">
                      <div class="col-lg-8">
                        <a>
                          {{ axis.name }}
                          <span class="d-block d-sm-inline">({{ axis.actions.length }} Actions)</span></a
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <br />
          <div>
            <hr class="d-block d-sm-none" />
            <div class="row">
              <h3 style="text-align: center">{{ 'MY_ACTIONS' | translate }}</h3>
            </div>
            <div *ngIf="axis?.actions.length; else noActions">
              <!-- start dev axis action list -->
              <div *ngFor="let action of axis.actions; let i = index; trackBy: identifyer">
                <mat-accordion>
                  <mat-expansion-panel expanded="true" hideToggle="true">
                    <mat-expansion-panel-header class="mat-expansion-panel-header-actions h-auto py-2 py-sm-3 action-header-border">
                      <mat-panel-title>
                        <div class="w-100">
                          <div class="row">
                            <div class="col col-md-7 col-lg-8">
                              <div class="row">
                                <div class="col-lg-7">
                                  <a class="pt-1 pt-md-0 action-bar">{{ action.dueDate | date : 'MM/yyyy' }} </a>
                                </div>
                                <div class="col-lg-5 text-lg-center">
                                  <span class="optional-color"
                                  >{{ action.title }}</span
                                  >
                                </div>
                              </div>
                            </div>
                            <div class="d-none d-md-block col-md-5 col-lg-4">
                              <div class="row">
                                <div class="col-md-6 text-center">
                                  <ng-container *ngIf="!condition.size">
                                    <span
                                            style="min-width: 44px"
                                            [ngStyle]="{
                                        'background-color': actionStatus[action.actionStatus]
                                      }"
                                            class="badge badge-pill float-md-center mt-1 mt-md-0">
                                      {{ action.actionStatus | translate }}</span
                                    >
                                  </ng-container>
                                  <ng-container *ngIf="condition">
                                    <span *ngFor="let cond of condition | keyvalue" class="float-md-center mt-1 mt-md-0">
                                      <span
                                              *ngIf="cond.value === i"
                                              style="min-width: 44px"
                                              [ngStyle]="{
                                          'background-color': actionStatus[cond.key]
                                        }"
                                              class="badge badge-pill">
                                        {{ cond.key | translate }}</span
                                      >
                                      <span
                                              *ngIf="cond.value !== i"
                                              style="min-width: 44px"
                                              [ngStyle]="{
                                          'background-color': actionStatus[action.actionStatus]
                                        }"
                                              class="badge badge-pill">
                                        {{ action.actionStatus | translate }}</span
                                      >
                                    </span>
                                  </ng-container>
                                </div>

                              </div>
                            </div>
                          </div>
                        </div>
                      </mat-panel-title>
                    </mat-expansion-panel-header>
                    <br />
                    <hr class="d-block d-md-none" />
                    <div class="myDashboard-profile">
                      <div class="row align-items-center">
                        <div class="col-12">
                          <div class="row mb-3">
                            <div class="col-md-4">
                              <div class="progress" style="height: 30px">
                                <div
                                        class="progress-bar progress-bar-striped progress-color"
                                        role="progressbar"
                                        [ngStyle]="{
                                    width: getWidth(action?.progress)
                                  }"
                                        aria-valuenow="25"
                                        aria-valuemin="0"
                                        aria-valuemax="100">
                                  {{ action?.progress }} %
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </mat-expansion-panel>
                </mat-accordion>
                <br />
              </div>
              <!-- end dev axis action list -->
            </div>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </div>
</div>













<ng-template #noDevelopmentAxises>
  <div class="no-data">{{ 'NO_DEVELOPMENT_AXISES_FOUND' | translate }}</div>
</ng-template>

<ng-template #noActions>
  <div class="no-data">{{ 'NO ACTION FOUND' | translate }}</div>
</ng-template>





