import { IAddress } from './address.model';

export interface IPayment {
  id?: string;
  code?: string;
  pspOrderId?: string;
  amount?: number;
  paymentModeId?: string;
  paymentStatusId?: string;
  paymentAddress?: IAddress;
}

export class Payment implements IPayment {
  constructor(
    public id?: string,
    public code?: string,
    public pspOrderId?: string,
    public amount?: number,
    public paymentModeId?: string,
    public paymentStatusId?: string,
    public paymentAddress?: IAddress
  ) {}
}
