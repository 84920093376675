import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { CmsService } from '../../cms.service';
import { takeUntil } from 'rxjs/operators';
import { EmbedVideoService } from 'ngx-embed-video';
import { Store } from '@ngrx/store';
import { AppState } from '../../../store/app.reducer';
import { CONTEXT } from '../../../shared/constants';

@Component({
  selector: 'app-hro-video-set',
  templateUrl: './hro-video-set.component.html',
  styleUrls: ['./hro-video-set.component.scss'],
})
export class HroVideoSetComponent implements OnInit, OnDestroy {
  @Input() width: any;
  @Input() height: any;

  @Input() componentId: any;
  @Input() dataSource: any;
  @Output() dataSourceChange = new EventEmitter<any>();

  data: any;
  iframeHtmls: any[] = [];
  videoLoaded = false;
  private destroyed$ = new Subject<void>();

  constructor(private cmsService: CmsService, private embedService: EmbedVideoService, private store: Store<AppState>) {}

  ngOnInit(): void {
    this.store
      .select(CONTEXT)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(contextId => {
        this.videoLoaded = false;
        this.data = [];
        this.cmsService
          .getCmsComponentMediaContentsByComponentAndContext(this.componentId, contextId)
          .pipe(takeUntil(this.destroyed$))
          .subscribe(res => {
            this.iframeHtmls = [];
            this.data = res;
            this.dataSource = res;
            this.dataSourceChange.emit(this.dataSource);
            this.videoLoaded = true;
            if (this.data.medias && this.data.medias.length) {
              for (const media of this.data.medias) {
                if (media) {
                  if (media?.url) {
                    if (media.publicUrl.indexOf('youtube') === -1) {
                      const embed = this.embedService.embed(media.publicUrl, {
                        query: { portrait: 0, color: '333' },
                        attr: {
                          width: this.width,
                          height: this.height,
                          style: 'width: 100%; max-width: 100%; min-height: 300px',
                          title: 'Passexcellence',
                        },
                      });
                      this.iframeHtmls.push(embed);
                    } else {
                      this.lazyEmbedYoutube(media);
                    }
                  } else {
                    this.iframeHtmls.push(media.publicUrl);
                  }
                }
              }
            }
          });
      });
  }

  lazyEmbedYoutube(media) {
    this.embedService.embed_image(media.publicUrl, { image: 'maxresdefault' }).then(result => {
      const embedUrl = this.getEmbedUrlForYoutubeVideo(media.publicUrl);
      const thumbnailHtml = this.getThumbnailHtml(result.link, embedUrl);
      const embed = this.embedService.embed(media.publicUrl, {
        query: { portrait: 0, color: '333' },
        attr: {
          width: this.width,
          height: this.height,
          style: 'width: 100%; max-width: 100%; min-height: 300px',
          title: 'Passexcellence',
          srcdoc: thumbnailHtml,
        },
      });
      this.iframeHtmls.push(embed);
    });
  }

  getEmbedUrlForYoutubeVideo(url) {
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    const match = url.match(regExp);

    const videoId = match && match[2].length === 11 ? match[2] : null;
    return 'https://www.youtube.com/embed/' + videoId + '?autoplay=1';
  }

  getThumbnailHtml(videoThumbnailLink, videoLink) {
    return `<style>* {padding:0;margin:0;overflow:hidden}html,body{height:100%}img{min-height: 300px}img,span{position:absolute;width:100%;top:0;bottom:0;margin:auto;}span{height:1.5em;text-align:center;font:48px/1.5 sans-serif;color:white;text-shadow:0 0 0.5em black}</style><a href=${videoLink}><img src=${videoThumbnailLink} alt='Passexcellence'><span>▶</span></a>`;
  }

  getMedia(media: any): any {
    return this.cmsService.downloadMedia(media.id, null);
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
