import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { IProduct } from '../../models/product.model';
import { take, takeUntil } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { AppState } from '../../store/app.reducer';
import { Subject } from 'rxjs';
import { IBeneficiary } from '../../models/Beneficiary.model';
import { ProductCustomerReview } from '../../models/product-customer-review.model';
import { ProductCustomerReviewService } from '../../services/product-customer-review.service';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { DatePipe } from '@angular/common';
import { B2BCustomerReviewAverage } from '../../shared/models/b-2-b-customer-review-average.model';

@Component({
  selector: 'app-product-reviews',
  templateUrl: './product-reviews.component.html',
  styleUrls: ['./product-reviews.component.scss'],
})
export class ProductReviewsComponent implements OnInit, OnDestroy {
  private $destroyed = new Subject();
  @Input() product: IProduct;
  productCustomerReviews = [];
  beneficiary: IBeneficiary;
  rating = 0;
  hovered = 0;
  ratingValue;
  isReviewFormSubmitted = false;
  productCustomerReview = new ProductCustomerReview();
  isReviewFormValid = false;
  b2BCustomerReviewAverage: B2BCustomerReviewAverage;
  currentRatingLabel;
  ratingLabel;
  readonly INITIAL_CUSTOMER_REVIEW_STATUS_ID = 'PENDING';

  constructor(
    private store: Store<AppState>,
    private reviewService: ProductCustomerReviewService,
    private translation: TranslateService,
    private toaster: ToastrService,
    public datepipe: DatePipe
  ) {}

  ngOnInit() {
    this.store
      .select('beneficiaryState', 'beneficiary')
      .pipe(takeUntil(this.$destroyed))
      .subscribe(beneficiary => {
        this.beneficiary = beneficiary;
      });
    if (this.product && this.product.id) {
      this.getReviews();
    }
  }

  ngOnDestroy() {
    this.$destroyed.next();
    this.$destroyed.complete();
  }

  rateChange() {
    this.ratingValue = this.getRateValue(this.rating);
    this.currentRatingLabel = this.getRateLabel(this.rating);
  }

  rateOnHovre(event) {
    this.hovered = event;
    this.ratingLabel = this.getRateLabel(event);
  }

  addReview() {
    this.isReviewFormSubmitted = true;
    if (!this.productCustomerReview.title || !this.productCustomerReview.content || !this.ratingValue) {
      this.isReviewFormValid = false;
      return;
    }
    this.isReviewFormValid = true;
    this.productCustomerReview.createdBy = this.beneficiary.email;
    this.productCustomerReview.customerId = this.beneficiary.id;
    this.productCustomerReview.customerReviewRatingLevelId = this.ratingValue;
    this.productCustomerReview.productId = this.product.id;
    this.productCustomerReview.customerReviewStatusId = this.INITIAL_CUSTOMER_REVIEW_STATUS_ID;
    this.reviewService
      .create(this.productCustomerReview)
      .pipe(takeUntil(this.$destroyed))
      .subscribe(
        res => {
          this.productCustomerReview = res;
          const msg = this.translation.instant('REVIEW_MUST_BE_APPROVED');
          const title = this.translation.instant('REVIEW_SENT_SUCCESSFULLY');
          this.toaster.success(msg, title);
          this.saveB2BCustomerReviewAverage();
        },
        error => {
          this.toaster.error(this.translation.instant(error.error.errorType));
        }
      );
  }

  private getReviews() {
    this.reviewService
      .findAllByProduct(this.product.id)
      .pipe(take(1))
      .subscribe(values => {
        this.productCustomerReviews = values;
      });
  }

  private saveB2BCustomerReviewAverage() {
    const index = this.beneficiary.employments?.findIndex(
      emloyement =>
        this.datepipe.transform(emloyement.startDate, 'yyyy-MM-dd') <= this.datepipe.transform(new Date(), 'yyyy-MM-dd') &&
        this.datepipe.transform(emloyement.endDate, 'yyyy-MM-dd') >= this.datepipe.transform(new Date(), 'yyyy-MM-dd')
    );
    if (index > -1) {
      this.reviewService
        .getB2BCustomerReviewAverageByB2BCustomerAndProduct(this.beneficiary.employments[index].b2BCustomerId, this.product.id)
        .pipe(takeUntil(this.$destroyed))
        .subscribe(
          b2bCustomerReview => {
            if (b2bCustomerReview === null) {
              this.b2BCustomerReviewAverage.customer = this.beneficiary.employments[index].b2BCustomerId;
              this.b2BCustomerReviewAverage.productId = this.product.id;
              if (!this.b2BCustomerReviewAverage.productCustomerReviews) {
                this.b2BCustomerReviewAverage.productCustomerReviews = [];
              }
              this.b2BCustomerReviewAverage.productCustomerReviews.push(this.productCustomerReview);
              this.b2BCustomerReviewAverage.value = this.rating;
              this.reviewService
                .createB2BCustomerReviewAverage(this.b2BCustomerReviewAverage)
                .pipe(takeUntil(this.$destroyed))
                .subscribe(
                  () => {
                    this.resetReviewForm();
                  },
                  error => {
                    this.toaster.error(this.translation.instant(error.error.errorType));
                  }
                );
            } else {
              this.b2BCustomerReviewAverage = b2bCustomerReview;
              this.b2BCustomerReviewAverage.productCustomerReviews.push(this.productCustomerReview);
              this.reviewService
                .updateB2BCustomerReviewAverage(this.b2BCustomerReviewAverage)
                .pipe(takeUntil(this.$destroyed))
                .subscribe(
                  () => {
                    this.resetReviewForm();
                  },
                  error => {
                    this.toaster.error(this.translation.instant(error?.error?.errorType));
                  }
                );
            }
          },
          error => {
            this.resetReviewForm();
            this.toaster.error(this.translation.instant(error.error.errorType));
          }
        );
    }
  }

  private getRateValue(value): string {
    let val;
    switch (value) {
      case 1:
        val = 'POOR';
        break;
      case 2:
        val = 'BELOW_AVERAGE';
        break;
      case 3:
        val = 'AVERAGE';
        break;
      case 4:
        val = 'ABOVE_AVERAGE';
        break;
      case 5:
        val = 'EXCELLENT';
        break;
      default:
        break;
    }
    return val;
  }

  private getRateLabel(value): string {
    let label;
    switch (value) {
      case 1:
        label = 'NOT_INTERESTING';
        break;
      case 2:
        label = 'NOT_VERY_INTERESTING';
        break;
      case 3:
        label = 'MODERATELY_INTERESTING';
        break;
      case 4:
        label = 'INTERESTING';
        break;
      case 5:
        label = 'VERY_INTERESTING';
        break;
      default:
        break;
    }
    return label;
  }

  private resetReviewForm() {
    this.isReviewFormSubmitted = false;
    this.productCustomerReview = new ProductCustomerReview();
    this.rating = 0;
    this.ratingValue = null;
  }
}
