import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { CmsService } from '../../../cms.service';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../store/app.reducer';
import { takeUntil } from 'rxjs/operators';
import { CONTEXT, PARTICULAR_ENTREPRISE_PAGE_CODE } from '../../../../shared/constants';

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss'],
})
export class BannerComponent implements OnInit, OnDestroy {
  readonly particularPageCode = PARTICULAR_ENTREPRISE_PAGE_CODE.PARTICULAR;
  readonly entreprisePageCode = PARTICULAR_ENTREPRISE_PAGE_CODE.ENTREPRISE;

  @Input() componentId: any;
  data: any;
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  @Input()
  videoComponentCode: string;

  constructor(private cmsService: CmsService, private store: Store<AppState>) {}

  ngOnInit(): void {
    this.store
      .select(CONTEXT)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(contextId => {
        this.cmsService
          .getCmsComponentBannerContentsByComponentAndContext(this.componentId, contextId)
          .pipe(takeUntil(this.destroyed$))
          .subscribe(res => {
            this.data = res;
          });
      });
  }

  getImage(image: any, format: string | null): any {
    if (!image) {
      return;
    }
    return this.cmsService.downloadMedia(image.id, format);
  }
  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
