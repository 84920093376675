import { ProductCustomerReview } from '../../models/product-customer-review.model';
import { CustomerReviewAverage, ICustomerReviewAverage } from '../../models/CustomerReviewAverage.model';

export interface IB2BCustomerReviewAverage extends ICustomerReviewAverage {
  customer?: string;
  productCustomerReviews?: ProductCustomerReview[];
}

export class B2BCustomerReviewAverage extends CustomerReviewAverage implements IB2BCustomerReviewAverage {
  constructor(public customer?: string, public productCustomerReviews?: ProductCustomerReview[]) {
    super();
  }
}
