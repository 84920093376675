import { environment } from '../../environments/environment';
import { Injectable } from '@angular/core';
import { GenericService } from './GenericService';
import { Observable } from 'rxjs';
import { IMedia } from '../models/media.model';

@Injectable({ providedIn: 'root' })
export class MediaService extends GenericService {
  constructor() {
    super();
  }

  downloadMedia(mediaId: string, format: string | null): any {
    return environment.apiUrl + 'api/export-media/' + mediaId + ' /' + format;
  }

  getBeneficiaryFiles(beneficiaryId: string): Observable<IMedia[]> {
    return this.api.get('api/retrieve-media-for-beneficiary/' + beneficiaryId);
  }
}
