import { Component, Input, OnInit } from '@angular/core';
import { UserBannerService } from '../../services/user-banner.service';

@Component({
  selector: 'app-hro-user-banner',
  templateUrl: './hro-user-banner.component.html',
  styleUrls: ['./hro-user-banner.component.scss'],
})
export class HroUserBannerComponent implements OnInit {
  banner: any;
  @Input() sellerId: string;

  constructor(private userBannerService: UserBannerService) {}

  ngOnInit(): void {
    this.getAdvertisingContentBySellerId();
  }
  getImage(image: any, format: string | null): any {
    if (!image) {
      return;
    }
    return this.userBannerService.downloadMedia(image, format);
  }

  getAdvertisingContentBySellerId() {
    this.userBannerService.getAdvertisingContentBySellerId(this.sellerId).subscribe(value => {
      this.banner = value;
    });
  }
}
