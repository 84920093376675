/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
import { createReducer, on } from '@ngrx/store';
import * as ContextActions from '../actions/context.actions';

export const initialState = '';

const contextReducer = createReducer(
  initialState,
  on(ContextActions.setContext, (state, action) => action.context)
);

export function ContextReducer(state: any, action: any) {
  return contextReducer(state, action);
}
