import { Component, OnInit } from '@angular/core';
import { AccountService } from '../../../services/auth/account.service';
import { LocalStorageService } from 'ngx-webstorage';
import { FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { HttpErrorResponse } from '@angular/common/http';
import { LANGUAGE } from '../../../shared/constants';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent implements OnInit {
  emailFormControl = new FormControl('', [Validators.email, Validators.pattern('[^@\\s]+@[^@\\s]+\\.[^@\\s]+')]);
  currentLocale: string;
  hasSubmitted = false;

  constructor(
    private authService: AccountService,
    private localStorage: LocalStorageService,
    private router: Router,
    private translate: TranslateService,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {}

  forgetPassword() {
    this.hasSubmitted = true;
    if (!this.emailFormControl.valid) {
      return;
    }
    this.authService.forgetPassword(this.localStorage.retrieve(LANGUAGE), this.emailFormControl.value).subscribe(
      () => {
        this.toastr.success(this.translate.instant('FORGET_PASSWORD_EMAIL_SENT_TOAST'));
        this.router.navigateByUrl('/login');
      },
      (error: HttpErrorResponse) => {
        if (error.status === 404) {
          this.toastr.error(this.translate.instant('FORGET_PASSWORD_EMAIL_NOT_FOUND_TOAST'));
        } else {
          this.toastr.error(this.translate.instant('GENERIC_ERROR_TOAST'));
        }
      }
    );
  }
}
