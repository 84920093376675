import { IAction } from './Action.model';

export interface IBeneficiaryDevelopmentAxis {
  id?: string;
  name?: string;
  startDate?: any;
  deadLine?: any;
  predefinedDevelopmentAxisId?: string;
  actions?: IAction[];
  progress?: number;
  createdDate?: Date;
}

export class BeneficiaryDevelopmentAxis implements IBeneficiaryDevelopmentAxis {
  constructor(
    public id?: string,
    public name?: string,
    public startDate?: any,
    public deadLine?: any,
    public predefinedDevelopmentAxisId?: string,
    public actions?: IAction[],
    public progress?: number,
    public createdDate?: Date
  ) {
    actions = actions || [];
  }
}
