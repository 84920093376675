import { ProductType } from './product-basic.model';
import { ISeoData } from '../shared/models/seo-data.model';

export interface ICartLine {
  lineNumber?: number;
  basePrice?: number;
  memberUnitPrice?: number;
  publicUnitPrice?: number;
  totalPrice?: number;
  quantity?: number;
  currencyId?: string;
  productId?: string;
  productName?: string;
  productMediaId?: string;
  unitId?: string;
  sellerId?: string;
  productType?: ProductType;
  _class?: string;
  seoData?: ISeoData;
  discount?: number;
}

export class CartLine implements ICartLine {
  constructor(
    public lineNumber?: number,
    public basePrice?: number,
    public totalPrice?: number,
    public quantity?: number,
    public currencyId?: string,
    public productId?: string,
    public unitId?: string,
    public productName?: string,
    public productMediaId?: string,
    public memberUnitPrice?: number,
    public publicUnitPrice?: number,
    public sellerId?: string,
    public productType?: ProductType,
    public _class?: string,
    public seoData?: ISeoData,
    public discount?: number
  ) {
    this._class = 'CartLineDTO';
  }
}
