<div class="page-title-area item-bg1">
  <div class="container">
    <div class="page-title-content">
      <ul>
        <li>
          <a href="index.html"> {{ 'HOME' | translate }} </a>
        </li>
        <li>{{ 'PAYMENT' | translate }}</li>
      </ul>
      <h2>{{ 'PAYMENT' | translate }}</h2>
    </div>
  </div>
</div>
<div class="checkout-area ptb-100">
  <div class="container" style="text-align: center">
    <div class="not-found">
      <div class="not-found__404" style="color: #525d66">
        {{ 'PAYMENT_SUCCESS' | translate }}
      </div>

      <div class="not-found__content">
        <h2 class="not-found__title">
          {{ 'THANK_YOU_FOR_ORDER' | translate }}
        </h2>

        <p class="not-found__text">
          {{ 'MSG_INFO' | translate }} <strong>+216 53 35 56 77</strong>.
          <br />
        </p>

        <p class="not-found__text"></p>
      </div>
    </div>
    <div style="height: 100%; margin: auto" class="col-12 col-lg-6 col-xl-6 mt-4 mt-lg-0">
      <div class="card mb-0">
        <div class="card-body">
          <h4 class="card-title">{{ 'BILLING_DETAILS' | translate }}</h4>

          <table class="checkout__totals">
            <thead class="checkout__totals-header">
              <tr style="text-align: initial">
                <th>{{ 'PAYMENT INFO' | translate }}</th>
                <th></th>
              </tr>
            </thead>

            <tbody class="checkout__totals-products" style="text-align: initial">
              <tr>
                <td>{{ 'ORDER CODE' | translate }} :</td>
                <td>{{ order.code }}</td>
              </tr>

              <tr>
                <td>{{ 'ORDER DATE' | translate }} :</td>
                <td>{{ order.createdDate | date : 'dd/MM/yyyy HH:mm:ss ' }}</td>
              </tr>
              <tr>
                <td>{{ 'TOTAL' | translate }} :</td>
                <td>{{ order.total + 'TND' }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
