import { IMedia } from './media.model';

export interface IProductBasic {
  id?: string;
  code?: string;
  media?: IMedia;
  availableQuantity?: number;
  price?: number;
  label?: string;
  brandName?: string;
  brandLogo?: string;
  isOnline?: boolean;
  productType?: ProductType;
  sellerId?: string;
}

export class ProductBasicModel implements IProductBasic {
  constructor(
    public id?: string,
    public code?: string,
    public media?: IMedia,
    public availableQuantity?: number,
    public price?: number,
    public label?: string,
    public brandLogo?: string,
    public isOnline?: boolean,
    public productType?: ProductType
  ) {}
}
export enum ProductType {
  BUNDLE = 'BUNDLE',
  PACK = 'PACK',
  PRODUCT = 'PRODUCT',
}
