import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IOrder } from '../models/order.model';
import { GenericService } from './GenericService';

@Injectable({
  providedIn: 'root',
})
export class OrderService extends GenericService {
  constructor() {
    super();
  }

  checkout(cartId) {
    this.createOrder(cartId).subscribe(order => {});
  }

  createOrder(orderObject: any): Observable<IOrder> {
    return this.api.post('api/passexcellence/orders1', orderObject);
  }
}
