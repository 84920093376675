import { createRequestOption } from '../services/utils/request-util';
import { convertResponse } from '../services/utils/response-converter';
import { Injectable, OnDestroy } from '@angular/core';
import { GenericService } from '../services/GenericService';
import { HttpClient } from '@angular/common/http';
import { ResponseWrapper } from '../models/response-wrapper.model';
import { map, takeUntil } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { environment } from '../../environments/environment';
import { IBlog } from '../shared/models/blog.model';
import { Store } from '@ngrx/store';
import { AppState } from '../store/app.reducer';
import { LOCALE_ID, LOCALE_STATE } from '../shared/constants';
import { CurrentLocaleService } from '../services/current-locale.service';

@Injectable({
  providedIn: 'root',
})
export class BlogService extends GenericService implements OnDestroy {
  private destroyed$ = new Subject();
  private currentLocaleId: string;

  constructor(private http: HttpClient, private store: Store<AppState>, private currentLocaleService: CurrentLocaleService) {
    super();
    this.currentLocaleId = currentLocaleService.getCurrentLocale();
    currentLocaleService.currentLocaleId$.pipe(takeUntil(this.destroyed$)).subscribe(localeId => (this.currentLocaleId = localeId));
  }

  create(blog: IBlog): Observable<IBlog> {
    return this.api.post('api/blogs/', blog);
  }

  update(blog: IBlog): Observable<IBlog> {
    return this.api.put('api/blogs/', blog);
  }

  findBlogById(id: string): Observable<IBlog> {
    return this.api.get('api/blogs/' + id);
  }

  findBlogPostByIdAndLocale(blogPostId: string): Observable<any> {
    return this.api.get('api/blog-posts-locale/' + blogPostId + '/' + this.currentLocaleId);
  }

  findBlogPostBySeoNameAndLocale(blogPostSeoName: string): Observable<any> {
    return this.api.get('api/blog-post-by-seo-name/' + blogPostSeoName + '/' + this.currentLocaleId);
  }

  findAllBlogsWithPagination(req?: any): Observable<ResponseWrapper> {
    const options = createRequestOption(req);
    return this.api.get('api/blogs', options).pipe(map(res => convertResponse(res)));
  }

  findAllBlogPostsWithPagination(blogId: string, req?: any): Observable<ResponseWrapper> {
    const options = createRequestOption(req);
    return this.api
      .get('api/blog-posts-list-with-pagination-locale/' + blogId + '/' + this.currentLocaleId, options)
      .pipe(map(res => convertResponse(res)));
  }

  saveImage(file: File, id: string, prodcutId: string): Observable<any> {
    const formdata: FormData = new FormData();
    formdata.append('file', file);
    return this.api.post('api/upload-media-for-blog/' + id + '/' + prodcutId, formdata);
  }

  downloadMedia(mediaId: string, format: string): any {
    return environment.apiUrl + 'api/export-media/' + mediaId + ' /' + format;
  }

  findAllBlogPostCommentssWithPagination(blogPostId: string, req?: any): Observable<ResponseWrapper> {
    const options = createRequestOption(req);
    return this.api.get('api/blog-comments-by-blog-post/' + blogPostId, options).pipe(map(res => convertResponse(res)));
  }

  ngOnDestroy() {
    this.destroyed$.next();
  }
}
