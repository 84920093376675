import { ISocialMediaNetwork } from './social-media-network.model';

export interface ISocialNetworkAccount {
  socialMediaNetwork?: ISocialMediaNetwork;
  link?: string;
  id?: string;
}

export class SocialNetworkAccount implements ISocialNetworkAccount {
  constructor(public socialMediaNetwork?: ISocialMediaNetwork, public link?: string, public id?: string) {}
}
