/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { Component, Inject, Input, PLATFORM_ID } from '@angular/core';
// import * as DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-read-wysiwyg',
  templateUrl: './read-wysiwyg.component.html',
  styleUrls: ['./read-wysiwyg.component.scss'],
})
export class ReadWysiwygComponent {
  public editor;
  public config = {
    readonly: true,
    language: 'fr',
    allowedContent: true,
    mediaEmbed: {
      previewsInData: true,
    },
  };

  isOnBrowser = false;

  @Input() value;

  constructor(@Inject(PLATFORM_ID) private platformId: any) {
    if (isPlatformBrowser(platformId)) {
      this.editor = require('@ckeditor/ckeditor5-build-decoupled-document');
      this.isOnBrowser = true;
    }
  }
}
