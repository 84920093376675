/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { BENEFICIARY_GROUP, COACH_GROUP, DRH_GROUP, SERVICE_PROVIDER_GROUP } from 'src/app/shared/constants';
import { Router } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class UserAccountService {
  public userIdentity: any;
  private authenticated = null;
  public authenticationState = new BehaviorSubject<any>(null);
  canPurchase = new BehaviorSubject(true);

  constructor(private http: HttpClient, private router: Router) {}

  fetch(): Observable<HttpResponse<any>> {
    return this.http.get<any>(environment.apiUrl + 'api/account', {
      observe: 'response',
    });
  }
  getCurrentUser(): Observable<any> {
    return this.http.get<any>(environment.apiUrl + 'api/account');
  }

  save(account: any): Observable<HttpResponse<any>> {
    return this.http.post(environment.apiUrl + 'api/account', account, {
      observe: 'response',
    });
  }

  authenticate(identity: any) {
    this.userIdentity = identity;
    this.authenticated = identity !== null;
    this.authenticationState.next(this.userIdentity);
  }

  identity(force?: boolean): Promise<any> {
    if (force) {
      this.userIdentity = undefined;
    }
    this.authenticated = true;
    if (this.userIdentity) {
      return Promise.resolve(this.userIdentity);
    }

    return this.fetch()
      .toPromise()
      .then(response => {
        const account = response.body;
        if (account) {
          this.userIdentity = account;
          this.authenticated = true;
        } else {
          this.userIdentity = null;
          this.authenticated = false;
        }
        this.authenticationState.next(this.userIdentity);
        return this.userIdentity;
      })
      .catch(err => {
        this.userIdentity = null;
        this.authenticated = false;
        this.authenticationState.next(this.userIdentity);
        return null;
      });
  }

  isAuthenticated(): boolean {
    return this.authenticated;
  }

  getImage(employeeId: string): Observable<any> {
    return this.http.get(environment.apiUrl + 'api/UserLogo/' + employeeId, {
      responseType: 'text',
    });
  }

  hasAnyAuthority(authorities: string[]): Promise<boolean> {
    return Promise.resolve(this.hasAnyAuthorityDirect(authorities));
  }

  hasAnyAuthorityDirect(authorities: string[]): boolean {
    if (!this.authenticated || !this.userIdentity || !this.userIdentity.userGroups) {
      return false;
    }
    const names = [];
    this.userIdentity.userGroups.forEach(group => names.push(group.code));
    for (let i = 0; i < authorities.length; i++) {
      if (names.indexOf(authorities[i]) != -1) {
        return true;
      }
    }
    return false;
  }

  setCanPurchase() {
    if (this.userIdentity) {
      if (this.hasAnyAuthorityDirect([DRH_GROUP, BENEFICIARY_GROUP])) {
        this.canPurchase.next(true);
      } else {
        this.canPurchase.next(false);
      }
    } else {
      this.canPurchase.next(true);
    }
  }

  redirectToSpace() {
    if (this.hasAnyAuthorityDirect([DRH_GROUP])) {
      this.router.navigate(['drh-space']);
    } else if (this.hasAnyAuthorityDirect([COACH_GROUP])) {
      this.router.navigate(['coach-space']);
    } else if (this.hasAnyAuthorityDirect([SERVICE_PROVIDER_GROUP])) {
      this.router.navigate(['service-provider-space']);
    } else {
      this.router.navigate(['beneficiary-space']);
    }
  }

  acceptTermsOfUse(userId) {
    return this.http.put(environment.apiUrl + 'api/accept-terms-of-use/' + userId, {});
  }

  generateQRCode(userLogin: string): Observable<string> {
    return this.http.post(environment.apiUrl + 'api/generateQRCodeForBeneficiary', userLogin, { responseType: 'text' });
  }

  getBeneficiaryProfilByCode(code: string) {
    return this.http.get(environment.apiUrl + 'api/getBeneficiaryProfilByCode/' + code);
  }
}
