import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as CountryActions from '../actions/country.actions';
import { catchError, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { CountryService } from '../../services/country.service';
import { Store } from '@ngrx/store';
import { AppState } from '../app.reducer';
import { EMPTY } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class CountryEffects {
  setCountries$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CountryActions.setCountriesEffect),
      withLatestFrom(this.store.select('countryState', 'countries')),
      mergeMap(([action, countries]) => {
        if (null !== countries && countries !== []) {
          return EMPTY;
        }
        return this.countryService.getAllCountries().pipe(
          map(res => CountryActions.setCountries({ countries: res })),
          catchError(error => {
            this.toastr.error(this.translate.instant('GENERIC_ERROR_MSG'));
            throw error;
          })
        );
      })
    )
  );

  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private countryService: CountryService,
    private toastr: ToastrService,
    private translate: TranslateService
  ) {}
}
