import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { LocalStorageService, SessionStorageService } from 'ngx-webstorage';
import { environment } from '../../../environments/environment';
import { SocialUser } from 'angularx-social-login';
import { IUserFacebook } from '../../models/user-facebook';
import { GenericService } from '../GenericService';
import { AppState } from '../../store/app.reducer';
import { setBeneficiary } from '../../store/actions/beneficiary.actions';
import { Store } from '@ngrx/store';

@Injectable({ providedIn: 'root' })
export class AuthServerProvider extends GenericService {
  constructor(
    private http: HttpClient,
    private $sessionStorage: LocalStorageService,
    private store: Store<AppState>,
    private localStorageService: LocalStorageService
  ) {
    super();
  }

  // getToken() {
  //   return localStorage.getItem('authenticationToken') || sessionStorage.getItem('authenticationToken');
  // }

  login(credentials: any): Observable<any> {
    const data = {
      username: credentials.username,
      password: credentials.password,
      rememberMe: credentials.rememberMe,
      fcmToken: credentials.fcmToken,
    };
    return this.http
      .post(environment.apiUrl + 'api/authenticate/front-office', data, {
        observe: 'response',
      })
      .pipe(map(authenticateSuccess.bind(this)));

    function authenticateSuccess(resp: any) {
      const bearerToken = resp.headers.get('Authorization');
      if (bearerToken && bearerToken.slice(0, 7) === 'Bearer ') {
        const jwt = bearerToken.slice(7, bearerToken.length);
        this.storeAuthenticationToken(jwt, credentials.rememberMe);
        return jwt;
      }
    }
  }

  loginWithToken(jwt: any, rememberMe: any) {
    if (jwt) {
      this.storeAuthenticationToken(jwt, rememberMe);
      return Promise.resolve(jwt);
    } else {
      return Promise.reject('auth-jwt-service Promise reject'); // Put appropriate error message here
    }
  }

  storeAuthenticationToken(jwt: any, rememberMe: any) {
   
      localStorage.setItem('authenticationToken', jwt);
    
  }

  logout(): Observable<any> {
    return new Observable(observer => {
      //this.$sessionStorage.clear();
      localStorage.removeItem('authenticationToken');
      sessionStorage.removeItem('authenticationToken');
      localStorage.clear();
      sessionStorage.clear();
      this.localStorageService.clear();
      observer.complete();
      this.store.dispatch(setBeneficiary({ beneficiary: null }));
     // this.appState.resetState();
    });
  }

  facebook(userFB: IUserFacebook): Observable<any> {
    return this.http
      .post(environment.apiUrl + 'api/registerOrAuthenticate/facebook', userFB, { observe: 'response' })
      .pipe(map(authenticateSuccess.bind(this)));

    function authenticateSuccess(resp) {
      const bearerToken = resp.headers.get('Authorization');
      if (bearerToken && bearerToken.slice(0, 7) === 'Bearer ') {
        const jwt = bearerToken.slice(7, bearerToken.length);
        this.storeAuthenticationToken(jwt, false);
        return jwt;
      }
    }
  }
  google(user: SocialUser): Observable<any> {
    const data = {
      firstName: user.firstName,
      lastName: user.lastName,
      id: user.id,
      authToken: user.authToken,
      email: user.email,
      authorizationCode: user.authorizationCode,
      photoUrl: user.photoUrl,
      name: user.name,
      provider: user.provider,
    };
    return this.http
      .post(environment.apiUrl + 'api/authenticateOrRegister/google', data, {
        observe: 'response',
      })
      .pipe(map(authenticateSuccess.bind(this)));
    function authenticateSuccess(resp) {
      const bearerToken = resp.headers.get('Authorization');
      if (bearerToken && bearerToken.slice(0, 7) === 'Bearer ') {
        const jwt = bearerToken.slice(7, bearerToken.length);
        this.storeAuthenticationToken(jwt, false);
        return jwt;
      }
    }
  }
  checkIfUserPresent(id: string): Observable<boolean> {
    return this.api.get('api/check-user-fb/' + id);
  }
}
