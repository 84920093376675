<div class="single-events-box mb-30">
  <div class="row">
    <div class="col-12 col-md-3" style="padding: 50px 10px 50px 10px">
      <div style="text-align: center">
        <img
          style="max-width: 150px; max-height: 150px"
          [src]="getImage(corporateSeller?.logo?.id, null)"
          [alt]="corporateSeller?.logo?.alt ?? corporateSeller?.name"
          onerror="this.src='assets/img/not-found.png'" />
      </div>
    </div>
    <div class="col-12 col-md-9">
      <div class="content" style="text-align: center">
        <h3 class="pt-2">
          <a
            [routerLink]="
              corporateSellerDetailsUrlPattern
                ? (corporateSellerDetailsUrlPattern | seoNameUrl : corporateSeller.seoData : currentLocaleId)
                : null
            "
            >{{ corporateSeller?.name }}</a
          >
        </h3>
        <hr style="width: 100px; color: #bc5b68; margin: auto; height: 2px" />
        <br />
        <a
          class="btn-details default-primary-btn"
          [routerLink]="
            corporateSellerDetailsUrlPattern
              ? (corporateSellerDetailsUrlPattern | seoNameUrl : corporateSeller.seoData : currentLocaleId)
              : null
          "
          >{{ 'MORE__DETAILS' | translate }}</a
        >
      </div>
    </div>
  </div>
</div>
