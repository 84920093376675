import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CorporateSellerBlockComponent } from './corporate-seller-block/corporate-seller-block.component';
import { CorporateSellerListComponent } from './corporate-seller-list/corporate-seller-list.component';
import { CorporateSellerDetailsComponent } from './corporate-seller-details/corporate-seller-details.component';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '../shared/shared.module';
import { RouterModule } from '@angular/router';
import { CorporateSellerRoutes } from './corporate-seller.routing';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@NgModule({
  declarations: [CorporateSellerBlockComponent, CorporateSellerDetailsComponent, CorporateSellerListComponent],
  imports: [
    CommonModule,
    TranslateModule,
    SharedModule,
    RouterModule.forChild(CorporateSellerRoutes),
    MatPaginatorModule,
    MatProgressSpinnerModule,
  ],
  // exports: [
  //     CorporateSellerBlockComponent,
  //     CorporateSellerDetailsComponent,
  //     CorporateSellerListComponent
  // ]
})
export class CorporateSellerModule {}
