<div class="page-title-area item-bg3">
  <div class="container">
    <div class="page-title-content">
      <ul>
        <li>
          <a href="index.html">{{ 'HOME' | translate }}</a>
        </li>
        <li>{{ 'BLOG' | translate }}</li>
      </ul>
      <h2>{{ 'BLOG' | translate }}</h2>
    </div>
  </div>
</div>
<div class="blog-area ptb-100">
  <div class="container">
    <div class="row">
      <div *ngFor="let post of blogsDTOList" class="col-lg-4 col-md-6">
        <div class="single-blog-post mb-30">
          <div class="post-image">
            <a
              [routerLink]="blogDetailsUrlPattern ? (blogDetailsUrlPattern | seoNameUrl : post.seoData : currentLocaleId) : null"
              class="d-block text-center text-sm-start">
              <img
                [src]="getImage(post.thumbnail?.id, null)"
                style="width: 320px; height: 260px; max-width: 100%"
                onerror="this.src='assets/img/not-found.png'"
                [alt]="post.thumbnail?.alt ?? post?.title" />
            </a>
            <!-- <div class="tag">
                            <a routerLink="/blog-style-1">Learning</a>
                        </div>-->
          </div>
          <div class="post-content">
            <!--<ul class="post-meta">
                            <li class="post-author">
                                <img [src]="getImage(post.thumbnail?.id, null)"
                                     class="d-inline-block rounded-circle mr-2"
                                     onerror="this.src='assets/img/default_image.webp'" alt="image">
                                By: <a routerLink="/blog-style-1" class="d-inline-block">Steven Smith</a>
                            </li>
                            <li><a routerLink="/blog-style-1">August 30, 2019</a></li>
                        </ul>-->
            <h3>
              <a
                [routerLink]="blogDetailsUrlPattern ? (blogDetailsUrlPattern | seoNameUrl : post.seoData : currentLocaleId) : null"
                class="d-inline-block"
                >{{ post.title }}</a
              >
            </h3>
            <a
              [routerLink]="blogDetailsUrlPattern ? (blogDetailsUrlPattern | seoNameUrl : post.seoData : currentLocaleId) : null"
              class="read-more-btn"
              >{{ 'READ_MORE' | translate }} <i class="bx bx-right-arrow-alt"></i
            ></a>
          </div>
        </div>
      </div>
      <div class="col-lg-12 col-md-12 col-sm-12">
        <div class="pagination-area text-center">
          <mat-paginator (page)="paginate($event)" [length]="totalItems" [pageSize]="size" [pageIndex]="pageIndex"></mat-paginator>
        </div>
      </div>
    </div>
  </div>
</div>
