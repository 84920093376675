import { Component, Inject, Input, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';

import { AppStateService } from '../../services/utils/state.service';
import { PageEvent } from '@angular/material/paginator';
import { IProductDTO } from '../../shared/models/productDTO';
import { ReplaySubject, Subscription } from 'rxjs';
import { CategoryService } from 'src/app/services/category.service';
import { ProductService } from '../../services/product.service';
import { Store } from '@ngrx/store';
import { AppState } from '../../store/app.reducer';
import { TranslateService } from '@ngx-translate/core';
import { LOCALE_STATE, LOCALES, PACKS_FAMILY, BUNDLES_FAMILY, PRODUCT_FAMILY_SERVICE } from '../../shared/constants';
import { take, takeUntil } from 'rxjs/operators';
import { PACK_DETAILS_COMPONENT_NAME, PRODUCT_DETAILS_COMPONENT_NAME } from '../../ComponentsIndex';
import { DynamicPathStorageService } from '../../services/dynamic-path-storage.service';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-product-list',
  templateUrl: './product-list.component.html',
  styleUrls: ['./product-list.component.scss'],
})
export class ProductListComponent implements OnInit, OnDestroy {
  @Input()
  onlyProducts: boolean;
  @Input()
  onlyPacks: boolean;
  @Input()
  onlyBundles: boolean;
  products: IProductDTO[] = [];
  currentLocale: string;
  productFamily: string = PRODUCT_FAMILY_SERVICE;
  totalItems: string;
  page = 0;
  size = 6;
  publicPriceSort = {
    id: 'public_price_asc',
    type: 'nested',
    label: 'PUBLIC_PRICE_ASC',
    sortDirection: 'asc',
    path: 'priceAttributes',
    field: 'priceAttributes.pricesPerCurrencies.amount',
    conditions: {
      'priceAttributes.productAttribute.id.keyword': 'PUBLIC_PRICE',
    },
  };
  filterObject = {
    categoryId: '',
    className: 'com.heero.pcm.domain.product.Product',
    searchValue: '',
    sortedField: '',
    queries: {
      online: true,
    },
    fields: [],
    sort: this.publicPriceSort,
    nestedQueries: [],
    nestedFields: [
      // {
      //     path: 'textAttributes', field: 'textAttributes.value.keyword',
      //     conditions: {'textAttributes.productAttribute.id.keyword': 'NAME'}
      // }
    ],
    onlyProducts: true,
    onlyPacks: false,
    onlyBundles: false,
    aggregations: [],
  };

  sortChoices = [
    {
      id: 'name_asc',
      label: 'NAME_ASC',
      type: 'nested',
      sortDirection: 'asc',
      path: 'textAttributes',
      field: 'textAttributes.value.keyword',
      conditions: { 'textAttributes.productAttribute.id.keyword': 'NAME' },
    },
    {
      id: 'name_desc',
      label: 'NAME_DESC',
      type: 'nested',
      sortDirection: 'desc',
      path: 'textAttributes',
      field: 'textAttributes.value.keyword',
      conditions: { 'textAttributes.productAttribute.id.keyword': 'NAME' },
    },
    // this.publicPriceSort,
    // {
    //     id: 'public_price_desc',
    //     label: 'PUBLIC_PRICE_DESC',
    //     type: 'nested',
    //     sortDirection: 'desc',
    //     path: 'priceAttributes',
    //     field: 'priceAttributes.pricesPerCurrencies.amount',
    //     conditions: {'priceAttributes.productAttribute.id.keyword': 'PUBLIC_PRICE'}
    //
    // },
    // {
    //     id: 'member_price_asc',
    //     label: 'MEMBER_PRICE_ASC',
    //     type: 'nested',
    //     sortDirection: 'asc',
    //     path: 'priceAttributes',
    //     field: 'priceAttributes.pricesPerCurrencies.amount',
    //     conditions: {'priceAttributes.productAttribute.id.keyword': 'MEMBER_PRICE'}
    //
    // },
    // {
    //     id: 'member_price_desc',
    //     label: 'MEMBER_PRICE_DESC',
    //     type: 'nested',
    //     sortDirection: 'desc',
    //     path: 'priceAttributes',
    //     field: 'priceAttributes.pricesPerCurrencies.amount',
    //     conditions: {'priceAttributes.productAttribute.id.keyword': 'MEMBER_PRICE'}
    //
    // }
  ];
  selectedSortField = null;
  categoryId: string;
  currencyId: string;
  subscription: Subscription = new Subscription();
  // @ViewChild('scrollMe') private myScrollContainer: ElementRef;
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  productDetailsPageUrlPattern: string;
  packDetailsPageUrlPattern: string;
  readonly PRODUCT_PRODUCT_TYPE = 'PRODUCT';
  isFetchingProducts = true;

  constructor(
    private productService: ProductService,
    private appStateService: AppStateService,
    private categoryService: CategoryService,
    private store: Store<AppState>,
    public translate: TranslateService,
    private dynamicPathStorageService: DynamicPathStorageService,
    @Inject(PLATFORM_ID) private platformId
  ) {
    this.currentLocale = appStateService.getCurrentLocaleId();
  }

  ngOnInit(): void {
    this.store.select(LOCALE_STATE, LOCALES).subscribe(() => {
      this.currencyId = this.appStateService.getCurrentCurrency();
      this.currentLocale = this.appStateService.getCurrentLocaleId();
    });
    this.filterObject.onlyProducts = this.onlyProducts;
    this.filterObject.onlyPacks = this.onlyPacks;
    this.filterObject.onlyBundles = this.onlyBundles;
    this.filterObject.nestedFields = [
      {
        path: 'textAttributes',
        field: 'textAttributes.value.keyword',
        conditions: {
          'textAttributes.productAttribute.id.keyword': 'NAME',
          'textAttributes.context.id.keyword': this.currentLocale,
        },
      },
    ];
    if (!this.onlyPacks && !this.onlyBundles) {
      this.filterObject.queries['_class.keyword'] = 'com.heero.pcm.domain.product.Product';
    } else if (this.onlyPacks) {
      this.filterObject.queries['_class.keyword'] = 'com.heero.pcm.domain.product.Pack';
      this.productFamily = PACKS_FAMILY;
    } else if (this.onlyBundles) {
      this.filterObject.queries['_class.keyword'] = 'com.heero.pcm.domain.bundle.Bundle';
      this.productFamily = BUNDLES_FAMILY;
    }
    // this.filterProducts();
    this.categoryService.sharedCategoryId.subscribe(value => {
      this.categoryId = value;
      this.page = 0;
      this.scrollToMe();
      this.filterProducts();
    });

    this.dynamicPathStorageService.urlPatterns$.pipe(takeUntil(this.destroyed$)).subscribe(() => {
      this.productDetailsPageUrlPattern =
        this.dynamicPathStorageService.getUrlPatternByComponentNameFromCmsPageList(PRODUCT_DETAILS_COMPONENT_NAME);
      this.packDetailsPageUrlPattern =
        this.dynamicPathStorageService.getUrlPatternByComponentNameFromCmsPageList(PACK_DETAILS_COMPONENT_NAME);
    });
  }

  filterProducts() {
    this.products = [];
    this.categoryId ? (this.filterObject.queries['categories.id'] = this.categoryId) : null;
    this.filterObject.fields = ['categories.label.localizedValues.' + this.currentLocale];
    this.isFetchingProducts = true;
    this.subscription = this.productService
      .searchAllProducts(this.filterObject, this.currencyId, {
        page: this.page,
        size: this.size,
      })
      .pipe(take(1))
      .subscribe(
        value => {
          this.products = value.json;
          this.totalItems = value.headers.get('X-Total-Count');
          this.isFetchingProducts = false;
        },
        () => {
          this.isFetchingProducts = false;
        }
      );
  }

  scrollToMe() {
    if (isPlatformBrowser(this.platformId)) {
      setTimeout(() => {
        const element = document.getElementById('scroll-to-me');
        element?.scrollIntoView({ behavior: 'smooth', inline: 'end' });
        // this.myScrollContainer.nativeElement.scrollIntoView(true);
        // const element = document.getElementById('scroll-to-me');
        // const y = element.getBoundingClientRect().top + window.pageYOffset + 10;
        // window.scrollTo({top: y, behavior: 'smooth'});
      });
    }
  }

  handlePageEvent($event: PageEvent) {
    this.page = $event.pageIndex;
    this.filterProducts();
  }

  sortProduct($event) {
    this.filterObject.sort = $event;
    this.filterProducts();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  resetFilter() {
    this.filterObject = {
      categoryId: '',
      className: 'com.heero.pcm.domain.product.Product',
      searchValue: '',
      sortedField: '',
      queries: {
        online: true,
      },
      fields: [],
      sort: this.publicPriceSort,
      nestedQueries: [],
      nestedFields: [
        {
          path: 'textAttributes',
          field: 'textAttributes.value.keyword',
          conditions: { 'textAttributes.productAttribute.id.keyword': 'NAME' },
        },
      ],
      onlyProducts: false,
      onlyPacks: false,
      onlyBundles: false,
      aggregations: [],
    };
    this.filterProducts();
  }
}
