/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { createReducer, on } from '@ngrx/store';
import * as CountryActions from '../actions/country.actions';
import { ICountry } from '../../models/country.model';

export interface CountryState {
  countries: ICountry[];
}

export const initialState: CountryState = {
  countries: null,
};

const countryReducer = createReducer(
  initialState,
  on(CountryActions.setCountries, (state, action) => ({
    ...state,
    countries: action.countries,
  }))
);

export function CountryReducer(state: any, action: any) {
  return countryReducer(state, action);
}
