import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, mergeMap, catchError } from 'rxjs/operators';
import { LocaleService } from '../../services/locale.service';
import * as LocaleActions from '../actions/locale.actions';
import { Store } from '@ngrx/store';
import { AppState } from '../app.reducer';
import { EMPTY } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { LOCALE_STATE, LOCALES } from '../../shared/constants';

@Injectable()
export class LocaleEffects {
  setLocales$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LocaleActions.setLocalesEffect),
      mergeMap(action => {
        if (!action.force) {
          this.store$.select(LOCALE_STATE, LOCALES).subscribe(locales => {
            if (!locales?.length) {
              return this.fetchLocales(action.force);
            } else {
              return EMPTY;
            }
          });
        } else {
          return this.fetchLocales(action.force);
        }
      })
    )
  );

  constructor(
    private actions$: Actions,
    private store$: Store<AppState>,
    private localeService: LocaleService,
    private toastr: ToastrService,
    private translate: TranslateService
  ) {}

  private fetchLocales(force: boolean) {
    return this.localeService.findAll(force).pipe(
      map(locales => {
        return LocaleActions.setLocales({ locales });
      }),
      catchError(error => {
        this.toastr.error(this.translate.instant('GENERIC_ERROR_MSG'));
        throw error;
      })
    );
  }
}
