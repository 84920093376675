/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { throwError as observableThrowError, Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { catchError } from 'rxjs/operators';

@Injectable()
export class APIService {
  constructor(private http: HttpClient) {}

  get(url: string, option?): Observable<any> {
    return this.http.get(environment.apiUrl + url, option).pipe(catchError(this.handleError));
  }

  post(url: string, body: any = {}, option?): Observable<any> {
    return this.http.post(environment.apiUrl + url, body, option);
  }

  put(url: string, body: any = {}, option?): Observable<any> {
    return this.http.put(environment.apiUrl + url, body, option).pipe(catchError(this.handleError));
  }

  delete(url: string, option?: any): Observable<any> {
    return this.http.delete(environment.apiUrl + url, option).pipe(catchError(this.handleError));
  }

  protected handleError(error: Response | any): any {
    let errMsg: string;
    if (error instanceof Response) {
      const body = error.json() || error || '';
      const err = JSON.stringify(body); //body.error ||
      errMsg = `${error.status} - ${error.statusText || ''} ${err}`;
    } else {
      errMsg = error?.error?.errorType; // || error.message ? error.message : error.toString();
    }
    return observableThrowError(errMsg);
  }
}
