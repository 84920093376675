import { Component, Inject, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import { AppStateService } from '../../services/utils/state.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Product } from '../../models/product.model';
import { CartService } from 'src/app/services/cart.service';
import { CartLine } from 'src/app/models/cartLine.model';
import { ProductService } from '../../services/product.service';
import { ProductCustomerReviewService } from 'src/app/services/product-customer-review.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { take, takeUntil } from 'rxjs/operators';
import { isPlatformBrowser } from '@angular/common';
import { B2BCustomerReviewAverage } from '../../shared/models/b-2-b-customer-review-average.model';
import { Store } from '@ngrx/store';
import { AppState } from '../../store/app.reducer';
import {
  BENEFICIARY_GROUP,
  COACH_GROUP,
  DEFAULT_CURRENCY_ID,
  DRH_GROUP,
  GENERIC_ERROR_MSG,
  PASSE_EXCELLENCE,
  SCREEN_SIZE,
  SELLER_ID_TO_DISPLAY,
  SERVICE_PROVIDER_GROUP,
} from '../../shared/constants';
import { UserAccountService } from 'src/app/services/auth/user-account.service';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { SEO, SeoService } from '../../services/seo.service';
import { DynamicPathRoutingService } from '../../services/dynamic-path-routing.service';
import { Subject } from 'rxjs';
import { CurrentLocaleService } from '../../services/current-locale.service';
import { SessionStorageService } from 'ngx-webstorage';
import { ResizeService } from '../../services/resize.service';

@Component({
  selector: 'app-product-details',
  templateUrl: './product-details.component.html',
  styleUrls: ['./product-details.component.scss'],
})
export class ProductDetailsComponent implements OnInit, OnDestroy {
  productImageSlides: OwlOptions = {
    loop: true,
    nav: false,
    dots: false,
    autoplayHoverPause: true,
    autoplay: true,
    margin: 30,
    navText: ["<i class='bx bx-left-arrow-alt'></i>", "<i class='bx bx-right-arrow-alt'></i>"],
    responsive: {
      0: {
        items: 1,
      },
    },
  };
  productSeoName: string;
  productId: string;
  currentLocale: string;
  product: Product;
  beneficiary;
  poductCustomerReviews = [];
  currencyId: string;
  b2BCustomerReviewAverage: B2BCustomerReviewAverage;
  displayPrice = false;
  canAddToCart = true;
  canBecomeMember = true;
  hasUniquePrice = false;
  private $destroyed = new Subject();
  authenticatedUser;
  isOnLargeScreen = false;

  constructor(
    private productService: ProductService,
    private appStateService: AppStateService,
    private activatedRoute: ActivatedRoute,
    private cartService: CartService,
    private reviewService: ProductCustomerReviewService,
    private toaster: ToastrService,
    private translation: TranslateService,
    private userAccountService: UserAccountService,
    private router: Router,
    private store: Store<AppState>,
    private seoService: SeoService,
    private dynamicPathRoutingService: DynamicPathRoutingService,
    private currentLocaleService: CurrentLocaleService,
    private sessionStorageService: SessionStorageService,
    @Inject(PLATFORM_ID) private platformId,
    private resizeService: ResizeService
  ) {
    this.currentLocale = this.currentLocaleService.getCurrentLocale();
  }

  ngOnInit(): void {
    this.currentLocaleService.currentLocaleId$.pipe(takeUntil(this.$destroyed)).subscribe(localeId => {
      this.currentLocale = localeId;
      if (this.product && this.product.seoData) {
        this.productSeoName = this.product.seoData.seoName.localizedValues[this.currentLocale];
      }
      if (this.product && this.currentLocale) {
        this.dynamicPathRoutingService.navigateToProductDetailsPage(this.productSeoName);
      }
    });
    if (isPlatformBrowser(this.platformId)) {
      this.authenticatedUser = this.sessionStorageService.retrieve('user');
      this.sessionStorageService
        .observe('user')
        .pipe(takeUntil(this.$destroyed))
        .subscribe(user => {
          this.authenticatedUser = user;
        });
    }
    this.currencyId = this.appStateService.getCurrentCurrency();
    this.b2BCustomerReviewAverage = new B2BCustomerReviewAverage();
    this.product = new Product();
    this.product.metaDescription = [];
    this.product.metaDescription.localizedValues = {};
    this.activatedRoute.params.pipe(takeUntil(this.$destroyed)).subscribe(params => {
      this.productSeoName = params.seoName;
      if (this.product && this.product.id) {
        this.currencyId = this.appStateService.getCurrentCurrency();
        this.getProduct();
      }
    });
    this.currencyId = this.appStateService.getCurrentCurrency();
    this.getProduct();
    this.checkCanAddToCart();

    this.store
      .select('beneficiaryState', 'beneficiary')
      .pipe(takeUntil(this.$destroyed))
      .subscribe(beneficiary => {
        this.beneficiary = beneficiary;
        if (!SELLER_ID_TO_DISPLAY.includes(this.product.sellerId)) {
          this.displayPrice = beneficiary?.member;
        }
      });
    this.resizeService.onResize$.pipe(takeUntil(this.$destroyed)).subscribe(screenSize => {
      if (screenSize === SCREEN_SIZE.LG || screenSize === SCREEN_SIZE.XL) {
        this.isOnLargeScreen = true;
      } else {
        this.isOnLargeScreen = false;
      }
    });
  }

  get isPassexcellenceProduct() {
    return this.product && this.product.sellerId == PASSE_EXCELLENCE;
  }

  get isAuthenticatedAsDRHOrBeneficiary() {
    return (
      this.authenticatedUser &&
      this.authenticatedUser.userGroups.some(userGroup => userGroup.id == BENEFICIARY_GROUP || userGroup.id == DRH_GROUP)
    );
  }

  setProductSeoData() {
    const seo: SEO = {};
    if (this.product.seoData) {
      seo.title = this.product.seoData.metaTitle?.localizedValues[this.currentLocale];
      seo.description = this.product.seoData.metaDescription?.localizedValues[this.currentLocale];
    }
    seo.imageUrl = this.product.medias
      ? this.product.medias[0]?.url
        ? this.product.medias[0].publicUrl
        : this.getImage(this.product.medias[0]?.id, 'PDP')
      : '';
    this.seoService.setSEO(seo);
  }

  getProduct() {
    const product$ = this.productService.getGenericProductBySeoName(this.productSeoName, this.currencyId ?? DEFAULT_CURRENCY_ID);
    product$.pipe(takeUntil(this.$destroyed)).subscribe(
      res => {
        this.product = res;
        this.checkCanAddToCart();
        this.setProductSeoData();
        this.productId = this.product.id;
        this.getReviews();
      },
      error => {
        if (isPlatformBrowser(this.platformId)) {
          this.toaster.error(this.translation.instant(error || GENERIC_ERROR_MSG));
          this.router.navigate(['/page-not-found']);
        }
      }
    );
  }

  checkCanAddToCart() {
    const user = this.appStateService.getUser();
    if (user && user.id) {
      const userGroups: string[] = user.userGroups.map(group => group.id);
      const isDRH = userGroups.includes(DRH_GROUP);
      const isCOACH = userGroups.includes(COACH_GROUP);
      const isServiceProvider = userGroups.includes(SERVICE_PROVIDER_GROUP);
      const isBeneficiary = userGroups.includes(BENEFICIARY_GROUP);
      if (SELLER_ID_TO_DISPLAY.includes(this.product.sellerId) && !(isCOACH || isServiceProvider)) {
        this.hasUniquePrice = true;
        this.displayPrice = true;
        return;
      }
      if (isDRH) {
        this.displayPrice = true;
        this.canAddToCart = true;
        this.canBecomeMember = false;
        return;
      }

      if (isCOACH || isServiceProvider) {
        this.displayPrice = this.hasUniquePrice;
        this.canAddToCart = false;
        this.canBecomeMember = false;
        return;
      }
      if (isBeneficiary) {
        this.displayPrice = this.hasUniquePrice || this.beneficiary?.member;
        this.canAddToCart = this.displayPrice;
        this.canBecomeMember = !this.beneficiary?.member;
        return;
      }
    } else {
      if (SELLER_ID_TO_DISPLAY.includes(this.product.sellerId)) {
        this.hasUniquePrice = true;
        this.displayPrice = true;
        return;
      }
      this.displayPrice = this.hasUniquePrice;
      this.canAddToCart = this.displayPrice;
      this.canBecomeMember = true;
    }
  }

  getImage(image: any, format: string | null): any {
    if (!image) {
      return;
    }
    return this.productService.downloadMedia(image, format);
  }

  counter(i) {
    return new Array(Math.floor(i));
  }

  addToCart() {
    const cartItem = new CartLine();
    cartItem.productId = this.product.id;
    cartItem.quantity = 1;
    cartItem.productName = this.product.label;
    cartItem.productMediaId =
      this.product.mediaPlp?.id || this.product.medias?.length ? this.product.medias[0].id || this.product.medias[0].publicUrl : null;
    const price = this.appStateService.hasMembership() ? this.product.priceWithPromotion : this.product.price;
    cartItem.basePrice = price?.amount ? price?.amount : 0;
    cartItem.currencyId = price?.currency ? price?.currency.id : this.currencyId;
    cartItem.sellerId = this.product.sellerId;
    cartItem.productType = this.product.productType;
    if (this.product.seoData) {
      cartItem.seoData = { ...this.product.seoData };
    }
    this.cartService.addItem(cartItem);
  }

  getReviews() {
    this.reviewService
      .findAllByProduct(this.product.id)
      .pipe(take(1))
      .subscribe(values => {
        this.poductCustomerReviews = values;
      });
  }

  navigateToUserSpace() {
    if (!this.userAccountService.isAuthenticated()) {
      this.router.navigate(['/login']);
    }
    const groupeDRH = [DRH_GROUP];
    const groupeBeneficary = [BENEFICIARY_GROUP];
    const groupeCoach = [COACH_GROUP];
    if (this.userAccountService.hasAnyAuthorityDirect(groupeDRH)) {
      this.router.navigate(['drh-space']);
    } else if (this.userAccountService.hasAnyAuthorityDirect(groupeBeneficary)) {
      this.router.navigate(['beneficiary-space']);
    } else if (this.userAccountService.hasAnyAuthorityDirect(groupeCoach)) {
      this.router.navigate(['coach-space']);
    } else if (this.userAccountService.hasAnyAuthorityDirect([SERVICE_PROVIDER_GROUP])) {
      this.router.navigate(['service-provider-space']);
    }
  }

  ngOnDestroy() {
    this.$destroyed.next();
    this.$destroyed.complete();
    this.seoService.resetSeo();
  }
}
