<div class="single-courses-box mb-30">
  <div class="courses-image">
    <!-- <a *ngIf="!linkToNewTab" [routerLink]="detailsPageUrlPattern ?
             (detailsPageUrlPattern | seoNameUrl : product.seoData : currentLocaleId) : null" style="cursor: pointer; text-align: center;"  class="d-block">
            <img style="width: 100%; max-width: 100%;height:250px;max-height: 300px" [alt]="product.name" [src]="getImage(product.mainImageId || product.thumbnailId ,'PLP')"
                 onerror="this.src='assets/img/not-found.png'">
        </a>
        <a *ngIf="linkToNewTab" [href]="detailsPageUrlPattern ?
        (detailsPageUrlPattern | seoNameUrl : product.seoData : currentLocaleId) : null" target="_blank">            
            <img style="width: 100%; max-width: 100%;height:250px;max-height: 300px" [alt]="product.name" [src]="getImage(product.mainImageId || product.thumbnailId ,'PLP')"
            onerror="this.src='assets/img/not-found.png'">
        </a> -->
        <a *ngIf="!linkToNewTab" class="cursor-pointer" [routerLink]="detailsPageUrlPattern ? (detailsPageUrlPattern | seoNameUrl : product.seoData : currentLocaleId) : null">
      <img
        style="width: 100%; max-width: 100%; height: 20vh; max-height: 200px"
        [alt]="product.name"
        [src]="getImage(product.mainImageId || product.thumbnailId, 'PLP')"
        onerror="this.src='assets/img/not-found.png'" />
    </a>
    <a *ngIf="linkToNewTab" class="cursor-pointer" [href]="detailsPageUrlPattern ? (detailsPageUrlPattern | seoNameUrl : product.seoData : currentLocaleId) : null">
      <img
        style="width: 100%; max-width: 100%; height: 20vh; max-height: 200px"
        [alt]="product.name"
        [src]="getImage(product.mainImageId || product.thumbnailId, 'PLP')"
        onerror="this.src='assets/img/not-found.png'" />
    </a>

    <div class="courses-tag">
      <a class="d-block cursor-default">{{ product.categoryName }}</a>
    </div>
    <ng-container *ngIf="!linkToNewTab">
      <a
        class="add-to-cart-btn cursor-pointer"
        (click)="bindProductToAction()"
        *ngIf="canAddToCart && isPassexcellenceProduct && isAuthenticatedAsDRHOrBeneficiary">
        {{ 'ADD_TO_CART' | translate }} <i class="bx bx-cart"></i
      ></a>
      <a class="add-to-cart-btn cursor-pointer" (click)="addToCart()" *ngIf="!isPassexcellenceProduct && isAuthenticatedAsDRHOrBeneficiary">
        {{ 'RESERVE' | translate }} <i class="bx bxs-shopping-bag"></i
      ></a>
    </ng-container>
  </div>
  <div class="courses-content my-0 py-0">
    <div class="course-author d-flex align-items-center" style="max-height: 70px">
      <div style="flex: 1">
        <img
          class="mr-2 mt-1"
          [src]="getImage(product.sellerPhotoId, null)"
          onerror="this.src='assets/img/not-found.png'"
          [alt]="product.sellerName"
          style="width: 100px; height: auto; max-height: 70px" />
      </div>
      <!-- <span>{{product.sellerName}}</span> -->
      <div style="display: flex; margin: 0px 0rem; flex: 1; justify-content: flex-end; align-items: baseline">
        <!-- <ng-container *ngIf="product?.discountPercentage">
                    <span style="color: var(--mainColor); font-weight: bold; ">
                        {{product?.discountPercentage }}%  
                    </span>
                    <p style=" margin-left: 5px; font-weight: bold; " > {{"DISCOUNT" | translate}}</p>
                </ng-container> -->
      </div>
    </div>
    <div class="courses-title">
      <h3 class="h5">
        <a
          *ngIf="!linkToNewTab"
          [routerLink]="detailsPageUrlPattern ? (detailsPageUrlPattern | seoNameUrl : product.seoData : currentLocaleId) : null"
          class="d-inline-block"
          >{{ product.name }}</a
        >
        <a
          *ngIf="linkToNewTab"
          [href]="detailsPageUrlPattern ? (detailsPageUrlPattern | seoNameUrl : product.seoData : currentLocaleId) : null"
          target="_blank"
          >{{ product.name }}</a
        >
      </h3>
    </div>
    <div style="text-align: center" class="courses-rating" *ngIf="product.averageRatings">
      <div *ngFor="let in of counter(product.averageRatings); let i = index" class="review-stars-rated">
        <i class="bx bxs-star"></i>
      </div>
      <div class="rating-total">({{ product?.averageRatings }})</div>
    </div>
  </div>
  <div class="courses-box-footer">
    <p class="text-center" *ngIf="!isPassexcellenceProduct">
      <span class="main-color" *ngIf="isAuthenticatedAsDRHOrBeneficiary">
        {{ ('BENEFIT_FROM_REDUCTION_FOR_MEMBERS_OF' | translate) + ' ' + (product.discountPercentage || 0) + ' %' }}
      </span>
      <ng-container *ngIf="!authenticatedUser">
        <a routerLink="/register" class="main-color">
          {{ 'PLEASE_SIGNUP' | translate }}
        </a>
        <span>{{ ('AND' | translate) + ' ' + ('BENEFIT_FROM_REDUCTION_FOR_MEMBERS' | translate) }}</span>
      </ng-container>
    </p>
    <ng-container *ngIf="isPassexcellenceProduct">
      <div
        style="display: flex; justify-content: space-between"
        [ngStyle]="{ 'justify-content': hasUniquePrice ? 'center' : 'non' }"
        *ngIf="displayPrice; else notMember">
        <div *ngIf="hasUniquePrice">
          <p>{{ 'MEMBER_PRICE' | translate }}</p>
          <span style="color: var(--mainColor); font-weight: bold">
            {{
              product?.price?.amount
                | currency : product?.price?.currency?.isoCode : '' : '0.' + product?.price?.currency?.numberOfDigistAfterDecimalsign + '-3'
            }}
            {{ product?.priceWithPromotion?.currency?.isoCode }}
          </span>
        </div>
        <ng-container *ngIf="!hasUniquePrice">
          <ng-container *ngIf="product?.price?.amount > 0 && product?.priceWithPromotion?.amount > 0; else notPrice">
            <div>
              <p>{{ 'PUBLIC_PRICE' | translate }}</p>
              <span style="color: var(--mainColor); font-weight: bold">
                {{
                  product?.price?.amount
                    | currency
                      : product?.price?.currency?.isoCode
                      : ''
                      : '0.' + product?.price?.currency?.numberOfDigistAfterDecimalsign + '-3'
                }}
                {{ product?.priceWithPromotion?.currency?.isoCode }}
              </span>
            </div>
            <div>
              <ng-container>
                <p>{{ 'MEMBER_PRICE' | translate }}</p>
                <span style="color: var(--mainColor); font-weight: bold">
                  {{
                    product?.priceWithPromotion?.amount
                      | currency
                        : product?.priceWithPromotion?.currency?.isoCode
                        : ''
                        : '0.' + product?.priceWithPromotion?.currency?.numberOfDigistAfterDecimalsign + '-3'
                  }}
                  {{ product?.priceWithPromotion?.currency?.isoCode }}
                </span>
              </ng-container>
            </div>
          </ng-container>
        </ng-container>
      </div>
    </ng-container>
    <div style="display: flex; justify-content: space-between">
      <button type="submit" (click)="bindProductToAction()" *ngIf="bindToAction" class="confirm-button" style="padding: 8px; margin: 10px">
        <span class="label">{{ isPassexcellenceProduct ? ('ADD_TO_CART' | translate) : ('RESERVE' | translate) }}</span>
      </button>
      <button
        *ngIf="canSendRequest && sendActionRequest"
        type="submit"
        (click)="sendRequest()"
        class="confirm-button"
        style="padding: 8px; margin: 10px">
        <span class="label">{{ 'REQUEST_PRODUCT' | translate }}</span>
      </button>
    </div>
  </div>
</div>

<ng-template #notMember>
  <div style="display: flex; justify-content: space-between" *ngIf="canBecomeMember">
    <a routerLink="/beneficiary-space" class="become-member text-break">{{ 'BECOME_A_MEMBER_TO_DISPLAY_PRICE' | translate }}</a>
  </div>
</ng-template>
<ng-template #notPrice>
  <div style="display: flex; justify-content: space-between">
    <a routerLink="/beneficiary-space" class="become-member text-break">{{ 'GET_DISCOUNT' | translate }}</a>
  </div>
</ng-template>
