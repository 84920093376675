<ngx-spinner bdColor="rgba(51,51,51,0.8)" color="#fff" size="medium" type="line-scale"></ngx-spinner>
<div class="register-area">
  <div class="row m-0">
    <div class="d-none d-lg-block col-lg-6 p-0 fixed-top mx-0">
      <img style="max-width: 100% !important; height: 100vh" src="assets/img/inscription.webp" alt="image" />
    </div>
    <div class="col-12 col-lg-6 offset-lg-6">
      <div class="register-content px-2 pt-3 pt-md-5">
        <div class="register-form">
          <div class="logo">
            <a routerLink="/"><img src="../../../../assets/img/black-logo.png" class="logo img-fluid" alt="image" /></a>
          </div>
          <h3>{{ 'OPEN_UP_YOUR_ACCOUNT' | translate }}</h3>
          <a class="register-link" routerLink="/login">{{ 'ALREADY_SIGNED_UP' | translate }}</a>
          <div>
            <div class="alert alert-danger" *ngIf="error">
              <strong>{{ title | translate }}</strong>
            </div>
          </div>
        </div>
        <mat-horizontal-stepper #stepper class="mx-auto px-0" linear>
          <mat-step [completed]="step1Complete">
            <div class="checkout-area mt-md-5 mt-3 pt-md-5 px-1">
              <div class="container mx-auto mx-lg-2 px-0">
                <form [formGroup]="signupForm" (submit)="validateFirstStepForm()">
                  <div class="row">
                    <div class="col-12">
                      <div class="billing-details">
                        <div class="row">
                          <div class="form-group col-md-6">
                            <label>{{ 'FIRSTNAME' | translate }}<span class="required"> *</span></label>
                            <input
                              type="text"
                              id="first_name"
                              name="first_name"
                              formControlName="firstName"
                              [(ngModel)]="beneficiary.firstName"
                              [ngClass]="{
                                'is-invalid': submitted && f.firstName?.errors
                              }"
                              class="form-control"
                              [disabled]="beneficiary.facebookId !== undefined || beneficiary.facebookId !== null" />
                            <div *ngIf="submitted && f.firstName?.errors" class="invalid-feedback">
                              <div *ngIf="f.firstName?.errors.required" style="float: left; margin-bottom: 5px">
                                {{ 'FIELD_REQUIRED' | translate }}
                              </div>
                            </div>
                          </div>
                          <div class="form-group col-md-6">
                            <label>{{ 'LASTNAME' | translate }}<span class="required"> *</span></label>
                            <input
                              type="text"
                              id="last_name"
                              name="last_name"
                              [(ngModel)]="beneficiary.lastName"
                              required
                              formControlName="lastName"
                              [ngClass]="{
                                'is-invalid': submitted && f.lastName?.errors
                              }"
                              [disabled]="beneficiary.facebookId !== undefined || beneficiary.facebookId !== null"
                              class="form-control" />
                            <div *ngIf="submitted && f.lastName?.errors" class="invalid-feedback">
                              <div *ngIf="f.lastName?.errors.required" style="float: left; margin-bottom: 5px">
                                {{ 'FIELD_REQUIRED' | translate }}
                              </div>
                            </div>
                          </div>
                          <div class="form-group col-md-12">
                            <label>{{ 'EMAIL' | translate }}<span class="required"> *</span></label>
                            <input
                              type="email"
                              id="email"
                              name="email"
                              formControlName="email"
                              [(ngModel)]="beneficiary.email"
                              class="form-control"
                              [ngClass]="{
                                'is-invalid': submitted && f.email.errors
                              }" />
                            <div *ngIf="submitted && f.email?.errors" class="invalid-feedback">
                              <div *ngIf="f.email.errors.required" style="float: left; margin-bottom: 5px">
                                {{ 'EMAIL_REQUIRED' | translate }}
                              </div>
                              <div *ngIf="f.email.errors.email" style="float: left; margin-bottom: 5px">
                                {{ 'EMAIL_INVALID' | translate }}
                              </div>
                            </div>
                            <div *ngIf="submitted && isEmailUsed && !f.email.errors" class="invalid-feedback d-block">
                              <div style="float: left; margin-bottom: 5px">
                                {{ 'EMAIL_USED' | translate }}
                              </div>
                            </div>
                          </div>
                          <div class="form-group col-md-6" *ngIf="!beneficiary.facebookId">
                            <label>{{ 'PASSWORD' | translate }}<span class="required"> *</span></label>
                            <input
                              #password
                              [type]="showPassword ? 'text' : 'password'"
                              id="password"
                              name="password"
                              [(ngModel)]="beneficiary.password"
                              required
                              formControlName="password"
                              class="form-control"
                              [ngClass]="{
                                'is-invalid': submitted && f.password.errors
                              }" />
                            <i
                              (click)="showPassword = !showPassword"
                              class="field-icon"
                              [ngClass]="password.type === 'password' ? ' bx bx-hide' : ' bx bx-show'"></i>
                            <div *ngIf="submitted && f.password?.errors" class="invalid-feedback">
                              <div *ngIf="f.password.errors.required" style="float: left; margin-bottom: 5px">
                                {{ 'PASSWORD_REQUIRED' | translate }}
                              </div>
                              <div *ngIf="f.password.errors.pattern" style="float: left; margin-bottom: 5px">
                                {{ 'PASSWORD_INVALID' | translate }}
                              </div>
                            </div>
                          </div>

                          <div class="form-group col-md-6" *ngIf="!beneficiary.facebookId">
                            <label>{{ 'CONFIRMPASSWORD' | translate }}<span class="required"> *</span></label>
                            <input
                              id="confirm_password"
                              name="confirm_password"
                              [(ngModel)]="confirmPassword"
                              formControlName="confirmPassword"
                              [ngClass]="{
                                'is-invalid': submitted && f.confirmPassword.errors
                              }"
                              [type]="showPassword ? 'text' : 'password'"
                              class="form-control" />

                            <div *ngIf="submitted && f.confirmPassword.errors" class="invalid-feedback">
                              <div *ngIf="f.confirmPassword.errors.NoPassswordMatch" style="float: left; margin-bottom: 5px">
                                {{ 'PASSWORD_NOT_MATCH' | translate }}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <button style="float: right" class="next-button" type="submit" matStepperNext>
                    {{ 'NEXT' | translate }}
                  </button>
                </form>
              </div>
            </div>
          </mat-step>

          <mat-step [completed]="step2Complete">
            <div class="checkout-area mt-4 px-1">
              <div class="container">
                <form [formGroup]="signup2Form" (submit)="validateStepForm2()">
                  <div class="row">
                    <div class="col-lg-12 col-md-12">
                      <div class="billing-details">
                        <div class="row">
                          <div class="form-group col-lg-6 col-md-6">
                            <label>{{ 'BIRTHDATE' | translate }}<span class="text-danger"> *</span></label>
                            <input
                              type="date"
                              id="birthdate"
                              name="birthdate"
                              formControlName="birthdate"
                              [ngClass]="{
                                'is-invalid': submitted2 && f2.birthdate?.errors
                              }"
                              class="form-control"
                              [(ngModel)]="beneficiary.birthDate" />
                            <div *ngIf="submitted2 && f2.birthdate?.errors" class="invalid-feedback">
                              <div *ngIf="f2.birthdate?.errors.required" style="float: left; margin-bottom: 5px">
                                {{ 'FIELD_REQUIRED' | translate }}
                              </div>
                            </div>
                          </div>

                          <div class="form-group col-lg-6 col-md-6">
                            <label>{{ 'GENDER' | translate }}</label>
                            <div style="display: flex">
                              <div *ngFor="let gen of genders">
                                <input
                                  type="radio"
                                  id="{{ gen.id }}"
                                  name="radio-group"
                                  [value]="gen"
                                  [(ngModel)]="gender"
                                  [ngModelOptions]="{ standalone: true }" />
                                <i
                                  *ngIf="gen.id === 'FEMALE'"
                                  style="font-size: 27px; margin: 5px; color: #ec899a"
                                  class="bx bx-female-sign"></i>
                                <i
                                  *ngIf="gen.id === 'MALE'"
                                  style="font-size: 27px; margin: 5px; color: #7ccaca"
                                  class="bx bx-male-sign"></i>
                              </div>
                            </div>
                          </div>
                          <!-- start nationalities field -->
                          <div class="col-lg-6 col-md-6">
                            <div class="form-group">
                              <label>{{ 'NATIONALITIES' | translate }}</label>
                              <div class="form-group">
                                <mat-form-field [floatLabel]="'never'">
                                  <mat-select
                                    panelClass="mat-select-panel-class"
                                    [formControl]="nationalityMultiCtrl"
                                    [placeholder]="'NATIONALITIES' | translate"
                                    [multiple]="true"
                                    #multiSelect
                                    (selectionChange)="updateBeneficiaryNationalitiesList()">
                                    <mat-option>
                                      <ngx-mat-select-search
                                        [placeholderLabel]="'NATIONALITIES' | translate"
                                        [noEntriesFoundLabel]="'NO_NATIONALITY_FOUND' | translate"
                                        [formControl]="nationalityMultiFilterCtrl"></ngx-mat-select-search>
                                    </mat-option>
                                    <mat-option *ngFor="let country of filteredNationalitiesMulti | async" [value]="country">
                                      {{ country.name }}
                                    </mat-option>
                                  </mat-select>
                                </mat-form-field>
                              </div>
                            </div>
                          </div>
                          <!-- end nationalities field -->
                          <div class="col-lg-6-md-6"></div>

                          <div class="col-lg-6 col-md-6">
                            <div class="form-group">
                              <label>{{ 'STRRET_ADDRESS' | translate }}</label>
                              <input
                                type="text"
                                class="form-control"
                                name="street"
                                [ngModelOptions]="{ standalone: true }"
                                id="field_street"
                                [(ngModel)]="address.street" />
                            </div>
                          </div>

                          <div class="col-lg-6 col-md-6">
                            <div class="form-group">
                              <label>{{ 'COUNTRY' | translate }}</label>
                              <input
                                type="text"
                                class="form-control input-wrapper"
                                [ngModelOptions]="{ standalone: true }"
                                name="country"
                                id="field_country"
                                [(ngModel)]="address.city.country.name.localizedValues[this.currentLocale]" />
                            </div>
                          </div>

                          <div class="col-lg-6 col-md-6">
                            <div class="form-group">
                              <label>{{ 'ZIP' | translate }}</label>
                              <input
                                type="text"
                                class="form-control"
                                name="zip"
                                [ngModelOptions]="{ standalone: true }"
                                id="field_zip"
                                [(ngModel)]="address.zip" />
                            </div>
                          </div>

                          <div class="col-lg-6 col-md-6">
                            <div class="form-group">
                              <label>{{ 'CITY' | translate }}</label>
                              <input
                                type="text"
                                class="form-control input-wrapper"
                                [ngModelOptions]="{ standalone: true }"
                                name="cityD"
                                id="field_cityD"
                                placeholder="{{ 'CITY' | translate }}"
                                [(ngModel)]="address.city.name" />
                            </div>
                          </div>

                          <div class="col-lg-6 col-md-6">
                            <div class="form-group">
                              <label>{{ 'PHONENUMBER' | translate }} 1<span class="required"> *</span></label>
                              <input
                                type="text"
                                class="form-control"
                                id="phone_number1"
                                name="phone_number1"
                                formControlName="phoneNumber"
                                [ngClass]="{
                                  'is-invalid': submitted2 && f2.phoneNumber?.errors
                                }"
                                [(ngModel)]="beneficiary.phoneNumber1"
                                pattern="^[0-9]{1,4}[-\s\./0-9]*$"
                                required />
                              <div *ngIf="submitted2 && f2.phoneNumber?.errors" class="invalid-feedback">
                                <div *ngIf="f2.phoneNumber?.errors.required" style="float: left; margin-bottom: 5px">
                                  {{ 'FIELD_REQUIRED' | translate }}
                                </div>
                                <div
                                  *ngIf="f2.phoneNumber?.errors.minlength && !f2.phoneNumber?.errors.pattern"
                                  style="float: left; margin-bottom: 5px">
                                  {{ 'MINIMUM_LENGTH' | translate }}: 8
                                  {{ 'CHARACTERS' | translate }}
                                </div>
                                <div *ngIf="f2.phoneNumber?.errors.pattern" style="float: left; margin-bottom: 5px">
                                  {{ 'INVALID_FIELD' | translate }}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-lg-6 col-md-6">
                            <div class="form-group">
                              <label>{{ 'PHONENUMBER' | translate }} 2</label>
                              <input
                                type="text"
                                class="form-control"
                                id="phone_number2"
                                name="phone_number2"
                                formControlName="phoneNumber2"
                                [(ngModel)]="beneficiary.phoneNumber2"
                                pattern="^[0-9]{1,4}[-\s\./0-9]*$" />
                              <div class="text-danger" *ngIf="submitted2 && f2.phoneNumber2.errors">
                                <div
                                  *ngIf="f2.phoneNumber2.errors.minlength && !f2.phoneNumber2?.errors.pattern"
                                  style="float: left; margin-bottom: 5px; font-size: 0.875em">
                                  {{ 'MINIMUM_LENGTH' | translate }}: 8
                                  {{ 'CHARACTERS' | translate }}
                                </div>
                                <div *ngIf="f2.phoneNumber2.errors.pattern" style="float: left; margin-bottom: 5px; font-size: 0.875em">
                                  {{ 'INVALID_FIELD' | translate }}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="form-group col-lg-12 col-md-12" *ngIf="!beneficiary.facebookId">
                            <label>{{ 'PERSONALINTERESTS' | translate }}</label>
                            <mat-chip-list #chipList>
                              <mat-chip
                                *ngFor="let personalInterest of beneficiary.personalInterests"
                                [selectable]="selectable"
                                [removable]="removable"
                                (removed)="remove(personalInterest)">
                                {{ personalInterest }}
                                <mat-icon matChipRemove>cancel</mat-icon>
                              </mat-chip>
                              <input
                                class="form-control"
                                [matChipInputFor]="chipList"
                                [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                [matChipInputAddOnBlur]="addOnBlur"
                                (matChipInputTokenEnd)="add($event)" />
                            </mat-chip-list>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <button style="float: left" class="previous-button" type="button" matStepperPrevious>
                    {{ 'PREVIOUS' | translate }}
                  </button>

                  <button style="float: right" class="next-button" type="submit" matStepperNext>
                    {{ 'NEXT' | translate }}
                  </button>
                </form>
              </div>
            </div>
          </mat-step>

          <mat-step class="mat-step-register">
            <div class="checkout-area mx-0 px-0 px-1">
              <div class="container px-0">
                <form [formGroup]="signup3Form" (submit)="register()">
                  <div class="row">
                    <div class="col-lg-12 col-md-12 mb-0">
                      <div class="billing-details">
                        <div class="row" style="margin-top: 25px">
                          <div class="col-lg-12 col-md-12">
                            <h5>{{ 'ATTENTE_PASSEXCELLENCE' | translate }}</h5>
                            <div class="row mb-4 mt-4">
                              <div class="col-12 col-sm-6 col-lg-3 mt-3" *ngFor="let expectation of expectations">
                                <div class="text-center">
                                  <button
                                    style="cursor: pointer"
                                    type="button"
                                    class="btn btn-sm mx-1 text-wrap d-lg-none w-75 w-75"
                                    [class.btn-primary]="elementExist(expectation)"
                                    [class.bg-light]="!elementExist(expectation)"
                                    (click)="addOrRemove(expectation)">
                                    {{ expectation.name.localizedValues[currentLocale] }}
                                  </button>
                                  <button
                                    style="cursor: pointer"
                                    type="button"
                                    class="btn btn-sm mx-1 text-wrap d-none d-lg-inline w-90"
                                    [class.btn-primary]="elementExist(expectation)"
                                    [class.bg-light]="!elementExist(expectation)"
                                    (click)="addOrRemove(expectation)">
                                    {{ expectation.name.localizedValues[currentLocale] }}
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="form-group col-lg-12 col-md-12">
                            <div class="form-check">
                              <input
                                id="other"
                                name="other"
                                class="form-check-input"
                                [ngModelOptions]="{ standalone: true }"
                                [(ngModel)]="other"
                                type="checkbox" />
                              <label class="form-check-label mr-2" for="other">
                                {{ 'OTHERTOBESPECIFIED' | translate }}
                              </label>
                            </div>
                          </div>
                          <div *ngIf="other" class="col-lg-12 col-md-12">
                            <label>{{ 'OTHEREXPECTATION' | translate }}</label>
                            <mat-chip-list #chipList2>
                              <mat-chip
                                *ngFor="let otherExpectation of beneficiary.otherExpectations"
                                [selectable]="selectable"
                                [removable]="removable"
                                (removed)="removeOtherExpectation(otherExpectation)">
                                {{ otherExpectation }}
                                <mat-icon matChipRemove>cancel</mat-icon>
                              </mat-chip>
                            </mat-chip-list>
                            <div class="form-group">
                              <input
                                class="form-control mb-4"
                                [matChipInputFor]="chipList2"
                                [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                [matChipInputAddOnBlur]="addOnBlur"
                                (matChipInputTokenEnd)="addOtherExpectation($event)" />
                            </div>
                          </div>

                          <div class="form-group col-lg-12 col-md-12">
                            <div class="form-check">
                              <input
                                id="term"
                                name="term"
                                [(ngModel)]="termAndCondition"
                                formControlName="acceptCgu"
                                [ngClass]="{
                                  'is-invalid': submitted3 && f3.acceptCgu?.errors
                                }"
                                class="form-check-input mr-2"
                                type="checkbox" />
                              <label class="form-check-label" for="term">
                                <a [routerLink]="termsAndConditionsPageUrl$ | async" class="text-wrap" target="_blank"
                                  >{{ 'ACCEPTTERMEANDCONDITION' | translate }}.
                                </a>
                                <span class="text-danger">*</span>
                              </label>
                              <div *ngIf="submitted3 && f3.acceptCgu.errors" class="invalid-feedback">
                                <div *ngIf="f3.acceptCgu.errors.required" style="float: left; margin-bottom: 5px">
                                  {{ 'ACCEPT_OUR_TERMS' | translate }}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row mb-3 mw-90 overflow-hidden">
                    <ngx-recaptcha2
                      [(ngModel)]="declarativeFormCaptchaValue"
                      name="captcha"
                      formControlName="captcha"
                      [hl]="recaptchaLocale"
                      [siteKey]="recaptchaSiteKey"
                      [ngClass]="{
                        'is-invalid': submitted3 && f3.captcha?.errors
                      }"></ngx-recaptcha2>
                    <span class="text-danger" *ngIf="submitted3 && f3.captcha.errors">
                      {{ 'FIELD_REQUIRED' | translate }}
                    </span>
                  </div>
                  <div class="row">
                    <div class="col-6">
                      <button class="previous-button" type="button" matStepperPrevious>
                        {{ 'PREVIOUS' | translate }}
                      </button>
                    </div>
                    <div class="col-6 text-right">
                      <button class="next-button" type="submit">
                        {{ 'SIGN_UP' | translate }}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </mat-step>
        </mat-horizontal-stepper>
      </div>
    </div>
  </div>
</div>
