import { Observable } from 'rxjs';
import { ResponseWrapper } from '../models/response-wrapper.model';
import { createRequestOption } from './utils/request-util';
import { map } from 'rxjs/operators';
import { convertResponse } from './utils/response-converter';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class DynamicPathService {
  constructor(private http: HttpClient) {}
  find(id: string): Observable<any> {
    return this.http.get(environment.apiUrl + 'api/dynamic-paths' + id);
  }
  // findByName(id: string): Observable<any> {
  //     return this.http.get(environment.apiUrl + 'api/cms-pages-by-page-code/' + id);
  // }
  //
  // findByPath(id: string): Observable<any> {
  //     return this.http.get(environment.apiUrl + 'api/cms-pages-by-url-key/' + id);
  // }
  findByOldUrl(id: string): Observable<any> {
    return this.http.get(environment.apiUrl + 'api/redirect-301-s/by-old-url' + id);
  }
  // public interceptNewMessageReceived(value: any) {
  //     this.route.next(value);
  // }
  query(req?: any): Observable<ResponseWrapper> {
    const options = createRequestOption(req);
    return this.http.get(environment.apiUrl + 'api/dynamic-paths/', options).pipe(map(res => convertResponse(res)));
  }
  findAllWithUrlKeyNotNull(): Observable<any[]> {
    return this.http.get<any[]>(environment.apiUrl + 'api/cms-pages/by-url-key-not-null');
  }
}
