<div
  class="hero-banner"
  style="background-color: transparent; height: auto; margin: 25px; margin: 1rem; padding-top: 2rem; background-position-y: center">
  <div class="d-table">
    <div style="margin-top: 20px">
      <div class="container-fluid" style="vertical-align: sub">
        <div class="row align-items-center">
          <div class="col-lg-7">
            <div class="banner-text mw-100">
              <!-- col-lg-6 col-md-12 col-sm-6 -->
              <div class="hero-banner-content mw-100">
                <p style="color: #1e5586; text-align: justify" class="mw-100">
                  {{ data?.content }}
                </p>
              </div>
            </div>
          </div>
          <div class="col-lg-5">
            <div class="hero-banner-image text-center mx-auto">
              <app-hro-video-set [componentId]="videoComponentCode" [(dataSource)]="dataSource"></app-hro-video-set>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
