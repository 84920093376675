import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TabsModule } from 'ngx-tabset';
import { NgxScrollTopModule } from 'ngx-scrolltop';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetModule, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { MatButtonModule } from '@angular/material/button';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FooterComponent } from './components/common/footer/footer.component';
import { LoginPageComponent } from './components/pages/login-page/login-page.component';
import { RegisterPageComponent } from './components/pages/register-page/register-page.component';
import { ErrorPageComponent } from './components/pages/error-page/error-page.component';
import { CartPageComponent } from './components/pages/cart-page/cart-page.component';
import { CheckoutPageComponent } from './components/pages/checkout-page/checkout-page.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { PromptComponentComponent } from './components/pwa/prompt-component/prompt-component.component';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { PwaService } from './services/pwa.service';
import { APIService } from './services/api.service';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { CMSModule } from './cms/cms.module';
import { BlockProductSetComponent } from './cms/components/block-product-set/block-product-set.component';
import { ContactComponent } from './components/pages/contact/contact.component';
import { ContactUsCmsComponent } from './components/pages/contact-us-cms/contact-us-cms.component';
import { TermsAndConditionsComponent } from './components/pages/terms-and-conditions/terms-and-conditions.component';
import { LocalStorageService, SessionStorageService } from 'ngx-webstorage';
import { AuthInterceptor } from './services/auth/auth.interceptor';
import { AuthExpiredInterceptor } from './services/auth/auth-expired.interceptor';
import { UserRouteAccessService } from './services/auth/user-route-access.service';
import { SharedModule } from './shared/shared.module';
import { MatDialogModule } from '@angular/material/dialog';
import { NgbActiveModal, NgbRatingModule } from '@ng-bootstrap/ng-bootstrap';
import { MatPaginatorIntl, MatPaginatorModule } from '@angular/material/paginator';
import { MatPaginationIntlService } from './shared/mat-paginationIntl.service';
import { ForgotPasswordComponent } from './components/pages/forgot-password/forgot-password.component';
import { MatSortModule } from '@angular/material/sort';
import { MAT_RADIO_DEFAULT_OPTIONS } from '@angular/material/radio';
import { TransferHttpCacheModule } from '@nguniversal/common';
import { NgxCaptchaModule } from 'ngx-captcha';

import { FacebookLoginProvider, GoogleLoginProvider, SocialAuthServiceConfig, SocialLoginModule } from 'angularx-social-login';
import { ResetPasswordComponent } from './components/pages/reset-password/reset-password.component';
import { ToastrModule } from 'ngx-toastr';
import { MatStepperModule } from '@angular/material/stepper';
import { MatChipsModule } from '@angular/material/chips';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatSelectModule } from '@angular/material/select';
import { DatePipe } from '@angular/common';
import { AngularFireMessagingModule } from '@angular/fire/compat/messaging';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFireDatabaseModule } from '@angular/fire/compat/database';
import { AngularFireModule } from '@angular/fire/compat';
import { WebNotificationService } from './services/web-notification.service';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { PagePaymentFailedComponent } from './components/pages/page-payment-failed/page-payment-failed.component';
import { PagePaymentSuccessComponent } from './components/pages/page-payment-success/page-payment-success.component';
import { DropDownListModule } from '@syncfusion/ej2-angular-dropdowns';
import { AboutUsComponent } from './components/pages/about-us/about-us.component';
import { HomePageOneComponent } from './components/pages/home-page-one/home-page-one.component';
import { ProductJourneyToExcellenceComponent } from './components/pages/product-journey-to-excellence/product-journey-to-excellence.component';
import { EntrepriseHomePageComponent } from './components/pages/entreprise-home-page/entreprise-home-page.component';
import { ParticularHomePageComponent } from './components/pages/particular-home-page/particular-home-page.component';
import { StoreModule } from '@ngrx/store';
import { appReducer } from './store/app.reducer';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { EffectsModule } from '@ngrx/effects';
import { LocaleEffects } from './store/effects/locale.effects';
import { InjectorModule } from './injector.module';
import { ContextEffects } from './store/effects/context.effects';
import { CountryEffects } from './store/effects/country.effects';
import { BeneficiaryEffects } from './store/effects/beneficiary.effects';
import { PASSE_EXCELLENCE } from './shared/constants';
import { NgxSpinnerModule } from 'ngx-spinner';
import { CookieService } from 'ngx-cookie-service';
import { DynamicPathGuard } from './services/guards/dynamic-path.guard';
import { CoachModule } from './coach/coach.module';
import { BlogModule } from './blog/blog.module';
import { CorporateSellerModule } from './corporate-seller/corporate-seller.module';
import { ProductDetailsComponent } from './product/product-details/product-details.component';
import { PackDetailsComponent } from './product/pack-details/pack-details.component';
import { PackEntriesComponent } from './product/pack-entries/pack-entries.component';
import { ProductComponent } from './product/product/product.component';
import { PrestationComponent } from './product/prestation/prestation.component';
import { PackListComponent } from './product/pack-list/pack-list.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
// import {OnServerService} from "./services/on-server.service";
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { CurrentLocaleService } from './services/current-locale.service';
import { SizeDetectorComponent } from './size-detector/size-detector.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ProductListComponent } from './product/product-list/product-list.component';
import { ProductReviewsComponent } from './product/product-reviews/product-reviews.component';
import { TermsOfUseComponent } from './components/pages/terms-of-use/terms-of-use.component';
import { PrivacyPolicyComponent } from './components/pages/privacy-policy/privacy-policy.component';
import { BeneficiaryProfilComponent } from './components/pages/beneficiary-profil/beneficiary-profil.component';
import {MatExpansionModule} from "@angular/material/expansion";

const initializer = (pwaService: PwaService) => () => pwaService.initPwaPrompt();

@NgModule({
  declarations: [
    AppComponent,
    SizeDetectorComponent,
    ProductComponent,
    PrestationComponent,
    PackListComponent,
    ProductDetailsComponent,
    PackDetailsComponent,
    PackEntriesComponent,
    AboutUsComponent,
    ErrorPageComponent,
    FooterComponent,
    CartPageComponent,
    CheckoutPageComponent,
    PromptComponentComponent,
    BlockProductSetComponent,
    ContactComponent,
    ContactUsCmsComponent,
    TermsAndConditionsComponent,
    TermsOfUseComponent,
    PrivacyPolicyComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    PagePaymentFailedComponent,
    PagePaymentSuccessComponent,
    HomePageOneComponent,
    ProductJourneyToExcellenceComponent,
    EntrepriseHomePageComponent,
    ParticularHomePageComponent,
    ProductReviewsComponent,
    PagePaymentFailedComponent,
    PagePaymentSuccessComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    LoginPageComponent,
    RegisterPageComponent,
    BeneficiaryProfilComponent,
  ],
    imports: [
        InjectorModule,
        BrowserModule.withServerTransition({appId: 'serverApp'}),
        AppRoutingModule,
        BrowserAnimationsModule,
        HttpClientModule,
        ReactiveFormsModule,
        BrowserModule,
        FormsModule,
        DropDownListModule,
        CarouselModule,
        // CountUpModule,
        TabsModule,
        NgxScrollTopModule,
        // StickyNavModule,
        // LightboxModule,
        FormsModule,
        MatToolbarModule,
        MatIconModule,
        MatBottomSheetModule,
        SharedModule,
        MatSortModule,
        MatDialogModule,
        AngularFireDatabaseModule,
        AngularFireAuthModule,
        SocialLoginModule,
        NgxSpinnerModule,
        AngularFireMessagingModule,
        AngularFireModule.initializeApp(environment.config),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: httpLoaderFactory,
                deps: [HttpClient],
            },
        }),
        NgbRatingModule,
        CoachModule,
        CorporateSellerModule,
        BlogModule,
        ServiceWorkerModule.register('ngsw-worker.js', {
            enabled: environment.production,
            // Register the ServiceWorker as soon as the app is stable
            // or after 30 seconds (whichever comes first).
            registrationStrategy: 'registerWhenStable:30000',
        }),
        MatButtonModule,
        CMSModule,
        MatStepperModule,
        MatChipsModule,
        MatCheckboxModule,
        MatGridListModule,
        MatSelectModule,
        ToastrModule.forRoot({
            timeOut: 2000,
            positionClass: 'toast-top-right',
            preventDuplicates: true,
        }),
        NgMultiSelectDropDownModule,
        MatAutocompleteModule,
        MatFormFieldModule,
        MatInputModule,
        MatPaginatorModule,
        EffectsModule.forRoot([LocaleEffects, ContextEffects, CountryEffects, BeneficiaryEffects]),
        StoreModule.forRoot(appReducer),
        StoreDevtoolsModule.instrument({logOnly: environment.production}),
        TransferHttpCacheModule,
        NgxMatSelectSearchModule,
        NgxCaptchaModule,
        MatProgressSpinnerModule,
        MatExpansionModule,
    ],
  entryComponents: [PromptComponentComponent],
  providers: [
    APIService,
    LocalStorageService,
    SessionStorageService,
    UserRouteAccessService,
    WebNotificationService,
    DatePipe,
    { provide: MatBottomSheetRef, useValue: {} },
    { provide: MAT_BOTTOM_SHEET_DATA, useValue: {} },
    {
      provide: APP_INITIALIZER,
      useFactory: initializer,
      deps: [PwaService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initLocaleOnServer,
      deps: [CurrentLocaleService],
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthExpiredInterceptor,
      multi: true,
    },
    {
      provide: MAT_RADIO_DEFAULT_OPTIONS,
      useValue: { color: 'primary' },
    },
    NgbActiveModal,
    {
      provide: MatPaginatorIntl,
      useClass: MatPaginationIntlService,
    },
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider('906802027170-9j8v166ut0fk0o364cgsta8tv2igqhda.apps.googleusercontent.com'),
          },
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider('254692926240112'),
          },
        ],
      } as SocialAuthServiceConfig,
    },
    CookieService,
    DynamicPathGuard,
  ],
  exports: [BlockProductSetComponent],
  bootstrap: [AppComponent],
})
export class AppModule {}
export function httpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, environment.apiUrl + 'api/i18nEntries/applicationLocale/' + PASSE_EXCELLENCE + '/', '');
}

export function initLocaleOnServer(currentLocaleService: CurrentLocaleService) {
  return () => {};
}
