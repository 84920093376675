import { Component, OnDestroy, OnInit } from '@angular/core';
import { DynamicPathRoutingService } from '../../../services/dynamic-path-routing.service';
import { map, takeUntil } from 'rxjs/operators';
import { Observable, ReplaySubject } from 'rxjs';
import {
  ABOUT_US_COMPONENT_NAME,
  BLOG_LIST_COMPONENT_NAME,
  CONTACT_US_CMS_COMPONENT_NAME,
  CORPORATE_SELLER_LIST_COMPONENT_NAME,
  PRIVACY_POLICY_COMPONENT_NAME,
  TERMS_AND_CONDITIONS_COMPONENT_NAME,
  TERMS_OF_USE_COMPONENT_NAME,
} from '../../../ComponentsIndex';
import { DynamicPathStorageService } from '../../../services/dynamic-path-storage.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit, OnDestroy {
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  corporateSellerListPageUrl$: Observable<string> = new Observable<string>();
  blogListPageUrl$: Observable<string> = new Observable<string>();
  contactUsPageUrl$: Observable<string> = new Observable<string>();
  termsAndConditionsPageUrl$: Observable<string> = new Observable<string>();
  termsOfUsePageUrl$: Observable<string> = new Observable<string>();
  privacyPolicyPageUrl$: Observable<string> = new Observable<string>();
  aboutUsPageUrl$: Observable<string> = new Observable<string>();

  constructor(private dynamicPathRoutingService: DynamicPathRoutingService, private dynamicPathStorageService: DynamicPathStorageService) {}

  ngOnInit(): void {
    this.corporateSellerListPageUrl$ = this.getUrlPatternObservable(CORPORATE_SELLER_LIST_COMPONENT_NAME);
    this.blogListPageUrl$ = this.getUrlPatternObservable(BLOG_LIST_COMPONENT_NAME);
    this.contactUsPageUrl$ = this.getUrlPatternObservable(CONTACT_US_CMS_COMPONENT_NAME);
    this.privacyPolicyPageUrl$ = this.getUrlPatternObservable(PRIVACY_POLICY_COMPONENT_NAME);
    this.termsOfUsePageUrl$ = this.getUrlPatternObservable(TERMS_OF_USE_COMPONENT_NAME);

    this.aboutUsPageUrl$ = this.getUrlPatternObservable(ABOUT_US_COMPONENT_NAME);
  }

  getUrlPatternObservable(componentName) {
    return this.dynamicPathStorageService.urlPatterns$.pipe(
      takeUntil(this.destroyed$),
      map(() => {
        const urlPattern = this.dynamicPathStorageService.getUrlPatternByComponentNameFromCmsPageList(componentName);
        if (!urlPattern) {
          return null;
        }
        return urlPattern.startsWith('/') ? urlPattern : '/' + urlPattern;
      })
    );
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
