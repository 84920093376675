import { Pipe, PipeTransform } from '@angular/core';
import { ISeoData } from '../models/seo-data.model';

@Pipe({
  name: 'seoNameUrl',
})
export class SeoNameUrlPipe implements PipeTransform {
  transform(urlPattern: string, seoData: ISeoData, localeId: string, blockRedirect?: boolean): string {
    if (blockRedirect) {
      return null;
    }
    const seoName = seoData ? seoData.seoName.localizedValues[localeId] : '';
    if (!seoName || !urlPattern) {
      return null;
    }
    let newLink = urlPattern;
    const urlFragments: string[] = urlPattern.split('/');
    if (!urlFragments) {
      return newLink;
    }
    const urlVariables = urlFragments.filter((value: string) => value.startsWith(':'));
    if (!urlVariables) {
      return newLink;
    }
    for (const url of urlVariables.values()) {
      const key: string = url.substring(1, url.length);
      if (key === 'seoName') {
        newLink = newLink.replace(url, seoName);
      }
    }
    if (!newLink.startsWith('/') && newLink) {
      newLink = '/' + newLink;
    }
    return newLink;
  }
}
