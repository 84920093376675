/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { HttpHeaders, HttpParams } from '@angular/common/http';

export const createRequestOption = (req?: any) => {
  const options = {
    params: {},
    headers: {},
    observe: 'response' as const,
  };
  if (req) {
    let params: HttpParams = new HttpParams();
    for (const propt of Object.entries<any>(req)) {
      params = params.set(propt[0], propt[1]);
    }
    options.params = params;
  }
  const token = localStorage.getItem('authenticationToken');
  const httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'my-auth-token',
    }),
  };
  httpOptions.headers = httpOptions.headers.set('Authorization', 'Bearer ' + token);
  options.headers = httpOptions.headers;

  return options;
};
