<div class="login-area">
  <div class="row m-0">
    <div class="col-lg-6 col-md-12 p-0">
      <div class="login-image">
        <img class="page-cover" src="assets/img/login-bg.jpg" alt="image" />
      </div>
    </div>
    <div class="col-lg-6 col-md-12 p-0">
      <div class="login-content">
        <div class="d-table">
          <div class="d-table-cell">
            <div class="login-form">
              <div class="logo">
                <a routerLink="/"><img src="../../../../assets/img/black-logo.png" class="logo" alt="image" /></a>
              </div>
              <h4>{{ 'RESET_PASSWORD' | translate }}</h4>
              <form [formGroup]="resetPasswordForm" (submit)="changePassword()">
                <div class="form-group">
                  <input
                    placeholder="{{ 'PASSWORD' | translate }}"
                    class="form-control"
                    [type]="showPassword ? 'text' : 'password'"
                    id="password"
                    name="password"
                    formControlName="password"
                    [ngClass]="{
                      'is-invalid': submitted && f.password?.errors
                    }"
                    [(ngModel)]="password" />
                  <i (click)="showPassword = !showPassword" class="field-icon" [ngClass]="showPassword ? ' bx bx-hide' : ' bx bx-show'"></i>
                  <div *ngIf="submitted && f.password?.errors" class="invalid-feedback">
                    <div *ngIf="f.password.errors.required" style="float: left; margin-bottom: 5px">
                      {{ 'PASSWORD_REQUIRED' | translate }}
                    </div>
                    <div *ngIf="f.password.errors.pattern" style="float: left; margin-bottom: 5px">
                      {{ 'PASSWORD_INVALID' | translate }}
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <input
                    id="confirm_password"
                    name="confirm_password"
                    placeholder="{{ 'CONFIRM_PASSWORD' | translate }}"
                    class="form-control"
                    [(ngModel)]="confirmPassword"
                    formControlName="confirmPassword"
                    [ngClass]="{
                      'is-invalid': submitted && f.confirmPassword.errors
                    }"
                    [type]="showPassword ? 'text' : 'password'"
                    class="form-control" />

                  <div *ngIf="submitted && f.confirmPassword.errors" class="invalid-feedback">
                    <div *ngIf="f.confirmPassword.errors.NoPassswordMatch" style="float: left; margin-bottom: 5px">
                      {{ 'PASSWORD_NOT_MATCH' | translate }}
                    </div>
                  </div>
                </div>
                <button type="submit">{{ 'SEND' | translate }}</button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
