import { Injectable, OnDestroy } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ISeoData } from '../shared/models/seo-data.model';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Router } from '@angular/router';
import { CurrentLocaleService } from './current-locale.service';

export interface SEO {
  title?: string;
  description?: string;
  imageUrl?: string;
  keywords?: string;
  type?: string;
  siteName?: string;
  url?: string;
}

@Injectable({
  providedIn: 'root',
})
export class SeoService implements OnDestroy {
  currentLocaleId: string;
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  private readonly defaultSEO: SEO = {
    title: 'Passexcellence',
    description: 'Passexcellence is the game changer!',
    imageUrl: '/assets/img/logo.png',
    keywords: '',
    type: 'website',
    siteName: 'Passexcellence',
    url: 'https://www.passexcellence.com/',
  };
  constructor(
    private htmlMetaService: Meta,
    private htmlTitleService: Title,
    private router: Router,
    currentLocaleService: CurrentLocaleService
  ) {
    currentLocaleService.currentLocaleId$.pipe(takeUntil(this.destroyed$)).subscribe(localeId => (this.currentLocaleId = localeId));
  }

  resetSeo(): void {
    this.setSEO(this.defaultSEO);
  }

  // set all required meta here, maybe separate them into methods(exp: setForTwitter, setForFacebook...)
  setSEO(seo: SEO): void {
    const title = seo.title && seo.title.length ? seo.title : this.defaultSEO.title;
    const description = seo.description && seo.description.length ? seo.description : this.defaultSEO.description;
    const imageUrl = seo.imageUrl && seo.imageUrl.length ? seo.imageUrl : this.defaultSEO.imageUrl;
    const keywords = seo.keywords && seo.keywords.length ? seo.keywords : this.defaultSEO.keywords;
    const type = seo.type && seo.type.length ? seo.type : this.defaultSEO.type;
    const url = this.router.url;
    const siteName = seo.siteName && seo.siteName.length ? seo.siteName : this.defaultSEO.siteName;

    this.htmlTitleService.setTitle(title);
    this.htmlMetaService.updateTag({
      property: 'og:site_name',
      content: siteName,
    });
    this.htmlMetaService.updateTag({ property: 'og:url', content: url });
    this.htmlMetaService.updateTag({ property: 'og:type', content: type });
    // facebook, linkedin, other sites
    this.htmlMetaService.updateTag({ property: 'og:title', content: title });
    this.htmlMetaService.updateTag({
      name: 'description',
      content: description,
    });
    this.htmlMetaService.updateTag({
      property: 'og:description',
      content: description,
    });
    this.htmlMetaService.updateTag({ property: 'og:image', content: imageUrl });
    // twitter
    this.htmlMetaService.updateTag({ name: 'twitter:title', content: title });
    this.htmlMetaService.updateTag({
      name: 'twitter:description',
      content: description,
    });
    this.htmlMetaService.updateTag({
      name: 'twitter:image',
      content: imageUrl,
    });

    this.htmlMetaService.updateTag({ name: 'keywords', content: keywords });
  }

  setSeoFromSeoDataAndMedia(seoData: ISeoData, mediaSrc?: any) {
    const seo: SEO = {};
    if (seoData) {
      if (seoData.metaTitle && seoData.metaTitle.localizedValues) {
        seo.title = seoData.metaTitle.localizedValues[this.currentLocaleId];
      }
      if (seoData.metaDescription && seoData.metaDescription.localizedValues) {
        seo.description = seoData.metaDescription.localizedValues[this.currentLocaleId];
      }
    }
    seo.imageUrl = mediaSrc;
    this.setSEO(seo);
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
