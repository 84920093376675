/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @angular-eslint/component-selector */
/* eslint-disable @typescript-eslint/ban-types */
import { Component, Inject, Input, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import { CartLine } from 'src/app/models/cartLine.model';
import { CartService } from 'src/app/services/cart.service';
import { AppStateService } from 'src/app/services/utils/state.service';
import { ProductService } from '../../services/product.service';
import { IProductDTO } from '../models/productDTO';
import { AppState } from '../../store/app.reducer';
import { Store } from '@ngrx/store';
import { IBeneficiary } from 'src/app/models/Beneficiary.model';
import {
  BENEFICIARY_GROUP,
  BUNDLES_FAMILY,
  COACH_GROUP,
  DRH_GROUP,
  LOCALE_ID,
  LOCALE_STATE,
  LOCALES,
  PRODUCT_FAMILY_SERVICE,
  SERVICE_PROVIDER_GROUP,
  SELLER_ID_TO_DISPLAY,
  PASSE_EXCELLENCE,
} from '../constants';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SessionStorageService } from 'ngx-webstorage';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'hro-product-card',
  templateUrl: './hro-product-card.component.html',
  styleUrls: ['./hro-product-card.component.scss'],
})
export class HroProductCardComponent implements OnInit, OnDestroy {
  private destroyed$ = new Subject<void>();
  @Input()
  product: IProductDTO;

  @Input()
  productFamily = PRODUCT_FAMILY_SERVICE;

  @Input()
  sendActionRequest: Function;

  @Input()
  bindToAction: Function;

  @Input()
  visibility: string;
  @Input()
  blockRedirect = false;

  @Input() detailsPageUrlPattern: string;

  beneficiary: IBeneficiary;
  displayPrice = false;
  canAddToCart = true;
  canBecomeMember = true;
  hasUniquePrice = false;
  @Input() linkToNewTab = false;

  canSendRequest = false;
  currencyId: string;

  currentLocaleId: string;
  authenticatedUser;

  constructor(
    private productService: ProductService,
    private cartService: CartService,
    private appState: AppStateService,
    private appStateService: AppStateService,
    private store: Store<AppState>,
    private router: Router,
    private sessionStorageService: SessionStorageService,
    @Inject(PLATFORM_ID) private platformId
  ) {}

  ngOnInit(): void {
    this.store
      .select(LOCALE_STATE, LOCALE_ID)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(localeId => (this.currentLocaleId = localeId));
    if (isPlatformBrowser(this.platformId)) {
      this.authenticatedUser = this.sessionStorageService.retrieve('user');
      this.sessionStorageService
        .observe('user')
        .pipe(takeUntil(this.destroyed$))
        .subscribe(user => {
          this.authenticatedUser = user;
        });
    }
    this.store
      .select('beneficiaryState', 'beneficiary')
      .pipe(takeUntil(this.destroyed$))
      .subscribe(beneficiary => {
        this.beneficiary = beneficiary;
      });
    if (this.visibility === 'ALSO_MY_MANAGER') {
      this.canSendRequest = true;
    }
    this.store.select(LOCALE_STATE, LOCALES).subscribe(() => {
      this.currencyId = this.appStateService.getCurrentCurrency();
    });
    this.checkUserCanAddToCart();
  }

  checkUserCanAddToCart() {
    const user = this.appStateService.getUser();
    if (user && user.id) {
      const userGroups: string[] = user.userGroups.map(group => group.id);
      const isDRH = userGroups.includes(DRH_GROUP);
      const isCOACH = userGroups.includes(COACH_GROUP);
      const isServiceProvider = userGroups.includes(SERVICE_PROVIDER_GROUP);
      const isBeneficiary = userGroups.includes(BENEFICIARY_GROUP);
      if (SELLER_ID_TO_DISPLAY.includes(this.product.sellerId) && !(isCOACH || isServiceProvider)) {
        this.hasUniquePrice = true;
        this.displayPrice = true;
        return;
      }
      if (isDRH) {
        this.displayPrice = true;
        this.canAddToCart = true;
        this.canBecomeMember = false;
        return;
      }

      if (isCOACH || isServiceProvider) {
        this.displayPrice = this.hasUniquePrice;
        this.canAddToCart = false;
        this.canBecomeMember = false;
        return;
      }
      if (isBeneficiary) {
        this.displayPrice = this.hasUniquePrice || this.beneficiary?.member;
        this.canAddToCart = this.displayPrice;
        this.canBecomeMember = !this.beneficiary?.member;
        return;
      }
    } else {
      if (SELLER_ID_TO_DISPLAY.includes(this.product.sellerId)) {
        this.hasUniquePrice = true;
        this.displayPrice = true;
        return;
      }
      this.displayPrice = this.hasUniquePrice;
      this.canAddToCart = this.displayPrice;
      this.canBecomeMember = true;
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getImage(image: string, format: string | null): any {
    if (!image) {
      return;
    }
    return this.productService.downloadMedia(image, format);
  }

  counter(i) {
    return new Array(Math.floor(i));
  }

  addToCart() {
    if (this.productFamily === BUNDLES_FAMILY) {
      this.router.navigate(['product/bundle-details/' + this.product.id]);
    }
    const cartItem = new CartLine();
    cartItem.seoData = this.product.seoData;
    cartItem.productType = this.product.productType;
    cartItem.productId = this.product.id;
    cartItem.quantity = 1;
    cartItem.productName = this.product.name;
    cartItem.productMediaId = this.product.mainImageId || this.product.thumbnailId;
    cartItem._class = 'CartLineDTO';
    const price = this.appState.hasMembership() ? this.product.priceWithPromotion : this.product.price;
    cartItem.basePrice = price ? price.amount : 0;
    cartItem.currencyId = price ? price.currency.id : this.currencyId;
    cartItem.sellerId = this.product.sellerId;
    cartItem.productType = this.product.productType;
    cartItem.discount = this.product.discountPercentage;
    this.cartService.addItem(cartItem);
  }

  get isPassexcellenceProduct() {
    return this.product && this.product.sellerId === PASSE_EXCELLENCE;
  }

  get isAuthenticatedAsDRHOrBeneficiary() {
    return (
      this.authenticatedUser &&
      this.authenticatedUser.userGroups.some(userGroup => userGroup.id === BENEFICIARY_GROUP || userGroup.id === DRH_GROUP)
    );
  }

  sendRequest() {
    this.sendActionRequest(this.product);
  }

  bindProductToAction() {
    this.addToCart();
    this.bindToAction ? this.bindToAction(this.product) : null;
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
