import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { LocalStorageService, SessionStorageService } from 'ngx-webstorage';
import { BehaviorSubject, Observable } from 'rxjs';
import { takeLast } from 'rxjs/operators';
import { Cart, ICart } from '../models/cart.model';
import { ICartLine } from '../models/cartLine.model';
import { PASSE_EXCELLENCE, SELLER_ID_TO_DISPLAY } from '../shared/constants';
import { UserAccountService } from './auth/user-account.service';
import { GenericService } from './GenericService';
import { AppStateService } from './utils/state.service';
import { Store } from '@ngrx/store';
import { AppState } from '../store/app.reducer';
import { ProductType } from '../models/product-basic.model';
import { isPlatformBrowser } from '@angular/common';
import { IBookingDTO } from '../models/bookingDTO.model';

@Injectable({
  providedIn: 'root',
})
export class CartService extends GenericService {
  private _cartData = new Cart();
  cart = new BehaviorSubject(this._cartData);
  private _currencyId;
  private _beneficiary;
  private _customerId;

  constructor(
    private localStorage: LocalStorageService,
    sessionStorage: SessionStorageService,
    private toastr: ToastrService,
    private translationService: TranslateService,
    private appState: AppStateService,
    private userAccountService: UserAccountService,
    private store: Store<AppState>,
    @Inject(PLATFORM_ID) private platformId
  ) {
    super();
    if (isPlatformBrowser(platformId)) {
      this.store.select('beneficiaryState', 'beneficiary').subscribe(beneficiary => (this._beneficiary = beneficiary));
      this.initializeCart();
      this.userAccountService.setCanPurchase();
      sessionStorage.observe('user').subscribe(user => {
        user ? this.getCustomerCart(user.id) : null;
        this.userAccountService.setCanPurchase();
      });
      localStorage.observe('user').subscribe(user => {
        user ? this.getCustomerCart(user.id) : null;
        this.userAccountService.setCanPurchase();
      });
      this.store.select('beneficiaryState', 'beneficiary').subscribe(beneficiary => {
        if (beneficiary != null && beneficiary.id) {
          this.getCustomerCart(beneficiary.id);
        }
      });
      this._currencyId = appState.getCurrentCurrency();
    }
  }

  // resetCart(){
  //   this._cartData = new Cart();
  //   this.cart.next(this._cartData)
  // }

  getCustomerCart(customerId: string): void {
    this._customerId = customerId;
    const id = customerId;
    this._getByCustomerId(id)
      .pipe(takeLast(1))
      .subscribe(cart => {
        if (!cart.currencyId) {
          cart.currencyId = this._currencyId;
        }
        this.localStorage.store('cart', cart);
        this.initializeCart();
      });
  }

  initializeCart(): void {
    const initCart = this.localStorage.retrieve('cart');
    if (initCart) {
      this._cartData = initCart;
      this._cartData.currencyId ? null : (this._cartData.currencyId = this._currencyId);
      this.calculate();
      this.cart.next(initCart);
    }
  }

  addItem(item: ICartLine): void {
    if (!this.userAccountService.canPurchase.value) {
      return;
    }
    const index = this._cartData.cartLines.findIndex(element => element.productId == item.productId);
    index == -1 ? this._cartData.cartLines.push(item) : this._cartData.cartLines[index].quantity++;
    let msg = '';
    if (item.sellerId == PASSE_EXCELLENCE) {
      msg = this.translationService.instant('ITEM_ADDED_TO_CART');
    } else {
      msg = this.translationService.instant('ITEM_ADDED_TO_RESERVATIONS');
    }
    this.toastr.success(msg);
    this.updateCart();
  }

  updateCartLineQuantity(item: ICartLine): void {
    const index = this._cartData.cartLines.findIndex(element => element.productId == item.productId);
    index == -1 ? null : (this._cartData.cartLines[index].quantity = item.quantity);
    this.updateCart();
  }

  removeItem(item: ICartLine): void {
    const index = this._cartData.cartLines.findIndex(element => element.productId === item.productId);
    this._cartData.cartLines.splice(index, 1);
    let msg = '';
    if (item.sellerId == PASSE_EXCELLENCE) {
      msg = this.translationService.instant('ITEM_REMOVED_FROM_CART');
    } else {
      msg = this.translationService.instant('ITEM_REMOVED_FROM_RESERVATIONS');
    }
    this.toastr.success(msg);
    this.updateCart();
  }

  removeItems(cartLine: ICartLine[], isOnReservationConfirmation = false): void {
    cartLine.forEach(item => {
      const index = this._cartData.cartLines.findIndex(element => element.productId === item.productId);
      this._cartData.cartLines.splice(index, 1);
      let msg = '';
      if (item.sellerId == PASSE_EXCELLENCE) {
        msg = this.translationService.instant('ITEM_REMOVED_FROM_CART');
      } else {
        if (!isOnReservationConfirmation) {
          msg = this.translationService.instant('ITEM_REMOVED_FROM_RESERVATIONS');
        } else {
          msg = this.translationService.instant('CONFIRMED_RESERVATION');
        }
      }
      this.toastr.success(msg);
    });
    this.updateCart();
  }

  updateCart(save = true, cart?: ICart): void {
    cart ? (this._cartData = cart) : null;
    this._cartData.customerId = this._beneficiary ? this._beneficiary.id : this.appState.getUser()?.id;
    this._cartData.createdBy = this.appState.getUser()?.id;
    this.calculate();
    this._cartData.currencyId = this._currencyId;
    save ? this.save(this._cartData) : null;
    this.cart.next(this._cartData);
    this.localStorage.store('cart', this._cartData);
  }

  calculate(): void {
    this._cartData.totalItems = 0;
    this._cartData.total = 0;
    this._cartData.subTotal = 0;
    this._cartData.totalTax = 0;
    this._cartData.totalToPay = 0;
    this._cartData.deliveryCost = this._cartData.deliveryCost || 0;
    this._cartData.cartLines.forEach((item: ICartLine) => {
      this._cartData.totalItems += item.quantity;
      this._cartData.totalToPay += item.quantity * item.basePrice;
      item.totalPrice = item.quantity * item.basePrice;
      if (item.productType === ProductType.PACK || SELLER_ID_TO_DISPLAY.includes(item.sellerId)) {
        this._cartData.total += item.totalPrice;
        this._cartData.subTotal += item.totalPrice;
      } else {
        // item.basePrice = 0;
        // item.totalPrice = 0;
        item.publicUnitPrice = 0;
        item.memberUnitPrice = 0;
      }
    });
    this._cartData.total += this._cartData.deliveryCost;
    this._cartData.totalToPay += this._cartData.deliveryCost;
  }

  save(cart: ICart): void {
    if (!cart.customerId) {
      return;
    }
    const subscription = cart.id ? this._update(cart) : this._create(cart);
    subscription.subscribe(
      res => {
        this._cartData = res;
        for (const key of Object.keys(res)) {
          this._cartData[key] = cart[key];
        }
      },
      error => {
        const errorMsg = this.translationService.instant(error.error.errorType);
        this.toastr.error(errorMsg);
      },
      () => this.updateCart(false)
    );
  }

  clearCart(): void {
    this.localStorage.clear('cart');
    this._cartData = new Cart();
    this.updateCart(false);
    this.getCustomerCart(this._customerId);
  }

  private _create(cart: ICart): Observable<ICart> {
    return this.api.post('api/carts', cart);
  }

  private _update(cart: ICart): Observable<ICart> {
    return this.api.put('api/carts', cart);
  }

  private _getByCustomerId(customerID: string): Observable<ICart> {
    return this.api.get('api/carts/findByCustomerId/' + customerID);
  }

  createBooking(booking: IBookingDTO): Observable<IBookingDTO> {
    return this.api.post('api/passexcellence/booking', booking);
  }
}
