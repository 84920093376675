import { Injectable } from '@angular/core';
import { IContext } from './models/context.model';
import { IContextItem } from './models/context-item.model';
import { GenericService } from '../services/GenericService';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ContextService extends GenericService {
  constructor() {
    super();
  }

  create(context: IContext): Observable<IContext> {
    return this.api.post('api/contexts', context);
  }

  update(context: IContext): Observable<IContext> {
    return this.api.put('api/contexts/', context);
  }

  find(id: string): Observable<IContext> {
    return this.api.get('api/contexts/' + id);
  }

  findContext(ids: string[]): Observable<IContext> {
    return this.api.post('api/get-context', ids);
  }

  findAll(): Observable<IContext[]> {
    return this.api.get('api/contexts');
  }

  delete(id: string): Observable<any> {
    return this.api.delete('api/contexts/' + id);
  }

  HasSameContexts(contextItems: IContextItem[]): Observable<IContext> {
    return this.api.post('api/contexts/has-context-item', contextItems);
  }
}
