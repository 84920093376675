import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LocalStorageService, SessionStorageService } from 'ngx-webstorage';
import { CurrencyService } from '../currency.service';
import { Store } from '@ngrx/store';
import { AppState } from '../../store/app.reducer';
import * as LocaleActions from '../../store/actions/locale.actions';
import * as ContextActions from '../../store/actions/context.actions';
import * as BeneficiaryActions from '../../store/actions/beneficiary.actions';
import { DRH_GROUP, ENGLISH_UK, FRENCH_FRANCE, LANGUAGE, LOCALE_ID, LOCALE_STATE, PASSE_EXCELLENCE } from '../../shared/constants';
import { UserAccountService } from '../auth/user-account.service';

@Injectable({ providedIn: 'root' })
export class AppStateService {
  private localeId: string;
  private langId: string;
  currencyId: string;
  private user: any;
  private hasValidatedMembership = false;

  constructor(
    public localStorage: LocalStorageService,
    private sessionStorage: LocalStorageService,
    public translate: TranslateService,
    public currencyService: CurrencyService,
    private userAccountService: UserAccountService,
    private store: Store<AppState>
  ) {
    this.getUser();
    this.getLocales();
    this.localStorage.observe(LOCALE_ID).subscribe(locale => {
      this.store.dispatch(LocaleActions.setLocaleId({ localeId: locale }));
      this.getLocales();
      this.store.dispatch(ContextActions.setContextEffect({ ids: [this.localeId] }));
    });
    this.localStorage.observe(LANGUAGE).subscribe(languageId => {
      this.langId = languageId;
      this.changeTranslationDefaultLanguage();
    });
    this.store.select(LOCALE_STATE, LOCALE_ID).subscribe(localeId => (this.localeId = localeId));
    this.store.select('beneficiaryState', 'beneficiary', 'member').subscribe(member => {
      if (member) {
        this.hasValidatedMembership = member;
      }
    });
  }

  resetState() {
    this.user = null;
  }

  changeTranslationDefaultLanguage() {
    this.translate.setDefaultLang(this.langId);
  }

  setCurrentLocale(localeId, langId) {
    this.localStorage.store(LOCALE_ID, localeId);
    this.localStorage.store(LANGUAGE, localeId);
    this.localStorage.store('languageId', langId);
  }
  setCurrency() {
    this.currencyService.getDefaultCurrency(PASSE_EXCELLENCE).subscribe(res => {
      this.localStorage.store('currencyId', res.id);
    });
  }
  initilizeCurrentLocale() {
    const localeId = this.localStorage.retrieve(LOCALE_ID);
    let langId = this.localStorage.retrieve('languageId');
    if (!langId) {
      if (localeId === FRENCH_FRANCE) {
        langId = 'fr';
      }
      if (localeId === ENGLISH_UK) {
        langId = 'en';
      }
    }
    if (localeId && langId) {
      this.setCurrentLocale(localeId, langId);
    } else {
      this.setCurrentLocale(FRENCH_FRANCE, 'fr');
    }
  }

  getCurrentLocaleId(): string {
    return this.localeId;
  }

  getCurrentCurrency(): string {
    this.currencyId = this.localStorage.retrieve('currencyId');
    return this.currencyId;
  }

  getLocales() {
    this.store.dispatch(LocaleActions.setLocalesEffect({ force: true }));
  }

  getUser(): any {
    this.user = this.sessionStorage.retrieve('user');
    return this.user;
  }

  setUser(user: any) {
    this.user = user;
    this.sessionStorage.store('user', user);
  }
  setBeneficiary(beneficiary: any) {
    this.sessionStorage.store('beneficiary', beneficiary);
    this.store.dispatch(BeneficiaryActions.setBeneficiary({ beneficiary }));
  }

  hasMembership(): boolean {
    const isMember = this.sessionStorage.retrieve('beneficiary')?.member || this.userAccountService.hasAnyAuthorityDirect([DRH_GROUP]);
    if (this.user?.member != null) {
      this.hasValidatedMembership = this.user?.member;
    } else if (isMember) {
      this.hasValidatedMembership = isMember;
    }
    return this.hasValidatedMembership;
  }
}
