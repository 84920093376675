import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as ContextActions from '../actions/context.actions';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { ContextService } from '../../cms/context.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class ContextEffects {
  setContext$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ContextActions.setContextEffect),
      mergeMap(action =>
        this.contextService.findContext(action.ids).pipe(
          map(context => ContextActions.setContext({ context: context.id })),
          catchError(error => {
            this.toastr.error(this.translate.instant('GENERIC_ERROR_MSG'));
            throw error;
          })
        )
      )
    )
  );

  constructor(
    private actions$: Actions,
    private contextService: ContextService,
    private toastr: ToastrService,
    private translate: TranslateService
  ) {}
}
