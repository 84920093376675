import { AbstractOrder, IAbstractOrder } from './abstractOrder.model';
import { ICartLine } from './cartLine.model';

export interface ICart extends IAbstractOrder {
  cartStatusId?: string;

  cartLines?: ICartLine[];

  createdBy?: string;

  createdDate?: Date;

  lastModifiedBy?: string;

  lastModifiedDate?: Date;

  promotionDiscount?: number;

  paymentModeId?: string;

  totalItems?: number;

  totalToPay?: number;
}

export class Cart extends AbstractOrder implements ICart {
  constructor(
    public cartStatusId?: string,

    public cartLines?: ICartLine[],

    public createdBy?: string,

    public createdDate?: Date,

    public lastModifiedBy?: string,

    public lastModifiedDate?: Date,

    public promotionDiscount?: number,

    public paymentModeId?: string,

    public totalItems?: number,
    public totalToPay?: number
  ) {
    super();
    this.cartLines = cartLines ? cartLines : [];
  }
}
