export interface IUnavailabilityForSchedule {
  Id?: string;
  Subject?: string;
  StartTime?: Date;
  EndTime?: Date;
  Description?: string;
  IsAllDay?: boolean;
  Category?: string;
  IsBlock?: boolean;
  statusId?: string;
  RecurrenceRule?: string;
  visible?: boolean;
  IsReadonly?: boolean;
}

export class UnavailabilityForSchedule implements IUnavailabilityForSchedule {
  constructor(
    public Id?: string,
    public Subject?: string,
    public StartTime?: Date,
    public EndTime?: Date,
    public Description?: string,
    public IsAllDay?: boolean,
    public visible?: boolean,
    public IsReadonly?: boolean,
    public IsBlock?: boolean,
    public Category?: string,
    public statusId?: string,
    public RecurrenceRule?: string
  ) {}
}
