import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject, Subscription } from 'rxjs';
import { AppStateService } from '../../services/utils/state.service';
import { take, takeUntil } from 'rxjs/operators';
import { CorporateSellerService } from '../corporate-seller.service';
import { IProductDTO } from '../../shared/models/productDTO';
import { PageEvent } from '@angular/material/paginator';
import { ServiceProviderService } from '../../service-provider-space/service-provider.service';
import { SeoService } from '../../services/seo.service';
import { DynamicPathRoutingService } from '../../services/dynamic-path-routing.service';
import { PACK_DETAILS_COMPONENT_NAME, PRODUCT_DETAILS_COMPONENT_NAME } from '../../ComponentsIndex';
import { DynamicPathStorageService } from '../../services/dynamic-path-storage.service';
import { CurrentLocaleService } from '../../services/current-locale.service';

@Component({
  selector: 'app-corporate-seller-details',
  templateUrl: './corporate-seller-details.component.html',
  styleUrls: ['./corporate-seller-details.component.scss'],
})
export class CorporateSellerDetailsComponent implements OnInit, OnDestroy {
  corporateSellerId: string;
  corporateSellerSeoName: string;
  corporateSeller: any;
  currentLocale: string;
  private isDead = new Subject();
  products: IProductDTO[] = [];
  filterObject = {
    categoryId: '',
    className: 'com.heero.pcm.domain.product.Product',
    searchValue: '',
    fields: [],
    sortedField: '',
    onlyProducts: false,
    onlyPacks: false,
  };
  subscription: Subscription = new Subscription();
  totalItems: string;
  page = 0;
  size = 6;
  currencyId: string;

  productDetailsPageUrlPattern: string;
  packDetailsPageUrlPattern: string;
  readonly PRODUCT_PRODUCT_TYPE = 'PRODUCT';

  constructor(
    private activatedRoute: ActivatedRoute,
    private corporateSellerService: CorporateSellerService,
    private appStateService: AppStateService,
    private serviceProviderService: ServiceProviderService,
    private seoService: SeoService,
    private dynamicPathRoutingService: DynamicPathRoutingService,
    private dynamicPathStorageService: DynamicPathStorageService,
    private currentLocaleService: CurrentLocaleService
  ) {
    this.currentLocale = this.currentLocaleService.getCurrentLocale();
  }

  filterProducts() {
    this.products = [];
    this.filterObject.fields = ['NAME', 'categories.label.localizedValues.' + this.currentLocale];
    if (!this.corporateSellerId) {
      return;
    }
    this.subscription = this.serviceProviderService
      .findProductList(this.corporateSellerId, this.currencyId, {
        page: this.page,
        size: this.size,
      })
      .pipe(take(1))
      .subscribe(value => {
        this.products = value.json;
        this.totalItems = value.headers.get('X-Total-Count');
      });
  }
  ngOnInit(): void {
    this.currencyId = this.appStateService.getCurrentCurrency();
    this.currentLocaleService.currentLocaleId$.pipe(takeUntil(this.isDead)).subscribe(res => {
      this.currentLocale = res;
      if (this.corporateSeller) {
        this.corporateSellerSeoName = this.corporateSeller.seoData
          ? this.corporateSeller.seoData.seoName.localizedValues[this.currentLocale]
          : '';
        this.dynamicPathRoutingService.navigateToCorporateSellerDetailsPage(this.corporateSellerSeoName);
      }
    });
    this.activatedRoute.params.pipe(takeUntil(this.isDead)).subscribe(params => {
      this.corporateSellerSeoName = params.seoName;
      if (this.corporateSeller) {
        this.getCorporateSeller();
      }
    });
    this.getCorporateSeller();
    this.filterProducts();

    this.dynamicPathStorageService.urlPatterns$.pipe(takeUntil(this.isDead)).subscribe(() => {
      this.productDetailsPageUrlPattern =
        this.dynamicPathStorageService.getUrlPatternByComponentNameFromCmsPageList(PRODUCT_DETAILS_COMPONENT_NAME);
      this.packDetailsPageUrlPattern =
        this.dynamicPathStorageService.getUrlPatternByComponentNameFromCmsPageList(PACK_DETAILS_COMPONENT_NAME);
    });
  }
  handlePageEvent($event: PageEvent) {
    this.page = $event.pageIndex;
    this.filterProducts();
  }

  getCorporateSeller() {
    if (this.corporateSellerSeoName && this.currentLocale) {
      const corporateSeller$ = this.corporateSellerService.getCorporateSellerBySeoName(this.corporateSellerSeoName, this.currentLocale);
      corporateSeller$.pipe(takeUntil(this.isDead)).subscribe(value => {
        this.corporateSeller = value;
        this.corporateSellerId = this.corporateSeller.id;
        this.filterProducts();
        this.setCorporateSellerSeoData();
      });
    }
  }

  setCorporateSellerSeoData() {
    const imageUrl = this.corporateSeller.logo && this.corporateSeller.logo.id ? this.getImage(this.corporateSeller.logo.id, null) : '';
    this.seoService.setSeoFromSeoDataAndMedia(this.corporateSeller.seoData, imageUrl);
  }

  getImage(image: any, format: string | null): any {
    if (!image) {
      return;
    }
    return this.corporateSellerService.downloadMedia(image, format);
  }

  ngOnDestroy(): void {
    this.isDead.next();
    this.seoService.resetSeo();
  }
}
