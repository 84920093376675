<div class="row" *ngIf="packEntries; else noProducts">
  <div class="col-lg-4 col-md-4 col-sm-12" style="padding: 5px" *ngFor="let product of packEntries">
    <a
      [routerLink]="
        productDetailsPageUrlPattern
          ? (productDetailsPageUrlPattern | seoNameUrl : product.productBasicDTO.seoData : currentLocaleId)
          : null
      ">
      <div class="card">
        <div>
          <img
            style="height: 200px; width: 100%; min-width: 100%; max-width: 100%"
            [src]="getImage(product.productBasicDTO.media, null)"
            onerror="this.src='assets/img/not-found.png'"
            class="card-img-top"
            [alt]="product.productBasicDTO?.media?.alt ?? product.productBasicDTO.label" />
        </div>
        <div class="card-body">
          <span class="card-text"> {{ product.productBasicDTO.label }} </span>
          <p class="card-text">{{ 'QUANTITY' | translate }}: {{ product.quantity }}</p>
        </div>
      </div>
    </a>
  </div>
</div>

<ng-template #noProducts>
  <div class="no-data">{{ 'NO_PRODUCTS' | translate }}</div>
</ng-template>
