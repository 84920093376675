import { Component, OnDestroy, OnInit } from '@angular/core';
import { CmsService } from '../../cms/cms.service';
import { SeoService } from '../../services/seo.service';
import { Store } from '@ngrx/store';
import { AppState } from '../../store/app.reducer';
import { Subject } from 'rxjs';
import { PRODUCTS_PAGE } from '../../shared/constants';
import { takeUntil } from 'rxjs/operators';
import { PRODUCT_COMPONENT_NAME } from '../../ComponentsIndex';
import { DynamicPathStorageService } from '../../services/dynamic-path-storage.service';
import { Router } from '@angular/router';
import { CurrentLocaleService } from '../../services/current-locale.service';

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss'],
})
export class ProductComponent implements OnInit, OnDestroy {
  private destroyed$ = new Subject();
  cmsPage: any;
  currentLocaleId: string;

  constructor(
    private cmsService: CmsService,
    private seoService: SeoService,
    private store: Store<AppState>,
    private dynamicPathStorageService: DynamicPathStorageService,
    private router: Router,
    private currentLocaleService: CurrentLocaleService
  ) {}

  ngOnInit(): void {
    this.currentLocaleService.currentLocaleId$.pipe(takeUntil(this.destroyed$)).subscribe(localeId => {
      this.currentLocaleId = localeId;
      if (this.cmsPage) {
        this.seoService.setSeoFromSeoDataAndMedia(this.cmsPage.seoData);
      }
      const currentLocaleUrlPattern = this.dynamicPathStorageService.getUrlPatternByComponentNameFromCmsPageList(PRODUCT_COMPONENT_NAME);
      if (currentLocaleUrlPattern) {
        this.router.navigate([currentLocaleUrlPattern]);
      }
    });
    this.cmsService
      .getCmsPageByCode(PRODUCTS_PAGE)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(res => {
        this.cmsPage = res;
        this.seoService.setSeoFromSeoDataAndMedia(this.cmsPage.seoData);
      });
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.seoService.resetSeo();
  }
}
