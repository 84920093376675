import { Component, OnDestroy, OnInit } from '@angular/core';
import { IContext } from '../../../cms/models/context.model';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CmsService } from '../../../cms/cms.service';
import { AppStateService } from 'src/app/services/utils/state.service';
import { HOME_PAGE } from '../../../shared/constants';

@Component({
  selector: 'app-home-page-one',
  templateUrl: './home-page-one.component.html',
  styleUrls: ['./home-page-one.component.scss'],
})
export class HomePageOneComponent implements OnInit, OnDestroy {
  title = 'cms-test';
  currentLocale: string;
  context: IContext | null | undefined;
  components: any[] | undefined;
  component: any;
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  constructor(private cmsService: CmsService, appStateService: AppStateService) {
    this.currentLocale = appStateService.getCurrentLocaleId();
  }

  ngOnInit(): void {
    this.getCmsPage();
  }

  getCmsPage(): void {
    this.cmsService
      .getCmsPageByCode(HOME_PAGE)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(res => {
        this.components = res.cmsComponents;
        res.cmsContainers.forEach(container => {
          container.cmsComponents.forEach(component => {
            this.components.push(component);
          });
        });
      });
  }

  getIdComponentByCode(code: string): any {
    if (this.components && this.components.length > 0) {
      const index = this.components.findIndex(component => component.code === code);
      if (index !== -1) {
        return this.components[index].id;
      }
    }
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
