/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppStateService } from '../../services/utils/state.service';
import { extend, L10n, loadCldr } from '@syncfusion/ej2-base';
import {
  ActionEventArgs,
  AgendaService,
  DayService,
  EJ2Instance,
  EventRenderedArgs,
  EventSettingsModel,
  MonthService,
  MoreEventsClickArgs,
  PopupOpenEventArgs,
  ResizeService,
  ScheduleComponent,
  WeekService,
  WorkHoursModel,
  WorkWeekService,
} from '@syncfusion/ej2-angular-schedule';
import { TranslateService } from '@ngx-translate/core';
import { take, takeUntil } from 'rxjs/operators';
import { CoachSpaceService } from '../../coach-space/coach-space.service';
import { CalendarEvent, ICalendarEvent } from '../../models/CalendarEvent.model';
import { IUnavailabilityForSchedule, UnavailabilityForSchedule } from '../../models/unavalibleForScheduleModel.model';
import { IAppointmentStatus } from '../../shared/models/appointment-status.model';
import { AppointmentService } from '../../services/appointment.service';
import { AppointmentStatusService } from '../../services/appointment-status.service';
import { Subject } from 'rxjs';
import { Appointment } from '../../shared/models/appointment.model';
import {
  APPOINMENT_CREATED,
  APPOINMENT_UPDATED,
  APPOINTMENT,
  APPROVED_APPOINTMENT,
  BENEFICIARY_GROUP,
  DISAPPROVED_APPOINTMENT,
  EDITOR,
  ENGLISH_UK,
  EVENT_CHANGED,
  EVENT_CREATED,
  EVENT_REMOVED,
  LOCALE_ID,
  LOCALE_STATE,
  PENDING_APPOINTMENT,
  QUICK_INFO,
  REPORTED_APPOINTMENT,
} from '../../shared/constants';
import { UserAccountService } from '../../services/auth/user-account.service';
import { IndividualCoach } from '../../models/coach.model';
import { Store } from '@ngrx/store';
import { AppState } from '../../store/app.reducer';
import { ToastrService } from 'ngx-toastr';
import { DropDownListComponent } from '@syncfusion/ej2-angular-dropdowns';
import { DynamicPathRoutingService } from '../../services/dynamic-path-routing.service';
import { CoachService } from '../coach.service';
import { COACH_DETAILS_COMPONENT_NAME } from '../../ComponentsIndex';
import { DynamicPathStorageService } from '../../services/dynamic-path-storage.service';
import { FormValidator } from '@syncfusion/ej2-angular-inputs';
import { NgxSpinnerService } from 'ngx-spinner';
import { Button } from '@syncfusion/ej2-buttons';

declare let require: any;
loadCldr(
  require('cldr-data/supplemental/numberingSystems.json'),
  require('cldr-data/main/fr-CH/ca-gregorian.json'),
  require('cldr-data/main/fr-CH/numbers.json'),
  require('cldr-data/main/fr-CH/timeZoneNames.json'),
  require('cldr-data/supplemental/numberingSystems.json')
);

@Component({
  selector: 'app-coach-book',
  templateUrl: './coach-book.component.html',
  styleUrls: ['./coach-book.component.scss'],
  encapsulation: ViewEncapsulation.None,

  providers: [
    DayService,
    WeekService,
    WorkWeekService,
    MonthService,
    AgendaService,
    ResizeService,
    // DragAndDropService,
  ],
})
export class CoachBookComponent implements OnInit, OnDestroy {
  @ViewChild('scheduleObj', { static: true })
  public scheduleObj: ScheduleComponent;
  public currentLocale: string;
  public local; // = "fr-CH";
  startDate: Date;
  statuses: IAppointmentStatus[] = [];
  allDay = false;
  public currentView = 'Week';
  customer = new IndividualCoach();
  user: any;
  endDate: Date;
  unavalibles: CalendarEvent[];
  appointmentStatus: IAppointmentStatus;
  unavalibleForSchedule: IUnavailabilityForSchedule[] = [];
  currentCurrency;
  voucherCode = '';
  allowEditing = true;
  allowDeleting = true;
  allowAdding = true;
  public eventSettings: EventSettingsModel = {
    dataSource: extend([], [], null, true) as Record<string, any>[],
    fields: {
      subject: {
        name: 'Subject',
        validation: {
          required: true,
        },
      },
    },
  };
  public selectedDate: Date;
  public fields = {
    text: 'name.localizedValues[currentLocale]',
    value: 'code',
  };
  public labelTypeAppointment: string[] = ['PROFILING_NOVA', 'COACHING'];
  public placeholder = 'SELECT_OPTION';
  @ViewChild('Subject')
  public dropDownListObject: DropDownListComponent;
  private coachId: string;
  coachSeoName: string;
  private _isDead$ = new Subject();
  coachDetailsPageUrlPattern: string;
  public show = true;
  public startHour = '08:00';
  public endHour = '20:00';
  public workHours: WorkHoursModel = { highlight: false };
  coach;
  public showWeekend = false;
  public workDays: number[] = [0, 1, 2, 3, 4, 5, 6];
  public timezoneData: Record<string, any>[] = [
    { text: 'UTC -12:00', value: 'Etc/GMT+12' },
    { text: 'UTC -11:00', value: 'Etc/GMT+11' },
    { text: 'UTC -10:00', value: 'Etc/GMT+10' },
    { text: 'UTC -09:00', value: 'Etc/GMT+9' },
    { text: 'UTC -08:00', value: 'Etc/GMT+8' },
    { text: 'UTC -07:00', value: 'Etc/GMT+7' },
    { text: 'UTC -06:00', value: 'Etc/GMT+6' },
    { text: 'UTC -05:00', value: 'Etc/GMT+5' },
    { text: 'UTC -04:00', value: 'Etc/GMT+4' },
    { text: 'UTC -03:00', value: 'Etc/GMT+3' },
    { text: 'UTC -02:00', value: 'Etc/GMT+2' },
    { text: 'UTC -01:00', value: 'Etc/GMT+1' },
    { text: 'UTC +00:00', value: 'Etc/GMT' },
    { text: 'UTC +01:00', value: 'Etc/GMT-1' },
    { text: 'UTC +02:00', value: 'Etc/GMT-2' },
    { text: 'UTC +03:00', value: 'Etc/GMT-3' },
    { text: 'UTC +04:00', value: 'Etc/GMT-4' },
    { text: 'UTC +05:00', value: 'Etc/GMT-5' },
    { text: 'UTC +05:30', value: 'Asia/Calcutta' },
    { text: 'UTC +06:00', value: 'Etc/GMT-6' },
    { text: 'UTC +07:00', value: 'Etc/GMT-7' },
    { text: 'UTC +08:00', value: 'Etc/GMT-8' },
    { text: 'UTC +09:00', value: 'Etc/GMT-9' },
    { text: 'UTC +10:00', value: 'Etc/GMT-10' },
    { text: 'UTC +11:00', value: 'Etc/GMT-11' },
    { text: 'UTC +12:00', value: 'Etc/GMT-12' },
    { text: 'UTC +13:00', value: 'Etc/GMT-13' },
    { text: 'UTC +14:00', value: 'Etc/GMT-14' },
  ];
  public validator: FormValidator;
  public timezone = 'UTC';
  public minDate: Date;
  public maxDate: Date;
  public selectedSubject: string;
  private labelTranslation: Map<string, string> = new Map();
  constructor(
    private activatedRoute: ActivatedRoute,
    private appStateService: AppStateService,
    private userAccountService: UserAccountService,
    private coachSpaceService: CoachSpaceService,
    public toastr: ToastrService,
    private appointmentService: AppointmentService,
    private router: Router,
    private appointmentStatusService: AppointmentStatusService,
    private translationService: TranslateService,
    private store: Store<AppState>,
    private dynamicPathRoutingService: DynamicPathRoutingService,
    private coachService: CoachService,
    private dynamicPathStorageService: DynamicPathStorageService,
    private spinner: NgxSpinnerService
  ) {
    this.currentLocale = appStateService.getCurrentLocaleId();
    if (this.currentLocale === ENGLISH_UK) {
      this.local = 'en-US';
    } else {
      this.local = 'fr-CH';
    }
    this.loadTranslationsForSchedule();
  }

  ngOnInit(): void {
    this.labelTypeAppointment.forEach(element => {
      this.translationService.get(element).subscribe(res => this.labelTranslation.set(res, element));
    });
    // this.getAvailibility();
    this.activatedRoute.params.subscribe(params => {
      this.coachSeoName = params.seoName;
      this.getCoach();
    });
    // this.getCustomer();
    this.store
      .select(LOCALE_STATE, LOCALE_ID)
      .pipe(takeUntil(this._isDead$))
      .subscribe(localeId => {
        this.currentLocale = localeId;
        this.currentLocale = this.appStateService.getCurrentLocaleId();
        this.currentCurrency = this.appStateService.getCurrentCurrency();

        if (this.currentLocale === ENGLISH_UK) {
          this.local = 'en-US';
        } else {
          this.local = 'fr-CH';
        }

        this.getStartAndEndDays();
        this.getCustomer();

        // renavigate to page with new locale seoname
        const seoName = this.coach && this.coach.seoData ? this.coach.seoData.seoName.localizedValues[this.currentLocale] : '';
        if (seoName && this.coach && this.coach.id) {
          this.router.navigate(['/coach/book/' + seoName]);
          return;
        }
      });
    this.translationService.onDefaultLangChange.subscribe(() => {
      this.loadTranslationsForSchedule();
    });

    // this.getStartAndEndDays();
    this.appointmentStatusService
      .query()
      .pipe(takeUntil(this._isDead$))
      .subscribe(res => (this.statuses = res.json));

    this.dynamicPathStorageService.urlPatterns$.pipe(takeUntil(this._isDead$)).subscribe(() => {
      this.coachDetailsPageUrlPattern =
        this.dynamicPathStorageService.getUrlPatternByComponentNameFromCmsPageList(COACH_DETAILS_COMPONENT_NAME);
    });
  }

  getCoach(): void {
    this.spinner.show();
    const coach$ = this.coachService.getIndividualCoachBySeoName(this.coachSeoName);
    coach$.pipe(takeUntil(this._isDead$)).subscribe(coach => {
      this.coach = coach;
      this.coachId = coach.id;
      this.getAvailibility();
      this.getUnavailble();
    });
  }

  getAvailibility(): void {
    if (!this.coachId) {
      return;
    }
    this.coachSpaceService
      .getCoachAvailability(this.coachId)
      .pipe(take(1))
      .subscribe(res => {
        this.startHour = res.startWorkHour ? res.startWorkHour : '00:00';
        this.endHour = res.endWorkHour ? res.endWorkHour : '23:59';
        this.workDays = res.workDays.length ? res.workDays : this.workDays;
        this.timezone = res.timezoneData;
      });
  }

  getStartAndEndDays(): void {
    this.selectedDate = new Date();
    this.startDate = new Date(this.selectedDate.setDate(this.selectedDate.getDate() - this.selectedDate.getDay()));
    this.endDate = new Date(this.selectedDate.setDate(this.selectedDate.getDate() - this.selectedDate.getDay() + 6));
    this.getInitialStartDay(this.startDate);
    this.getInitialEndDay(this.endDate);
  }

  getCustomer(): void {
    this.userAccountService
      .getCurrentUser()
      .pipe(take(1))
      .subscribe(res => {
        this.user = res;
        if (this.user.userGroups[0].code === BENEFICIARY_GROUP) {
          // this.store.dispatch(BeneficiaryActions.setBeneficiaryEffect());
          this.store.select('beneficiaryState', 'beneficiary').subscribe(beneficiary => {
            if (beneficiary) {
              this.customer = beneficiary;
            }
          });
        }
      });
  }

  getUnavailble(): void {
    this.coachSpaceService
      .getUnavaibleByCoach(this.coachId)
      .pipe(take(1))
      .subscribe(
        (result: any) => {
          this.unavalibles = result;
          this.eventSettings = {
            dataSource: extend(
              [],
              this.getUnavailbleForschedule(this.unavalibles),
              null,
              false
              // eslint-disable-next-line @typescript-eslint/ban-types
            ) as Object[],
            allowEditing: this.allowEditing,
            allowAdding: this.allowAdding,
            allowDeleting: this.allowDeleting,
          };
        },
        () => {
          this.spinner.hide();
        }
      );
  }

  // eslint-disable-next-line prettier/prettier
  getUnavailbleForschedule(unavalibles: ICalendarEvent[]): IUnavailabilityForSchedule[] {
    this.unavalibleForSchedule = [];
    unavalibles.forEach(unavailable => {
      let i = 0;
      if (unavailable.unavailabilityEntryTimeSlots != null) {
        unavailable.unavailabilityEntryTimeSlots.forEach(value => {
          const unavaibilityForSchedule = new UnavailabilityForSchedule();
          unavaibilityForSchedule.Id = unavailable.id + '_' + i;
          unavaibilityForSchedule.Subject = this.translationService.instant('NOT_AVAILABE');
          unavaibilityForSchedule.StartTime = value.startTime;
          unavaibilityForSchedule.EndTime = value.endTime;
          unavaibilityForSchedule.Category = unavailable.category;
          unavaibilityForSchedule.IsBlock = this.currentView != 'Month';
          unavaibilityForSchedule.RecurrenceRule = value.recurrenceRule;
          unavaibilityForSchedule.IsAllDay = false;
          unavaibilityForSchedule.IsReadonly = true;
          unavaibilityForSchedule.visible = false;
          i += 1;
          this.unavalibleForSchedule.push(unavaibilityForSchedule);
        });
      } else if (unavailable.category === APPOINTMENT) {
        if (unavailable.customerId == this.customer?.id) {
          const meetingForSchedule = new UnavailabilityForSchedule();
          meetingForSchedule.Id = unavailable.id;
          meetingForSchedule.Subject = unavailable.label;
          meetingForSchedule.StartTime = unavailable.startDate;
          meetingForSchedule.IsAllDay = false;
          meetingForSchedule.EndTime = unavailable.endDate;
          meetingForSchedule.Category = unavailable.category;
          meetingForSchedule.statusId = unavailable.appointmentStatusId;
          meetingForSchedule.visible = true;
          this.unavalibleForSchedule.push(meetingForSchedule);
        } else if (unavailable.appointmentStatusId === APPROVED_APPOINTMENT) {
          const meetingForSchedule = new UnavailabilityForSchedule();
          meetingForSchedule.Id = unavailable.id;
          meetingForSchedule.StartTime = unavailable.startDate;
          meetingForSchedule.IsAllDay = false;
          meetingForSchedule.EndTime = unavailable.endDate;
          meetingForSchedule.Category = unavailable.category;
          // meetingForSchedule.statusId = unavailable.appointmentStatusId;
          meetingForSchedule.IsBlock = this.currentView != 'Month';
          meetingForSchedule.visible = false;
          meetingForSchedule.IsReadonly = true;
          meetingForSchedule.Subject = this.translationService.instant('NOT_AVAILABE');
          this.unavalibleForSchedule.push(meetingForSchedule);
        }
      } else {
        const meetingForSchedule = new UnavailabilityForSchedule();
        meetingForSchedule.Id = unavailable.id;
        meetingForSchedule.Subject = this.translationService.instant('NOT_AVAILABE');
        meetingForSchedule.StartTime = unavailable.date;
        meetingForSchedule.IsAllDay = unavailable.allDay;
        meetingForSchedule.EndTime = unavailable.date;
        meetingForSchedule.Category = unavailable.category;
        meetingForSchedule.IsBlock = true;
        meetingForSchedule.RecurrenceRule = unavailable.recurrenceRule;
        meetingForSchedule.IsReadonly = true;
        // meetingForSchedule.IsAllDay = unavailable.allDay;
        meetingForSchedule.visible = false;
        this.unavalibleForSchedule.push(meetingForSchedule);
      }
    });
    this.spinner.hide();
    return this.unavalibleForSchedule;
  }

  changeCurrentView(): void {
    this.spinner.show();
    this.eventSettings = {
      dataSource: extend(
        [],
        this.getUnavailbleForschedule(this.unavalibles),
        null,
        false
        // eslint-disable-next-line @typescript-eslint/ban-types
      ) as Object[],
      allowEditing: this.allowEditing,
      allowAdding: this.allowAdding,
      allowDeleting: this.allowDeleting,
    };
  }

  loadTranslationsForSchedule(): void {
    L10n.load({
      'en-US': {
        schedule: {
          cancelButton: 'Cancel',
          saveButton: 'Save',
          newEvent: 'Add apointment',
          editEvent: 'Edit appointment',
          deleteEvent: 'Delete apointment',
        },
      },
      'fr-CH': {
        schedule: {
          day: 'jour',
          week: 'semaine',
          month: 'Mois',
          today: 'Aujourd`hui',
          cancelButton: this.translationService.instant('CANCEL'),
          saveButton: this.translationService.instant('SAVE'),
          newEvent: this.translationService.instant('NEW_APPOINTMENT'),
          editEvent: this.translationService.instant('EDIT_APPOINTMENT'),
          deleteEvent: this.translationService.instant('DELETE_APPOINTMENT'),
          moreDetails: this.translationService.instant('MORE_DETAILS'),
          deleteButton: this.translationService.instant('DELETE_BUTTON'),
          save: this.translationService.instant('SAVE'),
          addTitle: this.translationService.instant('ADD_TITLE'),
          deleteContent: this.translationService.instant('DELETE_CONTENT_APPOINTMENT'),
          previous: this.translationService.instant('PREVIOUS'),
          next: this.translationService.instant('NEXT'),
          delete: this.translationService.instant('DELETE'),
          cancel: this.translationService.instant('CANCEL'),
          End: this.translationService.instant('END'),
          more: this.translationService.instant('MORE'),
          MONTH_AGENDA: this.translationService.instant('MONTH_AGENDA'),
          blockAlert: this.translationService.instant('BLOCKEDALERT'),
          alert: this.translationService.instant('ALERT'),
          noEvents: '',
        },
        calendar: {
          today: 'Aujourd`hui',
        },
        datetimepicker: {
          today: this.translationService.instant('DAY'),
        },
      },
    });
  }

  getInitialStartDay(date: Date): void {
    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(0);
    date.setHours(date.getHours() + 1);
  }

  getInitialEndDay(date: Date): void {
    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(0);
    date.setHours(date.getHours() + 25);
  }

  onActionComplete(args: ActionEventArgs): void {
    args.data ? (args.data[0].Subject = this.selectedSubject) : null;
    if (args.requestType === EVENT_CREATED) {
      if (!this.voucherCode || this.voucherCode === '') {
        const errorMsg = this.translationService.instant('VOUCHER_REQUIRED');
        this.toastr.error(errorMsg);
        this.getUnavailble();
        return;
      }
      this.spinner.show();
      this.appointmentService.validateVoucher(this.voucherCode, this.customer.id).subscribe(
        () => {
          this.onValidateVoucherSuccess(args);
          this.spinner.hide();
        },
        error => {
          const errorMsg = this.translationService.instant(error);
          this.toastr.error(errorMsg);
          this.getUnavailble();
          this.voucherCode = '';
          this.spinner.hide();
        },
        () => {
          this.getUnavailble();
          this.voucherCode = '';
          this.spinner.hide();
        }
      );
    }
    if (args.requestType === EVENT_CHANGED) {
      const data: any = args.data[0] as any;
      const appointment = new Appointment();
      appointment.id = data.Id;
      appointment.label = data.Subject;
      appointment.startDate = data.StartTime;
      appointment.sellerId = this.coachId;
      appointment.endDate = data.EndTime;
      appointment.customerId = this.customer?.id;
      appointment.voucherCode = this.voucherCode;
      this.spinner.show();
      this.appointmentService.partialUpdate(appointment).subscribe(
        () => {
          this.spinner.hide();
          this.getUnavailble();
          const successMsg = this.translationService.instant(APPOINMENT_UPDATED);
          this.toastr.success(successMsg);
        },
        () => this.spinner.hide()
      );
    }

    if (args.requestType === EVENT_REMOVED) {
      const data = args.data[0] as any;
      this.appointmentService.delete(data.Id).subscribe(() => {
        this.getUnavailble();
      });
    }
  }

  onValidateVoucherSuccess(args: any): void {
    const data = args.data[0] as any;
    const appointment = new Appointment();
    if ((data.Subject != 'PROFILING_NOVA' || data.Subject != 'COACHING') && args.requestType == EVENT_CREATED) {
      data.Subject = this.selectedSubject;
      // this.selectedSubject = null;
    }
    appointment.label = data.Subject;
    appointment.startDate = data.StartTime;
    appointment.endDate = data.EndTime;
    appointment.sellerId = this.coachId;
    appointment.appointmentStatus = this.statuses.filter(status => status.id === PENDING_APPOINTMENT)[0];
    appointment.customerId = this.customer?.id;
    appointment.voucherCode = this.voucherCode;
    this.spinner.show();
    this.appointmentService.create(appointment).subscribe(
      () => {
        this.spinner.hide();
        this.getUnavailble();
        const successMsg = this.translationService.instant(APPOINMENT_CREATED);
        this.toastr.success(successMsg);
      },
      () => {
        this.spinner.hide();
        this.toastr.error('VOUCHER_NOT_VALID');
      }
    );
  }

  onEventRendered(args: EventRenderedArgs): void {
    if (args.data.statusId === APPROVED_APPOINTMENT && args.data.IsBlock !== true) {
      args.element.style.backgroundColor = '#22b24b';
    } else if (args.data.statusId === REPORTED_APPOINTMENT && args.data.IsBlock !== true) {
      args.element.style.backgroundColor = '#8f4fb4';
    } else if (args.data.statusId === PENDING_APPOINTMENT && args.data.IsBlock !== true) {
      args.element.style.backgroundColor = '#dc9a19';
    } else if (args.data.statusId === DISAPPROVED_APPOINTMENT && args.data.IsBlock !== true) {
      args.element.style.backgroundColor = '#bd4021';
    } else if (!args.data.IsAllDay) {
      // var now = new Date().toISOString().split("T")[0];
      // const isInTimeRange = this.startHour
      //   ? new Date(args.data.StartTime).getTime() >
      //     new Date(now + " " + this.startHour).getTime()
      //   : false;

      if (this.currentView != 'Month') {
        // if(isInTimeRange && !args.data.RecurrenceRule  ){
        //    // args.element.style.backgroundColor = "gray";
        // }else {
        //     //args.data.IsBlock = true;
        //    //(args.element as HTMLElement).style.width = '0px';
        // }
      } else {
        args.data.visible = false;
        args.element.hidden = true;
      }
    }
  }

  getAppointmentLabel(translationValue: string): string {
    const subject: string = this.labelTranslation.get(translationValue);
    this.selectedSubject = subject || translationValue;
    return subject;
  }
  onPopupOpen(args): void {
    if (args.type === QUICK_INFO) {
      args.cancel = true;
    }
    if (args.type === QUICK_INFO && !args.target.classList.contains('e-appointment')) {
      args.EditEventInfo = false;
      args.ViewEventInfo = false;
      args.EventContainer = false;
      args.cancel = true;
    }
    if (args.type === EDITOR && args.data.statusId == 'DISAPPROVED_APPOINTMENT') {
      args.cancel = true;
      return;
    }

    if (args.type === EDITOR && args.data.statusId === APPROVED_APPOINTMENT) {
      const buttonElement = '.e-schedule-dialog .e-event-delete';
      const deleteButton = document.querySelector(buttonElement);
      if (deleteButton && (deleteButton as EJ2Instance).ej2_instances) {
        ((deleteButton as EJ2Instance).ej2_instances[0] as Button).disabled = true;
      }
    }
    if (args.type === EDITOR && args.data.statusId !== 'PENDING_APPOINTMENT') {
      const isPosteriorDate = new Date(args.data.StartTime) < new Date();
      if (isPosteriorDate) {
        args.cancel = true;
      }
      this.appointmentStatus = this.statuses.filter(status => status.id === args.data.statusId)[0];
      const formElement: HTMLElement = args.element.querySelector('.e-schedule-form') as HTMLElement;
      const validator: FormValidator = (formElement as EJ2Instance).ej2_instances[0] as FormValidator;
      if (this.appointmentStatus == undefined) {
        validator.addRules('voucherCode', {
          required: [true, this.translationService.instant('REQUIRED_FIELD')],
        });
        validator.addRules('Subject', {
          required: [true, this.translationService.instant('REQUIRED_FIELD')],
        });
      }
    }
    args.data.Subject = this.getAppointmentLabel(args.data.Subject);
  }

  back() {
    const coachSeoName = this.coach.seoData.seoName.localizedValues[this.currentLocale];
    if (coachSeoName) {
      this.dynamicPathRoutingService.navigateToCoachDetailsPage(coachSeoName);
    }
  }

  // validateVoucher(): Promise<void> {
  //   return this.appointmentService
  //     .validateVoucher(this.voucherCode, this.customer.id)
  //     .toPromise();
  // }

  displaySelectedDate(event) {
    const selectedDate: Date = event.startTime;
    const selectedTile = selectedDate.getHours();
    const startDate = new Date(new Date(selectedDate).setHours(selectedDate.getHours() + (selectedTile == 0 ? 9 : 0)));
    event.startTime = startDate;
    event.endTime = new Date(new Date(startDate).setHours(startDate.getHours() + 2));
    const now = new Date(startDate).toISOString().split('T')[0];
    if (this.startHour) {
      const isInTimeRange = new Date(now + ' ' + this.startHour).getTime();
      this.minDate = new Date(new Date(selectedDate).setTime(isInTimeRange));
    }
    if (this.endHour) {
      const isInTimeRMax = new Date(now + ' ' + this.endHour).getTime();
      this.maxDate = new Date(new Date(selectedDate).setTime(isInTimeRMax));
    }
  }

  ngOnDestroy() {
    this._isDead$.next();
  }

  changeSubject(event, data) {
    if (event.itemData.value == 'PROFILING_NOVA') {
      const selectedDate: Date = data.StartTime;
      const selectedTile = selectedDate.getHours();
      const startDate = new Date(new Date(selectedDate).setHours(selectedDate.getHours() + (selectedTile == 0 ? 9 : 0)));
      data.EndTime = new Date(new Date(startDate).setHours(startDate.getHours() + 2));
      data.Subject = 'PROFILING_NOVA';
    }
    if (event.itemData.value == 'COACHING') {
      const selectedDate: Date = data.StartTime;
      const selectedTile = selectedDate.getHours();
      const startDate = new Date(new Date(selectedDate).setHours(selectedDate.getHours() + (selectedTile == 0 ? 9 : 0)));
      data.EndTime = new Date(new Date(startDate).setHours(startDate.getHours() + 1));
      data.Subject = 'COACHING';
    }
    this.selectedSubject = event.itemData.value;
  }

  public btnClick(): void {
    if (this.show) {
      this.scheduleObj.showWeekend = true;
      this.scheduleObj.dataBind();
    } else {
      this.scheduleObj.showWeekend = false;
      this.scheduleObj.dataBind();
    }
    this.show = !this.show;
  }
}
