import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { GenericService } from '../services/GenericService';
import { Store } from '@ngrx/store';
import { AppState } from '../store/app.reducer';
import { LOCALE_ID, LOCALE_STATE } from '../shared/constants';

@Injectable({
  providedIn: 'root',
})
export class CmsService extends GenericService {
  private currentLocaleId: string;

  constructor(private store: Store<AppState>) {
    super();
    this.store.select(LOCALE_STATE, LOCALE_ID).subscribe(localeId => (this.currentLocaleId = localeId));
  }

  getCmsComponentsByPageCodeAndCode(pageCode: string, code: string): Observable<any> {
    return this.api.get('api/cms-components-by-page-code-and-code/' + pageCode + '/' + code);
  }

  getCmsComponentHtmlContentsByComponentAndContext(componentId: string | undefined, contextId: string | undefined): Observable<any> {
    return this.api.get('api/cms-html-content-by-component-and-context/' + componentId + '/' + contextId);
  }
  getCmsComponentMediaContentsByComponentAndContext(componentId: string | undefined, contextId: string | undefined): Observable<any> {
    return this.api.get('api/cms-media-content-by-component-and-context/' + componentId + '/' + contextId);
  }
  getCmsComponentImageContentsByComponent(componentId: string): Observable<any> {
    return this.api.get('api/cms-image-content-by-component/' + componentId);
  }

  getCmsComponentBannerContentsByComponentAndContext(componentId: string | undefined, contextId: string | undefined): Observable<any> {
    return this.api.get('api/cms-banner-content-by-component-and-context/' + componentId + '/' + contextId);
  }
  getCmsComponentListContentsByComponentAndContext(componentId: string | undefined, contextId: string | undefined): Observable<any> {
    return this.api.get('api/cms-list-content-by-component-and-context/' + componentId + '/' + contextId);
  }

  getBannerImage(componentId: string, contextId: string): Observable<any> {
    return this.api.get('api/cms-image-content-banner/' + componentId + '/' + contextId);
  }

  getBanner(componentId: string | undefined, contextId: string | undefined): Observable<any> {
    return this.api.get('api/cms-media-content/' + componentId + '/' + contextId);
  }

  downloadMedia(mediaId: string, format: string | null): any {
    return environment.apiUrl + 'api/export-media/' + mediaId + ' /' + format;
  }

  getCmsPageByCode(code: string | undefined): Observable<any> {
    return this.api.get('api/cms-pages-by-code/' + code);
  }

  getCmsComponentById(id: string | undefined): Observable<any> {
    return this.api.get('api/cms-components-entity/' + id + '/' + this.currentLocaleId);
  }
}
