<div class="page-title-area item-bg4">
  <div class="container">
    <div class="page-title-content">
      <ul>
        <li>
          <a href="index.html">{{ 'HOME' | translate }}</a>
        </li>
        <li>{{ 'CATEGORIES' | translate }}</li>
      </ul>
      <h2>{{ 'CATEGORY_LIST' | translate }}</h2>
    </div>
  </div>
</div>

<div class="courses-categories-area pt-100">
  <div class="container">
    <div class="row col-sm-9" style="margin: auto">
      <div *ngFor="let category of categories" class="col mx-auto col-sm-6 col-lg-4 mb-5">
        <div class="single-courses-category" style="height: 100%; max-height: 100%">
          <a
            class="position-relative d-block"
            style="height: 100%; max-height: 100%"
            [routerLink]="productListByCategoryPageUrl ? productListByCategoryPageUrl : null"
            [queryParams]="{ category: category.id }"
            [ngClass]="{ 'active-one': category.id === categoryId }">
            <i class="bx bx-shape-triangle d-none d-md-block"></i>
            {{ category?.label }}
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
