<div class="page-title-area item-bg4">
  <div class="container">
    <div class="page-title-content">
      <ul>
        <li>
          <a href="index.html">{{ 'HOME' | translate }}</a>
        </li>
        <li>{{ 'CART' | translate }}</li>
      </ul>
      <h2>{{ 'CART' | translate }}</h2>
    </div>
  </div>
</div>

<!-- Start cart items -->
<div class="cart-area my-5" *ngIf="cartItems.length">
  <div class="container">
    <h3 class="optional-color mb-2">{{ 'CART' | translate }}</h3>
    <div class="row">
      <div class="col-lg-7">
        <div class="cart-table table-responsive">
          <table class="table table-bordered">
            <thead>
              <tr>
                <th scope="col" class="pl-2">{{ 'PRODUCT' | translate }}</th>
                <th scope="col" class="pl-2">{{ 'NAME' | translate }}</th>
                <th scope="col" class="pl-2">{{ 'UNIT_PRICE' | translate }}</th>
                <th scope="col" class="pl-2">{{ 'QUANTITY' | translate }}</th>
                <th scope="col" class="pl-2">{{ 'TOTAL' | translate }}</th>
                <th scope="col" class="pl-2"></th>
              </tr>
            </thead>
            <tbody class="d-table-row-group">
              <tr *ngFor="let cartItem of cartItems" trackBy="identifier">
                <td class="product-thumbnail pl-2">
                  <a
                    *ngIf="cartItem.productType === PRODUCT_PRODUCT_TYPE"
                    [routerLink]="
                      productDetailsUrlPattern ? (productDetailsUrlPattern | seoNameUrl : cartItem.seoData : currentLocaleId) : null
                    ">
                    <img
                      [src]="getImage(cartItem.productMediaId, 'PDPA')"
                      [alt]="cartItem.productName"
                      onerror="this.src='assets/img/not-found.png'" />
                  </a>
                  <a
                    *ngIf="cartItem.productType !== PRODUCT_PRODUCT_TYPE"
                    [routerLink]="packDetailsUrlPattern ? (packDetailsUrlPattern | seoNameUrl : cartItem.seoData : currentLocaleId) : null">
                    <img
                      [src]="getImage(cartItem.productMediaId, 'PDPA')"
                      [alt]="cartItem.productName"
                      onerror="this.src='assets/img/not-found.png'" />
                  </a>
                </td>
                <td class="product-name pl-2">
                  <a
                    *ngIf="cartItem.productType === PRODUCT_PRODUCT_TYPE"
                    [routerLink]="
                      productDetailsUrlPattern ? (productDetailsUrlPattern | seoNameUrl : cartItem.seoData : currentLocaleId) : null
                    ">
                    {{ cartItem?.productName }}
                  </a>
                  <a
                    *ngIf="cartItem.productType !== PRODUCT_PRODUCT_TYPE"
                    [routerLink]="packDetailsUrlPattern ? (packDetailsUrlPattern | seoNameUrl : cartItem.seoData : currentLocaleId) : null">
                    {{ cartItem?.productName }}
                  </a>
                </td>
                <td class="product-price pl-2">
                  <span class="unit-amount"
                    >{{
                      cartItem?.basePrice
                        | currency : currency?.isoCode : '' : '0.' + (currency?.numberOfDigistAfterDecimalsign || 3) + '-3'
                    }}
                    {{ currency?.isoCode }}</span
                  >
                </td>
                <td class="product-quantity pl-2">
                  <div class="input-counter">
                    <input
                      id="cart-quantity"
                      class="number-input"
                      name="cart-quantity"
                      type="number"
                      min="1"
                      value="1"
                      [(ngModel)]="cartItem.quantity"
                      [ngModelOptions]="{ standalone: true }"
                      (input)="setQuantity(cartItem)" />
                  </div>
                </td>
                <td class="product-subtotal pl-2">
                  <span class="subtotal-amount"
                    >{{
                      cartItem?.totalPrice
                        | currency : currency?.isoCode : '' : '0.' + (currency?.numberOfDigistAfterDecimalsign || 3) + '-3'
                    }}
                    {{ currency?.isoCode }}</span
                  >
                </td>
                <td class="product-subtotal pl-2">
                  <a routerLink="/cart" class="remove" (click)="removeItem(cartItem)"><i class="bx bx-trash"></i></a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="col-lg-5">
        <div class="cart-totals mt-3 mt-lg-0">
          <h3>{{ 'CART_TOTALS' | translate }}</h3>
          <ul>
            <li>
              {{ 'SUBTOTAL' | translate }}
              <span>
                {{ cart?.subTotal | currency : currency?.isoCode : '' : '0.' + (currency?.numberOfDigistAfterDecimalsign || 3) + '-3' }}
                {{ currency?.isoCode }}</span
              >
            </li>
            <li>
              {{ 'TOTAL' | translate }}
              <span>
                {{ cart.total | currency : currency?.isoCode : '' : '0.' + (currency?.numberOfDigistAfterDecimalsign || 3) + '-3' }}
                {{ currency?.isoCode }}</span
              >
            </li>
          </ul>
          <a *ngIf="cart.total > 0 || (cart.total == 0 && isMember)" (click)="order()" style="color: #ffffff" class="default-btn"
            ><i class="bx bx-shopping-bag icon-arrow before"></i><span class="label"> {{ 'PROCEED_TO_CHECKOUT' | translate }} </span
            ><i class="bx bx-shopping-bag icon-arrow after"></i
          ></a>
          <a *ngIf="cart.total == 0 && !isMember" (click)="navigateToUserSpace()" style="color: #ffffff" class="default-btn"
            ><i class="bx bx-shopping-bag icon-arrow before"></i><span class="label"> {{ 'BECOME_A_MEMBER' | translate }} </span
            ><i class="bx bx-shopping-bag icon-arrow after"></i
          ></a>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- End cart items -->

<!-- Start reservation items -->
<div class="cart-area my-5" *ngIf="reservationItems.length">
  <div class="container">
    <h3 class="optional-color mb-3">{{ 'RESERVATIONS' | translate }}</h3>
    <div class="row">
      <div class="col-lg-7">
        <form>
          <div class="cart-table table-responsive">
            <table class="table table-bordered">
              <thead>
                <tr>
                  <th scope="col" class="pl-2">{{ 'PRODUCT' | translate }}</th>
                  <th scope="col" class="pl-2">{{ 'NAME' | translate }}</th>
                  <th scope="col" class="pl-2">{{ 'DISCOUNT' | translate }}</th>
                  <th scope="col" class="pl-2">{{ 'QUANTITY' | translate }}</th>
                  <th scope="col" class="pl-2"></th>
                </tr>
              </thead>
              <tbody class="d-table-row-group">
                <tr *ngFor="let cartItem of reservationItems" trackBy="identifier">
                  <td class="product-thumbnail pl-2">
                    <a
                      *ngIf="cartItem.productType === PRODUCT_PRODUCT_TYPE"
                      [routerLink]="
                        productDetailsUrlPattern ? (productDetailsUrlPattern | seoNameUrl : cartItem.seoData : currentLocaleId) : null
                      ">
                      <img
                        [src]="getImage(cartItem.productMediaId, 'PDPA')"
                        [alt]="cartItem.productName"
                        onerror="this.src='assets/img/not-found.png'" />
                    </a>
                    <a
                      *ngIf="cartItem.productType !== PRODUCT_PRODUCT_TYPE"
                      [routerLink]="
                        packDetailsUrlPattern ? (packDetailsUrlPattern | seoNameUrl : cartItem.seoData : currentLocaleId) : null
                      ">
                      <img
                        [src]="getImage(cartItem.productMediaId, 'PDPA')"
                        [alt]="cartItem.productName"
                        onerror="this.src='assets/img/not-found.png'" />
                    </a>
                  </td>
                  <td class="product-name pl-2">
                    <a
                      *ngIf="cartItem.productType === PRODUCT_PRODUCT_TYPE"
                      [routerLink]="
                        productDetailsUrlPattern ? (productDetailsUrlPattern | seoNameUrl : cartItem.seoData : currentLocaleId) : null
                      ">
                      {{ cartItem?.productName }}
                    </a>
                    <a
                      *ngIf="cartItem.productType !== PRODUCT_PRODUCT_TYPE"
                      [routerLink]="
                        packDetailsUrlPattern ? (packDetailsUrlPattern | seoNameUrl : cartItem.seoData : currentLocaleId) : null
                      ">
                      {{ cartItem?.productName }}
                    </a>
                  </td>
                  <td class="product-price pl-2">
                    <!-- TODO update with discount field -->
                    <span class="unit-amount">{{ (cartItem.discount || 0) + ' %' }}</span>
                  </td>
                  <td class="product-quantity pl-2">
                    <div class="input-counter">
                      <input
                        id="reservation-quantity"
                        class="number-input"
                        name="reservation-quantity"
                        type="number"
                        min="1"
                        value="1"
                        [(ngModel)]="cartItem.quantity"
                        [ngModelOptions]="{ standalone: true }"
                        (input)="setQuantity(cartItem)" />
                    </div>
                  </td>
                  <td class="product-subtotal pl-2">
                    <a routerLink="/cart" class="remove" (click)="removeItem(cartItem)"><i class="bx bx-trash"></i></a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </form>
      </div>
      <div class="col-lg-5">
        <div class="cart-totals mt-3 mt-lg-0">
          <h3>{{ 'RESERVATIONS' | translate }}</h3>
          <a style="color: #ffffff" class="default-btn" (click)="confirmReservation()">
            <ng-container *ngIf="!isConfirmingReservation">
              <i class="bx bx-shopping-bag icon-arrow before"></i><span class="label"> {{ 'CONFIRM_RESERVATION' | translate }} </span
              ><i class="bx bx-shopping-bag icon-arrow after"></i>
            </ng-container>
            <mat-spinner *ngIf="isConfirmingReservation" [diameter]="25"></mat-spinner>
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- End reservation items -->

<div *ngIf="!cart.cartLines.length">
  <!-- <div class="no-data">{{ 'NO_ITEM_FOUND_IN_CART' | translate }}</div> -->
  <div class="no-data">
    <a [routerLink]="productListPageUrl$ | async" style="color: var(--mainColor)">{{ 'EMPTY_CART_COUNTINUE_PURCHASE' | translate }}</a>
  </div>
</div>
