import { Component, OnDestroy, OnInit } from '@angular/core';
import { CmsService } from '../../cms/cms.service';
import { SeoService } from '../../services/seo.service';
import { Subject } from 'rxjs';
import { PACKS_PAGE } from '../../shared/constants';
import { takeUntil } from 'rxjs/operators';
import { PACK_LIST_COMPONENT_NAME } from '../../ComponentsIndex';
import { DynamicPathStorageService } from '../../services/dynamic-path-storage.service';
import { Router } from '@angular/router';
import { CurrentLocaleService } from '../../services/current-locale.service';
import { CategoryService } from 'src/app/services/category.service';

@Component({
  selector: 'app-pack-list',
  templateUrl: './pack-list.component.html',
  styleUrls: ['./pack-list.component.scss'],
})
export class PackListComponent implements OnInit, OnDestroy {
  private destroyed$ = new Subject();
  cmsPage: any;
  currentLocaleId: string;

  constructor(
    private cmsService: CmsService,
    private seoService: SeoService,
    private dynamicPathStorageService: DynamicPathStorageService,
    private router: Router,
    private currentLocaleService: CurrentLocaleService,
    private categoryService: CategoryService
  ) {}

  ngOnInit(): void {
    this.categoryService.next(null);
    this.currentLocaleService.currentLocaleId$.pipe(takeUntil(this.destroyed$)).subscribe(localeId => {
      this.currentLocaleId = localeId;
      if (this.cmsPage) {
        this.seoService.setSeoFromSeoDataAndMedia(this.cmsPage.seoData);
      }
      const currentLocaleUrlPattern = this.dynamicPathStorageService.getUrlPatternByComponentNameFromCmsPageList(PACK_LIST_COMPONENT_NAME);
      if (currentLocaleUrlPattern) {
        this.router.navigate([currentLocaleUrlPattern]);
      }
    });
    this.cmsService
      .getCmsPageByCode(PACKS_PAGE)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(res => {
        this.cmsPage = res;
        this.seoService.setSeoFromSeoDataAndMedia(this.cmsPage.seoData);
      });
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.seoService.resetSeo();
  }
}
