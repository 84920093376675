import { CustomerReview, ICustomerReview } from './customer-review.model';

export interface IProductCustomerReview extends ICustomerReview {
  id?: string;
  productId?: string;
  customerReviewClassificationId?: string;
}

export class ProductCustomerReview extends CustomerReview implements IProductCustomerReview {
  constructor(public id?: string, public productId?: string, public customerReviewCategoryId?: string) {
    super();
  }
}
