import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CoachListComponent } from './coach-list/coach-list.component';
import { CoachDetailsComponent } from './coach-details/coach-details.component';
import { RouterModule } from '@angular/router';
// import {CoachRoutes} from './coach.routing';
import { SharedModule } from '../shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { MatRadioModule } from '@angular/material/radio';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatCardModule } from '@angular/material/card';
import { CoachBookComponent } from './coach-book/coach-book.component';
import { ScheduleAllModule } from '@syncfusion/ej2-angular-schedule';
import { DatePickerAllModule, DateTimePickerAllModule } from '@syncfusion/ej2-angular-calendars';
import { CheckBoxAllModule } from '@syncfusion/ej2-angular-buttons';
import { DropDownListAllModule } from '@syncfusion/ej2-angular-dropdowns';
import { NgSelectModule } from '@ng-select/ng-select';
import { CMSModule } from '../cms/cms.module';
import { CoachRoutes } from './coach.routing';
import { MatSelectModule } from '@angular/material/select';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { NgxSpinnerModule } from 'ngx-spinner';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
// import {CoachRoutes} from "./coach.routing";
// import {CoachRoutes} from "./coach.routing";

@NgModule({
  declarations: [CoachListComponent, CoachDetailsComponent, CoachBookComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(CoachRoutes),
    RouterModule,
    SharedModule,
    TranslateModule,
    MatRadioModule,
    FormsModule,
    MatCheckboxModule,
    MatCardModule,
    ScheduleAllModule,
    DateTimePickerAllModule,
    DatePickerAllModule,
    CheckBoxAllModule,
    DropDownListAllModule,
    NgSelectModule,
    CMSModule,
    MatSelectModule,
    NgxMatSelectSearchModule,
    ReactiveFormsModule,
    NgxSpinnerModule,
    MatProgressSpinnerModule,
  ],
  exports: [
    // CoachListComponent,
    // CoachDetailsComponent,
    // CoachBookComponent
  ],
})
export class CoachModule {}
