import { environment } from '../../environments/environment';
import { Injectable } from '@angular/core';
import { GenericService } from './GenericService';
import { Observable } from 'rxjs';
import { ICmsBannerContent } from '../models/CmsBannerContent.model';
import { BannerContentImport } from '../models/BannerContentImport.model';
import { ICmsMediaContent } from '../models/cms-media-content.model';

@Injectable({ providedIn: 'root' })
export class UserBannerService extends GenericService {
  constructor() {
    super();
  }

  downloadMedia(mediaId: string, format: string | null): any {
    return environment.apiUrl + 'api/export-media/' + mediaId + ' /' + format;
  }

  storeMedia(file: File): Observable<any> {
    const formdata: FormData = new FormData();
    formdata.append('file', file);
    return this.api.post('api/upload-media', formdata);
  }

  setBannerContentOnline(id: string, online: boolean): Observable<any> {
    return this.api.get('api/set-online/' + id + '/' + online);
  }

  deleteBannerContent(id: string): Observable<any> {
    return this.api.delete('api/cms-contents/' + id);
  }

  findAllBannerContentsBySeller(sellerId: string): Observable<ICmsBannerContent[]> {
    return this.api.get('api/banner-contents-by-seller/' + sellerId);
  }
  updateBannerContent(cmsComponentBannerContent: ICmsBannerContent): Observable<ICmsBannerContent> {
    return this.api.put('api/cms-banner-contents/', cmsComponentBannerContent);
  }

  saveBanner(bannerContentImport: BannerContentImport): Observable<ICmsMediaContent> {
    const token = localStorage.getItem('authenticationToken') ;
    const formData = new FormData();
    formData.append('file', bannerContentImport.file);
    formData.append('sellerId', bannerContentImport.sellerId);
    formData.append(
      'cmsComponentBannerContent',
      new Blob([JSON.stringify(bannerContentImport.cmsComponentBannerContent)], {
        type: 'application/json',
      })
    );

    const data$ = Observable.create(observer => {
      fetch(environment.apiUrl + 'api/cms-component-banner-contents/bySeller', {
        method: 'post',
        body: formData,
        headers: {
          Authorization: 'Bearer ' + token,
        },
      })
        .then(response => response.json()) // or text() or blob() etc.
        .then(data => {
          observer.next(data);
          observer.complete();
        })
        .catch(err => observer.error(err));
    });
    return data$;
  }

  getAdvertisingContentBySellerId(corporateSellerId: string): Observable<any> {
    return this.api.get('api/cms-banner-contents-to-display-by-seller/' + corporateSellerId);
  }
}
