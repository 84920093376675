import { Injectable } from '@angular/core';
import { GenericService } from '../services/GenericService';
import { Observable } from 'rxjs';
import { IndividualCoach } from '../models/coach.model';
import { IUnavailabilityEntry } from '../models/unavailabilityEntry.model';
import { LOCALE_ID } from '../shared/constants';
import { LocalStorageService } from 'ngx-webstorage';
import { ICoachDefaultAvailability } from '../models/coachDefaultAvailability.model';

@Injectable({
  providedIn: 'root',
})
export class CoachSpaceService extends GenericService {
  private localeId;
  constructor(localStorage: LocalStorageService) {
    super();
    this.localeId = localStorage.retrieve(LOCALE_ID);
    localStorage.observe(LOCALE_ID).subscribe(res => {
      this.localeId = res;
    });
  }

  getCoachByUserEmail(email: string): Observable<IndividualCoach> {
    return this.api.get('api/individual-coach/byemail/' + email + (this.localeId ? '?locale=' + this.localeId : ''));
  }

  getUnavaibleByCoachAndStatus(coachId: string): Observable<IUnavailabilityEntry> {
    return this.api.get('api/unavaibility-entries-by-coach-by-status/' + coachId);
  }
  getUnavaibleByCoach(coachId: string): Observable<IUnavailabilityEntry> {
    return this.api.get('api/unavaibility-entries-by-coach/' + coachId);
  }
  createUnavalbleCoaching(unavaibilityEntry: IUnavailabilityEntry): Observable<IUnavailabilityEntry> {
    return this.api.post('api/unavaibility-entries/', unavaibilityEntry);
  }
  updateUnavailbleCoaching(unavaibilityEntry: IUnavailabilityEntry): Observable<IUnavailabilityEntry> {
    return this.api.put('api/unavaibility-entries/', unavaibilityEntry);
  }

  deleteUnavalbleCoaching(unavaibilityEntryId: string): Observable<IUnavailabilityEntry> {
    return this.api.delete('api/unavaibility-entries/' + unavaibilityEntryId);
  }

  updateCoachAvailability(availability: ICoachDefaultAvailability): Observable<ICoachDefaultAvailability> {
    return this.api.post('api/coach-availability', availability);
  }

  getCoachAvailability(idCoach: string): Observable<ICoachDefaultAvailability> {
    return this.api.get('api/coach-availability/' + idCoach);
  }
}
