<ng-container *ngFor="let cmsComponentContent of data">
  <div class="partner-area pt-40 pb-40" *ngIf="cmsComponentContent.elements && cmsComponentContent.elements.length > 0">
    <div class="container">
      <div class="section-title">
        <h2>{{ cmsComponentContent.title }}</h2>
        <p>{{ cmsComponentContent.description }}</p>
      </div>
      <div class="partner-slides">
        <owl-carousel-o [options]="partnerSlides">
          <ng-template carouselSlide *ngFor="let cmsEntity of cmsComponentContent.elements">
            <div class="single-partner-item position-relative mx-auto" style="width: 95%; height: 95%">
              <a class="d-block align-middle">
                <img
                  [src]="cmsEntity.photoId ? getImage(cmsEntity.photoId, null) : 'assets/img/not-found.png'"
                  onerror="this.src='assets/img/not-found.png'"
                  class="align-middle position-absolute px-2"
                  [alt]="cmsEntity.name"
                  style="max-height: 15rem; max-width: 15rem; top: 50%; left: 50%; transform: translate(-50%, -50%)" />
              </a>
            </div>
          </ng-template>
        </owl-carousel-o>
      </div>
    </div>
  </div>
</ng-container>
