import { ILocale } from '../../models/locale.model';
import { Media } from '../../models/media.model';
import { ISeoData } from './seo-data.model';

export interface IBlogPostDTOModel {
  id?: string;
  title?: string;
  description?: string;
  views?: number;
  countReplies?: number;
  readingTimeInMinutes?: number;
  blogId?: string;
  allowComments?: boolean;
  allowAnonymousInteractions?: boolean;
  medias?: Media[];
  thumbnail?: Media;
  locales?: ILocale[];
  seoData?: ISeoData;
}

export class BlogPostDTOModel implements IBlogPostDTOModel {
  constructor(
    public id?: string,
    public title?: string,
    public description?: string,
    public views?: number,
    public countReplies?: number,
    public readingTimeInMinutes?: number,
    public blogId?: string,
    public allowComments?: boolean,
    public allowAnonymousInteractions?: boolean,
    public medias?: Media[],
    public thumbnail?: Media,
    public locales?: ILocale[],
    public seoData?: ISeoData
  ) {}
}
