import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { FilterObject, IFilterObject } from '../../shared/models/filterObject';
import { CoachService } from '../coach.service';
import { LocalStorageService } from 'ngx-webstorage';
import { take, takeUntil } from 'rxjs/operators';
import { ReplaySubject, Subject } from 'rxjs';
import { CmsService } from '../../cms/cms.service';
import { CoachingDomain } from 'src/app/models/coaching-domain.model';
import { COACHES_PAGE, LOCALE_ID, SCREEN_SIZE } from '../../shared/constants';
import { Store } from '@ngrx/store';
import { AppState } from '../../store/app.reducer';
import { COACH_DETAILS_COMPONENT_NAME, COACH_LIST_COMPONENT_NAME } from '../../ComponentsIndex';
import { SeoService } from '../../services/seo.service';
import { DynamicPathStorageService } from '../../services/dynamic-path-storage.service';
import { Router } from '@angular/router';
import { FormControl } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { CurrentLocaleService } from '../../services/current-locale.service';
import { ResizeService } from 'src/app/services/resize.service';
import { ViewportService } from '../../services/viewport.service';

@Component({
  selector: 'app-coach-list',
  templateUrl: './coach-list.component.html',
  styleUrls: ['./coach-list.component.scss'],
  // encapsulation: ViewEncapsulation.None
})
export class CoachListComponent implements OnInit, OnDestroy, AfterViewInit {
  constructor(
    private coachService: CoachService,
    private localSorage: LocalStorageService,
    private cmsService: CmsService,
    private dynamicPathStorageService: DynamicPathStorageService,
    private seoService: SeoService,
    private router: Router,
    private currentLocaleService: CurrentLocaleService,
    private resizeService: ResizeService,
    private renderer: Renderer2,
    private viewportService: ViewportService
  ) {}
  cmsPage: any;
  filterObject: IFilterObject = new FilterObject();
  coaches = [];
  isFetchingCoaches = true;
  currentLocale: string;
  alphabetList = [
    'A',
    'B',
    'C',
    'D',
    'E',
    'F',
    'G',
    'H',
    'I',
    'J',
    'K',
    'L',
    'M',
    'N',
    'O',
    'P',
    'Q',
    'R',
    'S',
    'T',
    'U',
    'V',
    'W',
    'X',
    'Y',
    'Z',
  ];
  // countries = [];
  // filteredCountries = [];
  coachingDomains = [];
  entrepriseCoaching = [];
  lifeCoachingDomain = [];
  socialSolidarityCoachingDomain = [];
  readonly SIZE = 8;
  page = 0;
  coachFilterObject = {
    countryIds: [],
    domainIds: [],
    alphabets: [],
    className: '',
    iCFAccreditationLevelIds: [],
    languageIds: [],
  };
  accreditationLevels = [];
  languages = [];
  displayALlDomains = false;
  displayALlAccreditationLevels = false;
  displayALlLanguages = false;
  components: any[] | undefined;
  private destroyed$ = new Subject();
  coachDetailsPageUrlPattern: string;

  protected countries: any[] = [];
  public countryMultiCtrl: FormControl = new FormControl();
  public countryMultiFilterCtrl: FormControl = new FormControl();
  public filteredCountriesMulti: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  canLoadMore = true;

  @ViewChild('multiSelect', { static: true }) multiSelect: MatSelect;

  screenSize: SCREEN_SIZE;
  isOnSmallScreen = false;
  isFiltersSectionDisplayed = false;
  isListSectionDisplayed = true;

  totalCoachesCount;
  hasMoreAvailableCoachesToShow = false;
  @ViewChild('showMore') private showMore: ElementRef;

  btnTypes = ['btn blueButton', 'btn purpleButton', 'btn pinkButton', 'btn orangeButton'];

  resetSmallScreenDisplay() {
    this.isFiltersSectionDisplayed = false;
    this.isListSectionDisplayed = true;
  }

  ngOnInit(): void {
    // set initial selection
    this.countryMultiCtrl.setValue(this.countries);
    // load the initial bank list
    this.filteredCountriesMulti.next(this.countries.slice());
    // listen for search field value changes
    this.countryMultiFilterCtrl.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe(() => {
      this.filterCountriesMulti();
    });
    this.getCmsPage();
    this.getALlCoachingDomains();
    this.getCoutries();
    this.getLanguages();
    this.getAccreditationLevels();
    this.getCoaches();
    this.currentLocaleService.currentLocaleId$.pipe(takeUntil(this.destroyed$)).subscribe(localeId => {
      this.currentLocale = localeId;
      if (this.cmsPage) {
        this.seoService.setSeoFromSeoDataAndMedia(this.cmsPage.seoData);
      }
      const currentLocaleUrlPattern = this.dynamicPathStorageService.getUrlPatternByComponentNameFromCmsPageList(COACH_LIST_COMPONENT_NAME);
      if (currentLocaleUrlPattern) {
        this.router.navigate([currentLocaleUrlPattern]);
      }
    });
    this.localSorage
      .observe(LOCALE_ID)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        this.getALlCoachingDomains();
        this.getCoutries();
        this.getLanguages();
        this.getAccreditationLevels();
      });
    this.dynamicPathStorageService.urlPatterns$.pipe(takeUntil(this.destroyed$)).subscribe(() => {
      this.coachDetailsPageUrlPattern =
        this.dynamicPathStorageService.getUrlPatternByComponentNameFromCmsPageList(COACH_DETAILS_COMPONENT_NAME);
    });
    this.resizeService.onResize$.pipe(takeUntil(this.destroyed$)).subscribe(screenSize => {
      this.screenSize = screenSize;
      if (!this.isOnSmallScreen && !(this.screenSize == SCREEN_SIZE.LG || screenSize == SCREEN_SIZE.XL)) {
        this.isOnSmallScreen = true;
        this.resetSmallScreenDisplay();
      } else if (this.isOnSmallScreen && (this.screenSize == SCREEN_SIZE.LG || screenSize == SCREEN_SIZE.XL)) {
        this.isOnSmallScreen = false;
        this.resetSmallScreenDisplay();
      }
    });
    this.renderer.listen('window', 'scroll', this.checkShowMore.bind(this));
    this.renderer.listen('window', 'resize', this.checkShowMore.bind(this));
  }

  checkShowMore() {
    if (!this.viewportService.isInViewport(this.showMore.nativeElement, 250, true)) {
      this.canLoadMore = true;
    } else {
      if (this.canLoadMore) {
        if (this.hasMoreAvailableCoachesToShow && !this.isFetchingCoaches) {
          this.showMoreCoaches();
        }
        this.canLoadMore = false;
      }
    }
  }

  displayFiltersSection() {
    this.isFiltersSectionDisplayed = true;
    this.isListSectionDisplayed = false;
  }

  displayList() {
    this.isFiltersSectionDisplayed = false;
    this.isListSectionDisplayed = true;
  }

  ngAfterViewInit() {
    this.setInitialValue();
  }

  protected setInitialValue() {
    this.filteredCountriesMulti.pipe(take(1), takeUntil(this.destroyed$)).subscribe(() => {
      if (this.multiSelect) {
        this.multiSelect.compareWith = (a: any, b: any) => a && b && a.id === b.id;
      }
    });
  }

  protected filterCountriesMulti() {
    if (!this.countries) {
      return;
    }
    // get the search keyword
    let search = this.countryMultiFilterCtrl.value;
    if (!search) {
      this.filteredCountriesMulti.next(this.countries.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the countries
    this.filteredCountriesMulti.next(this.countries.filter(country => country.name.toLowerCase().indexOf(search) > -1));
  }

  getCoaches() {
    this.isFetchingCoaches = true;
    this.coachFilterObject.className = 'com.softparadigm.passexcellence.domain.IndividualCoach';
    const coaches$ = this.coachService.filterCoaches(this.coachFilterObject, this.page, this.SIZE).pipe(takeUntil(this.destroyed$));
    coaches$.subscribe(
      res => {
        for (const coach of res.body) {
          this.coaches.push(coach);
        }

        this.totalCoachesCount = +res.headers.get('X-Total-Count');
        if (!(this.coaches.length === this.totalCoachesCount) && this.totalCoachesCount) {
          this.hasMoreAvailableCoachesToShow = true;
        } else {
          this.hasMoreAvailableCoachesToShow = false;
        }
        this.isFetchingCoaches = false;
      },
      () => (this.isFetchingCoaches = false)
    );
  }

  identifyer = (index: number, item: any) => item.id;

  showMoreCoaches() {
    this.page++;
    this.getCoaches();
  }

  getImageSet(image: any): any {
    return this.coachService.downloadMedia(image.id, null);
  }

  getALlCoachingDomains() {
    this.coachService
      .getAllCoachingDomains()
      .pipe(takeUntil(this.destroyed$))
      .subscribe(coachingDomains => {
        this.filterCoachingDomain(coachingDomains);
      });
  }

  filterCoachingDomain(coachingDomains) {
    const IDS = ['EXECUTIVE_COACHING', 'MANAGER_COACHING', 'ENTREPRENEURS_COACHING', 'TEAM_COACHING', 'CAREER_COACHING'];

    this.entrepriseCoaching = [];
    this.lifeCoachingDomain = [];
    coachingDomains.forEach((element: CoachingDomain) => {
      const doamin = IDS.find(id => id === element.id);
      if (doamin) {
        this.entrepriseCoaching.push(element);
      } else if (element.id == 'COSSAM') {
        this.socialSolidarityCoachingDomain.push(element);
      } else {
        this.lifeCoachingDomain.push(element);
      }
    });
  }

  getCoutries() {
    this.coachService
      .getCoutries()
      .pipe(takeUntil(this.destroyed$))
      .subscribe(coutries => {
        this.countries = [].concat(coutries);
        // set initial selection
        this.countryMultiCtrl.setValue([]);
        // load the initial bank list
        this.filteredCountriesMulti.next(this.countries.slice());
      });
  }

  getAccreditationLevels() {
    this.coachService
      .getAccreditationLevels()
      .pipe(takeUntil(this.destroyed$))
      .subscribe(accreditationLevels => {
        this.accreditationLevels = [].concat(accreditationLevels);
      });
  }

  resetPagination() {
    this.isFetchingCoaches = true;
    this.page = 0;
    this.coaches = [];
  }

  getLanguages() {
    this.coachService
      .getLanguages()
      .pipe(takeUntil(this.destroyed$))
      .subscribe(languages => {
        this.languages = [].concat(languages);
      });
  }

  selectDomain(domain) {
    const index = this.coachFilterObject.domainIds.findIndex(domainId => domainId === domain.id);
    if (index > -1) {
      this.coachFilterObject.domainIds.splice(index, 1);
    } else {
      this.coachFilterObject.domainIds.push(domain.id);
    }
    this.resetPagination();
    this.getCoaches();
  }

  selectAlphabet(alpha) {
    const index = this.coachFilterObject.alphabets.findIndex(alphaCase => alphaCase === alpha);
    this.coachFilterObject.alphabets.pop();
    if (index == -1) {
      this.coachFilterObject.alphabets.push(alpha);
    }
    this.resetPagination();
    this.getCoaches();
  }

  updateCoachList() {
    const selectedCountries = this.countryMultiCtrl.value;
    let selectedCountriesIds = [];
    if (selectedCountries && selectedCountries.length > 0) {
      selectedCountriesIds = selectedCountries.map(country => country.id);
    }
    this.coachFilterObject.countryIds = selectedCountriesIds;
    this.resetPagination();
    this.getCoaches();
  }

  selectAccreditationLevel(iCFAccreditationLevel) {
    const index = this.coachFilterObject.iCFAccreditationLevelIds.findIndex(
      iCFAccreditationLevelId => iCFAccreditationLevelId === iCFAccreditationLevel.id
    );
    if (index > -1) {
      this.coachFilterObject.iCFAccreditationLevelIds.splice(index, 1);
    } else {
      this.coachFilterObject.iCFAccreditationLevelIds.push(iCFAccreditationLevel.id);
    }
    this.resetPagination();
    this.getCoaches();
  }

  selectLanguage(language) {
    const index = this.coachFilterObject.languageIds.findIndex(languageId => languageId === language.id);
    if (index > -1) {
      this.coachFilterObject.languageIds.splice(index, 1);
    } else {
      this.coachFilterObject.languageIds.push(language.id);
    }
    this.resetPagination();
    this.getCoaches();
  }

  displayDomains(b: boolean) {
    this.displayALlDomains = b;
  }

  getBtnClass(index): string {
    return this.btnTypes[index % 5];
  }

  selected(list: any[], element: any[]) {
    return list.findIndex(value => value === element) > -1;
  }

  getIdComponentByCode(code: string): any {
    if (this.components && this.components.length > 0) {
      const index = this.components.findIndex(component => component.code === code);
      if (index !== -1) {
        return this.components[index].id;
      }
    }
  }

  getCmsPage(): void {
    this.cmsService
      .getCmsPageByCode(COACHES_PAGE)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(res => {
        this.cmsPage = res;
        this.seoService.setSeoFromSeoDataAndMedia(res.seoData);
        this.components = res.cmsComponents;
        // this loop is for getting components that are inside the containers !!
        res.cmsContainers?.forEach(container => {
          container.cmsComponents.forEach(component => {
            this.components.push(component);
          });
        });
      });
  }
  ngOnDestroy() {
    this.destroyed$.next();
    this.seoService.resetSeo();
  }
}
