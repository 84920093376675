// import { T } from "@angular/cdk/keycodes";

export function getSelectedItemById<T>(id: string, dataArray: any[]): T {
  if (!id) {
    return this.T;
  }
  const index = dataArray.findIndex(item => item.id === id);
  if (index != -1) {
    return dataArray[index];
  }
  return new this.T();
}
