export const environment = {
  production: true,
  apiUrl: '',
  googleAnalyticsID: 'UA-204657097-2',
  config: {
    apiKey: 'AIzaSyCN8GlBmyAVTqWtADqXK-oS_Reor1KhT34',
    authDomain: 'pass-excellence.firebaseapp.com',
    databaseURL: 'https://pass-excellence-default-rtdb.firebaseio.com',
    projectId: 'pass-excellence',
    storageBucket: 'pass-excellence.appspot.com',
    messagingSenderId: '1090051205616',
    appId: '1:1090051205616:web:f6d372ea583248be191535',
  },
  recaptchaSiteKey: '6LefiX0cAAAAAJHw-eTZZGHWrri0Oz9fTnlxMPG0',
};
