import { CoachDetailsComponent } from './coach/coach-details/coach-details.component';
import { CoachListComponent } from './coach/coach-list/coach-list.component';
import { TermsAndConditionsComponent } from './components/pages/terms-and-conditions/terms-and-conditions.component';
import { EntrepriseHomePageComponent } from './components/pages/entreprise-home-page/entreprise-home-page.component';
import { ParticularHomePageComponent } from './components/pages/particular-home-page/particular-home-page.component';
import { ContactUsCmsComponent } from './components/pages/contact-us-cms/contact-us-cms.component';
import { BlogListComponent } from './blog/blog-list/blog-list.component';
import { BlogDetailsComponent } from './blog/blog-details/blog-details.component';
import { CorporateSellerListComponent } from './corporate-seller/corporate-seller-list/corporate-seller-list.component';
import { CorporateSellerDetailsComponent } from './corporate-seller/corporate-seller-details/corporate-seller-details.component';
import { ProductDetailsComponent } from './product/product-details/product-details.component';
import { PackDetailsComponent } from './product/pack-details/pack-details.component';
import { ProductComponent } from './product/product/product.component';
import { PrestationComponent } from './product/prestation/prestation.component';
import { PackListComponent } from './product/pack-list/pack-list.component';
import { AboutUsComponent } from './components/pages/about-us/about-us.component';
import { ProductJourneyToExcellenceComponent } from './components/pages/product-journey-to-excellence/product-journey-to-excellence.component';
import { TermsOfUseComponent } from './components/pages/terms-of-use/terms-of-use.component';
import { PrivacyPolicyComponent } from './components/pages/privacy-policy/privacy-policy.component';

export const COACH_LIST_COMPONENT_NAME = 'CoachListComponent';
export const COACH_DETAILS_COMPONENT_NAME = 'CoachDetailsComponent';

export const CORPORATE_SELLER_LIST_COMPONENT_NAME = 'CorporateSellerListComponent';
export const CORPORATE_SELLER_DETAILS_COMPONENT_NAME = 'CorporateSellerDetailsComponent';

export const BLOG_LIST_COMPONENT_NAME = 'BlogListComponent';
export const BLOG_DETAILS_COMPONENT_NAME = 'BlogDetailsComponent';

export const ENTREPRISE_HOME_PAGE_COMPONENT_NAME = 'EntrepriseHomePageComponent';
export const PARTICULAR_HOME_PAGE_COMPONENT_NAME = 'ParticularHomePageComponent';
export const CONTACT_US_CMS_COMPONENT_NAME = 'ContactUsCmsComponent';
export const ABOUT_US_COMPONENT_NAME = 'AboutUsComponent';
export const TERMS_AND_CONDITIONS_COMPONENT_NAME = 'TermsAndConditionsComponent';
export const TERMS_OF_USE_COMPONENT_NAME = 'TermsOfUseComponent';
export const PRIVACY_POLICY_COMPONENT_NAME = 'PrivacyPolicyComponent';

export const PRODUCT_COMPONENT_NAME = 'ProductComponent';
export const PRESTATION_COMPONENT_NAME = 'PrestationComponent';
export const PACK_LIST_COMPONENT_NAME = 'PackListComponent';

export const PRODUCT_JOURNEY_TO_EXCELLENCE_COMPONENT_NAME = 'ProductJourneyToExcellenceComponent';
export const PRODUCT_DETAILS_COMPONENT_NAME = 'ProductDetailsComponent';
export const PACK_DETAILS_COMPONENT_NAME = 'PackDetailsComponent';

export interface ProjectComponent {
  component: any;
  componentClassName: string;
}
export const projectComponents: ProjectComponent[] = [
  {
    component: CoachListComponent,
    componentClassName: COACH_LIST_COMPONENT_NAME,
  },
  {
    component: CoachDetailsComponent,
    componentClassName: COACH_DETAILS_COMPONENT_NAME,
  },
  {
    component: ProductJourneyToExcellenceComponent,
    componentClassName: PRODUCT_JOURNEY_TO_EXCELLENCE_COMPONENT_NAME,
  },
  {
    component: CorporateSellerListComponent,
    componentClassName: CORPORATE_SELLER_LIST_COMPONENT_NAME,
  },
  {
    component: CorporateSellerDetailsComponent,
    componentClassName: CORPORATE_SELLER_DETAILS_COMPONENT_NAME,
  },
  {
    component: BlogListComponent,
    componentClassName: BLOG_LIST_COMPONENT_NAME,
  },
  {
    component: BlogDetailsComponent,
    componentClassName: BLOG_DETAILS_COMPONENT_NAME,
  },
  {
    component: PrestationComponent,
    componentClassName: PRESTATION_COMPONENT_NAME,
  },
  {
    component: ProductComponent,
    componentClassName: PRODUCT_COMPONENT_NAME,
  },
  {
    component: ProductDetailsComponent,
    componentClassName: PRODUCT_DETAILS_COMPONENT_NAME,
  },
  {
    component: PackListComponent,
    componentClassName: PACK_LIST_COMPONENT_NAME,
  },
  {
    component: PackDetailsComponent,
    componentClassName: PACK_DETAILS_COMPONENT_NAME,
  },
  {
    component: EntrepriseHomePageComponent,
    componentClassName: ENTREPRISE_HOME_PAGE_COMPONENT_NAME,
  },
  {
    component: ParticularHomePageComponent,
    componentClassName: PARTICULAR_HOME_PAGE_COMPONENT_NAME,
  },
  {
    component: AboutUsComponent,
    componentClassName: ABOUT_US_COMPONENT_NAME,
  },
  {
    component: ContactUsCmsComponent,
    componentClassName: CONTACT_US_CMS_COMPONENT_NAME,
  },
  {
    component: TermsAndConditionsComponent,
    componentClassName: TERMS_AND_CONDITIONS_COMPONENT_NAME,
  },
  {
    component: TermsOfUseComponent,
    componentClassName: TERMS_OF_USE_COMPONENT_NAME,
  },
  {
    component: PrivacyPolicyComponent,
    componentClassName: PRIVACY_POLICY_COMPONENT_NAME,
  },
];
