import { IMedia } from '../../models/media.model';

export interface IWorkItemType {
  id?: string;
  code?: string;
  codePrefix?: string;
  name?: any;
  requestAction?: any;
  nameByLocale?: string;
  icon?: IMedia;
  color?: string;
}
export class WorkItemType implements IWorkItemType {
  constructor(
    public id?: string,
    public code?: string,
    public name?: any,
    public codePrefix?: string,
    public requestAction?: any,
    public nameByLocale?: string,
    public icon?: IMedia,
    public color?: string
  ) {}
}
