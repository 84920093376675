<div class="login-area" *ngIf="emailExit">
  <div class="row m-0">
    <div class="col-lg-6 col-md-12 p-0">
      <div class="login-image"></div>
    </div>
    <div class="col-lg-6 col-md-12 p-0">
      <div class="login-content">
        <div class="d-table">
          <div class="d-table-cell">
            <div class="login-form">
              <div class="logo">
                <a routerLink="/"><img src="../../../../assets/img/black-logo.png" class="imgLogo" alt="image" /></a>
              </div>
              <h3>{{ 'WELCOME' | translate }}</h3>
              <a class="register-link" routerLink="/register">{{ 'NEW_TO' | translate }}</a>
              <form [formGroup]="loginForm" (ngSubmit)="login()">
                <div>
                  <div class="alert alert-danger" *ngIf="authenticationError">
                    <strong>{{ 'ERROR-MESSAGE1' | translate }} </strong>
                    {{ (userIsDisabled ? 'USER_DISABLED' : 'ERROR-MESSAGE2') | translate }}
                  </div>
                </div>
                <div class="form-group">
                  <input
                    type="email"
                    placeholder="{{ 'EMAIL' | translate }}"
                    class="form-control"
                    formControlName="username"
                    [ngClass]="{
                      'is-invalid': submitted && f.username.errors
                    }" />
                  <div *ngIf="submitted && f.username.errors" class="invalid-feedback">
                    <div *ngIf="f.username.errors.required" style="float: left; margin-bottom: 5px">
                      {{ 'EMAIL_REQUIRED' | translate }}
                    </div>
                    <div *ngIf="f.username.errors.email" style="float: left; margin-bottom: 5px">
                      {{ 'EMAIL_INVALID' | translate }}
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <input
                    #password
                    [type]="showPassword ? 'text' : 'password'"
                    placeholder="{{ 'PASSWORD' | translate }}"
                    class="form-control"
                    formControlName="password"
                    [ngClass]="{
                      'is-invalid': submitted && f.password.errors
                    }" />
                  <i
                    (click)="password.type = password.type == 'password' ? 'text' : 'password'"
                    class="field-icon"
                    [ngClass]="password.type === 'password' ? ' bx bx-hide' : ' bx bx-show'"></i>
                  <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                    <div *ngIf="f.password.errors?.required" style="float: left; margin-bottom: 5px">
                      {{ 'PASSWORD_REQUIRED' | translate }}
                    </div>
                    <div *ngIf="f.password.errors?.pattern" style="float: left; margin-bottom: 5px">
                      {{ 'PASSWORD_INVALID' | translate }}
                    </div>
                  </div>
                </div>
                <button type="submit">{{ 'LOGIN' | translate }}</button>
                <div class="forgot-password">
                  <a routerLink="/forgot-password">{{ 'FORGOT_PASSWORD' | translate }}</a>
                </div>
                <div class="connect-with-social">
                  <button type="submit" class="facebook" (click)="signInWithFB()">
                    <i class="bx bxl-facebook"></i>{{ 'CONNECT_WITH_FB' | translate }}
                  </button>
                  <button type="submit" class="twitter" (click)="loginWithGoogle()">
                    <i class="bx bxl-google"></i>{{ 'CONNECT_WITH_GOOGLE' | translate }}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="!emailExit">
  <app-register-page [socialUser]="socialUser" [fcmToken]="fcmToken"></app-register-page>
</div>
