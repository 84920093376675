import { makeStateKey } from '@angular/platform-browser';

export const DRH_GROUP = 'DRH';
export const BENEFICIARY_GROUP = 'BENEFICIARY_GROUP';
export const COACH_GROUP = 'COACH_GROUP';
export const SERVICE_PROVIDER_GROUP = 'SERVICE_PROVIDER_GROUP';
export const ADVERTISING_COMPONENT = 'ADVERTISING_COMPONENT';
export const CLICTOPAY_PAGEVIEW_DESKTOP = 'DESKTOP';
export const CLICTOPAY_PAGEVIEW_MOBILE = 'MOBILE';
export const PAYMENT_ID_KEY_STORAGE = 'PAYMETNT_KEY_STORAGE';
export const ORDER_KEY_STORAGE = 'KEY:ORDER';
export const ORDER_ID_KEY_STORAGE = 'KEY:ORDERID';
export const PRODUCT_FAMILY_SERVICE = 'DEFAULT_PRODUCT_FAMILY';
export const SELLER_ID_TO_DISPLAY = ['PASSEXCELLENCE']; // "SWISSNOVA", "GROUPAXION"
// export const PRODUCTS_ID_TO_DISPLAY = ["PROFILING_NOVA", "COACHING"];

export const ENGLISH_UK = 'ENGLISH_UK';
export const FRENCH_FRANCE = 'FRENCH_FRANCE';
export const LOCALES = 'locales';
export const LOCALE_ID = 'localeId';
export const LOCALE_ID_COOKIE = 'LOCALE_ID_COOKIE';
export const CONTEXT = 'context';
export const LOCALE_STATE = 'localeState';
export const CONTACT = 'contact';
export const ENTREPRISE_HOME_PAGE = 'ENTREPRISE_HOME_PAGE';
export const PARTICULAR_HOME_PAGE = 'PARTICULAR_HOME_PAGE';
export const LANGUAGE = 'language';
export const HOME_PAGE = 'HOME_PAGE';
export const MONTH = 'Month';
export const DAY = 'Day';
export const WEEK = 'Week';
export const APPOINTMENT = 'appointment';
export const APPROVED_APPOINTMENT = 'APPROVED_APPOINTMENT';
export const PENDING_APPOINTMENT = 'PENDING_APPOINTMENT';
export const DISAPPROVED_APPOINTMENT = 'DISAPPROVED_APPOINTMENT';
export const REPORTED_APPOINTMENT = 'REPORTED_APPOINTMENT';
export const APPOINMENT_UPDATED = 'APPOINMENT_UPDATED';
export const APPOINMENT_CREATED = 'APPOINMENT_CREATED';
export const EVENT_CREATED = 'eventCreated';
export const EVENT_CHANGED = 'eventChanged';
export const EVENT_REMOVED = 'eventRemoved';
export const QUICK_INFO = 'QuickInfo';
export const EDITOR = 'Editor';
export const UNAVAIBILITY = 'unavaibility';
export const MONTH_AGENDA = 'MONTH_AGENDA';
export const CONSIGNMENT_STATUS_PENDING = 'CONSIGNMENT_STATUS_PENDING';
export const PASSE_EXCELLENCE = 'PASSEXCELLENCE';
export const BENEFICIARY_PAGE = 'BENEFICIARY_PAGE';
export const COACHES_PAGE = 'COACHES_PAGE';
export const CORPORATE_SELLERS_PAGE = 'CORPORATE_SELLERS_PAGE';
export const PRODUCT_CATEGORY = 'PRODUCT_CATEGORY';
export const TUNISIA = 'TUNISIA';
export const ABOUT_US = 'ABOUT_US';
export const BENEFITS_PAGE = 'BENEFITS_PAGE';
export const HEADER_CONTACT_PAGE = 'HEADER_CONTACT_PAGE';
export const DEFAULT_FAMILY = 'DEFAULT_FAMILY';
export const PACKS_FAMILY = 'PACKS_FAMILY';
export const BUNDLES_FAMILY = 'BUNDLES_FAMILY';

export const PROFILING_NOVA = 'PROFILING_NOVA';

export const COACHING = 'COACHING';

export const PRODUCTS_PAGE = 'PRODUCTS_PAGE';
export const PRODUCTS_BY_CATEGORIES_PAGE = 'PRODUCTS_BY_CATEGORIES_PAGE';
export const PACKS_PAGE = 'PACKS_PAGE';
export const BLOGS_PAGE = 'BLOGS_PAGE';
export const CONTACT_US = 'CONTACT_US';
export const TERMS_OF_USE_AND_PRIVACY_POLICY = 'TERMS_OF_USE_AND_PRIVACY_POLICY';
export const TERMS_OF_USE = 'TERMS_OF_USE';
export const PRIVACY_POLICY = 'PRIVACY_POLICY';

export const FRENCH_FRANCE_LANG_ID = 'fr';
export const ENGLISH_UK_LANG_ID = 'en';

// components
export const ENTREPRISE_HEADING_BANNER = 'ENTREPRISE_HEADING_BANNER';
export const PARTICULAR_HEADING_BANNER = 'PARTICULAR_HEADING_BANNER';

export const enum PARTICULAR_ENTREPRISE_BANNER_CODE {
  ENTREPRISE = 'ENTREPRISE_HEADING_BANNER',
  PARTICULAR = 'PARTICULAR_HEADING_BANNER',
}

export const enum PARTICULAR_ENTREPRISE_PAGE_CODE {
  ENTREPRISE = 'ENTREPRISE_HOME_PAGE',
  PARTICULAR = 'PARTICULAR_HOME_PAGE',
}

export const localeIdStateKey = makeStateKey<string>(LOCALE_ID);

export const DEFAULT_CURRENCY_ID = 'TND';

export const BENEFICIARY_ALREADY_EXIST_HTTP_ERROR_CODE = 'BENEFICIARY_ALREADY_EXIST';
export const PATTERN = '(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z0-9d$@$!%*?&].{7,}';

export const enum SCREEN_SIZE {
  XS,
  SM,
  MD,
  LG,
  XL,
}

export const GENERIC_ERROR_MSG = 'GENERIC_ERROR_MSG';

export const PRODUCT_STATUS_DELETED = 'DELETED';
