import { Injectable, OnDestroy, Inject, PLATFORM_ID } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
const { match } = require('node-match-path');
import { routes } from '../../app-routing.module';
import { DynamicPathService } from '../dynamic-path.service';
import { projectComponents } from '../../ComponentsIndex';
import { ReplaySubject } from 'rxjs';
import { FRENCH_FRANCE, LOCALE_ID } from '../../shared/constants';
import { takeUntil } from 'rxjs/operators';
import { LocalStorageService } from 'ngx-webstorage';
import { isPlatformServer } from '@angular/common';

@Injectable()
export class DynamicPathGuard implements CanActivate, OnDestroy {
  currentLocaleId: string;
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  constructor(
    private router: Router,
    private dynamicPathService: DynamicPathService,
    private localStorageService: LocalStorageService,
    @Inject(PLATFORM_ID) platformId: any
  ) {
    // TODO detect client language on server
    if (isPlatformServer(platformId)) {
      this.currentLocaleId = FRENCH_FRANCE;
    } else {
      this.currentLocaleId = this.localStorageService.retrieve(LOCALE_ID);
      this.localStorageService
        .observe(LOCALE_ID)
        .pipe(takeUntil(this.destroyed$))
        .subscribe(localeId => (this.currentLocaleId = localeId));
    }
  }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    this.router.resetConfig(routes);
    this.dynamicPathService.findAllWithUrlKeyNotNull().subscribe(
      (data: { spaComponentName: string; urlPattern: any }[]) => {
        this.addNewRoutes(data);
        if (this.isRouteValid(state)) {
          // check route locale and set it on server
          this.router.navigateByUrl(state.url);
          return false;
        } else {
          this.dynamicPathService.findByOldUrl(state.url).subscribe(
            res => {
              if (res && res.id) {
                this.router.navigateByUrl(res.newUrl);
              } else {
                this.router.navigateByUrl('page-not-found');
              }
            },
            error => {
              this.router.navigateByUrl('page-not-found');
            }
          );
        }

        return false;
      },
      () => {
        return false;
      }
    );
    return false;
  }

  addNewRoutes(data: { spaComponentName: string; urlPattern: any }[]) {
    for (const route of data) {
      const projCompo = projectComponents.find(projComponent => projComponent.componentClassName === route.spaComponentName);
      if (projCompo && projCompo.component) {
        const languages = Object.keys(route.urlPattern.localizedValues);
        if (languages && languages.length) {
          for (const lang of languages) {
            let languageUrlPattern = route.urlPattern.localizedValues[lang];
            if (languageUrlPattern.startsWith('/')) {
              languageUrlPattern = languageUrlPattern.substring(1);
            }
            const routeToAdd = {
              path: languageUrlPattern,
              component: projCompo.component,
            };
            this.router.config.unshift(routeToAdd);
          }
        }
      }
    }
  }

  isRouteValid(state: RouterStateSnapshot) {
    let routesMatching: any[] = [];
    routesMatching = this.router.config
      .filter(value => value.path !== '**' && value.path !== '')
      .filter(value => this.isMatchingPattern(value.path ?? '', state.url));
    return routesMatching.length > 0;
  }

  isMatchingPattern(path: string, url: string): boolean {
    if (!path.length) {
      path = '/';
    }
    if (!url.length) {
      url = '/';
    }
    if (path[0] !== '/') {
      path = '/' + path;
    }
    if (url[0] !== '/') {
      url = '/' + url;
    }
    const queryParamsStartIndex = url.indexOf('?');
    if (queryParamsStartIndex !== -1) {
      url = url.slice(0, queryParamsStartIndex);
    }
    const isMatching = match(path, url).matches;
    return isMatching;
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
