import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomePageOneComponent } from './components/pages/home-page-one/home-page-one.component';
import { LoginPageComponent } from './components/pages/login-page/login-page.component';
import { RegisterPageComponent } from './components/pages/register-page/register-page.component';
import { ErrorPageComponent } from './components/pages/error-page/error-page.component';
import { CartPageComponent } from './components/pages/cart-page/cart-page.component';
import { CheckoutPageComponent } from './components/pages/checkout-page/checkout-page.component';
import { ContactComponent } from './components/pages/contact/contact.component';
import { ForgotPasswordComponent } from './components/pages/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './components/pages/reset-password/reset-password.component';
import { HroAccountComponent } from './shared/hro-account/hro-account.component';
import { HroNotificationListComponent } from './shared/hro-notification-list/hro-notification-list.component';
import { PagePaymentFailedComponent } from './components/pages/page-payment-failed/page-payment-failed.component';
import { PagePaymentSuccessComponent } from './components/pages/page-payment-success/page-payment-success.component';
import { UserRouteAccessService } from './services/auth/user-route-access.service';
import { DynamicPathGuard } from './services/guards/dynamic-path.guard';
import { CoachBookComponent } from './coach/coach-book/coach-book.component';
import { BENEFICIARY_GROUP } from './shared/constants';
import {BeneficiaryProfilComponent} from "./components/pages/beneficiary-profil/beneficiary-profil.component";

export const routes: Routes = [
  { path: '', component: HomePageOneComponent },
  { path: 'preview-theme/:previewThemeId', component: HomePageOneComponent },
  {
    path: 'preview-available-theme/:previewAvailableThemeId',
    component: HomePageOneComponent,
  },
  { path: 'login', component: LoginPageComponent },
  {
    path: 'register',
    component: RegisterPageComponent,
    canActivate: [UserRouteAccessService],
  },
  { path: 'cart', component: CartPageComponent },
  { path: 'checkout', component: CheckoutPageComponent },
  { path: 'contact-cms', component: ContactComponent },
  {
    path: 'coach/book/:seoName',
    component: CoachBookComponent,
    data: {
      authorities: [BENEFICIARY_GROUP],
    },
    canActivate: [UserRouteAccessService],
  },
  { path: 'forgot-password', component: ForgotPasswordComponent },
  { path: 'profil/:code', component: BeneficiaryProfilComponent },
  {
    path: 'account',
    component: HroAccountComponent,
    data: {
      authorities: [],
    },
    canActivate: [UserRouteAccessService],
  },
  {
    path: 'notifications',
    component: HroNotificationListComponent,
    data: {
      authorities: [],
    },
    canActivate: [UserRouteAccessService],
  },
  { path: 'reset-password/:key', component: ResetPasswordComponent },
  { path: 'payment/failed', component: PagePaymentFailedComponent },
  { path: 'payment/success', component: PagePaymentSuccessComponent },
  {
    path: 'drh-space',
    loadChildren: () => import('./drh-space/drh-space.module').then(m => m.DrhSpaceModule),
  },
  {
    path: 'beneficiary-space',
    loadChildren: () => import('./beneficiary-space/beneficiary-space.module').then(m => m.BeneficiarySpaceModule),
  },
  {
    path: 'product',
    loadChildren: () => import('./product/product.module').then(m => m.ProductModule),
  },
  {
    path: 'coach-space',
    loadChildren: () => import('./coach-space/coach-space.module').then(m => m.CoachSpaceModule),
  },
  {
    path: 'service-provider-space',
    loadChildren: () => import('./service-provider-space/service-provider-space.module').then(m => m.ServiceProviderSpaceModule),
  },
  {
    path: 'inbox',
    loadChildren: () => import('./inbox/inbox.module').then(m => m.InboxModule),
  },
  {
    path: 'page-not-found',
    component: ErrorPageComponent,
  },
  {
    path: '**',
    component: ErrorPageComponent,
    canActivate: [DynamicPathGuard],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      relativeLinkResolution: 'legacy',
      initialNavigation: 'enabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
