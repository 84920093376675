import { IIndividualSeller, IndividualSeller } from './individualSeller.model';
import { ILanguage } from './language.model';
import { ISocialNetworkAccount } from './social-network-account.model';

export interface IIndividualCoach extends IIndividualSeller {
  id?: string;
  summary?: any;
  countryId?: string;
  coachingDomainId?: string[];
  icfAccreditationLevelId?: string;
  languages?: ILanguage[];
  socialNetworkAccounts?: ISocialNetworkAccount[];
}

export class IndividualCoach extends IndividualSeller implements IIndividualCoach {
  constructor(
    public id?: string,
    public summary?: any,
    public countryId?: string,
    public coachingDomainId?: string[],
    public icfAccreditationLevelId?: string,
    public languages?: ILanguage[],
    public socialNetworkAccounts?: ISocialNetworkAccount[]
  ) {
    super();
  }
}
