<div [ngSwitch]="componentId">
  <app-hro-banner *ngSwitchCase="'BANNER'" [data]="data" [videoComponentCode]="videoComponentCode"></app-hro-banner>
  <app-hro-banner-coaches *ngSwitchCase="'BANNER_COACHES'" [data]="data" [videoComponentCode]="videoComponentCode"></app-hro-banner-coaches>
  <app-home-header
    *ngSwitchCase="'ENTREPRISE_HEADING_BANNER'"
    [componentCode]="componentId"
    [pageCode]="entreprisePageCode"></app-home-header>
  <app-home-header
    *ngSwitchCase="'PARTICULAR_HEADING_BANNER'"
    [componentCode]="componentId"
    [pageCode]="particularPageCode"></app-home-header>
</div>
