<ngx-spinner bdColor="rgba(51,51,51,0.8)" color="#fff" size="medium" type="line-scale"> </ngx-spinner>
<div class="container">
  <div class="avatar-upload">
    <div class="avatar-edit">
      <input type="file" id="imageUpload" (change)="onImageChange($event)" accept=".png, .jpg, .jpeg" />
      <label for="imageUpload"><i style="font-size: 30px; color: #696666" class="bx bx-edit-alt"></i></label>
    </div>
    <div class="avatar-preview">
      <img
        id="imagePreview"
        [src]="getImage(customer.image, null)"
        onerror="this.src='assets/img/not-found.png'"
        [alt]="customer.image?.alt ?? customer.firstName + ' ' + customer.lastName" />
    </div>
  </div>
</div>
