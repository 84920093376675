import { GenericService } from './GenericService';
import { Injectable } from '@angular/core';
import { convertResponse } from './utils/response-converter';
import { createRequestOption } from './utils/request-util';
import { ResponseWrapper } from '../models/response-wrapper.model';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { IRequest } from '../shared/models/request.model';
import { IAnonymousUser } from '../shared/models/anonymousUser.model';
import { IWorkItemType } from '../shared/models/request-type.model';

@Injectable({ providedIn: 'root' })
export class RequestTypeService extends GenericService {
  constructor() {
    super();
  }

  createRequest(request: IRequest): Observable<IRequest> {
    return this.api.post('api/requests', request);
  }

  createAnonynoms(anonymousUser: IAnonymousUser): Observable<IRequest> {
    return this.api.post('api/anonymous-users', anonymousUser);
  }

  update(requestType: IWorkItemType): Observable<IWorkItemType> {
    return this.api.put('api/request-types', requestType);
  }

  find(id: string): Observable<IWorkItemType> {
    return this.api.get('api/request-types/' + id);
  }

  findAll(): Observable<IWorkItemType[]> {
    return this.api.get('api/request-types');
  }

  delete(id: string): Observable<any> {
    return this.api.delete('api/request-types/' + id);
  }

  query(req?: any): Observable<ResponseWrapper> {
    const options = createRequestOption(req);
    return this.api.get('api/request-types/', options).pipe(map(res => convertResponse(res)));
  }

  downloadMedia(mediaId: string, format: string): any {
    return environment.apiUrl + 'api/export-media/' + mediaId + ' /' + format;
  }

  createOrderRequest(request: IRequest): Observable<IRequest> {
    return this.api.post('api/ask-about-order-requests', request);
  }
  createProductRequest(request: IRequest): Observable<IRequest> {
    return this.api.post('api/ask-about-product-requests', request);
  }
}
