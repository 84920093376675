<div class="page-title-area item-bg2">
  <div class="container">
    <div class="page-title-content">
      <ul>
        <li>
          <a href="index.html">{{ 'HOME' | translate }}</a>
        </li>
        <li>{{ 'CONTACT' | translate }}</li>
      </ul>
      <h2>{{ 'CONTACT' | translate }}</h2>
    </div>
  </div>
</div>

<div class="contact-area pb-100" style="margin-top: 30px">
  <div class="container">
    <div class="section-title">
      <div class="" *ngIf="getIdComponentByCode('HEADER_CONTACT_PAGE_COMPONENT')">
        <app-html [componentId]="getIdComponentByCode('HEADER_CONTACT_PAGE_COMPONENT')"></app-html>
      </div>
    </div>
    <div class="contact-form">
      <form [formGroup]="contactForm" (ngSubmit)="createRequest()">
        <div class="row">
          <div class="col-lg-12 col-md-12">
            <div class="form-group">
              <label>{{ 'NAME' | translate }} <span class="required">*</span></label>
              <input
                required
                minlength="3"
                maxlength="50"
                [disabled]="user.id"
                [(ngModel)]="user.firstName"
                formControlName="name"
                type="text"
                class="form-control"
                id="name"
                placeholder="{{ 'NAME' | translate }}" />
              <div class="alert alert-danger" *ngIf="submitted && f.name.errors">
                <div>{{ 'NAME_REQUIRED' | translate }}.</div>
              </div>
            </div>
          </div>
          <div class="col-lg-12 col-md-12">
            <div class="form-group">
              <label>{{ 'EMAIL' | translate }} <span class="required">*</span></label>
              <input
                [disabled]="user.id"
                email
                required
                [(ngModel)]="user.email"
                formControlName="email"
                type="email"
                class="form-control"
                id="email"
                placeholder="{{ 'EMAIL' | translate }}" />
              <div class="alert alert-danger" *ngIf="submitted && f.email.errors">
                {{ 'REQUIRED_FIELD' | translate }}
              </div>
            </div>
          </div>
          <div class="col-lg-12 col-md-12">
            <div class="form-group">
              <label>{{ 'PHONENUMBER' | translate }} <span class="required">*</span></label>
              <input
                [disabled]="user.id"
                required
                [(ngModel)]="user.phoneNumber1"
                formControlName="number"
                type="text"
                class="form-control"
                id="number"
                placeholder="{{ 'PHONENUMBER' | translate }}" />
              <div class="alert alert-danger" *ngIf="submitted && f.number.errors">
                {{ 'REQUIRED_FIELD' | translate }}
              </div>
            </div>
          </div>
          <div class="col-lg-12 col-md-12">
            <div class="form-group">
              <label>{{ 'SUBJECT' | translate }} <span class="required">*</span></label>
              <select class="form-select" formControlName="subject" required [(ngModel)]="workItemType">
                <option style="color: black" *ngFor="let type of types" [ngValue]="type">
                  {{ type.name.localizedValues[currentLocale] }}
                </option>
              </select>
              <div class="alert alert-danger" *ngIf="submitted && f.subject.errors">
                {{ 'REQUIRED_FIELD' | translate }}
              </div>
            </div>
          </div>
          <div *ngIf="workItemType?.id === 'PRODUCT_QUESTIONS'" class="mb-3">
            <mat-form-field class="example-full-width" appearance="fill">
              <mat-label>{{ 'PRODUCT' | translate }} <span class="required">*</span></mat-label>
              <input
                type="text"
                (input)="searchProduct($event.target.value)"
                placeholder="{{ 'CHOOSE_PRODUCT' | translate }}"
                matInput
                [formControl]="myControl"
                [matAutocomplete]="auto" />
              <mat-autocomplete
                autoActiveFirstOption
                #auto="matAutocomplete"
                [displayWith]="getName"
                (optionSelected)="selectProduct($event.option.value)">
                <mat-option *ngFor="let option of products" [value]="option">
                  {{ option.name }}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
            <div class="alert alert-danger" *ngIf="submitted && myControl.hasError('productSelected')">
              {{ 'REQUIRED_FIELD' | translate }}
            </div>
          </div>
          <div *ngIf="workItemType?.id === 'ORDER_INQUIRIES'" class="ng-autocomplete mb-3">
            <label>{{ 'ORDER' | translate }} <span class="required">*</span></label>
            <input
              required
              minlength="3"
              maxlength="50"
              [(ngModel)]="orderReference"
              formControlName="orderReference"
              type="text"
              class="form-control"
              id="orderReference"
              placeholder="{{ 'NAME' | translate }}" />
            <div class="alert alert-danger" *ngIf="submitted && f.orderReference.errors">{{ 'REQUIRED_FIELD' | translate }}.</div>
          </div>
          <div class="col-lg-12 col-md-12">
            <div class="form-group">
              <label>{{ 'MESSAGE' | translate }} <span class="required">*</span></label>
              <textarea
                required
                [(ngModel)]="request.description"
                formControlName="message"
                id="message"
                cols="30"
                rows="5"
                class="form-control"
                placeholder="{{ 'TYPE_MSG' | translate }}"></textarea>
              <div class="alert alert-danger" *ngIf="submitted && f.message.errors">{{ 'REQUIRED_FIELD' | translate }}.</div>
            </div>
          </div>
          <div>
            <ngx-recaptcha2
              [(ngModel)]="declarativeFormCaptchaValue"
              [hl]="recaptchaLocale"
              [siteKey]="recaptchaSiteKey"
              required
              formControlName="captcha"></ngx-recaptcha2>
          </div>
          <div class="col-lg-12 col-md-12">
            <button type="submit" class="default-btn">
              <i class="bx bx-paper-plane icon-arrow before"></i><span class="label">{{ 'SEND' | translate }}</span
              ><i class="bx bx-paper-plane icon-arrow after"></i>
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
