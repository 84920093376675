import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { IContext } from '../../../cms/models/context.model';
import { ReplaySubject } from 'rxjs';
import { Router } from '@angular/router';
import { ContextService } from '../../../cms/context.service';
import { CmsService } from '../../../cms/cms.service';
import { takeUntil } from 'rxjs/operators';
import { AppStateService } from 'src/app/services/utils/state.service';
import { ABOUT_US } from '../../../shared/constants';
import { SeoService } from '../../../services/seo.service';
import { ABOUT_US_COMPONENT_NAME } from '../../../ComponentsIndex';
import { DynamicPathStorageService } from '../../../services/dynamic-path-storage.service';
import { CurrentLocaleService } from '../../../services/current-locale.service';

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AboutUsComponent implements OnInit, OnDestroy {
  cmsPage: any;
  currentLocale: string;
  contextItemIds: string[] | undefined;
  context: IContext | null | undefined;
  components: any[] | undefined;
  component: any;
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  constructor(
    private router: Router,
    private contextService: ContextService,
    private cmsService: CmsService,
    appStateService: AppStateService,
    private seoService: SeoService,
    private dynamicPathStorageService: DynamicPathStorageService,
    private currentLocaleService: CurrentLocaleService
  ) {
    this.currentLocale = appStateService.getCurrentLocaleId();
  }
  ngOnInit(): void {
    this.currentLocaleService.currentLocaleId$.pipe(takeUntil(this.destroyed$)).subscribe(localeId => {
      this.currentLocale = localeId;
      if (this.cmsPage) {
        this.seoService.setSeoFromSeoDataAndMedia(this.cmsPage.seoData);
      }
      const currentLocaleUrlPattern = this.dynamicPathStorageService.getUrlPatternByComponentNameFromCmsPageList(ABOUT_US_COMPONENT_NAME);
      if (currentLocaleUrlPattern) {
        this.router.navigate([currentLocaleUrlPattern]);
      }
    });
    this.getContext();
    this.getCmsPage();
  }

  getContext(): void {
    this.contextItemIds = [];
    this.context = null;
    this.contextItemIds.push(this.currentLocale);
    this.contextService
      .findContext(this.contextItemIds)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(context => {
        this.context = context;
      });
  }

  getCmsPage(): void {
    this.cmsService
      .getCmsPageByCode(ABOUT_US)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(res => {
        this.cmsPage = res;
        this.seoService.setSeoFromSeoDataAndMedia(this.cmsPage.seoData);
        this.components = res.cmsComponents;
      });
  }

  getIdComponentByCode(code: string): any {
    if (this.components && this.components.length > 0) {
      const index = this.components.findIndex(component => component.code === code);
      if (index !== -1) {
        return this.components[index].id;
      }
    }
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
    this.seoService.resetSeo();
  }
}
