<div class="partner-area pt-40 pb-40" *ngFor="let cmsComponentContent of data">
  <div class="container">
    <div class="section-title">
      <h2>{{ cmsComponentContent.title }}</h2>
      <p>{{ cmsComponentContent.description }}</p>
    </div>
    <div class="partner-slides">
      <owl-carousel-o [options]="partnerSlides">
        <ng-template carouselSlide *ngFor="let cmsEntity of cmsComponentContent.elements">
          <a
            [routerLink]="
              corporateSellerDetailPageUrl ? (corporateSellerDetailPageUrl | seoNameUrl : cmsEntity.seoData : currentLocale) : null
            ">
            <div class="single-partner-item position-relative mx-auto cursor-pointer" style="width: 95%; height: 95%">
              <div class="align-middle">
                <img
                  [src]="getImage(cmsEntity.photoId, null)"
                  onerror="this.src='assets/img/partner/partner1.png'"
                  class="align-middle position-absolute px-2"
                  [alt]="cmsEntity.name"
                  style="max-height: 15rem; max-width: 15rem; top: 50%; left: 50%; transform: translate(-50%, -50%)" />
                <!--<h5 style="color: white">{{cmsEntity.name}}</h5>-->
              </div>
            </div>
          </a>
        </ng-template>
      </owl-carousel-o>
    </div>
  </div>
</div>
