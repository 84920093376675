import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'idUrl',
})
export class IdUrlPipe implements PipeTransform {
  constructor() {}

  transform(urlPattern: string, id: string): string {
    if (!id || !urlPattern) {
      return null;
    }
    let newLink = urlPattern;
    const urlFragments: string[] = urlPattern.split('/');
    if (!urlFragments) {
      return newLink;
    }
    const urlVariables = urlFragments.filter((value: string) => value.startsWith(':'));
    if (!urlVariables) {
      return newLink;
    }
    for (const url of urlVariables.values()) {
      const key: string = url.substring(1, url.length);
      if (key === 'id') {
        newLink = newLink.replace(url, id);
      }
    }
    if (!newLink.startsWith('/') && newLink) {
      newLink = '/' + newLink;
    }
    return newLink;
  }
}
