import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { CategoryService } from 'src/app/services/category.service';
import { LOCALE_ID, LOCALE_STATE, PRODUCT_CATEGORY } from '../constants';
import { Store } from '@ngrx/store';
import { AppState } from '../../store/app.reducer';
import { DynamicPathRoutingService } from '../../services/dynamic-path-routing.service';
import { Observable, ReplaySubject } from 'rxjs';
import { PACK_LIST_COMPONENT_NAME, PRESTATION_COMPONENT_NAME } from '../../ComponentsIndex';
import { map, takeUntil } from 'rxjs/operators';
import { DynamicPathStorageService } from '../../services/dynamic-path-storage.service';

@Component({
  selector: 'app-mega-menu',
  templateUrl: './mega-menu.component.html',
  styleUrls: ['./mega-menu.component.scss'],
})
export class MegaMenuComponent implements OnInit, OnDestroy {
  categories = [
    {
      label: 'SERVICE_CATEGORIES',
      code: 'Catégories de prestations',
      children: [],
    },
    {
      label: 'Packs',
      code: 'PACKS',
      children: [
        {
          label: 'OUR_PACKS',
          code: 'PRE-DEFINED',
          id: 'packs',
        },
      ],
    },
  ];
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  packListPageUrl$: Observable<string> = new Observable<string>();
  productListByCategoryPageUrl: string;

  readonly PACKS_CATEGORY_ID = 'packs';

  @Output() onLinkClick = new EventEmitter<void>();

  constructor(
    private categoryService: CategoryService,
    private store: Store<AppState>,
    private dynamicPathRoutingService: DynamicPathRoutingService,
    private dynamicPathStorageService: DynamicPathStorageService
  ) {}

  ngOnInit(): void {
    this.getCategories();
    this.store.select(LOCALE_STATE, LOCALE_ID).subscribe(() => this.getCategories());
    // this.packListPageUrl$ = this.getUrlPatternObservable(PACK_LIST_COMPONENT_NAME);
    // this.getUrlPatternObservable(PRESTATION_COMPONENT_NAME).subscribe(urlPattern => this.productListByCategoryPageUrl = urlPattern);

    this.packListPageUrl$ = this.dynamicPathStorageService.urlPatterns$.pipe(
      takeUntil(this.destroyed$),
      map(() => this.dynamicPathStorageService.getUrlPatternByComponentNameFromCmsPageList(PACK_LIST_COMPONENT_NAME))
    );

    this.dynamicPathStorageService.urlPatterns$.pipe(takeUntil(this.destroyed$)).subscribe(() => {
      this.productListByCategoryPageUrl =
        this.dynamicPathStorageService.getUrlPatternByComponentNameFromCmsPageList(PRESTATION_COMPONENT_NAME);
    });
  }

  getCategories() {
    this.categoryService.getCategoriesByParentId(PRODUCT_CATEGORY).subscribe(res => {
      this.categories[0].children = res;
    });
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
