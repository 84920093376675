<div class="courses-categories-area pt-40 pb-40" style="padding-top: 25px" *ngFor="let cmsComponentContent of data">
  <div class="container">
    <div class="section-title">
      <h2>{{ cmsComponentContent.title }}</h2>
      <p>{{ cmsComponentContent.description }}</p>
    </div>
    <div class="row">
      <div class="col-lg-4 col-md-4 col-sm-6" *ngFor="let element of cmsComponentContent.elements">
        <div
          [routerLink]="journeyToExcellenceProductDetailsPageUrl ? (journeyToExcellenceProductDetailsPageUrl | idUrl : element.id) : null"
          class="single-categories-courses-item bg1 mb-30 shadow cursor-pointer"
          style="background-image: url('../../../../assets/img/BG_{{ element.id }}.webp');">
          <div class="journey-icon">
            <img
              max-height="50"
              width="80"
              height="80"
              class="mb-"
              [src]="'assets/img/' + element.id + '.png'"
              [alt]="element.name"
              class="img-fluid rounded-circle" />
          </div>
          <h3>{{ element?.name }}</h3>
          <!-- <span>{{element.numberOfProducts}} {{"COURSES" | translate}}</span> -->
          <a
            [routerLink]="journeyToExcellenceProductDetailsPageUrl ? (journeyToExcellenceProductDetailsPageUrl | idUrl : element.id) : null"
            class="learn-more-btn"
            >{{ 'LEARN_MORE' | translate }} <i class="bx bx-book-reader"></i
          ></a>
        </div>
      </div>
    </div>
  </div>
</div>
