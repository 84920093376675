import { Component, OnDestroy, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { IBlog } from '../../shared/models/blog.model';
import { BlogService } from '../blog.services';
import { Subject } from 'rxjs';
import { DynamicPathRoutingService } from '../../services/dynamic-path-routing.service';
import { AppState } from '../../store/app.reducer';
import { Store } from '@ngrx/store';
import { DynamicPathService } from '../../services/dynamic-path.service';
import { BLOG_DETAILS_COMPONENT_NAME, BLOG_LIST_COMPONENT_NAME } from '../../ComponentsIndex';
import { DynamicPathStorageService } from '../../services/dynamic-path-storage.service';
import { CmsService } from '../../cms/cms.service';
import { SeoService } from '../../services/seo.service';
import { BLOGS_PAGE } from '../../shared/constants';
import { Router } from '@angular/router';
import { CurrentLocaleService } from '../../services/current-locale.service';

@Component({
  selector: 'app-blog-list',
  templateUrl: './blog-list.component.html',
  styleUrls: ['./blog-list.component.scss'],
})
export class BlogListComponent implements OnInit, OnDestroy {
  cmsPage: any;
  pageIndex = 1;
  size = 6;
  totalItems;
  blogsDTOList: IBlog[];
  private _isDead$ = new Subject();
  blog: IBlog;
  currentLocaleId: string;
  blogDetailsUrlPattern: string;
  constructor(
    protected blogService: BlogService,
    private dynamicPathRoutingService: DynamicPathRoutingService,
    private store: Store<AppState>,
    private dynamicPathService: DynamicPathService,
    private dynamicPathStorageService: DynamicPathStorageService,
    private cmsService: CmsService,
    private seoService: SeoService,
    private router: Router,
    private currentLocaleService: CurrentLocaleService
  ) {}

  ngOnInit(): void {
    this.paginate(this.pageIndex);
    this.currentLocaleService.currentLocaleId$.pipe(takeUntil(this._isDead$)).subscribe(localeId => {
      this.currentLocaleId = localeId;
      if (this.cmsPage) {
        this.seoService.setSeoFromSeoDataAndMedia(this.cmsPage.seoData);
        const currentLocaleUrlPattern =
          this.dynamicPathStorageService.getUrlPatternByComponentNameFromCmsPageList(BLOG_LIST_COMPONENT_NAME);
        if (currentLocaleUrlPattern) {
          this.router.navigate([currentLocaleUrlPattern]);
        }
      }
    });

    this.dynamicPathStorageService.urlPatterns$.pipe(takeUntil(this._isDead$)).subscribe(() => {
      this.blogDetailsUrlPattern = this.dynamicPathStorageService.getUrlPatternByComponentNameFromCmsPageList(BLOG_DETAILS_COMPONENT_NAME);
    });
    this.cmsService
      .getCmsPageByCode(BLOGS_PAGE)
      .pipe(takeUntil(this._isDead$))
      .subscribe(res => {
        this.cmsPage = res;
        this.seoService.setSeoFromSeoDataAndMedia(this.cmsPage.seoData);
      });
  }

  loadAll() {
    this.blogService
      .findAllBlogPostsWithPagination('BLOG_1', {
        page: this.pageIndex - 1,
        size: this.size,
      })
      .pipe(takeUntil(this._isDead$))
      .subscribe(
        blogLists => {
          this.blogsDTOList = blogLists.json;
          this.totalItems = blogLists.headers.get('x-total-count');
        },
        () => {}
      );
  }

  paginate(event: any) {
    this.pageIndex = event;
    this.loadAll();
  }

  getImage(image: any, format: string | null): any {
    if (!image) {
      return;
    }
    return this.blogService.downloadMedia(image, format);
  }

  ngOnDestroy() {
    this._isDead$.next();
    this.seoService.resetSeo();
  }
}
