import { Injectable } from '@angular/core';
import { GenericService } from './GenericService';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { ResponseWrapper } from '../models/response-wrapper.model';
import { createRequestOption } from './utils/request-util';
import { map } from 'rxjs/operators';
import { convertResponse } from './utils/response-converter';
import { ProductFamily } from '../models/product-family.model';
import { Category } from '../models/category.model';
import { IProductToSaveInSeller } from '../models/productToSave.model';
import { AppStateService } from './utils/state.service';
import { Store } from '@ngrx/store';
import { AppState } from '../store/app.reducer';
import { LOCALE_ID, LOCALE_STATE } from '../shared/constants';

@Injectable({
  providedIn: 'root',
})
export class ProductService extends GenericService {
  private currentLocaleId: string;
  private type = 'IMAGE';
  private productAttributeType = 'MEDIAS_ATTRIBUTE';

  constructor(private appStateService: AppStateService, private store: Store<AppState>) {
    super();
    this.store.select(LOCALE_STATE, LOCALE_ID).subscribe(localeId => (this.currentLocaleId = localeId));
  }

  find(id: string, currencyId: string): Observable<any> {
    return this.api.get('api/products-by-id/' + id + '/' + currencyId + '/PDP/' + this.currentLocaleId);
  }

  // findProductPriceListByProductIdAndLocale(productId: string, channel: string): Observable<any[]> {
  //     return this.api.get('api/product-prices/' + productId + '/' + channel);
  // }

  storeMedia(file: File): Observable<any> {
    const formdata: FormData = new FormData();
    formdata.append('file', file);
    return this.api.post('api/upload-media-for-product-service-provider/' + this.productAttributeType + '/' + this.type, formdata);
  }

  getAllFamiliesByLocaleId(id: string): Observable<ProductFamily[]> {
    return this.api.get('api/product-families/' + id + '/' + this.currentLocaleId);
  }

  getAllCategoriesByLocaleId(id: string): Observable<Category[]> {
    return this.api.get('api/categories/' + id + '/' + this.currentLocaleId);
  }

  createProduct(product: IProductToSaveInSeller): Observable<ResponseWrapper> {
    return this.api.post('api/save-product/', product);
  }

  updateProduct(product: IProductToSaveInSeller): Observable<ResponseWrapper> {
    return this.api.put('api/save-product/', product);
  }

  deleteProducts(productsIds): Observable<any> {
    const options = {
      body: productsIds,
    };
    return this.api.delete('api/products-multiple-delete', options);
  }

  findProductToSave(id: string): Observable<any> {
    return this.api.get('api/products-to-save/' + id + '/' + this.currentLocaleId);
  }

  downloadMedia(mediaId: string, format: string | null): any {
    return environment.apiUrl + 'api/export-media/' + mediaId + '/' + format;
  }

  searchAllProducts(filterObject, currencyId, req): Observable<ResponseWrapper> {
    const options = createRequestOption(req);
    return this.api
      .post('api/indexed-products-pagination/' + this.currentLocaleId + '/' + currencyId + '/PLP', filterObject, options)
      .pipe(map(res => convertResponse(res)));
  }

  searchProductBySellerAndNAme(name, sellerID, currencyId, req): Observable<ResponseWrapper> {
    const options = createRequestOption(req);
    return this.api
      .get('api/search-product-list/service-provider/' + sellerID + '/' + this.currentLocaleId + '/' + currencyId + '/' + name, options)
      .pipe(map(res => convertResponse(res)));
  }

  searchAllConsignment(filterObject, req): Observable<ResponseWrapper> {
    const options = createRequestOption(req);
    return this.api
      .post('api/indexed-consignment-pagination/' + this.currentLocaleId, filterObject, options)
      .pipe(map(res => convertResponse(res)));
  }

  // findAllCodes(): Observable<any[]> {
  //     return this.api.get('api/products-basics');
  // }
  //
  // getGenericProduct(id: string, contextId: string, currencyId: string): Observable<any> {
  //     return this.api.get(`api/passexcellence/products-by-id/${id}/${currencyId}`);
  // }

  getGenericProductBySeoName(seoName: string, currencyId: string): Observable<any> {
    return this.api.get(`api/products-by-seo-name/${seoName}/${this.currentLocaleId}/${currencyId}`);
  }

  // getGenericPack(id: string, contextId: string, currencyId: string): Observable<any> {
  //     return this.api.get(`api/packs-by-id/${id}/${currencyId}/PDP/${this.currentLocaleId}`);
  // }

  getGenericPackBySeoName(seoName: string, currencyId: string) {
    return this.api.get(`api/packs-by-seo-name/${seoName}/${this.currentLocaleId}/${currencyId}`);
  }

  // searchCorporateSellers(filterObject, req): Observable<ResponseWrapper> {
  //     const options = createRequestOption(req);
  //     return this.api.post('api/indexed-corporate-seller-pagination/', filterObject, options).pipe(
  //         map((res) => convertResponse(res)));
  // }
  //
  // getCorporateSellerById(corporateSellerId: string): Observable<any> {
  //     return this.api.get('api/corporate-sellers/' + corporateSellerId);
  // }

  getPredefinedActionProducts(actionId: string): Observable<any> {
    const currencyId = this.appStateService.getCurrentCurrency();
    return this.api.get('api/predefined-action-products/' + actionId + '/' + this.currentLocaleId + '/' + currencyId);
  }
  getPredefinedActionLibraries(actionId: string): Observable<any> {
    const currencyId = this.appStateService.getCurrentCurrency();
    return this.api.get('api/predefined-action-libraries/' + actionId + '/' + this.currentLocaleId );
  }
  getProductsByJourneryToExcellence(journeyToExcellenceId: string, req): Observable<any> {
    const currencyId = this.appStateService.getCurrentCurrency();
    const localeId = this.appStateService.getCurrentLocaleId();
    const options = createRequestOption(req);
    return this.api.get('api/journey-products/' + journeyToExcellenceId + '/' + localeId + '/' + currencyId, options);
  }
}
