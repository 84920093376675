import { Component, OnDestroy, OnInit } from '@angular/core';
import { IPayment } from '../../../models/payment.model';
import { CartService } from '../../../services/cart.service';
import { LocalStorageService } from 'ngx-webstorage';
import { PaymentService } from '../../../services/payment.service';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { OrderService } from '../../../services/order.service';
import { ProductService } from '../../../services/product.service';
import { IOrder, Order } from '../../../models/order.model';
import { IProduct } from '../../../models/product.model';
import { LANGUAGE, ORDER_ID_KEY_STORAGE, ORDER_KEY_STORAGE, PAYMENT_ID_KEY_STORAGE } from '../../../shared/constants';
import * as SecureLS from 'secure-ls';

@Component({
  selector: 'app-payment-success',
  templateUrl: './page-payment-success.component.html',
  styleUrls: ['./page-payment-success.component.scss'],
})
export class PagePaymentSuccessComponent implements OnInit, OnDestroy {
  cart: any;
  successPayment: IPayment;
  pspOrderId: string;
  constructor(
    public cartService: CartService,
    private router: Router,
    public localStorage: LocalStorageService,
    public translate: TranslateService,
    public paymentService: PaymentService,
    public orderService: OrderService,
    public productService: ProductService
  ) {
    if (!this.translate.currentLang) {
      this.translate.use(this.localStorage.retrieve(LANGUAGE));
    }
    this.localStorage.observe(LANGUAGE).subscribe(() => {
      this.translate.use(this.localStorage.retrieve(LANGUAGE));
    });
    this.secureLocaStorage = new SecureLS({ encodingType: 'aes' });
  }

  public secureLocaStorage: SecureLS;
  public orderObject: any;
  public order: IOrder;
  products: IProduct[];

  total: number;

  ngOnInit(): void {
    this.products = [];
    this.order = new Order();
    this.orderObject = this.secureLocaStorage.get(ORDER_KEY_STORAGE).data;
    this.pspOrderId = this.secureLocaStorage.get(ORDER_ID_KEY_STORAGE).data;
    if (!this.pspOrderId || !this.orderObject) {
      this.router.navigate(['/']);
    }

    this.paymentService
      .getPaymentStatus(this.secureLocaStorage.get(ORDER_ID_KEY_STORAGE).data, this.secureLocaStorage.get(PAYMENT_ID_KEY_STORAGE).data)
      .subscribe(() => {
        this.secureLocaStorage.remove(PAYMENT_ID_KEY_STORAGE);
      });

    this.paymentService.findByPspOrderId(this.pspOrderId).subscribe(payment => {
      this.order.total = payment.amount;
      this.successPayment = payment;
      this.successPayment.paymentStatusId = '4';
      this.paymentService.update(this.successPayment).subscribe(() => {
        this.orderService.createOrder(this.orderObject).subscribe(orderData => {
          this.order = orderData;
          this.cartService.clearCart();
        });
        this.secureLocaStorage.remove(ORDER_KEY_STORAGE);
        this.secureLocaStorage.remove(ORDER_ID_KEY_STORAGE);
      });
    });
  }

  ngOnDestroy() {
     this.cartService.clearCart();
  }
}
