import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-hro-statistics',
  templateUrl: './hro-statistics.component.html',
  styleUrls: ['./hro-statistics.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class HroStatisticsComponent implements OnInit {
  @Input()
  data: any;
  constructor(public sanitizer: DomSanitizer) {}

  ngOnInit(): void {}
}
