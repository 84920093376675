import { Injectable } from '@angular/core';
import { Platform } from '@angular/cdk/platform';

@Injectable({
  providedIn: 'root',
})
export class PwaService {
  // private promptEvent: any;

  constructor(
    // private bottomSheet: MatBottomSheet,
    private platform: Platform
  ) {}

  public initPwaPrompt(): void {
    if (this.platform.ANDROID) {
      window.addEventListener(
        'beforeinstallprompt',
        (event: any) => {
          event.preventDefault();
        },
        { passive: true }
      );
    }
    if (this.platform.IOS) {
      const isInStandaloneMode = 'standalone' in window.navigator && window.navigator; //.standalone;
      if (!isInStandaloneMode) {
        // this.openPromptComponent('ios');
      }
    }
  }
}
