import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MegaMenuComponent } from './mega-menu/mega-menu.component';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { HroNavbarComponent } from './hro-navbar/hro-navbar.component';
import { HroProductCardComponent } from './hro-product-card/hro-product-card.component';
import { HroEditProfilImageComponent } from '../components/pages/hro-edit-profil-image/hro-edit-profil-image.component';
import { HroNotificationListComponent } from './hro-notification-list/hro-notification-list.component';
import { HroUserBannerEditComponent } from './hro-user-banner-edit/hro-user-banner-edit.component';
import { UiSwitchModule } from 'ngx-toggle-switch';
import { AdvertisingContentsCarouselComponent } from './advertising-contents-carousel/advertising-contents-carousel.component';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { HroUserBannerComponent } from './hro-user-banner/hro-user-banner.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ProductGalleryComponent } from './product-gallery/product-gallery.component';
import { CategoryComponent } from '../product/category/category.component';
import { ProductListComponent } from '../product/product-list/product-list.component';
import { MatPaginatorModule } from '@angular/material/paginator';
import { SeoNameUrlPipe } from './pipes/seo-name-url.pipe';
import { IdUrlPipe } from './pipes/id-url.pipe';
import { HroCompanyDetailComponent } from '../drh-space/hro-company-detail/hro-company-detail.component';
import { LightboxModule } from 'ngx-lightbox';
import { SafePipe } from './pipes/safe.pipe';
import { ReadWysiwygComponent } from './read-wysiwyg/read-wysiwyg.component';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { IfBrowserPlatformDirective } from './directives/if-browser-platform.directive';
import { IfServerPlatformDirective } from './directives/if-server-plarform.directive';
import { EditWysiwygComponent } from './edit-wysiwyg/edit-wysiwyg.component';
import { LazyImgDirective } from './directives/lazy-img.directive';
import { VoucherListComponent } from './voucher-list/voucher-list.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { AppointmentListComponent } from './appointment-list/appointment-list.component';
import { EditAppointmentComponent } from './edit-appointment/edit-appointment.component';
import { ReactiveFormsModule } from '@angular/forms';
import {DateTimePickerAllModule} from "@syncfusion/ej2-angular-calendars";

@NgModule({
  declarations: [
    ProductListComponent,
    CategoryComponent,
    MegaMenuComponent,
    HroNavbarComponent,
    HroProductCardComponent,
    HroEditProfilImageComponent,
    HroNotificationListComponent,
    HroUserBannerEditComponent,
    AdvertisingContentsCarouselComponent,
    HroUserBannerComponent,
    ProductGalleryComponent,
    SeoNameUrlPipe,
    SafePipe,
    IdUrlPipe,
    HroCompanyDetailComponent,
    ReadWysiwygComponent,
    EditWysiwygComponent,
    IfBrowserPlatformDirective,
    IfServerPlatformDirective,
    LazyImgDirective,
    VoucherListComponent,
    AppointmentListComponent,
    EditAppointmentComponent,
  ],
  exports: [
    ProductListComponent,
    CategoryComponent,
    HroNavbarComponent,
    HroProductCardComponent,
    HroEditProfilImageComponent,
    HroUserBannerEditComponent,
    HroUserBannerComponent,
    ProductGalleryComponent,
    SeoNameUrlPipe,
    IdUrlPipe,
    SafePipe,
    HroCompanyDetailComponent,
    ReadWysiwygComponent,
    EditWysiwygComponent,
    IfBrowserPlatformDirective,
    IfServerPlatformDirective,
    LazyImgDirective,
    VoucherListComponent,
    AppointmentListComponent,
    EditAppointmentComponent,
  ],
  providers: [SeoNameUrlPipe, IdUrlPipe, SafePipe],
    imports: [
        CommonModule,
        MatPaginatorModule,
        TranslateModule,
        RouterModule,
        UiSwitchModule,
        CarouselModule,
        NgxSpinnerModule,
        LightboxModule,
        CKEditorModule,
        MatProgressSpinnerModule,
        MatTableModule,
        MatSortModule,
        ReactiveFormsModule,
        DateTimePickerAllModule,
    ],
})
export class SharedModule {}
