<div *ngIf="data">
  <div *ngFor="let media of data?.medias">
    <div class="row">
      <div>
        <img
          width="4226"
          height="2609"
          [ngStyle]="{ width: width }"
          [src]="getImageSet(media)"
          [alt]="media.alt ?? 'image'"
          style="height: auto" />
      </div>
    </div>
  </div>
</div>
