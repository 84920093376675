import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { ProductService } from '../../../services/product.service';
import { CmsService } from '../../cms.service';
import { takeUntil } from 'rxjs/operators';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/store/app.reducer';
import { LOCALE_ID, LOCALE_STATE } from '../../../shared/constants';
import { DynamicPathRoutingService } from '../../../services/dynamic-path-routing.service';
import { CORPORATE_SELLER_DETAILS_COMPONENT_NAME } from '../../../ComponentsIndex';
import { DynamicPathStorageService } from '../../../services/dynamic-path-storage.service';

@Component({
  selector: 'app-hro-partner-set',
  templateUrl: './hro-partner-set.component.html',
  styleUrls: ['./hro-partner-set.component.scss'],
})
export class HroPartnerSetComponent implements OnInit, OnDestroy {
  @Input() componentId: any;
  data: any;
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  public currentLocale: string;
  corporateSellerDetailPageUrl: string;

  constructor(
    private productService: ProductService,
    private cmsService: CmsService,
    private store: Store<AppState>,
    private dynamicPathRoutingService: DynamicPathRoutingService,
    private dynamicPathStorageService: DynamicPathStorageService
  ) {}
  partnerSlides: OwlOptions = {
    loop: true,
    nav: false,
    dots: false,
    autoplayHoverPause: true,
    autoplay: true,
    margin: 30,
    navText: ["<i class='bx bx-left-arrow-alt'></i>", "<i class='bx bx-right-arrow-alt'></i>"],
    responsive: {
      0: {
        items: 1,
      },
      576: {
        items: 2,
      },
      768: {
        items: 4,
      },
      1200: {
        items: 6,
      },
    },
  };
  ngOnInit(): void {
    this.store
      .select(LOCALE_STATE, LOCALE_ID)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(localeId => {
        this.currentLocale = localeId;

        this.getComponentData();
      });
    this.dynamicPathStorageService.urlPatterns$.pipe(takeUntil(this.destroyed$)).subscribe(() => {
      this.corporateSellerDetailPageUrl = this.dynamicPathStorageService.getUrlPatternByComponentNameFromCmsPageList(
        CORPORATE_SELLER_DETAILS_COMPONENT_NAME
      );
    });
  }
  getComponentData() {
    this.cmsService
      .getCmsComponentById(this.componentId)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(res => {
        this.data = res.cmsContents.filter(element => element.context.id === this.currentLocale);
      });
  }

  getImage(image: any, format: string | null): any {
    if (!image) {
      return;
    }
    return this.productService.downloadMedia(image, format);
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
