/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { IAppointmentStatus } from './appointment-status.model';

export interface IAppointment {
  id?: string;
  label?: string;
  createDate?: any;
  startDate?: any;
  endDate?: any;
  duration?: number;
  sellerId?: string;
  customerId?: string;
  customerName?: string;
  sellerName?: string;
  appointmentStatus?: IAppointmentStatus;
  voucherCode?: string;
  scheduleProposed?: any[];
}
export class Appointment implements IAppointment {
  constructor(
    public id?: string,
    public label?: string,
    public createDate?: any,
    public startDate?: any,
    public endDate?: any,
    public sellerId?: string,
    public customerId?: string,
    public customerName?: string,
    public duration?: number,
    public appointmentStatus?: IAppointmentStatus,
    public voucherCode?: string
  ) {}
}
