import { IProductBasic } from './product-basic.model';

export interface IAction {
  id?: string;
  title?: string;
  description?: string;
  dueDate?: any;
  abandonedAt?: any;
  completedAt?: any;
  reasonOfAbandonment?: string;
  predefinedActionId?: string;
  actionStatus?: ActionStatus;
  predefinedActionName?: any;
  actionVisibility?: ActionVisibility;
  purchasedProduct?: IProductBasic;
  progress?: number;
  actionValidationRequest?: any;
  axeName?: string;
  productWasPurshased?: boolean;
  productInCard?: boolean;
}

export class Action implements IAction {
  constructor(
    public id?: string,
    public title?: string,
    public description?: string,
    public dueDate?: any,
    public abandonedAt?: any,
    public completedAt?: any,
    public reasonOfAbandonment?: string,
    public predefinedActionId?: string,
    public actionStatus?: ActionStatus,
    public actionVisibility?: ActionVisibility,
    public predefinedActionName?: any,
    public purchasedProduct?: IProductBasic,
    public actionValidationRequest?: any,

    public progress?: number
  ) {}
}
export enum ActionStatus {
  TO_DO = '#1e5386',
  IN_PROGRESS = '#e48f0f',
  /*
    CLOSED = '#52984a',
*/

  CLOSED = '#155d1b',
  ABANDONED = '#bd2130',
}
export enum ActionVisibility {
  ONLY_ME = 'ONLY_ME',
  ALSO_MY_MANAGER = 'ALSO_MY_MANAGER',
}

export enum ActionValidationRequestStatus {
  ACCEPTED = '#67b373',
  REFUSED = '#D4604F',
  PENDING = 'rgb(220, 154, 25)',
}
