import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpResponse } from '@angular/common/http';
import { GenericService } from '../GenericService';
import { environment } from '../../../environments/environment';

@Injectable({ providedIn: 'root' })
export class AccountService extends GenericService {
  constructor() {
    super();
  }
  create(customer): Observable<any> {
    return this.api.post('api/register', customer);
  }

  update(customer): Observable<HttpResponse<any>> {
    return this.api.put('api/account/', customer, { observe: 'response' });
  }

  find(id: string): Observable<any> {
    return this.api.get('api/customers/' + id);
  }

  findAll(): Observable<any[]> {
    return this.api.get('api/customers/');
  }

  delete(email: string): Observable<any> {
    return this.api.delete('api/users/' + email);
  }

  // findByEMail(id: string): Observable<any> {
  //   return this.api.get('api/b-2-c-customers/byemail/' + id);
  // }
  //
  // findByEMailB2b(id: string): Observable<any> {
  //   return this.api.get('api/b-2-b-customers/byemail/' + id);
  // }
  //
  // createEmployee(employee: any): Observable<any> {
  //   return this.api.post('api/employees/register/', employee);
  // }
  //
  // updateEmployee(employee: any,oldEmail:String): Observable<HttpResponse<any>> {
  //   return this.api.put('api/employees/account/'+oldEmail, employee,{observe: 'response'});
  // }
  //
  // deleteEmployee(email: string): Observable<any> {
  //   return this.api.delete('api/users/employees/' + email);
  // }
  //
  //
  // findByEMailEmployee(id: string): Observable<any> {
  //   return this.api.get('api/employees/byemail/' + id);
  // }
  //
  // createSellerUser(sellerUser: any): Observable<any> {
  //   return this.api.post('api/seller-users/register/', sellerUser);
  // }
  //
  // updateSellerUser(sellerUser: any): Observable<HttpResponse<any>> {
  //   return this.api.put('api/seller-users/account/', sellerUser,{observe: 'response'});
  // }
  //
  // deleteSellerUser(email: string): Observable<any> {
  //   return this.api.delete('api/users/seller-users/' + email);
  // }
  //
  //
  // findByEMailSellerUser(id: string): Observable<any> {
  //   return this.api.get('api/seller-users/byemail/' + id);
  // }

  forgetPassword(lang: string, mail: string) {
    return this.api.post('api/account/reset-password/init/' + lang, mail);
  }

  changePassword(kyeAndPassword: any) {
    return this.api.post('api/account/reset-password/finish', kyeAndPassword);
  }
  // deleteB2CCustomer(email: string): Observable<any> {
  //   return this.api.delete('api/users/customers-b-2-c/' + email);
  // }
  // saveImageCompany(file: File, typeId: string): Observable<any> {
  //   const formdata: FormData = new FormData();
  //   formdata.append('file', file);
  //   return this.api.post( 'api/upload-media-for-company/' + typeId, formdata);
  // }

  saveImageUser(file: File, typeId: string): Observable<any> {
    const formdata: FormData = new FormData();
    formdata.append('file', file);
    return this.api.post('api/upload-media-for-user/' + typeId, formdata);
  }

  // createCorporateSeller(customerB2b: any): Observable<any> {
  //   return this.api.post('api/corporate-sellers/register/', customerB2b);
  // }
  //
  // updateCorporateSeller(customerB2b: any): Observable<HttpResponse<any>> {
  //   return this.api.put('api/corporate-sellers/account/', customerB2b,{ observe: 'response' });
  // }
  // deleteCorporateSeller(email: string): Observable<HttpResponse<any>> {
  //   return this.api.delete('api/corporate-sellers/' + email);
  // }
  //
  // findByEMailCorporateSeller(id: string): Observable<any> {
  //   return this.api.get('api/corporate-sellers/byemail/' + id);
  // }
  //
  // createIndividualSeller(customerB2b: any): Observable<any> {
  //   return this.api.post('api/individual-sellers/register/', customerB2b);
  // }
  //
  // updateIndividualSeller(customerB2b: any): Observable<HttpResponse<any>> {
  //   return this.api.put('api/individual-sellers/account/', customerB2b,{ observe: 'response' });
  // }
  // deleteIndividualSeller(email: string): Observable<HttpResponse<any>> {
  //   return this.api.delete('api/users/individual-sellers/' + email);
  // }
  //
  // findByEMailIndividualSeller(id: string): Observable<any> {
  //   return this.api.get('api/individual-sellers/byemail/' + id);
  // }
  //
  // createCorporateSellerEmployee(customerB2b: any): Observable<any> {
  //   return this.api.post('api/corporate-seller-employees/register/', customerB2b);
  // }
  //
  //
  //
  // deleteCorporateSellerEmployee(email: string): Observable<HttpResponse<any>> {
  //   return this.api.delete('api/users/corporate-seller-employees/' + email);
  // }
  //
  // findByEMailCorporateSellerEmployee(id: string): Observable<any> {
  //   return this.api.get('api/corporate-seller-employees/byemail/' + id);
  // }

  changeNewPassword(password: any) {
    return this.api.post('api/account/change-password', password);
  }

  downloadMedia(mediaId: string, format: string | null): any {
    return environment.apiUrl + 'api/export-media/' + mediaId + ' /' + format;
  }
}
