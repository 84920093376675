import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { GenericService } from './GenericService';
import { ILocale } from '../models/locale.model';
import { Store } from '@ngrx/store';
import { AppState } from '../store/app.reducer';
import { LOCALE_STATE } from '../shared/constants';

@Injectable({ providedIn: 'root' })
export class LocaleService extends GenericService {
  // private locales: ILocale[] = [];
  private localeId: string;
  constructor(private store: Store<AppState>) {
    super();
    this.store.select(LOCALE_STATE).subscribe(localeState => {
      // this.locales = localeState.locales;
      this.localeId = localeState.localeId;
    });
  }

  find(id: string): Observable<ILocale> {
    return this.api.get('api/locales/' + id);
  }

  // _findById(id: string) {
  //   if (this.locales?.length) {
  //     const index = this.locales.find(item => item.id === id)
  //     return of(index);
  //   }
  // }

  findAll(force?: boolean): Observable<ILocale[]> {
    return this.api.get('api/locales/by-locale/' + this.localeId);
  }
}
