<div *ngIf="getIdComponentByCode('BANNER')">
  <app-banner [componentId]="getIdComponentByCode('BANNER')" [videoComponentCode]="getIdComponentByCode('BANNER_VIDEO')"></app-banner>
</div>

<div class="instructor-area pt-100 pb-70" style="padding-bottom: 25px">
  <div class="container">
    <div class="row">
      <div class="col-12 col-md-6" *ngIf="getIdComponentByCode('TEXT_PASSPORT')">
        <app-html [componentId]="getIdComponentByCode('TEXT_PASSPORT')"></app-html>
      </div>
      <div class="col-12 col-md-6" style="text-align: center" *ngIf="getIdComponentByCode('IMAGE_PASSPORT')">
        <app-hro-image-set width="100%" height="auto" [componentId]="getIdComponentByCode('IMAGE_PASSPORT')"></app-hro-image-set>
      </div>
    </div>
  </div>
</div>
<div *ngIf="getIdComponentByCode('JOURNEY_TO_EXCELLENCE')" style="text-align: center">
  <app-list [componentId]="getIdComponentByCode('JOURNEY_TO_EXCELLENCE')"></app-list>
</div>

<div *ngIf="getIdComponentByCode('EXCELLENCE_PATHWAY')" style="text-align: center; margin-top: 2rem">
  <app-html [componentId]="getIdComponentByCode('EXCELLENCE_PATHWAY')"></app-html>
</div>
<div *ngIf="getIdComponentByCode('ADD_BANNER')" style="text-align: center; margin-top: 5rem">
  <app-html [componentId]="getIdComponentByCode('ADD_BANNER')"></app-html>
</div>
<div *ngIf="getIdComponentByCode('TOP_PRODUCTS')" style="text-align: center">
  <hro-product-set [componentId]="getIdComponentByCode('TOP_PRODUCTS')"></hro-product-set>
</div>

<div *ngIf="getIdComponentByCode('STATISTICS')">
  <app-html [componentId]="getIdComponentByCode('STATISTICS')"></app-html>
</div>

<div *ngIf="getIdComponentByCode('SERVICE_PROVIDERS')" style="text-align: center">
  <app-hro-partner-set [componentId]="getIdComponentByCode('SERVICE_PROVIDERS')"></app-hro-partner-set>
</div>
<div *ngIf="getIdComponentByCode('COACHES')" style="text-align: center">
  <app-list [componentId]="getIdComponentByCode('COACHES')"></app-list>
</div>

<div *ngIf="getIdComponentByCode('B2B_CUSTOMERS')" style="text-align: center">
  <app-list [componentId]="getIdComponentByCode('B2B_CUSTOMERS')"></app-list>
</div>

<div *ngIf="getIdComponentByCode('TESTIMONIALS')" style="text-align: center">
  <app-list [componentId]="getIdComponentByCode('TESTIMONIALS')"></app-list>
</div>
