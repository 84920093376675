import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { CmsService } from '../../cms.service';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { DynamicPathRoutingService } from '../../../services/dynamic-path-routing.service';
import { Store } from '@ngrx/store';
import { AppState } from '../../../store/app.reducer';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { COACH_DETAILS_COMPONENT_NAME } from '../../../ComponentsIndex';
import { DynamicPathStorageService } from '../../../services/dynamic-path-storage.service';
import { LOCALE_ID, LOCALE_STATE } from '../../../shared/constants';

@Component({
  selector: 'app-hro-coaches',
  templateUrl: './hro-coaches.component.html',
  styleUrls: ['./hro-coaches.component.scss'],
})
export class HroCoachesComponent implements OnInit, OnDestroy {
  @Input()
  data: any;
  private destroyed$ = new Subject();
  public currentLocale: string;
  coachDetailsPageUrlPattern: string;

  constructor(
    private cmsService: CmsService,
    private dynamicPathRoutingService: DynamicPathRoutingService,
    private store: Store<AppState>,
    private dynamicPathStorageService: DynamicPathStorageService
  ) {}
  coachSlides: OwlOptions = {
    loop: true,
    nav: false,
    dots: false,
    autoplayHoverPause: true,
    autoplay: true,
    margin: 30,
    navText: ["<i class='bx bx-left-arrow-alt'></i>", "<i class='bx bx-right-arrow-alt'></i>"],
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 2,
      },
      768: {
        items: 3,
      },
      1200: {
        items: 6,
      },
    },
  };
  ngOnInit(): void {
    this.store
      .select(LOCALE_STATE, LOCALE_ID)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(localeId => (this.currentLocale = localeId));
    this.dynamicPathStorageService.urlPatterns$.pipe(takeUntil(this.destroyed$)).subscribe(() => {
      this.coachDetailsPageUrlPattern =
        this.dynamicPathStorageService.getUrlPatternByComponentNameFromCmsPageList(COACH_DETAILS_COMPONENT_NAME);
    });
  }

  getImage(image: any, format: string | null): any {
    if (!image) {
      return;
    }
    return this.cmsService.downloadMedia(image, format);
  }

  ngOnDestroy() {
    this.destroyed$.next();
  }
}
