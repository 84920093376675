<!-- wrapped content here to avoid viewencapsulation.none effects -->
<div class="coach-list-page">
  <div class="page-title-area item-bg1 item-coach" style="background-position-y: 25%">
    <div class="container">
      <div class="page-title-content">
        <ul>
          <li>
            <a href="index.html">{{ 'HOME' | translate }}</a>
          </li>
          <li>{{ 'COACHS' | translate }}</li>
        </ul>
        <h2 style="color: white" class="text-break">
          {{ 'OUR_NETWORK_OF_PROFESSIONAL_COACHES' | translate }}
        </h2>
      </div>
    </div>
  </div>
  <app-banner
    *ngIf="components"
    [componentId]="getIdComponentByCode('BANNER_COACHES')"
    [videoComponentCode]="getIdComponentByCode('BANNER_COACHES_VIDEO')"></app-banner>
  <div class="instructor-area">
    <div style="min-width: 100%" class="container col-12 pt-50">
      <div class="row">
        <div *ngIf="!isOnSmallScreen || isListSectionDisplayed" class="col-lg-9">
          <div class="row" *ngIf="isOnSmallScreen">
            <div class="col-12 text-right">
              <button class="btn btn-secondary mr-2" (click)="displayFiltersSection()">
                <i class="bx bx-filter-alt"></i>
              </button>
            </div>
          </div>
          <div *ngIf="coaches && coaches.length > 0" class="instructor-area">
            <div class="my-3">
              <div class="row">
                <div *ngFor="let coach of coaches; trackBy: identifyer" class="col-lg-3 col-md-6 col-sm-6">
                  <div class="single-instructor-member mb-30">
                    <div class="mw-90 h-100 pb-4 pt-3">
                      <a
                        [routerLink]="
                          coachDetailsPageUrlPattern ? (coachDetailsPageUrlPattern | seoNameUrl : coach.seoData : currentLocale) : null
                        "
                        class="w-100">
                        <img
                          class="m-auto rounded-circle border border-white img-shadow"
                          [src]="coach.photo.url ? coach.photo.publicUrl : getImageSet(coach.photo)"
                          [alt]="coach.photo?.alt ?? coach.firstName + ' ' + coach.lastName"
                          *ngIf="coach?.photo?.id; else noImage"
                          style="height: 120px; width: 120px" />
                      </a>
                      <h5 class="pt-2">
                        <a
                          [routerLink]="
                            coachDetailsPageUrlPattern ? (coachDetailsPageUrlPattern | seoNameUrl : coach.seoData : currentLocale) : null
                          "
                          >{{ coach?.firstName }} {{ coach?.lastName }}</a
                        >
                      </h5>
                    </div>
                  </div>
                </div>
                <!--                                <div class="row" *ngIf="hasMoreAvailableCoachesToShow && !isFetchingCoaches" #showMore>-->
                <!--                                    <div class="col-sm-9 col-md-6 col-lg-4 mx-auto">-->
                <!--                                        <p class="alert alert-light bg-light-2 text-center radius-1 h6 cursor-pointer" (click)="showMoreCoaches()">{{"SHOW_MORE" | translate}}</p>-->
                <!--                                    </div>-->
                <!--                                </div>-->
                <div #showMore></div>
                <div *ngIf="isFetchingCoaches" class="row">
                  <div class="col-sm-9 col-md-6 col-lg-4 mx-auto">
                    <p class="alert alert-light bg-light-2 text-center loading-alert h6">
                      {{ 'LOADING_DATA' | translate }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="!isFetchingCoaches && (!coaches || !coaches.length)" class="no-data mb-5">
            {{ 'NO_COACH_FOUND' | translate }}
          </div>
          <div *ngIf="isFetchingCoaches && (!coaches || !coaches.length)" class="row my-5">
            <div class="col-sm-9 col-md-6 col-lg-4 mx-auto">
              <p class="alert alert-light bg-light-2 text-center loading-alert h6">
                {{ 'LOADING_DATA' | translate }}
              </p>
            </div>
          </div>
        </div>
        <div *ngIf="!isOnSmallScreen || isFiltersSectionDisplayed" class="col-lg-3">
          <div class="row" *ngIf="isOnSmallScreen">
            <div class="col-12 text-right">
              <button class="btn btn-secondary mr-2" (click)="displayList()">
                <i class="bx bx-window-close"></i>
              </button>
            </div>
          </div>
          <div class="filterBox">
            <h6 style="margin-bottom: 2px" class="example-h2">
              {{ 'CORPORATE_COACHING' | translate }}
            </h6>
            <section
              *ngFor="let domain of entrepriseCoaching; let i = index"
              class="example-section"
              [ngStyle]="!displayALlDomains && i > 4 && { display: 'none' }">
              <div class="form-check">
                <input
                  (change)="selectDomain(domain)"
                  mdbCheckbox
                  class="form-check-input"
                  type="checkbox"
                  value=""
                  [checked]="coachFilterObject.domainIds.includes(domain.id)"
                  [id]="'flexCheckDefault' + domain.id" />
                <label style="cursor: pointer" class="form-check-label" [for]="'flexCheckDefault' + domain.id">
                  {{ domain.name }}
                </label>
              </div>
            </section>
            <h6 style="margin-bottom: 2px" class="example-h2">
              {{ 'LIFE_COACHING' | translate }}
            </h6>
            <section
              *ngFor="let domain of lifeCoachingDomain; let i = index"
              class="example-section"
              [ngStyle]="!displayALlDomains && i > 4 && { display: 'none' }">
              <div class="form-check">
                <input
                  (change)="selectDomain(domain)"
                  mdbCheckbox
                  class="form-check-input"
                  type="checkbox"
                  value=""
                  [checked]="coachFilterObject.domainIds.includes(domain.id)"
                  [id]="'flexCheckDefault' + domain.id" />
                <label style="cursor: pointer" class="form-check-label" [for]="'flexCheckDefault' + domain.id">
                  {{ domain.name }}
                </label>
              </div>
            </section>
            <h6 style="margin-bottom: 2px" class="example-h2">
              {{ 'SOCIAL_AND_SOLIDARITY_COACHING' | translate }}
            </h6>
            <section
              *ngFor="let domain of socialSolidarityCoachingDomain; let i = index"
              class="example-section"
              [ngStyle]="!displayALlDomains && i > 4 && { display: 'none' }">
              <div class="form-check">
                <input
                  (change)="selectDomain(domain)"
                  mdbCheckbox
                  class="form-check-input"
                  type="checkbox"
                  value=""
                  [checked]="coachFilterObject.domainIds.includes(domain.id)"
                  [id]="'flexCheckDefault' + domain.id" />
                <label style="cursor: pointer" class="form-check-label" [for]="'flexCheckDefault' + domain.id">
                  {{ domain.name }}
                </label>
              </div>
            </section>
            <div class="displayAll" *ngIf="coachingDomains && coachingDomains.length > 4">
              <span (click)="displayDomains(true)" *ngIf="!displayALlDomains">{{ 'DISPLAY_ALL' | translate }}</span>
              <span (click)="displayDomains(false)" *ngIf="displayALlDomains">{{ 'DISPLAY_LESS' | translate }}</span>
            </div>
          </div>
          <div class="filterBox">
            <h6 style="margin-bottom: 2px" class="example-h2">
              {{ 'IN_ALPHABETICAL_ORDER' | translate }}
            </h6>
            <ul class="list-inline">
              <li
                [ngStyle]="
                  selected(coachFilterObject.alphabets, case) && {
                    color: 'blue',
                    'font-weight': 'bold'
                  }
                "
                class="list-inline-item filterItem"
                *ngFor="let case of alphabetList"
                style="text-decoration: underline"
                (click)="selectAlphabet(case)">
                {{ case }}
              </li>
            </ul>
            <div></div>
          </div>

          <div class="filterBox">
            <h6 style="margin-bottom: 2px" class="example-h2">
              {{ 'BY_ACCREDITATION_LEVEL' | translate }}
            </h6>
            <button
              [class.notSelectedItem]="!selected(coachFilterObject.iCFAccreditationLevelIds, accreditationLevel.id)"
              (click)="selectAccreditationLevel(accreditationLevel)"
              class="position-relative filter-button"
              [ngStyle]="!displayALlAccreditationLevels && i > 4 && { display: 'none' }"
              style="margin-right: 15px; margin-top: 5px; font-family: var(--fontFamily1)"
              *ngFor="let accreditationLevel of accreditationLevels; let i = index"
              [ngClass]="getBtnClass(i)">
              {{ accreditationLevel.name }}
            </button>
            <div class="displayAll" *ngIf="accreditationLevels && accreditationLevels.length > 4">
              <span (click)="displayALlAccreditationLevels = true" *ngIf="!displayALlAccreditationLevels">{{
                'DISPLAY_ALL' | translate
              }}</span>
              <span (click)="displayALlAccreditationLevels = false" *ngIf="displayALlAccreditationLevels">{{
                'DISPLAY_LESS' | translate
              }}</span>
            </div>
          </div>

          <div class="filterBox">
            <h6 style="margin-bottom: 2px" class="example-h2">
              {{ 'BY_COUNTRY' | translate }}
            </h6>
            <div class="form-group">
              <mat-form-field>
                <mat-select
                  panelClass="mat-select-panel-class"
                  [formControl]="countryMultiCtrl"
                  [placeholder]="'COUNTRY' | translate"
                  [multiple]="true"
                  #multiSelect
                  (selectionChange)="updateCoachList()">
                  <mat-option>
                    <ngx-mat-select-search
                      [placeholderLabel]="'COUNTRY' | translate"
                      [noEntriesFoundLabel]="'NO_COUNTRY_FOUND' | translate"
                      [formControl]="countryMultiFilterCtrl"></ngx-mat-select-search>
                  </mat-option>
                  <mat-option *ngFor="let country of filteredCountriesMulti | async" [value]="country">
                    {{ country.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>

          <div class="filterBox">
            <h6 style="margin-bottom: 2px" class="example-h2">
              {{ 'BY_LANGUAGE' | translate }}
            </h6>
            <button
              [class.notSelectedItem]="!selected(coachFilterObject.languageIds, language.id)"
              class="position-relative filter-button"
              (click)="selectLanguage(language)"
              [ngStyle]="!displayALlLanguages && i > 4 && { display: 'none' }"
              style="margin-right: 15px; margin-top: 5px; font-family: var(--fontFamily1)"
              *ngFor="let language of languages; let i = index"
              [ngClass]="getBtnClass(i)">
              {{ language.name }}
            </button>

            <div class="displayAll" *ngIf="languages && languages.length > 4">
              <span (click)="displayALlLanguages = true" *ngIf="!displayALlLanguages">{{ 'DISPLAY_ALL' | translate }}</span>
              <span (click)="displayALlLanguages = false" *ngIf="displayALlLanguages">{{ 'DISPLAY_LESS' | translate }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <ng-template #noImage>
    <img
      class="m-auto rounded-circle border border-white img-shadow"
      style="height: 120px; width: auto"
      src="assets/img/instructor1.webp"
      style="height: 120px; width: 120px"
      alt="image" />
  </ng-template>
</div>
