import { Injectable } from '@angular/core';
import { GenericService } from './GenericService';
import { Observable } from 'rxjs';
import { ResponseWrapper } from '../models/response-wrapper.model';
import { createRequestOption } from './utils/request-util';
import { map } from 'rxjs/operators';
import { convertResponse } from './utils/response-converter';

@Injectable({
  providedIn: 'root',
})
export class ExpectationService extends GenericService {
  constructor() {
    super();
  }

  query(req?: any): Observable<ResponseWrapper> {
    const options = createRequestOption(req);
    return this.api.get('api/expectations/', options).pipe(map(res => convertResponse(res)));
  }
}
