import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AccountService } from '../../../services/auth/account.service';
import { IMedia } from '../../../models/media.model';
import { NgxSpinnerService } from 'ngx-spinner';
@Component({
  selector: 'app-hro-edit-profil-image',
  templateUrl: './hro-edit-profil-image.component.html',
  styleUrls: ['./hro-edit-profil-image.component.scss'],
})
export class HroEditProfilImageComponent implements OnInit {
  @Input()
  customer;
  @Output()
  media = new EventEmitter<IMedia>();
  @Output()
  onMediaChange = new EventEmitter<IMedia>();
  constructor(private accountService: AccountService, private spinner: NgxSpinnerService) {}

  ngOnInit(): void {
    // this.media = this.customer.media;
  }

  onImageChange(event) {
    this.spinner.show();
    const reader = new FileReader();
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      reader.readAsDataURL(file);
      reader.onload = () => {
        setTimeout(() => {}, 500);
        this.accountService.saveImageUser(file, this.customer.id).subscribe(res => {
          this.media.emit(res);
          this.customer.image = res;
          this.spinner.hide();
          this.onMediaChange.emit(res);
        });
      };
    }
  }
  getImage(image, format) {
    if (image) {
      return this.accountService.downloadMedia(image.id, format);
    }
  }
}
