// TODO get currentLocaleId only from this service everywhere in the app
import { Store } from '@ngrx/store';
import { AppState } from '../store/app.reducer';
import { LocalStorageService } from 'ngx-webstorage';
import { Inject, Injectable, OnDestroy, Optional, PLATFORM_ID } from '@angular/core';
import { REQUEST } from '@nguniversal/express-engine/tokens';
import { TransferState } from '@angular/platform-browser';
import { isPlatformBrowser } from '@angular/common';
import { ENGLISH_UK, ENGLISH_UK_LANG_ID, FRENCH_FRANCE, FRENCH_FRANCE_LANG_ID, LOCALE_ID, localeIdStateKey } from '../shared/constants';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as LocaleActions from '../store/actions/locale.actions';

@Injectable({
  providedIn: 'root',
})
export class CurrentLocaleService implements OnDestroy {
  private currentLocaleId: string = FRENCH_FRANCE;
  currentLocaleId$: BehaviorSubject<string> = new BehaviorSubject<string>(this.currentLocaleId);
  destroy$: Subject<void> = new Subject<void>();

  constructor(
    private localStorageService: LocalStorageService,
    @Inject(PLATFORM_ID) platformId: string,
    @Optional() @Inject(REQUEST) private request: any,
    transferState: TransferState,
    private store: Store<AppState>
  ) {
    if (isPlatformBrowser(platformId)) {
      if (transferState.hasKey(localeIdStateKey)) {
        this.localStorageService.store(LOCALE_ID, transferState.get(localeIdStateKey, null));
      }
      this.currentLocaleId = localStorageService.retrieve(LOCALE_ID) ?? FRENCH_FRANCE;
      this.currentLocaleId$.next(this.currentLocaleId);
      localStorageService
        .observe(LOCALE_ID)
        .pipe(takeUntil(this.destroy$))
        .subscribe(localeId => {
          this.currentLocaleId = localeId ?? FRENCH_FRANCE;
          this.currentLocaleId$.next(this.currentLocaleId);
        });
    } else {
      const urlParts = this.request.url.split('/');
      if (urlParts && urlParts.length > 1) {
        const localePart = urlParts[1];
        if (localePart === FRENCH_FRANCE_LANG_ID && !transferState.hasKey(localeIdStateKey)) {
          this.currentLocaleId = FRENCH_FRANCE;
          transferState.set(localeIdStateKey, this.currentLocaleId);
        }
        if (localePart === ENGLISH_UK_LANG_ID && !transferState.hasKey(localeIdStateKey)) {
          this.currentLocaleId = ENGLISH_UK;
          transferState.set(localeIdStateKey, this.currentLocaleId);
        }
      } else {
        this.currentLocaleId = FRENCH_FRANCE;
      }
      this.store.dispatch(LocaleActions.setLocaleId({ localeId: this.currentLocaleId }));
      // emit locale id in the app
      this.currentLocaleId$.next(this.currentLocaleId);
    }
  }

  getCurrentLocale() {
    return this.currentLocaleId;
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
