import { Component, Input, OnInit } from '@angular/core';
import { CmsService } from '../../cms.service';

@Component({
  selector: 'app-hro-banner-coaches',
  templateUrl: './hro-banner-coaches.component.html',
  styleUrls: ['./hro-banner-coaches.component.scss'],
})
export class HroBannerCoachesComponent implements OnInit {
  @Input()
  data: any;
  @Input()
  videoComponentCode: string;

  dataSource: any;
  constructor(private cmsService: CmsService) {}

  ngOnInit(): void {}

  getImage(image: any, format: string | null): any {
    if (!image) {
      return;
    }
    return this.cmsService.downloadMedia(image.id, format);
  }
}
