import { Injectable } from '@angular/core';
import { APIService } from './api.service';
import { ServiceLocator } from './ServiceLocator';

@Injectable()
export class GenericService {
  protected api: APIService;

  constructor() {
    this.api = ServiceLocator.injector.get(APIService);
  }
}
