<hro-categories></hro-categories>
<div class="my-dashboard-area">
  <div class="container">
    <div class="myDashboard-navigation mb-0" style="text-align: center">
      <ul>
        <li>
          <a class="px-2 px-sm-3" (click)="saveComponentName('OFFERS')" [ngClass]="{ active: componentName === 'OFFERS' }">{{
            'OFFERS' | translate
          }}</a>
        </li>
        <li>
          <a class="px-2 px-sm-3" (click)="saveComponentName('PACKS')" [ngClass]="{ active: componentName === 'PACKS' }">{{
            'PACKS' | translate
          }}</a>
        </li>
      </ul>
    </div>
    <div class="myDashboard-content">
      <div id="scroll-to-me"></div>
      <app-product-list
        *ngIf="componentName === 'OFFERS'"
        [onlyProducts]="true"
        [onlyPacks]="false"
        [onlyBundles]="false"></app-product-list>
      <app-product-list
        *ngIf="componentName === 'PACKS'"
        [onlyPacks]="true"
        [onlyProducts]="false"
        [onlyBundles]="false"></app-product-list>
    </div>
  </div>
</div>

<div *ngIf="getIdComponentByCode('MOST_POPULAR_PACKS')" style="text-align: center">
  <hro-popular-packs [componentId]="getIdComponentByCode('MOST_POPULAR_PACKS')"></hro-popular-packs>
</div>
