import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GenericService } from './GenericService';
import { ICountry } from '../models/country.model';
import { IState } from '../models/state.model';
import { ICity } from '../models/city.model';
import { IGender } from '../models/gender.model';
import { Store } from '@ngrx/store';
import { AppState } from '../store/app.reducer';
import { LOCALE_ID, LOCALE_STATE } from '../shared/constants';

@Injectable({ providedIn: 'root' })
export class UsersService extends GenericService {
  private currentLocaleId: string;

  constructor(private store: Store<AppState>) {
    super();
    this.store.select(LOCALE_STATE, LOCALE_ID).subscribe(localeId => (this.currentLocaleId = localeId));
  }

  checkIfUserExistsByLogin(email: string) {
    return this.api.get(`api/users/exists-by-login/${email}`);
  }

  findAllCities(): Observable<ICity[]> {
    return this.api.get('api/cities/');
  }

  findAllStates(): Observable<IState[]> {
    return this.api.get('api/all-states');
  }

  findAllGendersByLocale(): Observable<IGender[]> {
    return this.api.get('api/genders-by-locale/' + this.currentLocaleId);
  }

  findCountry(id: string): Observable<ICountry> {
    return this.api.get('api/countries/' + id);
  }

  findAllCountries(): Observable<ICountry[]> {
    return this.api.get('api/countries/');
  }

  getCountries(): Observable<any> {
    return this.api.get('api/countries-by-locale/' + this.currentLocaleId);
  }

  deleteAccountRequest(request): Observable<void> {
    return this.api.post('api/delete-account', request);
  }
}
