import { LocaleReducer, LocaleState } from './reducers/locale.reducer';
import { ActionReducerMap } from '@ngrx/store';
import { ContextReducer } from './reducers/context.reducer';
import { BeneficiaryReducer, BeneficiaryState } from './reducers/beneficiary.reducer';
import { CountryReducer, CountryState } from './reducers/country.reducer';

export interface AppState {
  localeState: LocaleState;
  context: string;
  beneficiaryState: BeneficiaryState;
  countryState: CountryState;
}

export const appReducer: ActionReducerMap<AppState> = {
  localeState: LocaleReducer,
  context: ContextReducer,
  beneficiaryState: BeneficiaryReducer,
  countryState: CountryReducer,
};
