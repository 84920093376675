<div class="page-title-area item-bg1">
  <div class="container">
    <div class="page-title-content">
      <ul>
        <li>
          <a href="../../../index.html">{{ 'HOME' | translate }}</a>
        </li>
        <li>{{ 'OUR_PARTNERS_ITEM' | translate }}</li>
        <li>{{ 'CORPORATE_SELLERS_DETAILS' | translate }}</li>
      </ul>
      <h2>{{ corporateSeller?.name }}</h2>
    </div>
  </div>
</div>
<div class="container">
  <div class="mb-4 px-2 py-5">
    <div class="row">
      <div class="col-md-4">
        <div class="text-md-center px-2">
          <img
            class="shadow-sm bg-white p-2 mt-2 mb-3"
            style="max-width: 100%; max-height: 150px"
            *ngIf="corporateSeller && corporateSeller.logo && corporateSeller.logo.id; else placeholder"
            [src]="getImage(corporateSeller?.logo?.id, null)"
            [alt]="corporateSeller?.logo?.alt ?? corporateSeller.name" />
          <ng-template #placeholder>
            <img
              class="shadow-sm bg-white p-2 mt-2 mb-3"
              style="max-width: 100%; max-height: 150px"
              src="assets/img/not-found.png"
              alt="image" />
          </ng-template>
        </div>
      </div>
      <div class="col-md-8">
        <div class="px-3">
          <div>
            <h3>
              <a [href]="corporateSeller?.companyWebsite" target="_blank">{{ corporateSeller?.name }}</a>
              <hr style="width: 200px; color: #bc5b68; height: 2px" />
            </h3>
            <a [href]="corporateSeller?.companyWebsite" target="_blank">
              <span *ngIf="corporateSeller?.companyWebsite">
                <i class="bx bx-world"></i>
                {{ corporateSeller?.companyWebsite }}</span
              ></a
            >
          </div>

          <p class="text-break mt-2">
            {{ corporateSeller?.companyDescription?.localizedValues[currentLocale] }}
          </p>
        </div>
      </div>
    </div>
  </div>
  <app-hro-user-banner *ngIf="corporateSellerId" [sellerId]="corporateSellerId"></app-hro-user-banner>
</div>
<div class="container" style="margin-bottom: 2rem">
  <div style="padding: 5px" class="row" *ngIf="products && products.length > 0">
    <h3 style="text-align: center; margin-bottom: 2%; margin-top: 2%">
      {{ 'PROPOSEDOFFERS' | translate }}
    </h3>
    <hro-product-card
      class="col-lg-4 col-md-6"
      *ngFor="let product of products"
      [product]="product"
      [detailsPageUrlPattern]="
        product.productType === PRODUCT_PRODUCT_TYPE ? productDetailsPageUrlPattern : packDetailsPageUrlPattern
      "></hro-product-card>
    <div class="col-lg-12 col-md-12 col-sm-12">
      <div class="pagination-area text-center">
        <mat-paginator
          (page)="handlePageEvent($event)"
          [length]="totalItems"
          [pageSize]="size"
          [showFirstLastButtons]="false"
          [pageIndex]="page">
        </mat-paginator>
      </div>
    </div>
  </div>
</div>
