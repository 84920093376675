import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class ViewportService {
  constructor(@Inject(PLATFORM_ID) private platformId) {}

  isInViewport(elm, value = 0, isAddition = false) {
    if (isPlatformBrowser(this.platformId)) {
      const elDistanceToTop = elm.getBoundingClientRect().top;
      return elDistanceToTop + (isAddition ? value : -value) < window.innerHeight;
    }
  }
}
