<app-size-detector *appIfBrowserPlatform></app-size-detector>
<app-hro-navbar *ngIf="isPlateform"></app-hro-navbar>
<router-outlet></router-outlet>
<app-footer
  *ngIf="
    !(location === '/login' || location === '/forgot-password' || location === '/register' || location === '/coming-soon')
  "></app-footer>
<div class="cookies-alert bg-dark py-2" *ngIf="!hasAcceptedCookies && isPlateform && !(!hasAcceptedTermsOfUse && isLoggedIn)">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-md-8 col-lg-10">
        <p class="d-block d-md-none text-white text-center">
          {{ 'COOKIES_ALERT_TEXT' | translate }}
        </p>
        <p class="d-none d-md-block text-white text-left">
          {{ 'COOKIES_ALERT_TEXT' | translate }}
        </p>
      </div>
      <div class="col-md-4 col-lg-2 text-center text-md-left">
        <button class="btn accepted mt-2 mt-sm-3 mb-2 mx-2" (click)="onAcceptCookies()" style="min-width: 150px">
          {{ 'COOKIES_ALERT_CONFIRM' | translate }}
        </button>
        <button class="btn btn-danger mt-2 mt-sm-3 mb-2 mx-2" (click)="onDefuseCookies()" style="min-width: 150px">
          {{ 'COOKIES_ALERT_REFUSE' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
<ng-template #acceptTermsModal>
  <div class="modal-body w-100" style="background-color: transparent">
    <div
      class="py-5 mt-5 rounded"
      style="background: linear-gradient(to right, var(--optionalColor), var(--mainColor))"
      *ngIf="!hasAcceptedTermsOfUse && isLoggedIn">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-12 mt-2 mb-5 text-center">
            <h5 class="text-white">
              {{ 'WELCOME_TO_PASSEXCELLENCE' | translate }}
            </h5>
            <h5 class="text-white">
              {{ 'TERMS_AND_CONDITIONS_AGREEMENT' | translate }}
            </h5>

            <a [routerLink]="termsAndConditionsPageUrl$ | async" target="_blank" style="color: aliceblue; text-decoration: underline"
              >{{ 'READ_TERMS_AND_CONDITIONS' | translate }}.
            </a>
          </div>
          <div class="col-12 text-center">
            <button mat-raised-button color="warn" class="mx-2" (click)="onRefuseTerms()">
              {{ 'I_DECLINE' | translate }}
            </button>

            <button mat-raised-button color="primary" style="background-color: #192a56" class="mx-2" (click)="onAcceptTerms()">
              {{ 'I_AGREE' | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
<!-- end accept terms modal -->
