import { Routes } from '@angular/router';

export const CoachRoutes: Routes = [
  // {
  //     path: 'coach',
  //     redirectTo: 'coach/book',
  //     pathMatch: 'full',
  // },
  // {
  //     path: 'coach/book/:id',
  //     component: CoachBookComponent,
  //     data: {
  //         authorities: [BENEFICIARY_GROUP],
  //     },
  //     canActivate: [UserRouteAccessService]
  // },
];
