<div
  [ngStyle]="{
    background: 'linear-gradient(to right, var(--optionalColor) ,  var(--mainColor))'
  }"
  class="hero-banner">
  <div class="d-table">
    <div class="d-table-cell">
      <div class="container-fluid">
        <div class="row align-items-center">
          <div class="col-lg-6">
            <div class="hero-banner-content">
              <h1 class="text-center d-block d-lg-none top-title">
                {{ data?.title }}
              </h1>
              <h1 class="d-none d-lg-block top-title">{{ data?.title }}</h1>
              <p *ngIf="data?.content && data?.content.length">
                {{ data?.content }}
              </p>
              <div class="btn-box">
                <a [routerLink]="particularHomePageUrl$ | async" class="default-primary-btn mb-2">{{ 'PARTICULAR' | translate }}</a>
                <a [routerLink]="entrepriseHomePageUrl$ | async" class="default-secondary-btn mb-2">{{ 'ENTREPRISE' | translate }}</a>
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="row">
              <div class="offset-lg-1 col-md-12 col-lg-8">
                <div class="hero-banner-image text-center mx-auto">
                  <app-hro-video-set [componentId]="videoComponentCode"></app-hro-video-set>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
