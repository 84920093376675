import { Component, Input, OnInit } from '@angular/core';
import { CmsService } from '../../cms.service';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { AppStateService } from '../../../services/utils/state.service';

@Component({
  selector: 'app-hro-testimonial',
  templateUrl: './hro-testimonial.component.html',
  styleUrls: ['./hro-testimonial.component.scss'],
})
export class HroTestimonialComponent implements OnInit {
  @Input()
  data: any;
  currentLocale: string;

  constructor(private cmsService: CmsService, private appState: AppStateService) {
    this.currentLocale = this.appState.getCurrentLocaleId();
  }

  testimonialsSlides: OwlOptions = {
    loop: true,
    nav: false,
    dots: true,
    autoplayHoverPause: true,
    autoplay: true,
    center: true,
    navText: ["<i class='bx bx-left-arrow-alt'></i>", "<i class='bx bx-right-arrow-alt'></i>"],
    responsive: {
      0: {
        items: 1,
      },
      576: {
        items: 2,
      },
      768: {
        items: 2,
      },
      1200: {
        items: 3,
      },
    },
  };

  ngOnInit(): void {}

  getImage(image: any, format: string | null): any {
    if (!image) {
      return;
    }
    return this.cmsService.downloadMedia(image, format);
  }
}
