import { Media } from './media.model';

export interface ICustomerReview {
  id?: string;
  title?: string;
  content?: string;
  reasonBlocked?: string;
  dateBlocked?: Date;
  dateApproved?: Date;
  customerReviewStatusId?: string;
  customerId?: string;
  customerReviewRatingLevelId?: string;
  attachments?: Media[];
  createdBy?: string;
  createdDate?: Date;
}

export class CustomerReview implements ICustomerReview {
  constructor(
    public id?: string,
    public title?: string,
    public content?: string,
    public reasonBlocked?: string,
    public dateBlocked?: Date,
    public dateApproved?: Date,
    public customerReviewStatusId?: string,
    public customerId?: string,
    public customerReviewRatingLevelId?: string,
    public attachments?: Media[],
    public createdBy?: string,
    public createdDate?: Date
  ) {}
}
