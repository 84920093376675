import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { ReplaySubject, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { Address, IAddress } from 'src/app/models/address.model';
import { ICart } from 'src/app/models/cart.model';
import { Country, ICountry } from 'src/app/models/country.model';
import { IPayment, Payment } from 'src/app/models/payment.model';
import { IPaymentMode } from 'src/app/models/paymentMode.model';
import { CartService } from 'src/app/services/cart.service';
import { OrderService } from 'src/app/services/order.service';
import { PaymentModeService } from 'src/app/services/payment-mode.service';
import { PaymentService } from 'src/app/services/payment.service';
import {
  DRH_GROUP,
  LOCALE_ID,
  LOCALE_STATE,
  ORDER_ID_KEY_STORAGE,
  ORDER_KEY_STORAGE,
  PASSE_EXCELLENCE,
  PAYMENT_ID_KEY_STORAGE,
} from '../../../shared/constants';
import * as SecureLS from 'secure-ls';
import { Store } from '@ngrx/store';
import { AppState } from '../../../store/app.reducer';
import * as CountryActions from '../../../store/actions/country.actions';
import { IBeneficiary } from 'src/app/models/Beneficiary.model';
import { UserAccountService } from 'src/app/services/auth/user-account.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { DynamicPathRoutingService } from '../../../services/dynamic-path-routing.service';
import { DynamicPathStorageService } from '../../../services/dynamic-path-storage.service';
import { PACK_DETAILS_COMPONENT_NAME, PRODUCT_DETAILS_COMPONENT_NAME } from '../../../ComponentsIndex';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-checkout-page',
  templateUrl: './checkout-page.component.html',
  styleUrls: ['./checkout-page.component.scss'],
})
export class CheckoutPageComponent implements OnInit, OnDestroy {
  private destroyed$ = new Subject<void>();
  cart: ICart;
  countries: ICountry[] = [];
  selectedCountry: ICountry;
  address: IAddress = new Address();
  paymentModes: IPaymentMode[];
  filtredPaymentModes: IPaymentMode[];
  selectedPaymentMode: IPaymentMode;
  creatingOrder = false;
  payment: IPayment;
  private secureLocaStorage: SecureLS;
  beneficiary: IBeneficiary;
  productDetailsUrlPattern;
  packDetailsUrlPattern;
  currentLocaleId: string;
  readonly PRODUCT_PRODUCT_TYPE = 'PRODUCT';
  hasClickedPlaceOrder = false;
  readonly PASSEXCELLENCE_ID = PASSE_EXCELLENCE;
  public countryFilterCtrl: FormControl = new FormControl();

  filteredCountries: ReplaySubject<ICountry[]> = new ReplaySubject<ICountry[]>(1);

  constructor(
    private cartService: CartService,
    private translationService: TranslateService,
    private orderService: OrderService,
    private accountService: UserAccountService,
    private toastr: ToastrService,
    private paymenyModeService: PaymentModeService,
    private paymentService: PaymentService,
    private spinner: NgxSpinnerService,
    private store: Store<AppState>,
    private dynamicPathRoutingService: DynamicPathRoutingService,
    private dynamicPathStorageService: DynamicPathStorageService
  ) {
    this.getCart();
    this.store.select('beneficiaryState', 'beneficiary').subscribe(beneficiary => {
      this.beneficiary = JSON.parse(JSON.stringify(beneficiary));
      this.selectPaymentModeToDisplay();
    });
  }
  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  ngOnInit(): void {
    this.secureLocaStorage = new SecureLS({ encodingType: 'aes' });
    this.store
      .select(LOCALE_STATE, LOCALE_ID)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(localeID => {
        this.currentLocaleId = localeID;
        this._getAllPaymentMode();
      });

    this.dynamicPathStorageService.urlPatterns$.pipe(takeUntil(this.destroyed$)).subscribe(_ => {
      this.productDetailsUrlPattern =
        this.dynamicPathStorageService.getUrlPatternByComponentNameFromCmsPageList(PRODUCT_DETAILS_COMPONENT_NAME);
      this.packDetailsUrlPattern = this.dynamicPathStorageService.getUrlPatternByComponentNameFromCmsPageList(PACK_DETAILS_COMPONENT_NAME);
    });
    this.store.select('countryState', 'countries').subscribe(countries => {
      this.countries = countries;
      this.filterCountries();
    });
    this.store.dispatch(CountryActions.setCountriesEffect());
    this.countryFilterCtrl.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe(() => {
      this.filterCountries();
    });
  }

  protected filterCountries(): void {
    if (!this.countries) {
      return;
    }
    let search = this.countryFilterCtrl.value;
    if (!search) {
      this.filteredCountries.next(this.countries.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    this.filteredCountries.next(this.countries.filter(country => country.name.toLowerCase().indexOf(search) > -1));
  }

  getCart(): void {
    this.cartService.cart.subscribe(cart => {
      this.cart = cart;
    });
  }

  _getAllPaymentMode(): void {
    this.paymenyModeService
      .getAll()
      .pipe(take(1))
      .subscribe(modes => {
        this.paymentModes = modes;
        this.selectPaymentModeToDisplay();
      });
  }

  selectPaymentModeToDisplay(): void {
    if (this.beneficiary && !this.accountService.hasAnyAuthorityDirect([DRH_GROUP])) {
      this.filtredPaymentModes = this.paymentModes?.filter(mode => mode.id === 'CREDIT_CART');
    } else {
      this.filtredPaymentModes = this.paymentModes?.filter(mode => mode.id === 'PURCHASE_ORDER');
    }
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  async placeOrder() {
    this.hasClickedPlaceOrder = true;
    if (!this.selectedCountry || !this.address.street || !this.address.zip) {
      return;
    }
    if (this.cart.total != 0 && (!this.selectedPaymentMode || !this.selectedPaymentMode.id)) {
      const msg = this.translationService.instant('SELECT_PAYMENT_MODE');
      this.toastr.error(msg);
      return;
    }
    const orderIsValid = await this.verifyOrderForm();
    if (!orderIsValid) {
      return;
    }
    this.creatingOrder = true;
    this.address.country = new Country(this.selectedCountry.id);

    await this._createPayment();
    this.cart.payments = [this.payment];
    // eslint-disable-next-line no-console
    await console.info();
    await this.cartService.updateCart(true, this.cart);
    if (this.selectedPaymentMode?.id === 'CREDIT_CART') {
      const orderObject = {
        cartID: this.cart.id,
        address: this.address,
        payments: [this.payment],
      };
      this.secureLocaStorage.set(ORDER_KEY_STORAGE, { data: orderObject });
      this.secureLocaStorage.set(PAYMENT_ID_KEY_STORAGE, {
        data: this.payment.code,
      });
      this.authorizeToPaymentProvider(this.cart, this.payment);
    } else {
      await this.createOrder();
    }
  }

  verifyOrderForm(): boolean {
    if (!this.selectedCountry?.id) {
      const msg = this.translationService.instant('ORDER_DETAIL_NOT_COMPLETED');
      this.toastr.error(msg);
      return false;
    } else if (!this.selectedPaymentMode && this.cart.total != 0) {
      const msg = this.translationService.instant('SELECT_PAYMENT_MODE');
      this.toastr.error(msg);
    }
    return true;
  }

  private createOrder() {
    const orderObject = {
      cartID: this.cart.id,
      deliveryAddress: this.address,
      payments: [this.payment],
    };
    this.spinner.show();
    this.orderService.createOrder(orderObject).subscribe(
      () => {
        this.spinner.hide();
        const successMsg = this.translationService.instant('ORDER_CREATED');
        this.toastr.success(successMsg);
        this.cartService.clearCart();
        this.dynamicPathRoutingService.navigateToProductListPage();
      },
      error => {
        this.spinner.hide();
        const errorMsg = this.translationService.instant(error.error.errorType || 'ERROR_OCCURRED_WHEN_CREATING_ORDER');
        this.toastr.error(errorMsg);
      }
    );
  }

  private async _createPayment() {
    const payment = new Payment();
    payment.paymentModeId = this.selectedPaymentMode?.id;
    payment.code = this.cart.code;
    payment.amount = this.cart.total;

    if (payment.amount == 0) {
      payment.paymentStatusId = '1';
    } else if (this.selectedPaymentMode.id === 'CREDIT_CARD') {
      payment.paymentStatusId = '2';
    } else {
      payment.paymentStatusId = '4';
    }

    this.payment = await this.paymentService.create(payment).toPromise();
    return payment;
  }

  authorizeToPaymentProvider(cart: ICart, payment: IPayment): void {
    this.paymentService.authorizeToPaymentProvider(cart.id, payment.code).subscribe(
      resp => {
        if (resp.formUrl) {
          this.secureLocaStorage.set(ORDER_ID_KEY_STORAGE, {
            data: resp.orderId,
          });
          const currentPayment = this.cart.payments[this.cart.payments.length - 1];
          currentPayment.pspOrderId = resp.orderId;
          this.paymentService.update(currentPayment).subscribe(data => {
            this.cart.payments.push(data);
            this.cartService.updateCart(true, this.cart);
          });
          window.location.href = resp.formUrl;
        }
      },
      () => {
        this.spinner.hide();
        const errorMsg = this.translationService.instant('ERROR_OCCURED');
        this.toastr.error(errorMsg);
      }
    );
  }
}
