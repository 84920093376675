import { ICurrency } from '../models/currency.model';
import { GenericService } from './GenericService';
import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../store/app.reducer';
import { LOCALE_ID, LOCALE_STATE } from '../shared/constants';

@Injectable({ providedIn: 'root' })
export class CurrencyService extends GenericService {
  public currencies: ICurrency[] = [];
  private currentLocaleId: string;

  constructor(private store: Store<AppState>) {
    super();
    this.store.select(LOCALE_STATE, LOCALE_ID).subscribe(localeId => (this.currentLocaleId = localeId));
  }

  create(currency: ICurrency): Observable<ICurrency> {
    return this.api.post('api/currencies/', currency);
  }

  update(currency: ICurrency): Observable<ICurrency> {
    return this.api.put('api/currencies/', currency);
  }

  find(id: string): Observable<ICurrency> {
    return this.api.get('api/currencies/' + id + '/', this.currentLocaleId);
  }

  findAll(): Observable<ICurrency[]> {
    if (this.currencies.length) {
      return of(this.currencies);
    }
    return this.api.get('api/currencies-active-by/' + this.currentLocaleId);
  }

  delete(id: string): Observable<any> {
    return this.api.delete('api/currencies/' + id);
  }

  getDefaultCurrency(id: string): Observable<ICurrency> {
    return this.api.get('api/applications-default-currency/' + id);
  }
}
