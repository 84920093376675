import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BlogService } from '../blog.services';
import { BlogPostDTOModel, IBlogPostDTOModel } from '../../shared/models/BlogPostDTO.model';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { SeoService } from '../../services/seo.service';
import { Store } from '@ngrx/store';
import { AppState } from '../../store/app.reducer';
import { DynamicPathRoutingService } from '../../services/dynamic-path-routing.service';
import { CurrentLocaleService } from '../../services/current-locale.service';

@Component({
  selector: 'app-blog-details',
  templateUrl: './blog-details.component.html',
  styleUrls: ['./blog-details.component.scss'],
})
export class BlogDetailsComponent implements OnInit, OnDestroy {
  blogPostId: string;
  blogPostSeoName: string;
  private _isDead$ = new Subject();
  pageIndex = 1;
  size = 6;
  totalItems;
  blogPost: IBlogPostDTOModel = new BlogPostDTOModel();
  blogCommentDTOForListModels: any[] = [];
  currentLocaleId: string;

  constructor(
    private activatedRoute: ActivatedRoute,
    protected blogService: BlogService,
    private store: Store<AppState>,
    private seoService: SeoService,
    private dynamicPathRoutingService: DynamicPathRoutingService,
    private currentLocaleService: CurrentLocaleService
  ) {}

  ngOnInit(): void {
    this.currentLocaleService.currentLocaleId$.pipe(takeUntil(this._isDead$)).subscribe(localeId => {
      this.currentLocaleId = localeId;
      if (this.blogPost && this.blogPostSeoName) {
        this.blogPostSeoName = this.blogPost.seoData ? this.blogPost.seoData.seoName.localizedValues[this.currentLocaleId] : '';
        this.dynamicPathRoutingService.navigateToBlogDetailsPage(this.blogPostSeoName);
      }
    });
    this.activatedRoute.params.pipe(takeUntil(this._isDead$)).subscribe(params => {
      this.blogPostSeoName = params.seoName;

      if (this.blogPostSeoName && this.blogPost && this.blogPost.id) {
        this.getBlogPost();
      }
    });
    this.getBlogPost();
  }

  getBlogPost() {
    if (this.blogPostSeoName) {
      const blogPost$ = this.blogService.findBlogPostBySeoNameAndLocale(this.blogPostSeoName);
      blogPost$.pipe(takeUntil(this._isDead$)).subscribe(blogPost => {
        this.blogPost = blogPost;
        this.blogPostId = blogPost.id;
        this.setBlogPostSeoData();
        this.loadAllComment();
      });
    }
  }

  setBlogPostSeoData() {
    const imageUrl =
      this.blogPost.thumbnail && this.blogPost.thumbnail.id
        ? this.blogPost.medias[0].url
          ? this.blogPost.medias[0].publicUrl
          : this.getImage(this.blogPost.medias[0].id, null)
        : '';
    this.seoService.setSeoFromSeoDataAndMedia(this.blogPost.seoData, imageUrl);
  }

  getImage(image: any, format: string | null): any {
    if (!image) {
      return;
    }
    return this.blogService.downloadMedia(image, format);
  }

  loadAllComment() {
    this.blogService
      .findAllBlogPostCommentssWithPagination(this.blogPostId, {
        page: this.pageIndex - 1,
        size: this.size,
      })
      .pipe(takeUntil(this._isDead$))
      .subscribe(commentsList => {
        this.blogCommentDTOForListModels = commentsList.json;
        this.totalItems = commentsList.headers.get('x-total-count');
      });
  }

  ngOnDestroy() {
    this._isDead$.next();
    this.seoService.resetSeo();
  }
}
