import { Injectable } from '@angular/core';
import { GenericService } from './GenericService';
import { Observable } from 'rxjs';
import { HttpResponse } from '@angular/common/http';
import { ResponseWrapper } from '../models/response-wrapper.model';
import { createRequestOption } from './utils/request-util';
import { map } from 'rxjs/operators';
import { convertResponse } from './utils/response-converter';
import { IAppointment } from '../shared/models/appointment.model';

@Injectable({
  providedIn: 'root',
})
export class AppointmentService extends GenericService {
  constructor() {
    super();
  }
  update(appointment: IAppointment): Observable<HttpResponse<any>> {
    return this.api.put('api/appointments/', appointment, {
      observe: 'response',
    });
  }
  partialUpdate(appointment: IAppointment): Observable<HttpResponse<any>> {
    return this.api.put('api/appointments/partial-update/', appointment, {
      observe: 'response',
    });
  }
  create(appointment: any): Observable<any> {
    return this.api.post('api/appointments/', appointment);
  }

  delete(id: string): Observable<any> {
    return this.api.delete('api/appointments/' + id);
  }

  find(id: string): Observable<any> {
    return this.api.get('api/appointments/' + id);
  }
  query(id: string, req?: any): Observable<ResponseWrapper> {
    const options = createRequestOption(req);
    return this.api.get('api/appointments/' + id, options).pipe(map(res => convertResponse(res)));
  }

  findBySeller(sellerId: string, req?: any): Observable<ResponseWrapper> {
    const options = createRequestOption(req);
    return this.api.get('api/appointments/by-seller/' + sellerId, options).pipe(map(res => convertResponse(res)));
  }
  findByCustomer(customerId: string, req?: any): Observable<ResponseWrapper> {
    const options = createRequestOption(req);
    return this.api.get('api/appointments/by-customer/' + customerId, options).pipe(map(res => convertResponse(res)));
  }
  // findAppoitementDetailsBySeller(
  //   sellerId: string,
  //   req?: any
  // ): Observable<ResponseWrapper> {
  //   const options = createRequestOption(req);
  //   return this.api
  //     .get("api/appointments-details/by-seller/" + sellerId, options)
  //     .pipe(map((res) => convertResponse(res)));
  // }
  // findByCustomId(customerId: string, req?: any): Observable<ResponseWrapper> {
  //   const options = createRequestOption(req);
  //   return this.api
  //     .get("api/appointments/by-customer/" + customerId, options)
  //     .pipe(map((res) => convertResponse(res)));
  // }
  // findByCustomIdAndSellerId(
  //   customerId: string,
  //   sellerId: string,
  //   req?: any
  // ): Observable<ResponseWrapper> {
  //   const options = createRequestOption(req);
  //   return this.api
  //     .get(
  //       "api/appointments/by-customer-and-seller/" + customerId,
  //       +"/" + sellerId + options
  //     )
  //     .pipe(map((res) => convertResponse(res)));
  // }

  validateVoucher(voucherCode: string, customerId: string): Observable<any> {
    return this.api.get('api/validate-voucher/' + voucherCode + '/' + customerId);
  }
}
