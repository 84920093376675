import { Injectable } from '@angular/core';
import { GenericService } from '../services/GenericService';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { Store } from '@ngrx/store';
import { AppState } from '../store/app.reducer';
import { LOCALE_ID, LOCALE_STATE } from '../shared/constants';

@Injectable({
  providedIn: 'root',
})
export class CoachService extends GenericService {
  private currentLocaleId: string;

  constructor(private store: Store<AppState>) {
    super();
    this.store.select(LOCALE_STATE, LOCALE_ID).subscribe(localeId => (this.currentLocaleId = localeId));
  }
  downloadMedia(mediaId: string, format: string | null): any {
    return environment.apiUrl + 'api/export-media/' + mediaId + ' /' + format;
  }

  getAllCoachingDomains(): Observable<any> {
    return this.api.get('api/coaching-domains-by-locale/' + this.currentLocaleId);
  }

  getCoutries(): Observable<any> {
    return this.api.get('api/countries-by-locale/' + this.currentLocaleId);
  }

  filterCoaches(filterObject, page: number, size: number): Observable<any> {
    return this.api.post(`api/frontoffice/filter-coaches-pageable?page=${page}&size=${size}`, filterObject, {
      observe: 'response',
    });
  }

  getAccreditationLevels(): Observable<any> {
    return this.api.get('api/icf-accreditation-levels-by-locale/' + this.currentLocaleId);
  }

  getLanguages(): Observable<any> {
    return this.api.get('api/languages-by-locale/' + this.currentLocaleId);
  }

  // getIndividualCoachById(coachId): Observable<any> {
  //     return this.api.get('api/coach-details/' + coachId + '/' + this.currentLocaleId);
  // }

  getIndividualCoachBySeoName(coachSeoName: string): Observable<any> {
    return this.api.get('api/coach-by-seo-name/' + coachSeoName + '/' + this.currentLocaleId);
  }

  updateCoach(coach): Observable<any> {
    return this.api.put('api/coaches/', coach);
  }
}
