import { Injectable } from '@angular/core';
import { GenericService } from '../services/GenericService';
import { Observable, Subject } from 'rxjs';
import { ResponseWrapper } from '../models/response-wrapper.model';
import { createRequestOption } from '../services/utils/request-util';
import { map } from 'rxjs/operators';
import { convertResponse } from '../services/utils/response-converter';
import { IBeneficiary } from '../models/Beneficiary.model';
import { IAction } from '../models/Action.model';
import { IPredefinedDevelopmentAxis } from '../models/predefined-development-axis.model';
import { AppStateService } from '../services/utils/state.service';
import { IBeneficiaryDevelopmentAxis } from '../models/BeneficiaryDevelopmentAxisDTO.model';
import { Store } from '@ngrx/store';
import { AppState } from '../store/app.reducer';
import { LOCALE_ID, LOCALE_STATE } from '../shared/constants';
import { ActionValidationRequest } from '../models/actionValidationRequest.model';

@Injectable({
  providedIn: 'root',
})
export class BeneficiaryService extends GenericService {
  notifyChanges = new Subject<void>();
  private currentLocaleId: string;

  constructor(private appStateService: AppStateService, private store: Store<AppState>) {
    super();
    this.store.select(LOCALE_STATE, LOCALE_ID).subscribe(localeId => (this.currentLocaleId = localeId));
  }
  find(id: string): Observable<any> {
    return this.api.get('api/beneficiaries/' + id);
  }
  findWithB2bCustomerId(id: string, b2bCustomerId): Observable<any> {
    return this.api.get('api/beneficiaries/' + id + '/' + b2bCustomerId + '/' + this.currentLocaleId);
  }
  query(id: string, req?: any): Observable<ResponseWrapper> {
    const options = createRequestOption(req);
    return this.api.get('api/beneficiaries/by-customer/' + id, options).pipe(map(res => convertResponse(res)));
  }

  searchBenifiaciary(customerB2BId: string, name: string, req?: any): Observable<ResponseWrapper> {
    const options = createRequestOption(req);
    return this.api
      .get('api/beneficiaries/by-customer/' + customerB2BId + '/by-name/' + name, options)
      .pipe(map(res => convertResponse(res)));
  }

  create(beneficiary: IBeneficiary): Observable<any> {
    return this.api.post('api/beneficiaries/', beneficiary);
  }

  update(beneficiary: IBeneficiary): Observable<any> {
    return this.api.put('api/beneficiaries/', beneficiary);
  }

  addDevelopmentAxeToBeneficiary(beneficiaryId, developmentAxe): Observable<any> {
    return this.api.post('api/beneficiary-development-axes-by-beneficiary/' + beneficiaryId, developmentAxe);
  }

  addActionToAxe(axeId, action): Observable<any> {
    return this.api.post('api/action-by-development-axe/' + axeId, action);
  }

  addActionToAxeForEmployee(employeeId: string, axeId, action): Observable<any> {
    return this.api.post('api/create-action-for-employee/' + employeeId + '/' + axeId, action);
  }

  createAction(action: IAction, devAxis: string): Observable<any> {
    return this.api.post('api/actions/' + devAxis, action);
  }

  updateAction(action: IAction): Observable<any> {
    return this.api.put('api/actions/', action);
  }

  updateDevelopementAxis(beneficiaryDevelopmentAxis: IBeneficiaryDevelopmentAxis): Observable<any> {
    return this.api.put('api/beneficiary-development-axes/', beneficiaryDevelopmentAxis);
  }

  deleteAction(action: IAction): Observable<any> {
    return this.api.delete('api/actions/' + action.id);
  }

  deleteDevAxis(axisId: string): Observable<any> {
    return this.api.delete('api/beneficiary-development-axes/' + axisId);
  }

  validateActionRequest(actionId: string): Observable<IAction> {
    return this.api.put('api/validate-action-purchase/' + actionId);
  }

  purshaseProduct(actionId: string): Observable<IAction> {
    return this.api.put('api/purchase-product/' + actionId);
  }

  refuseActionRequest(actionId: string, note: string): Observable<IAction> {
    return this.api.put('api/refuse-action-purchase/' + actionId, note);
  }

  bindProductToAction(userId: string, b2bCustomerId: string, visibility, action: IAction): Observable<any> {
    const options = createRequestOption({ b2bCustomerId });
    return this.api.put('api/bind-product-to-actions/' + userId + '/' + visibility, action, options);
  }

  getBeneficiaryByUserLogin(login: string, currencyId: string): Observable<ResponseWrapper> {
    const options = createRequestOption({ localeId: this.currentLocaleId });
    return this.api.get('api/beneficiaries/by-login/' + login + '/' + currencyId, options);
  }

  getActionDetails(id: string, login: string, currencyId: string): Observable<ResponseWrapper> {
    const options = createRequestOption({ localeId: this.currentLocaleId });
    return this.api.get('api/get-action-details/' + id + '/' + login + '/' + currencyId, options);
  }

  findAllPredefinedDevelopmentAxisWithTranslation(): Observable<IPredefinedDevelopmentAxis[]> {
    return this.api.get('api/predefined-development-axes-list-with-translation/' + this.currentLocaleId);
  }
  saveMediaInBeneficiary(file: File, benefiaciaryId: string): Observable<any> {
    const formdata: FormData = new FormData();
    formdata.append('file', file);
    return this.api.post('api/upload-media-for-beneficiary/' + benefiaciaryId, formdata);
  }

  saveNovaProfilesInBeneficiary(file: File, benefiaciaryId: string): Observable<any> {
    const formdata: FormData = new FormData();
    formdata.append('file', file);
    return this.api.post('api/upload-novaProfiles-for-beneficiary/' + benefiaciaryId, formdata);
  }

  getOrderByBeneficairyId(id: string, currencyId, req?: any): Observable<any> {
    const options = createRequestOption(req);
    return this.api.get('api/orderLines-By-Customer/' + id + '/' + currencyId, options);
  }

  getPurchasedProduct(id: string, currencyId: string, req?: any): Observable<any> {
    const options = createRequestOption(req);
    return this.api.get('api/get-purchased-product-by-customer/' + id + '/' + this.currentLocaleId, options);
  }

  getPredefinedActionByPredefinedAxe(axeId): Observable<any> {
    return this.api.get('api/predefined-actions-by-predefined-axe-with-translation/' + axeId + '/' + this.currentLocaleId);
  }

  updateActionStatus(actionId, status, axisId, beneficiaryId: string): Observable<any> {
    return this.api.get('api/update-action-status/' + actionId + '/' + status + '/' + axisId + '/' + beneficiaryId);
  }

  register(beneficiary: IBeneficiary): Observable<any> {
    return this.api.post('api/beneficiaries/register', beneficiary);
  }

  getActionRequests(b2bCustomerId: string, currencyId: string, req?): Observable<any> {
    const options = createRequestOption(req);
    return this.api
      .get('api/get-actions-requests/' + b2bCustomerId + '/' + currencyId + '/' + this.currentLocaleId, options)
      .pipe(map(res => convertResponse(res)));
  }
  getActionRequestsByStatus(b2bCustomerId: string, currencyId: string, status: string, req?): Observable<any> {
    const options = createRequestOption(req);
    return this.api
      .get('api/get-actions-requests/' + b2bCustomerId + '/' + currencyId + '/' + status + '/' + this.currentLocaleId, options)
      .pipe(map(res => convertResponse(res)));
  }

  importBeneficiaries(customerB2BId: string, file) {
    const formdata: FormData = new FormData();
    formdata.append('file', file);
    return this.api.post('api/import-beneficiaries/' + customerB2BId, formdata);
  }

  downloadBeneficiariesByB2BCustomerId(b2bCustomerId: string) {
    return this.api.get('api/download-beneficiaries-excel-file-by-b2b-customer/' + b2bCustomerId + '/' + this.currentLocaleId, {
      responseType: 'blob',
    });
  }

  downloadBeneficiariesImportFileTemplate() {
    return this.api.get('api/download-beneficiaries-import-excel-template', {
      responseType: 'blob',
    });
  }

  findAllBooking(customerId: string, currencyId: string, req?): Observable<any> {
    const options = createRequestOption(req);
    return this.api
      .get('api/get-booked-product-by-customer/' + customerId + '/' + this.currentLocaleId, options)
      .pipe(map(res => convertResponse(res)));
  }

  updateActionRequest(actionRequestDTO: IAction): Observable<IAction> {
    return this.api.put('api/update-action', actionRequestDTO);
  }

  getBeneficiaryByUserLoginForUserProfil(login: string, currencyId: string): Observable<ResponseWrapper> {
    const options = createRequestOption({ localeId: this.currentLocaleId });
    return this.api.get('api/beneficiaries/login/' + login + '/' + currencyId, options);
  }
}
