import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpResponse } from '@angular/common/http';
import { GenericService } from './GenericService';
import { IUserWebNotification } from '../models/user-web-notification.model';
import { ResponseWrapper } from '../models/response-wrapper.model';
import { createRequestOption } from './utils/request-util';
import { map } from 'rxjs/operators';
import { convertResponse } from './utils/response-converter';

@Injectable()
export class WebNotificationService extends GenericService {
  private newVoteRetrospectiveReceived = new BehaviorSubject(false);
  sharedNewVote = this.newVoteRetrospectiveReceived.asObservable();

  constructor() {
    super();
  }
  getMyNotifications(): Observable<IUserWebNotification[]> {
    return this.api.get('api/user-notifications/mine/not-read/');
  }
  // getMyNotificationsReaded():Observable<IUserWebNotification[]>  {
  //     return this.api.get('api/user-notifications/mine/read/');
  // }
  public interceptNewVoteReceiver() {
    this.newVoteRetrospectiveReceived.next(true);
  }
  query(req?: any): Observable<ResponseWrapper> {
    const options = createRequestOption(req);
    return this.api.get('api/user-notifications/mine', options).pipe(map(res => convertResponse(res)));
  }
  update(webNotification: IUserWebNotification): Observable<HttpResponse<any>> {
    return this.api.put('api/user-notifications', webNotification, {
      observe: 'response',
    });
  }

  updateAll(webNotifications: IUserWebNotification[]): Observable<HttpResponse<any>> {
    return this.api.put('api/user-notifications/all', webNotifications, {
      observe: 'response',
    });
  }
  delete(id: string): Observable<any> {
    return this.api.delete('api/user-notifications/' + id);
  }
  deleteAll(ids: string[]): Observable<any> {
    return this.api.post('api/user-notifications/delete', ids);
  }
}
