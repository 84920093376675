import { Component, Inject, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import { Product } from '../../models/product.model';
import { B2BCustomerReviewAverage } from '../../shared/models/b-2-b-customer-review-average.model';
import { ProductService } from '../../services/product.service';
import { AppStateService } from '../../services/utils/state.service';
import { ActivatedRoute } from '@angular/router';
import { CartService } from '../../services/cart.service';
import { ProductCustomerReviewService } from '../../services/product-customer-review.service';
import { ToastrService } from 'ngx-toastr';
import { isPlatformBrowser } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../store/app.reducer';
import { CartLine } from '../../models/cartLine.model';
import { take, takeUntil } from 'rxjs/operators';
import { Pack } from '../../models/pack.model';
import { BENEFICIARY_GROUP, DEFAULT_CURRENCY_ID, DRH_GROUP, GENERIC_ERROR_MSG, SCREEN_SIZE } from '../../shared/constants';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { Subject } from 'rxjs';
import { SEO, SeoService } from '../../services/seo.service';
import { DynamicPathRoutingService } from '../../services/dynamic-path-routing.service';
import { CurrentLocaleService } from '../../services/current-locale.service';
import { SessionStorageService } from 'ngx-webstorage';
import { ResizeService } from '../../services/resize.service';

@Component({
  selector: 'app-pack-details',
  templateUrl: './pack-details.component.html',
  styleUrls: ['./pack-details.component.scss'],
})
export class PackDetailsComponent implements OnInit, OnDestroy {
  packId: string;
  packSeoName: string;
  currentLocale: string;
  pack: Pack = new Pack();
  beneficiary;
  poductCustomerReviews = [];
  currencyId: string;
  b2BCustomerReviewAverage: B2BCustomerReviewAverage;
  productImageSlides: OwlOptions = {
    loop: true,
    nav: false,
    dots: false,
    autoplayHoverPause: true,
    autoplay: true,
    margin: 30,
    navText: ["<i class='bx bx-left-arrow-alt'></i>", "<i class='bx bx-right-arrow-alt'></i>"],
    responsive: {
      0: {
        items: 1,
      },
    },
  };
  private $destroyed = new Subject();
  authenticatedUser;
  isOnLargeScreen = false;

  constructor(
    private productService: ProductService,
    private appStateService: AppStateService,
    private activatedRoute: ActivatedRoute,
    private cartService: CartService,
    private reviewService: ProductCustomerReviewService,
    private toaster: ToastrService,
    private translation: TranslateService,
    private store: Store<AppState>,
    private seoService: SeoService,
    private dynamicPathRoutingService: DynamicPathRoutingService,
    private currentLocaleService: CurrentLocaleService,
    @Inject(PLATFORM_ID) private platformId,
    private sessionStorageService: SessionStorageService,
    private resizeService: ResizeService
  ) {
    this.currentLocale = this.currentLocaleService.getCurrentLocale();
  }

  ngOnInit(): void {
    this.currentLocaleService.currentLocaleId$.pipe(takeUntil(this.$destroyed)).subscribe(localeId => {
      this.currentLocale = localeId;
      this.currentLocale = localeId;
      if (this.pack && this.pack.seoData && this.currentLocale) {
        this.packSeoName = this.pack.seoData.seoName.localizedValues[this.currentLocale];
        this.dynamicPathRoutingService.navigateToPackDetailsPage(this.packSeoName);
      }
    });
    this.currencyId = this.appStateService.getCurrentCurrency();
    this.b2BCustomerReviewAverage = new B2BCustomerReviewAverage();
    this.pack = new Product();
    this.pack.metaDescription = [];
    this.pack.metaDescription.localizedValues = {};
    this.activatedRoute.params.pipe(takeUntil(this.$destroyed)).subscribe(params => {
      this.packSeoName = params.seoName;
      if (this.pack) {
        this.getPack();
      }
    });
    this.currencyId = this.appStateService.getCurrentCurrency();

    this.getPack();

    this.store
      .select('beneficiaryState', 'beneficiary')
      .pipe(takeUntil(this.$destroyed))
      .subscribe(beneficiary => (this.beneficiary = beneficiary));
    if (isPlatformBrowser(this.platformId)) {
      this.authenticatedUser = this.sessionStorageService.retrieve('user');
      this.sessionStorageService
        .observe('user')
        .pipe(takeUntil(this.$destroyed))
        .subscribe(user => {
          this.authenticatedUser = user;
        });
    }
    this.getReviews();

    this.resizeService.onResize$.pipe(takeUntil(this.$destroyed)).subscribe(screenSize => {
      if (screenSize === SCREEN_SIZE.LG || screenSize === SCREEN_SIZE.XL) {
        this.isOnLargeScreen = true;
      } else {
        this.isOnLargeScreen = false;
      }
    });
  }

  getPack() {
    const pack$ = this.productService.getGenericPackBySeoName(this.packSeoName, this.currencyId ?? DEFAULT_CURRENCY_ID);
    pack$.pipe(takeUntil(this.$destroyed)).subscribe(res => {
      this.pack = res;
      this.setPackSeoData();
      this.packId = this.pack.id;
    });
  }

  get isAuthenticatedAsDRHOrBeneficiary() {
    return (
      this.authenticatedUser &&
      this.authenticatedUser.userGroups.some(userGroup => userGroup.id === BENEFICIARY_GROUP || userGroup.id === DRH_GROUP)
    );
  }

  setPackSeoData() {
    const seo: SEO = {};
    if (this.pack.seoData) {
      seo.title = this.pack.seoData.metaTitle.localizedValues[this.currentLocale];
      seo.description = this.pack?.seoData?.metaDescription?.localizedValues[this.currentLocale];
    }
    seo.imageUrl =
      this.pack.medias && this.pack.medias.length > 0
        ? this.pack.medias[0].url
          ? this.pack.medias[0].publicUrl
          : this.getImage(this.pack.medias[0].id, 'PDP')
        : '';
    this.seoService.setSEO(seo);
  }

  getImage(image: any, format: string | null): any {
    if (!image) {
      return;
    }
    return this.productService.downloadMedia(image, format);
  }

  counter(i) {
    return new Array(Math.floor(i));
  }

  addToCart() {
    const cartItem = new CartLine();
    cartItem.productId = this.pack.id;
    cartItem.quantity = 1;
    cartItem.productName = this.pack.label;
    cartItem.productMediaId = this.pack.media?.id;
    const price = this.appStateService.hasMembership() ? this.pack.priceWithPromotion : this.pack.price;
    cartItem.basePrice = price.amount;
    cartItem.currencyId = price.currency.id;
    cartItem.sellerId = this.pack.sellerId;
    cartItem.productType = this.pack.productType;
    if (this.pack.seoData) {
      cartItem.seoData = { ...this.pack.seoData };
    }
    this.cartService.addItem(cartItem);
  }

  getReviews() {
    this.reviewService
      .findAllByProduct(this.packId)
      .pipe(take(1))
      .subscribe(values => {
        this.poductCustomerReviews = values;
      });
  }

  ngOnDestroy() {
    this.$destroyed.next();
    this.seoService.resetSeo();
  }
}
