/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-types */
import { createAction, props } from '@ngrx/store';
import { IBeneficiary } from '../../models/Beneficiary.model';
import { BeneficiaryDevelopmentAxis } from '../../models/BeneficiaryDevelopmentAxisDTO.model';
import { IAction } from '../../models/Action.model';

export const anyBeneficiaryAction = createAction('[Beneficiary] ANY ACTION');
// reducer actions
export const setBeneficiary = createAction('[Beneficiary] SET BENEFICIARY', props<{ beneficiary: IBeneficiary }>());

export const addDevelopmentAxis = createAction('[Beneficiary] ADD DEV AXIS', props<{ devAxis: BeneficiaryDevelopmentAxis }>());

export const updateDevelopmentAxis = createAction('[Beneficiary] UPDATE DEV AXIS', props<{ devAxis: BeneficiaryDevelopmentAxis }>());

export const addDevAxisAction = createAction('[Beneficiary] ADD DEV AXIS ACTION', props<{ devAxisAction: IAction; devAxisId: string }>());

export const updateDevAxisAction = createAction(
  '[Beneficiary] UPDATE DEV AXIS ACTION',
  props<{ devAxisAction: IAction; devAxisId: string }>()
);

export const deleteDevAxisAction = createAction(
  '[Beneficiary] DELETE DEV AXIS ACTION',
  props<{ devAxisActionId: string; devAxisId: string }>()
);

export const deleteDevAxis = createAction('[Beneficiary] DELETE DEV AXIS', props<{ devAxisId: string }>());

// effects actions
export const setBeneficiaryEffect = createAction('[Beneficiary] SET BENEFICIARY EFFECT');

export const updateDevelopmentAxisEffect = createAction(
  '[Beneficiary] UPDATE DEV AXIS EFFECT',
  props<{
    devAxis: BeneficiaryDevelopmentAxis;
    onSuccess: Function;
    onFinish: Function;
  }>()
);

export const addDevelopmentAxisEffect = createAction(
  '[Beneficiary] ADD DEV AXIS EFFECT',
  props<{
    devAxis: BeneficiaryDevelopmentAxis;
    onSuccess: Function;
    onFinish: Function;
  }>()
);

export const addDevAxisActionEffect = createAction(
  '[Beneficiary] ADD DEV AXIS ACTION EFFECT',
  props<{ devAxisAction: IAction; devAxisId: string; onFinish: Function }>()
);

export const bindProductToDevAxisActionEffect = createAction(
  '[Beneficiary] BIND PRODUCT TO DEV AXIS ACTION EFFECT',
  props<{
    devAxisAction: IAction;
    devAxisId: string;
    userId: string;
    b2bCustomerId: string;
    visibility: string;
    onFinish: Function;
  }>()
);
export const bindLibraryToDevAxisActionEffect = createAction(
  '[Beneficiary] BIND LIBRARY TO DEV AXIS ACTION EFFECT',
  props<{
    devAxisAction: IAction;
    devAxisId: string;
    userId: string;
    b2bCustomerId: string;
    visibility: string;
    onFinish: Function;
  }>()
);
export const updateDevAxisActionEffect = createAction(
  '[Beneficiary] UPDATE DEV AXIS ACTION EFFECT',
  props<{ devAxisAction: IAction; devAxisId: string; onFinish: Function }>()
);

export const getActionDetailsEffect = createAction(
  '[Beneficiary] GET  ACTION DETAILS EFFECT',
  props<{
    actionId: string;
    login: string;
    currencyId: string;
    onFinish: Function;
  }>()
);

export const updateDevAxisActionStatusEffect = createAction(
  '[Beneficiary] UPDATE DEV AXIS ACTION STATUS EFFECT',
  props<{
    devAxisActionId: string;
    devAxisActionStatus: any;
    devAxisId: string;
  }>()
);

export const deleteDevAxisActionEffect = createAction(
  '[Beneficiary] DELETE DEV AXIS ACTION EFFECT',
  props<{
    devAxisAction: IAction;
    devAxisId: string;
    onSuccess: Function;
    onFinish: Function;
  }>()
);

export const deleteDevAxisEffect = createAction(
  '[Beneficiary] DELETE DEV AXIS EFFECT',
  props<{ devAxisId: string; onSuccess: Function; onFinish: Function }>()
);
