import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IPaymentMode } from '../models/paymentMode.model';
import { GenericService } from './GenericService';
import { Store } from '@ngrx/store';
import { AppState } from '../store/app.reducer';
import { LOCALE_ID, LOCALE_STATE } from '../shared/constants';

@Injectable({
  providedIn: 'root',
})
export class PaymentModeService extends GenericService {
  private currentLocaleId: string;

  constructor(private store: Store<AppState>) {
    super();
    this.store.select(LOCALE_STATE, LOCALE_ID).subscribe(localeId => (this.currentLocaleId = localeId));
  }

  getAll(): Observable<IPaymentMode[]> {
    return this.api.get('api/payment-modes-translation/' + this.currentLocaleId);
  }
}
