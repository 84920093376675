export interface IFilterObject {
  className?: string;
  searchValue?: string;
  sortOrder?: string;
  sortedField?: string;
  fields?: string[];
}

export class FilterObject implements IFilterObject {
  constructor(
    public className?: string,
    public searchValue?: string,
    public sortOrder?: string,
    public sortedField?: string,
    public fields?: string[]
  ) {}
}
