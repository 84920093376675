<div class="products-details-tab-content">
  <div class="product-review-form">
    <h3>{{ 'CUSTOMER_REVIEWS' | translate }}</h3>
    <div class="review-comments">
      <div class="review-item" *ngFor="let review of productCustomerReviews">
        <div class="rating">
          <ngb-rating [(rate)]="review.customerReviewRatingLevelValue" readonly="true" [max]="5">
            <ng-template let-fill="fill" let-index="index">
              <span class="star" [class.filled]="fill === 100">&#9733;</span>
            </ng-template>
          </ngb-rating>
        </div>
        <span
          ><strong>{{ review.createdBy }}</strong> {{ 'ON' | translate }}
          <strong>
            {{ review.createdDate | date : 'dd/MM/yyyy HH:mm:ss' }}
          </strong></span
        >
        <h3>{{ review.title }}</h3>
        <p>{{ review.content }}</p>
      </div>
    </div>
    <div class="review-form" *ngIf="beneficiary">
      <h3>{{ 'WRITE_REVIEW' | translate }}</h3>
      <div class="form-text small">
        <div
          *ngIf="hovered === 0 && rating !== 0"
          class="text-danger"
          [ngClass]="{
            'text-danger': rating < 3,
            'color-average': rating == 3,
            'text-success': rating > 3
          }">
          {{ currentRatingLabel | translate }}
        </div>
      </div>
      <ngb-rating [(rate)]="rating" (hover)="rateOnHovre($event)" (leave)="hovered = 0" (rateChange)="rateChange()" [max]="5">
        <ng-template let-fill="fill" let-index="index">
          <span class="star" [class.filled]="fill === 100" [class.bad]="index < 2" [class.average]="index == 2">&#9733;</span>
        </ng-template>
      </ngb-rating>

      <div class="form-text small">
        <ng-container *ngIf="hovered !== 0">
          <div
            class=""
            [ngClass]="{
              'text-danger': hovered < 3,
              'color-average': hovered == 3,
              'text-success': hovered > 3
            }">
            {{ ratingLabel | translate }}
          </div>
        </ng-container>
      </div>
      <div [hidden]="!(!rating && isReviewFormSubmitted)">
        <span class="text-danger"> {{ 'PLEASE_ADD_RATING' | translate }}. </span>
      </div>
      <form>
        <div class="row">
          <div class="col-lg-12 col-md-12">
            <div class="form-group">
              <input
                #reviewTitleField="ngModel"
                required
                type="text"
                id="review-title"
                name="review-title"
                placeholder="{{ 'ENTRE_YOUR_REVIEW_TITLE' | translate }} "
                class="form-control"
                [(ngModel)]="productCustomerReview.title" />
              <div class="mt-2" [hidden]="!(!productCustomerReview.title && isReviewFormSubmitted)">
                <span class="text-danger">
                  {{ 'THIS FIELD IS' | translate }}
                  {{ 'REQUIRED' | translate }}.
                </span>
              </div>
            </div>
          </div>
          <div class="col-lg-12 col-md-12">
            <div class="form-group">
              <textarea
                #reviewBodyField="ngModel"
                required
                name="review-body"
                id="review-body"
                cols="30"
                rows="6"
                placeholder="{{ 'WRITE_REVIEW_CONTENT' | translate }} "
                class="form-control"
                [(ngModel)]="productCustomerReview.content"></textarea>
              <div class="mt-2" [hidden]="!(!productCustomerReview.content && isReviewFormSubmitted)">
                <span class="text-danger">
                  {{ 'THIS FIELD IS' | translate }}
                  {{ 'REQUIRED' | translate }}.
                </span>
              </div>
            </div>
          </div>
          <div class="col-lg-12 col-md-12">
            <button type="submit" class="default-btn" (click)="addReview()">
              <i class="bx bx-paper-plane icon-arrow before"></i><span class="label">{{ 'SUBMIT_COMMENT' | translate }} </span
              ><i class="bx bx-paper-plane icon-arrow after"></i>
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
