import { Component, OnDestroy, OnInit } from '@angular/core';
import { CmsService } from '../../cms/cms.service';
import { AppStateService } from '../../services/utils/state.service';
import { takeUntil } from 'rxjs/operators';
import { IContext } from '../../cms/models/context.model';
import { ReplaySubject } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { CategoryService } from 'src/app/services/category.service';
import { environment } from 'src/environments/environment';
import { BENEFITS_PAGE, PRODUCTS_BY_CATEGORIES_PAGE } from '../../shared/constants';
import { SeoService } from '../../services/seo.service';
import { PRESTATION_COMPONENT_NAME } from '../../ComponentsIndex';
import { DynamicPathStorageService } from '../../services/dynamic-path-storage.service';
import { CurrentLocaleService } from '../../services/current-locale.service';

@Component({
  selector: 'app-prestation',
  templateUrl: './prestation.component.html',
  styleUrls: ['./prestation.component.scss'],
})
export class PrestationComponent implements OnInit, OnDestroy {
  cmsPage: any;
  componentName = 'OFFERS';
  currentLocale: string;
  context: IContext | null | undefined;
  components: any[] | undefined;
  component: any;
  categoryId: string;
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  showBundle: boolean;

  constructor(
    private cmsService: CmsService,
    appStateService: AppStateService,
    private activatedRoute: ActivatedRoute,
    private categoryService: CategoryService,
    private seoService: SeoService,
    private dynamicPathStorageService: DynamicPathStorageService,
    private router: Router,
    private currentLocaleService: CurrentLocaleService
  ) {
    this.currentLocale = currentLocaleService.getCurrentLocale();
    this.showBundle = !environment.production;
  }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(params => {
      this.categoryService.next(params.category);
      this.categoryId = params.category;
    });
    this.getCmsPage();
    this.currentLocaleService.currentLocaleId$.pipe(takeUntil(this.destroyed$)).subscribe(localeId => {
      this.currentLocale = localeId;
      if (this.cmsPage) {
        this.seoService.setSeoFromSeoDataAndMedia(this.cmsPage.seoData);
      }
      const currentLocaleUrlPattern = this.dynamicPathStorageService.getUrlPatternByComponentNameFromCmsPageList(PRESTATION_COMPONENT_NAME);
      if (currentLocaleUrlPattern) {
        this.router.navigate([currentLocaleUrlPattern], {
          queryParamsHandling: 'preserve',
        });
      }
    });
  }

  getCmsPage(): void {
    this.cmsService
      .getCmsPageByCode(BENEFITS_PAGE)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(res => {
        this.components = res.cmsComponents;
        // this loop is for getting components that are inside the containers !!
        res?.cmsContainers?.forEach(container => {
          container.cmsComponents.forEach(component => {
            this.components.push(component);
          });
        });
      });

    this.cmsService
      .getCmsPageByCode(PRODUCTS_BY_CATEGORIES_PAGE)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(res => {
        this.cmsPage = res;
        this.seoService.setSeoFromSeoDataAndMedia(this.cmsPage.seoData);
      });
  }

  getIdComponentByCode(code: string): any {
    if (this.components && this.components.length > 0) {
      const index = this.components.findIndex(component => component.code === code);
      if (index !== -1) {
        return this.components[index].id;
      }
    }
  }
  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
    this.seoService.resetSeo();
  }
  saveComponentName(componentName) {
    this.componentName = componentName;
  }
}
