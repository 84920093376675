import { Component, Inject, OnDestroy, OnInit, PLATFORM_ID, TemplateRef, ViewChild } from '@angular/core';
import { DOCUMENT, isPlatformBrowser, Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { AppStateService } from './services/utils/state.service';
import { UserAccountService } from './services/auth/user-account.service';
import { AngularFireMessaging } from '@angular/fire/compat/messaging';
import { WebNotificationService } from './services/web-notification.service';
import { LoginService } from './services/auth/loginone.service';
import { NavigationCancel, NavigationEnd, Router } from '@angular/router';
import { filter, map, takeUntil } from 'rxjs/operators';
import { InboxService } from './inbox/inbox.service';
import { environment } from '../environments/environment';
import firebase from 'firebase/compat/app';
import { BENEFICIARY_GROUP, ENGLISH_UK, FRENCH_FRANCE, LOCALE_ID, PASSE_EXCELLENCE } from './shared/constants';
import { Store } from '@ngrx/store';
import { AppState } from './store/app.reducer';
import * as AuthActions from './store/actions/beneficiary.actions';
import { DomSanitizer, TransferState } from '@angular/platform-browser';
import 'rxjs/add/operator/filter';
import { LocalStorageService, SessionStorageService } from 'ngx-webstorage';
import { DrhSpaceService } from './drh-space/drh-space.service';
import { CookieService } from 'ngx-cookie-service';
import { SeoService } from './services/seo.service';
import { Observable, Subject } from 'rxjs';
import * as LocaleActions from './store/actions/locale.actions';
// import {OnServerService} from "./services/on-server.service";
import { localeIdStateKey } from './transfer-state-keys';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DynamicPathStorageService } from './services/dynamic-path-storage.service';
import { TERMS_AND_CONDITIONS_COMPONENT_NAME, TERMS_OF_USE_COMPONENT_NAME } from './ComponentsIndex';
import { CurrentLocaleService } from './services/current-locale.service';
const config = {
  apiKey: 'AIzaSyCN8GlBmyAVTqWtADqXK-oS_Reor1KhT34',
  authDomain: 'pass-excellence.firebaseapp.com',
  databaseURL: 'https://pass-excellence-default-rtdb.firebaseio.com',
  projectId: 'pass-excellence',
  storageBucket: 'pass-excellence.appspot.com',
  messagingSenderId: '1090051205616',
  appId: '1:1090051205616:web:f6d372ea583248be191535',
};

declare let gtag;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [
    Location,
    {
      provide: LocationStrategy,
      useClass: PathLocationStrategy,
    },
  ],
})
export class AppComponent implements OnInit, OnDestroy {
  private destroyed$ = new Subject();
  location: any;
  routerSubscription: any;
  private account: any;
  private currentUser: any;
  baseUrl = environment.apiUrl + 'api/style-sheets-by-application/';
  previewBaseUrl = environment.apiUrl + 'api/style-sheets-by-theme/';
  styleSheetUrl = '';
  hasAcceptedCookies = false;
  themeId;
  availableThemeId;
  previewAvailableThemeBaseUrl = environment.apiUrl + 'api/style-sheet-by-theme-version-variation/';
  isPlateform = false;
  readonly HAS_ACCEPTED_COOKIES = 'hasAcceptedCookies';
  hasAcceptedTermsOfUse = true;
  isLoggedIn = false;
  termsAndConditionsPageUrl$: Observable<string> = new Observable<string>();
  @ViewChild('acceptTermsModal', { static: true })
  acceptTermsModal: TemplateRef<any>;
  newFirebaseMessages$;
  user;

  constructor(
    public translate: TranslateService,
    private appStateService: AppStateService,
    private userAccountService: UserAccountService,
    private router: Router,
    private angularFireMessaging: AngularFireMessaging,
    private webNotificationService: WebNotificationService,
    private inboxService: InboxService,
    private loginService: LoginService,
    private store: Store<AppState>,
    public sanitizer: DomSanitizer,
    @Inject(DOCUMENT) private document,
    public sessionStorage: LocalStorageService,
    private drhSpaceService: DrhSpaceService,
    @Inject(PLATFORM_ID) private platformId: any,
    private seoService: SeoService,
    // onServerService: OnServerService,
    private cookieService: CookieService,
    private transferState: TransferState,
    private localStorage: LocalStorageService,
    private ngbModal: NgbModal,
    private dynamicPathStorageService: DynamicPathStorageService,
    private currentLocaleService: CurrentLocaleService
  ) {
    // translate.addLangs([ENGLISH_UK, FRENCH_FRANCE]);
    // const localeId = currentLocaleService.getCurrentLocale();
    // if (isPlatformServer(platformId) && localeId) {
    //     translate.setDefaultLang(localeId);
    // } else {
    //     const transferStateLocale = transferState.get(localeIdStateKey, null);
    //     translate.setDefaultLang(transferStateLocale ?? FRENCH_FRANCE);
    // }
    translate.addLangs([ENGLISH_UK, FRENCH_FRANCE]);
    translate.setDefaultLang(FRENCH_FRANCE);

    if (isPlatformBrowser(platformId)) {
      const navEndEvents$ = this.router.events.pipe(filter(event => event instanceof NavigationEnd));

      navEndEvents$.subscribe((event: NavigationEnd) => {
        this.isPlateform = !(event.url.includes('login') || event.url.includes('forgot-password') || event.url.includes('register'));
        gtag('config', environment.googleAnalyticsID, {
          page_path: event.urlAfterRedirects,
        });
      });
    }
  }

  ngOnInit() {
    this.termsAndConditionsPageUrl$ = this.dynamicPathStorageService.urlPatterns$.pipe(
      map(() => {
        const urlPattern = this.dynamicPathStorageService.getUrlPatternByComponentNameFromCmsPageList(TERMS_OF_USE_COMPONENT_NAME);
        if (!urlPattern) {
          return null;
        }
        return urlPattern.startsWith('/') ? urlPattern : '/' + urlPattern;
      })
    );
    if (isPlatformBrowser(this.platformId)) {
      this.user = this.sessionStorage.retrieve('user');
     this.onUserSessionChange();
      this.sessionStorage
        .observe('user')
        .pipe(takeUntil(this.destroyed$))
        .subscribe(user => {
          this.user = user;
          this.onUserSessionChange();
        });
    }
    this.seoService.resetSeo();
    this.hasAcceptedCookies = Boolean(this.cookieService.get(this.HAS_ACCEPTED_COOKIES));
    this.styleSheetUrl = this.baseUrl + PASSE_EXCELLENCE;
    const token = localStorage.getItem('authenticationToken') ;

    if (token) {
      this.userAccountService
        .identity()
        .then(account => {
          this.account = account;
          this.isLoggedIn = true;
          // this.hasAcceptedTermsOfUse = this.account.hasAcceptedTermsOfUse;
          // if (this.isLoggedIn && !this.hasAcceptedTermsOfUse) {
          //     this.openAcceptTermsModal()
          // }
          this.getBeneficiary(account);
        })
        .catch(() => {
          this.loginService.logout();
          this.appStateService.setUser(null);
          this.appStateService.setBeneficiary(null);
        });
    }
    if (isPlatformBrowser(this.platformId)) {
      this.setupLocale();
    }
    this.appStateService.setCurrency();
    this.recallJsFuntions();
    if (!firebase.apps.length) {
      firebase.initializeApp(config);
    }

    if (isPlatformBrowser(this.platformId)) {
      if (token) {
        this.getCurrentUser();
      }
      this.sessionStorage.observe('user').subscribe(user => {
        if (user != null) {
          this.getCurrentUser();
        } else if (this.newFirebaseMessages$) {
          this.newFirebaseMessages$.off();
        }
      });
    }

    this.angularFireMessaging.messages.subscribe(
      (payload: any) => {
        if (payload.notification.title === 'Notification') {
          this.webNotificationService.interceptNewVoteReceiver();
        }
      },
      () => {}
    );

    this.themeId = this.sessionStorage.retrieve('themeId');
    this.availableThemeId = this.sessionStorage.retrieve('availableThemeId');
    if (this.themeId) {
      this.styleSheetUrl = this.previewBaseUrl + this.themeId;
      this.document.getElementById('customCss').setAttribute('href', this.styleSheetUrl);
    } else if (this.availableThemeId) {
      this.styleSheetUrl = this.previewAvailableThemeBaseUrl + this.availableThemeId;
      this.document.getElementById('customCss').setAttribute('href', this.styleSheetUrl);
    } else {
      this.styleSheetUrl = this.baseUrl + PASSE_EXCELLENCE;
      this.document.getElementById('customCss').setAttribute('href', this.styleSheetUrl);
    }
  }

  onUserSessionChange() {
    if (this.user) {
      this.isLoggedIn = true;
      this.hasAcceptedTermsOfUse = this.user.hasAcceptedTermsOfUse;
    } else {
      this.isLoggedIn = false;
    }
    if (this.isLoggedIn && !this.hasAcceptedTermsOfUse) {
      this.openAcceptTermsModal();
    } else {
      this.ngbModal.dismissAll();
    }
  }

  private getCurrentUser() {
    this.userAccountService.getCurrentUser().subscribe(account => {
      this.currentUser = account;
      if (account) {
        this.callFirebase();
      } else if (this.newFirebaseMessages$) {
        this.newFirebaseMessages$.off();
      }
    });
  }

  private callFirebase() {
    this.newFirebaseMessages$ = firebase
      .database()
      .ref(this.currentUser?.id + '/')
      .orderByChild('type')
      .equalTo('message');
    this.newFirebaseMessages$.on('value', () => {
      this.inboxService.interceptNewMessageReceived(true);
      this.inboxService.interceptCount();
    });
  }

  openAcceptTermsModal() {
    this.ngbModal.open(this.acceptTermsModal, {
      backdrop: false,
      size: 'lg',
      windowClass: 'bg-secondary-op-2 pt-5 mt-5',
      modalDialogClass: 'accept-terms-dialog',
    });
  }

  onAcceptTerms() {
    this.ngbModal.dismissAll();
    this.userAccountService.acceptTermsOfUse(this.user.id).subscribe(() => {
      this.hasAcceptedTermsOfUse = true;
      this.sessionStorage.store('user', {
        ...this.user,
        hasAcceptedTermsOfUse: true,
      });
      this.getBeneficiary(this.user);
    });
  }

  onRefuseTerms() {
    this.ngbModal.dismissAll();
    this.loginService.logout();
    this.isLoggedIn = false;
  }

  async setupLocale() {
    const transferStateLocale = this.transferState.get(localeIdStateKey, null);
    if (transferStateLocale) {
      await this.store.dispatch(LocaleActions.setLocaleId({ localeId: transferStateLocale }));
      await this.localStorage.store(LOCALE_ID, transferStateLocale);
    }
    this.appStateService.initilizeCurrentLocale();
  }

  onAcceptCookies() {
    const trueBoolean = Boolean(true);
    const trueString = trueBoolean.toString();
    this.cookieService.set(this.HAS_ACCEPTED_COOKIES, trueString);
    this.hasAcceptedCookies = true;
  }

  onDefuseCookies() {
    const trueBoolean = Boolean(false);
    const trueString = trueBoolean.toString();
    this.cookieService.set(this.HAS_ACCEPTED_COOKIES, trueString);
    this.hasAcceptedCookies = true;
  }

  getBeneficiary(account) {
    const index = account.userGroups.findIndex(item => item.id === BENEFICIARY_GROUP); // hasAnyAuthorityDirect
    if (index !== -1) {
      this.store.dispatch(AuthActions.setBeneficiaryEffect());
    } else {
      this.drhSpaceService.getCustomerByUserLogin(account.login).subscribe(res => {
        this.appStateService.setBeneficiary(res);
      });
    }
  }

  recallJsFuntions() {
    this.routerSubscription = this.router.events
      .pipe(filter(event => event instanceof NavigationEnd || event instanceof NavigationCancel))
      .subscribe(event => {
        this.location = this.router.url;
        if (!(event instanceof NavigationEnd)) {
          return;
        }
        if (isPlatformBrowser(this.platformId)) {
          window.scrollTo(0, 0);
        }
      });
  }

  ngOnDestroy() {
    this.destroyed$.next();
  }
}
