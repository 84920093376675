import { BehaviorSubject, ReplaySubject } from 'rxjs';
import { Injectable, OnDestroy } from '@angular/core';
import { DynamicPathService } from './dynamic-path.service';
import { ICmsPage } from '../shared/models/cms-page.model';
import { takeUntil } from 'rxjs/operators';
import { CurrentLocaleService } from './current-locale.service';

@Injectable({
  providedIn: 'root',
})
export class DynamicPathStorageService implements OnDestroy {
  urlPatterns$: BehaviorSubject<ICmsPage[]> = new BehaviorSubject<ICmsPage[]>([]);
  urlPatterns: ICmsPage[] = [];
  currentLocaleId: string;
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  constructor(private dynamicPathService: DynamicPathService, private currentLocaleService: CurrentLocaleService) {
    this.fetchCmsPages();
    this.currentLocaleService.currentLocaleId$.pipe(takeUntil(this.destroyed$)).subscribe(localeId => {
      this.currentLocaleId = localeId;
      if (this.urlPatterns && this.urlPatterns.length > 0) {
        this.urlPatterns$.next(this.urlPatterns);
      }
    });
  }
  fetchCmsPages() {
    this.dynamicPathService.findAllWithUrlKeyNotNull().subscribe(res => {
      this.urlPatterns = res;
      this.urlPatterns$.next(res);
    });
  }

  getUrlPatternByComponentNameFromCmsPageList(spaComponentName: string) {
    if (this.urlPatterns && this.urlPatterns.length > 0) {
      const cmsPageIndex = this.urlPatterns.findIndex(page => page.spaComponentName === spaComponentName);
      if (cmsPageIndex === -1) {
        return null;
      }
      const cmsPage = this.urlPatterns[cmsPageIndex];
      if (!cmsPage.urlPattern) {
        return null;
      }
      const urlPatternForCurrentLocale = cmsPage.urlPattern.localizedValues[this.currentLocaleId];
      if (!urlPatternForCurrentLocale) {
        return null;
      }
      return urlPatternForCurrentLocale.startsWith('/') ? urlPatternForCurrentLocale : '/' + urlPatternForCurrentLocale;
    } else {
      return null;
    }
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
