<ngx-spinner bdColor="rgba(51,51,51,0.8)" color="#fff" size="medium" type="line-scale"></ngx-spinner>
<div class="page-title-area item-bg1">
  <div class="container">
    <div class="page-title-content">
      <ul>
        <li>
          <a href="index.html">{{ 'HOME' | translate }}</a>
        </li>
        <li>{{ 'ACCOUNT' | translate }}</li>
      </ul>
      <h2>{{ 'ACCOUNT' | translate }}</h2>
    </div>
  </div>
</div>
<div class="checkout-area">
  <div class="container" style="min-height: 300px">
    <form *ngIf="!isFetchingUserData">
      <div class="row">
        <div class="col-lg-12 col-md-12">
          <div class="billing-details" style="margin-top: 30px" *ngIf="customer">
            <h3 class="title">{{ 'PERSONAL_INFORMATION' | translate }}</h3>
            <div class="row">
              <div class="col-lg-12 col-md-12 row" style="margin-bottom: 15px; margin-top: 55px">
                <div class="col-lg-6 col-md-6">
                  <app-hro-edit-profil-image
                    *ngIf="user"
                    [customer]="user"
                    (media)="loadAll()"
                    (onMediaChange)="save($event)"></app-hro-edit-profil-image>
                </div>
                <div *ngIf="qrCodeImage" class="col-lg-6 col-md-6 qrCode">
                  <img [src]="qrCodeImage" alt="QR Code" />
                </div>
              </div>
              <div class="col-lg-6 col-md-6">
                <div class="form-group">
                  <label>{{ 'FIRSTNAME' | translate }}<span class="required"> *</span></label>
                  <input type="text" class="form-control" [ngModelOptions]="{ standalone: true }" [(ngModel)]="customer.firstName" />
                </div>
              </div>
              <div class="col-lg-6 col-md-6">
                <div class="form-group">
                  <label> {{ 'LASTNAME' | translate }}<span class="required"> *</span></label>
                  <input type="text" class="form-control" [ngModelOptions]="{ standalone: true }" [(ngModel)]="customer.lastName" />
                </div>
              </div>
              <div class="col-lg-6 col-md-6">
                <div class="form-group">
                  <label>{{ 'EMAIL' | translate }} <span class="required"> *</span></label>
                  <input
                    type="email"
                    class="form-control"
                    [ngModelOptions]="{ standalone: true }"
                    disabled
                    [(ngModel)]="customer.email"
                    pattern="^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                    #value="ngModel"
                    required />
                </div>
              </div>

              <div class="col-lg-6 col-md-6">
                <div class="form-group">
                  <label>{{ 'GENDER' | translate }}</label>
                  <div style="display: flex">
                    <div *ngFor="let gen of genders">
                      <input type="radio" id="{{ gen.id }}" name="radio-group-{{ gen.id }}" [value]="gen.id" [(ngModel)]="gender.id" />
                      <i *ngIf="gen.id === 'FEMALE'" style="font-size: 27px; margin: 5px; color: #ec899a" class="bx bx-female-sign"></i>
                      <i *ngIf="gen.id === 'MALE'" style="font-size: 27px; margin: 5px; color: #7ccaca" class="bx bx-male-sign"></i>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-lg-6 col-md-6">
                <div class="form-group">
                  <label>{{ 'PHONENUMBER' | translate }}1<span class="required"> *</span></label>
                  <input
                    type="text"
                    class="form-control"
                    [ngModelOptions]="{ standalone: true }"
                    [(ngModel)]="customer.phoneNumber1"
                    [minLength]="8"
                    [maxLength]="13"
                    pattern="^[0-9]{1,4}[-\s\./0-9]*$"
                    #valuephone1="ngModel"
                    required />
                  <div *ngIf="valuephone1.errors?.pattern" class="text-danger">
                    <small class="chankya-block"
                      >{{ 'PHONE' | translate }} <strong>{{ 'NOT_VALID' | translate }}</strong></small
                    >
                  </div>
                </div>
              </div>
              <div class="col-lg-6 col-md-6">
                <div class="form-group">
                  <label>{{ 'PHONENUMBER' | translate }}2 <span class="required"></span></label>
                  <input
                    type="text"
                    class="form-control"
                    [ngModelOptions]="{ standalone: true }"
                    [(ngModel)]="customer.phoneNumber2"
                    pattern="^[0-9]{1,4}[-\s\./0-9]*$"
                    #valuephone="ngModel"
                    [minLength]="8"
                    [maxLength]="13" />
                  <div *ngIf="valuephone.errors?.pattern" class="text-danger">
                    <small class="chankya-block"
                      >{{ 'PHONE' | translate }} <strong>{{ 'NOT_VALID' | translate }}</strong></small
                    >
                  </div>
                </div>
              </div>
              <ng-container *ngIf="isCoach && locales">
                <div class="col-lg-6 col-md-6" *ngFor="let locale of locales">
                  <div class="form-group">
                    <label class="d-inline mr-2 mb-2">{{ 'SUMMARY' | translate }} </label>
                    <img
                      *ngIf="locale?.languageId"
                      src="assets/img/{{ locale?.languageId }}-flag.jpg"
                      class="shadow-sm mb-2"
                      style="height: 20px"
                      [alt]="locale.name" />

                    <textarea
                      name="summary"
                      id="summary"
                      cols="30"
                      rows="10"
                      class="form-control"
                      [ngModelOptions]="{ standalone: true }"
                      [(ngModel)]="customer.summary?.localizedValues[locale.id]"></textarea>
                  </div>
                </div>
              </ng-container>
              <hr *ngIf="isCoach" />

              <div *ngIf="isCoach" class="col-lg-6 col-md-6">
                <div class="form-group">
                  <label>{{ 'DOMAIN' | translate }}</label>
                  <div class="select-box">
                    <ng-multiselect-dropdown
                      name="domaine"
                      id="field_domaine"
                      [ngModelOptions]="{ standalone: true }"
                      [settings]="dropdownSettings"
                      [data]="coachingDomains"
                      [(ngModel)]="coachingDomain">
                    </ng-multiselect-dropdown>
                  </div>
                </div>
              </div>
              <div *ngIf="isCoach" class="col-lg-6 col-md-6">
                <div class="form-group">
                  <label>{{ 'ACCREDITATION_LEVEL' | translate }}</label>
                  <div class="select-box">
                    <select
                      class="form-select"
                      name="field_accerditional"
                      id="field_accerditional"
                      [(ngModel)]="accreditationLevel"
                      required>
                      <option>
                        {{ 'SELECT_ACCREDITATION_LEVEL' | translate }}
                      </option>
                      <option
                        *ngFor="let accerditional of accreditationLevels"
                        [ngValue]="accerditional.id === customer?.icfAccreditationLevelId ? accreditationLevel : accerditional">
                        {{ accerditional.name }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              <div *ngIf="isCoach" class="col-lg-6 col-md-6">
                <div class="form-group">
                  <label>{{ 'LANGUAGES' | translate }}</label>

                  <ng-multiselect-dropdown
                    name="languages"
                    id="languages"
                    [ngModelOptions]="{ standalone: true }"
                    [settings]="dropdownSettings"
                    [data]="languages"
                    [(ngModel)]="customer.languages">
                  </ng-multiselect-dropdown>
                </div>
              </div>
              <div *ngIf="isCoach" class="col-lg-6 col-md-6">
                <div class="form-group">
                  <label>{{ 'COUNTRY' | translate }}</label>
                  <div class="select-box">
                    <select class="form-select" name="field_country" id="field_country" [(ngModel)]="customer.countryId" required>
                      <option [ngValue]="null">
                        {{ 'COUNTRY' | translate }}
                      </option>
                      <option *ngFor="let countr of countries" [ngValue]="countr.id">
                        {{ countr.name }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="billing-details">
            <h3 class="title">{{ 'ADDRESS' | translate }}</h3>

            <div class="row">
              <div class="col-lg-6 col-md-6">
                <div class="form-group">
                  <label for="field_street">{{ 'STRRET_ADDRESS' | translate }}</label>
                  <input type="text" class="form-control input-wrapper" name="street" id="field_street" [(ngModel)]="address.street" />
                </div>
              </div>

              <div class="col-lg-6 col-md-6">
                <div class="form-group">
                  <label for="field_countryD">{{ 'COUNTRY' | translate }}</label>
                  <input
                    type="text"
                    class="form-control input-wrapper"
                    name="countryD"
                    id="field_countryD"
                    [(ngModel)]="address.city.country.name?.localizedValues[this.currentLocale]" />
                </div>
              </div>
              <!-- state field start -->
              <div class="col-lg-6 col-md-6" *ngIf="customer && customer.countryId === tunisiaCountryId">
                <div class="form-group">
                  <label>{{ 'STATE' | translate }}</label>
                  <div class="select-box">
                    <select class="form-select" name="stateD" id="field_stateD" [(ngModel)]="address.state" required>
                      <option *ngFor="let state of states" [ngValue]="state.name === address.state?.name ? address.state : state">
                        {{ state.name }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              <!-- state field end -->
              <div class="col-lg-6 col-md-6">
                <div class="form-group">
                  <label for="field_cityD">{{ 'CITY' | translate }}</label>
                  <input type="text" class="form-control input-wrapper" name="cityD" id="field_cityD" [(ngModel)]="address.city.name" />
                </div>
              </div>

              <div class="col-lg-6 col-md-6">
                <div class="form-group">
                  <label for="field_zipD">{{ 'ZIP' | translate }}</label>
                  <input type="text" class="form-control input-wrapper" name="zipD" id="field_zipD" [(ngModel)]="address.zip" />
                </div>
              </div>
            </div>
          </div>
          <div class="billing-details" *ngIf="isCoach">
            <h3 class="title">{{ 'SOCIAL_NETWORKS' | translate }}</h3>
            <div class="row">
              <div class="col-lg-6 col-md-6" *ngIf="customer && customer.socialNetworkAccounts && customer.socialNetworkAccounts.length">
                <ng-container *ngFor="let socialNetworkAccount of customer.socialNetworkAccounts">
                  <div class="row">
                    <div class="col-1">
                      <img
                        height="30"
                        width="30"
                        class="mt-2 mx-auto px-1 px-sm-0"
                        [src]="getImage(socialNetworkAccount.socialMediaNetwork.icon.id, null)"
                        [alt]="socialNetworkAccount.socialMediaNetwork.icon.alt ?? socialNetworkAccount.socialMediaNetwork.name"
                        [title]="socialNetworkAccount.socialMediaNetwork.name" />
                    </div>
                    <div class="col-11">
                      <div class="form-group">
                        <input
                          type="text"
                          class="form-control input-wrapper"
                          [name]="socialNetworkAccount.socialMediaNetwork.name"
                          [id]="socialNetworkAccount.socialMediaNetwork.id"
                          [(ngModel)]="socialNetworkAccount.link" />
                      </div>
                    </div>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>
          <div>
            <button type="submit" (click)="save()" class="default-btn">
              <i class="bx bx-save icon-arrow before"></i><span class="label">{{ 'SAVE' | translate }}</span
              ><i class="bx bx-save icon-arrow after"></i>
            </button>
          </div>
          <div class="billing-details" style="margin-top: 30px">
            <h3 class="title">{{ 'PASSWORD_CHANGE' | translate }}</h3>
            <form [formGroup]="resetPasswordForm" (submit)="changePassword()">
              <div class="row">
                <div class="col-lg-12 col-md-12">
                  <div class="form-group">
                    <label>{{ 'CURRENT_PASSWORD' | translate }}<span class="required"> *</span></label>
                    <input
                      placeholder="{{ 'PASSWORD' | translate }}"
                      class="form-control"
                      [type]="showPassword ? 'text' : 'password'"
                      name="password"
                      formControlName="password"
                      [ngClass]="{
                        'is-invalid': submitted && f.password?.errors
                      }"
                      [(ngModel)]="password" />
                    <i
                      (click)="showPassword = !showPassword"
                      class="field-icon"
                      [ngClass]="showPassword ? ' bx bx-hide' : ' bx bx-show'"></i>
                    <div *ngIf="submitted && f.password?.errors" class="invalid-feedback">
                      <div *ngIf="f.password.errors.required" style="float: left; margin-bottom: 5px">
                        {{ 'PASSWORD_REQUIRED' | translate }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 col-md-6">
                  <div class="form-group">
                    <label>{{ 'NEW_PASSWORD' | translate }}<span class="required"> *</span></label>
                    <input
                      placeholder="{{ 'PASSWORD' | translate }}"
                      class="form-control"
                      [type]="showPassword ? 'text' : 'password'"
                      formControlName="newPassword"
                      name="newPassword"
                      [ngClass]="{
                        'is-invalid': submitted && f.newPassword?.errors
                      }"
                      [(ngModel)]="newPassword" />
                    <i
                      (click)="showPassword = !showPassword"
                      class="field-icon"
                      [ngClass]="showPassword ? ' bx bx-hide' : ' bx bx-show'"></i>
                    <div *ngIf="submitted && f.newPassword?.errors" class="invalid-feedback">
                      <div *ngIf="f.newPassword.errors.required" style="float: left; margin-bottom: 5px">
                        {{ 'PASSWORD_REQUIRED' | translate }}
                      </div>
                      <div *ngIf="f.newPassword.errors.pattern" style="float: left; margin-bottom: 5px">
                        {{ 'PASSWORD_INVALID' | translate }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 col-md-6">
                  <div class="form-group">
                    <label>{{ 'CONFIRM_PASSWORD' | translate }}<span class="required"> *</span></label>
                    <input
                      placeholder="{{ 'PASSWORD' | translate }}"
                      class="form-control"
                      [type]="showPassword ? 'text' : 'password'"
                      name="confirmPassword"
                      formControlName="confirmPassword"
                      [ngClass]="{
                        'is-invalid': submitted && f.confirmPassword?.errors
                      }"
                      [(ngModel)]="confirmPassword" />
                    <i
                      (click)="showPassword = !showPassword"
                      class="field-icon"
                      [ngClass]="showPassword ? ' bx bx-hide' : ' bx bx-show'"></i>
                    <div *ngIf="submitted && f.confirmPassword?.errors" class="invalid-feedback">
                      <div *ngIf="f.confirmPassword.errors.NoPassswordMatch" style="float: left; margin-bottom: 5px">
                        {{ 'PASSWORD_NOT_MATCH' | translate }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <button type="submit" class="default-btn">
                  <i class="bx bx-plus-circle icon-arrow before"></i><span class="label">{{ 'CHANGE_PASSWORD' | translate }}</span
                  ><i class="bx bx-plus-circle icon-arrow after"></i>
                </button>
              </div>
            </form>
          </div>
          <div class="billing-details" style="margin: 30px 30px">
            <h3 class="title">{{ 'REQUEST_DELETE_ACCOUNT' | translate }}</h3>
            <div>
              <button type="submit" (click)="deleteAccount()" class="btn btn-danger">
                <span class="label">{{ 'DELETE_MY_ACCOUNT' | translate }}</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>

<ng-template #confirmAction>
  <div class="modal-header">
    <h5 style="font-weight: bold" class="modal-title">
      {{ 'DELETE_ACCOUNT_CONFIRMATION' | translate }}
    </h5>
    <button type="button" class="close" aria-label="Close" (click)="close()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="alert alert-warning" role="alert">
      {{ 'DELETE_ACCOUNT_WARNING' | translate }}
      {{ 'DELETE_ACCOUNT_WARNING_2' | translate }}
    </div>
    <label>{{ 'TYPE_DELETE_MY_ACCOUNT_TO_CONFIRM' | translate }}</label>
    <input type="text" (input)="validateInput($event.target.value)" required class="form-control" />
  </div>
  <div class="form-group" style="padding: 0.5rem">
    <label>{{ 'DELETE_ACCOUNT_DESCRIPTION' | translate }}</label>
    <textarea rows="5" [ngModelOptions]="{ standalone: true }" [(ngModel)]="confirmDeleteInput" class="form-control"></textarea>
  </div>
  <div style="display: flex; flex-wrap: wrap; justify-content: flex-end">
    <button (click)="close()" class="btn btn-secondary mr-2 mb-3" type="button">
      <i class="fa fa-ban icons"></i> {{ 'CANCEL' | translate }}
    </button>
    <button (click)="confirmDeleteAction()" class="btn btn-danger mr-2 mb-3" type="button" [disabled]="!canSendRequest">
      <i class="fa fa-close"></i>{{ 'DELETE_ACCOUNT' | translate }}
    </button>
  </div>
</ng-template>
