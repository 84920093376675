import { ICity } from './city.model';
import { ICountry } from './country.model';
import { IState } from './state.model';

export interface IAddress {
  id?: string;
  state?: IState;
  city?: ICity;
  country?: ICountry;
  street?: string;
  firstName?: string;
  lastName?: string;
  phoneNumber?: string;
  zip?: string;
  longitude?: number;
  latitude?: number;
  email?: string;
}

export class Address implements IAddress {
  constructor(
    public id?: string,
    public state?: IState,
    public street?: string,
    public zip?: string,
    public firstName?: string,
    public lastName?: string,
    public phoneNumber?: string,
    public longitude?: number,
    public latitude?: number,
    public city?: ICity,
    public email?: string
  ) {}
}
