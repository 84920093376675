<footer class="footer-area">
  <div class="container">
    <div class="row">
      <div class="col-sm-6 col-md-4">
        <div class="single-footer-widget mb-30">
          <h3>{{ 'CONTACT_US' | translate }}</h3>
          <ul class="contact-us-link">
            <li>
              <i class="bx bx-map"></i>
              <p class="text-white cursor-default">19, Rue Missouri - Les Jardins d’El Menzah 2094 Tunis.</p>
            </li>
            <li>
              <i class="bx bx-phone-call"></i>
              <a href="tel:+21653355677">+216 53 35 56 77</a>
            </li>
          </ul>
          <ul class="social-link">
            <li>
              <a href="https://www.facebook.com/Pass-excellence-103342898924185" class="d-block" target="_blank" aria-label="Facebook"
                ><i class="bx bxl-facebook"></i
              ></a>
            </li>
            <li>
              <a href="https://www.instagram.com/passexcellence/" class="d-block" target="_blank" aria-label="Instagram"
                ><i class="bx bxl-instagram"></i
              ></a>
            </li>
            <li>
              <a href="https://www.linkedin.com/company/passexcellence/" class="d-block" target="_blank" aria-label="Linkedin"
                ><i class="bx bxl-linkedin"></i
              ></a>
            </li>
            <li>
              <a href="https://www.youtube.com/channel/UClwAabBDn0vnoFIgTGEPgSQ" class="d-block" target="_blank" aria-label="Youtube"
                ><i class="bx bxl-youtube"></i
              ></a>
            </li>
          </ul>
        </div>
      </div>
      <div class="col-sm-6 col-md-4">
        <div class="single-footer-widget mb-30">
          <h3>Support</h3>
          <ul class="support-link">
            <li>
              <a target="_blank" [routerLink]="termsOfUsePageUrl$ | async">{{ 'TERMS_OF_USE' | translate }} </a>
            </li>
            <li>
              <a target="_blank" [routerLink]="privacyPolicyPageUrl$ | async">{{ 'PRIVACY_POLICY' | translate }} </a>
            </li>

            <!-- <li><a routerLink="/">FAQ</a></li> -->
            <li>
              <a [routerLink]="contactUsPageUrl$ | async">{{ 'CONTACT_US' | translate }}</a>
            </li>
          </ul>
        </div>
      </div>
      <div class="col-sm-6 col-md-4">
        <div class="single-footer-widget mb-30">
          <h3>{{ 'USEFUL_LINKS' | translate }}</h3>
          <ul class="useful-link">
            <li>
              <a [routerLink]="aboutUsPageUrl$ | async">{{ 'WHO_WE_ARE' | translate }}</a>
            </li>
            <li>
              <a [routerLink]="corporateSellerListPageUrl$ | async">{{ 'OUR_PARTNERS' | translate }}</a>
            </li>
            <li>
              <a [routerLink]="blogListPageUrl$ | async">{{ 'BLOG' | translate }}</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <div class="footer-bottom-area">
    <div class="container">
      <div class="logo">
        <a routerLink="/" class="d-inline-block"
          ><img class="imgLogo" src="../../../../assets/img/logo.png" width="640" height="120" alt="logo"
        /></a>
      </div>
      <p>
        <i class="bx bx-copyright"></i>2023 <a routerLink="/" target="_blank">Pass excellence</a> | {{ 'ALL_RIGHTS_RESERVED' | translate }}.
      </p>
    </div>
  </div>
</footer>
<ngx-scrolltop></ngx-scrolltop>
