import { AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Gender, IGender } from '../../../models/gender.model';
import { UsersService } from '../../../services/users.service';
import { map, take, takeUntil } from 'rxjs/operators';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { Router } from '@angular/router';
import { Address, IAddress } from '../../../models/address.model';
import { City, ICity } from '../../../models/city.model';
import { Country, ICountry } from '../../../models/country.model';
import { MatChipInputEvent } from '@angular/material/chips';
import { ENTER } from '@angular/cdk/keycodes';
import { Beneficiary, IBeneficiary } from '../../../models/Beneficiary.model';
import { BeneficiaryService } from '../../../beneficiary-space/beneficiary.service';
import { IState } from '../../../models/state.model';
import { IExpectation } from '../../../shared/models/expectation.model';
import { ExpectationService } from '../../../services/expectation.service';
import { MediaService } from '../../../services/media.service';
import { SocialUser } from 'angularx-social-login';
import { LoginService } from '../../../services/auth/loginone.service';
import { LocalStorageService, SessionStorageService } from 'ngx-webstorage';
import { UserAccountService } from '../../../services/auth/user-account.service';
import { StateStorageService } from '../../../services/auth/state-storage.service';
import { UserFacebook } from '../../../models/user-facebook';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/store/app.reducer';
import { BENEFICIARY_ALREADY_EXIST_HTTP_ERROR_CODE, ENGLISH_UK, GENERIC_ERROR_MSG, PATTERN, TUNISIA } from '../../../shared/constants';
import { DynamicPathRoutingService } from '../../../services/dynamic-path-routing.service';
import { TERMS_AND_CONDITIONS_COMPONENT_NAME, TERMS_OF_USE_COMPONENT_NAME } from '../../../ComponentsIndex';
import { DynamicPathStorageService } from '../../../services/dynamic-path-storage.service';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from 'src/environments/environment';
import { CurrentLocaleService } from 'src/app/services/current-locale.service';
import { MatStepper } from '@angular/material/stepper';

@Component({
  selector: 'app-register-page',
  templateUrl: './register-page.component.html',
  styleUrls: ['./register-page.component.scss'],
})
export class RegisterPageComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() socialUser: SocialUser;
  @Input() fcmToken: string;
  private _isDead$ = new Subject();
  public countries: ICountry[];
  public countryT: ICountry = new Country();
  public cities: ICity[];
  public states: IState[];
  public genders: IGender[];
  public gender: IGender;
  public currentLocale: string;
  beneficiary: IBeneficiary;
  confirmPassword: string;
  error: boolean;
  title: string;
  address: IAddress;
  separatorKeysCodes: number[] = [ENTER];
  other: boolean;
  termAndCondition: boolean;
  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  expectations: IExpectation[] = [];
  dropdownSettings: IDropdownSettings = {};
  declarativeFormCaptchaValue;
  termsAndConditionsPageUrl$: Observable<string> = new Observable<string>();

  showPassword = false;
  submitted = false;
  submitted2 = false;
  submitted3 = false;
  pattern = PATTERN;
  signupForm: FormGroup;
  signup2Form: FormGroup;
  signup3Form: FormGroup;
  step1Complete = false;
  step2Complete = false;
  isEmailUsed = false;

  // protected nationalities: any[] = [];
  public nationalityMultiCtrl: FormControl = new FormControl();
  public nationalityMultiFilterCtrl: FormControl = new FormControl();
  public filteredNationalitiesMulti: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);

  readonly recaptchaSiteKey = environment.recaptchaSiteKey;
  recaptchaLocale = 'fr';

  @ViewChild('stepper', { static: true }) stepper: MatStepper;
  @ViewChild('multiSelect', { static: true }) multiSelect: MatSelect;

  constructor(
    private usersService: UsersService,
    private formBuilder: FormBuilder,
    private beneficiaryService: BeneficiaryService,
    private router: Router,
    private toastr: ToastrService,
    private expectationService: ExpectationService,
    private mediaService: MediaService,
    private loginService: LoginService,
    private sessionStorage: LocalStorageService,
    private userAccountService: UserAccountService,
    private stateStorageService: StateStorageService,
    private store: Store<AppState>,
    private currentLocaleService: CurrentLocaleService,
    private translate: TranslateService,
    private dynamicPathRoutingService: DynamicPathRoutingService,
    private dynamicPathStorageService: DynamicPathStorageService,
    private spinner: NgxSpinnerService
  ) {
    this.currentLocaleService.currentLocaleId$.subscribe(localeId => {
      this.currentLocale = localeId;
      this.setRecaptchaLocale();
    });
    const authenticationToken = localStorage.getItem('authenticationToken');
    if (authenticationToken) {
      this.router.navigate(['']);
    }
  }

  setRecaptchaLocale() {
    if (this.currentLocale === ENGLISH_UK) {
      this.recaptchaLocale = 'en';
    } else {
      this.recaptchaLocale = 'fr';
    }
  }

  ngOnInit(): void {
    this.initializeNationalitiesField();
    this.store
      .select('beneficiaryState', 'beneficiary')
      .pipe(takeUntil(this._isDead$))
      .subscribe(beneficiary => {
        if (beneficiary != null) {
          this.router.navigate(['']);
        }
      });
    this.beneficiary = new Beneficiary();
    if (this.socialUser && this.socialUser.id) {
      this.beneficiary.firstName = this.socialUser.firstName;
      this.beneficiary.facebookId = this.socialUser.id;
      this.beneficiary.lastName = this.socialUser.lastName;
    }
    this.beneficiary.addresses = [];
    this.beneficiary.nationalities = [];
    this.gender = new Gender();
    this.address = new Address();
    this.address.city = new City();
    this.address.city.country = new Country();
    this.address.city.country.name = {};
    this.address.city.country.name.localizedValues = {};
    this.usersService
      .findAllCities()
      .pipe(takeUntil(this._isDead$))
      .subscribe(cities => (this.cities = cities));
    this.usersService
      .getCountries()
      .pipe(takeUntil(this._isDead$))
      .subscribe(countries => {
        this.countries = countries;
        this.nationalityMultiCtrl.setValue([]);
        this.filteredNationalitiesMulti.next(this.countries.slice());
      });
    this.usersService
      .findAllStates()
      .pipe(takeUntil(this._isDead$))
      .subscribe(states => (this.states = states));
    this.usersService
      .findCountry(TUNISIA)
      .pipe(takeUntil(this._isDead$))
      .subscribe(countryT => (this.countryT.name = countryT.name));
    this.usersService
      .findAllGendersByLocale()
      .pipe(takeUntil(this._isDead$))
      .subscribe(genders => (this.genders = genders));
    this.expectationService.query().subscribe(res => (this.expectations = res.json));
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'name',
      selectAllText: this.translate.instant('SELECT_ALL'),
      unSelectAllText: this.translate.instant('UNSELECT_ALL'),
    };
    this.termsAndConditionsPageUrl$ = this.dynamicPathStorageService.urlPatterns$.pipe(
      takeUntil(this._isDead$),
      map(() => {
        const urlPattern = this.dynamicPathStorageService.getUrlPatternByComponentNameFromCmsPageList(TERMS_OF_USE_COMPONENT_NAME);
        if (!urlPattern) {
          return null;
        }
        return urlPattern.startsWith('/') ? urlPattern : '/' + urlPattern;
      })
    );
    this.signupForm = this.socialUser ? this.initializeFacebookSignupForm() : this.initializeRegularSignupForm();

    this.signup2Form = this.formBuilder.group({
      birthdate: ['', Validators.required],
      phoneNumber: ['', [Validators.required, Validators.minLength(8)]],
      phoneNumber2: ['', [Validators.minLength(8)]],
    });

    this.signup3Form = this.formBuilder.group({
      acceptCgu: [false, Validators.requiredTrue],
      captcha: [false],
    });

    this.signupForm
      .get('email')
      .valueChanges.pipe(takeUntil(this._isDead$))
      .subscribe(() => (this.isEmailUsed = false));

    this.signup2Form.statusChanges.subscribe(state => {
      this.step2Complete = state == 'VALID';
    });
  }

  initializeRegularSignupForm() {
    return this.formBuilder.group(
      {
        firstName: ['', Validators.required],
        lastName: ['', Validators.required],
        // email: ["", [Validators.required, Validators.email], [this.usersService.emailValidator()]],
        email: ['', [Validators.required, Validators.email]],
        password: ['', [Validators.required, Validators.pattern(this.pattern)]],
        confirmPassword: ['', Validators.compose([Validators.required])],
      },
      {
        validator: this.passwordMatchValidator,
      }
    );
  }

  initializeFacebookSignupForm() {
    return this.formBuilder.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
    });
  }

  ngAfterViewInit() {
    this.setInitialValue();
    this.stepper.selectionChange.pipe(takeUntil(this._isDead$)).subscribe(selection => {
      if (selection.selectedIndex === 0) {
        this.step1Complete = false;
      } else if (selection.selectedIndex === 1) {
        this.step2Complete = false;
      }
    });
  }

  updateBeneficiaryNationalitiesList() {
    const selectedNationalities: [] = this.nationalityMultiCtrl.value;
    this.beneficiary.nationalities = selectedNationalities.map((country: any) => {
      const name = new Map<string, string>();
      name.set(this.currentLocale, country.name);
      return {
        id: country.id,
        name: {
          localizedValues: name,
        },
      };
    });
  }

  initializeNationalitiesField() {
    this.nationalityMultiCtrl.setValue([]);
    const countryList = this.countries && this.countries.length > 0 ? this.countries.slice() : [];
    this.filteredNationalitiesMulti.next(countryList);
    this.nationalityMultiFilterCtrl.valueChanges.pipe(takeUntil(this._isDead$)).subscribe(() => {
      this.filterNationalitiesMulti();
    });
  }

  protected setInitialValue() {
    this.filteredNationalitiesMulti.pipe(take(1), takeUntil(this._isDead$)).subscribe(() => {
      this.multiSelect.compareWith = (a: any, b: any) => a && b && a.id === b.id;
    });
  }

  protected filterNationalitiesMulti() {
    if (!this.countries) {
      return;
    }
    // get the search keyword
    let search = this.nationalityMultiFilterCtrl.value;
    if (!search) {
      this.filteredNationalitiesMulti.next(this.countries.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    this.filteredNationalitiesMulti.next(this.countries.filter(country => country.name.toLowerCase().indexOf(search) > -1));
  }

  validateFirstStepForm() {
    this.submitted = true;
    this.usersService.checkIfUserExistsByLogin(this.signupForm.get('email').value).subscribe(
      res => {
        this.isEmailUsed = res;
        if (this.isEmailUsed) {
          return;
        }
        if (this.signupForm.invalid) {
          return;
        }
        this.step1Complete = true;
        setTimeout(() => {
          this.stepper.next();
        });
      },
      () => {
        this.toastr.error(this.translate.instant(GENERIC_ERROR_MSG));
      }
    );
  }

  validateStepForm2() {
    this.submitted2 = true;
    if (this.signup2Form.invalid) {
      return;
    }
    this.step2Complete = true;
    setTimeout(() => {
      this.stepper.next();
    });
  }

  passwordMatchValidator(control: AbstractControl) {
    const password: string = control.get('password')?.value;
    const confirmPassword: string = control.get('confirmPassword')?.value;
    if (password !== confirmPassword) {
      control.get('confirmPassword')?.setErrors({ NoPassswordMatch: true });
    } else {
      control.get('confirmPassword')?.setErrors(null);
    }
  }

  get f() {
    return this.signupForm?.controls;
  }

  get f2() {
    return this.signup2Form?.controls;
  }
  get f3() {
    return this.signup3Form?.controls;
  }

  ngOnDestroy(): void {
    this._isDead$.next();
    this._isDead$.complete();
  }

  register() {
    this.submitted3 = true;
    if (this.signup3Form.invalid || this.signup2Form.invalid || this.signupForm.invalid || !this.declarativeFormCaptchaValue) {
      return;
    }
    if (this.gender.id) {
      this.beneficiary.genderId = this.gender.id;
    }
    this.beneficiary.login = this.beneficiary.email;
    // for (let i = 0; i < this.beneficiary.nationalities.length; i++) {
    //   let value = new Country();
    //   value.name = {};
    //   value.name.localizedValues = {};
    //   value.name.localizedValues[this.currentLocale] = this.beneficiary.nationalities[i].name;
    //   value.id = this.beneficiary.nationalities[i].id;
    //   this.beneficiary.nationalities[i] = value;
    // }
    this.beneficiary.addresses.push(this.address);
    this.beneficiary.login = this.beneficiary.email;
    if (this.beneficiary.facebookId) {
      this.registerWithFb();
    } else {
      this.beneficiary.hasAcceptedTermsOfUse = true;
      this.spinner.show();
      this.beneficiaryService.register(this.beneficiary).subscribe(
        () => {
          this.spinner.hide();
          this.toastr.success(this.translate.instant('SUCCESS_REGISTER'));
          this.router.navigate(['/login']);
        },
        error => {
          this.spinner.hide();
          this.error = true;
          this.title = error?.error?.errorKey;
          if (error.error.errorType === BENEFICIARY_ALREADY_EXIST_HTTP_ERROR_CODE) {
            this.title = this.translate.instant(BENEFICIARY_ALREADY_EXIST_HTTP_ERROR_CODE);
          } else if (!this.title) {
            this.title = this.translate.instant('GENERIC_ERROR_MSG');
          }
        }
      );
    }
  }

  public add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;
    if ((value || '').trim()) {
      this.beneficiary.personalInterests.push(value.trim());
    }
    if (input) {
      input.value = '';
    }
  }

  public remove(personalInterest): void {
    const index = this.beneficiary.personalInterests.indexOf(personalInterest);
    if (index >= 0) {
      this.beneficiary.personalInterests.splice(index, 1);
    }
  }

  public addOtherExpectation(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;
    if ((value || '').trim()) {
      this.beneficiary.otherExpectations.push(value.trim());
    }
    if (input) {
      input.value = '';
    }
  }

  public removeOtherExpectation(personalInterest): void {
    const index = this.beneficiary.otherExpectations.indexOf(personalInterest);
    if (index >= 0) {
      this.beneficiary.otherExpectations.splice(index, 1);
    }
  }
  getImage(image: any, format: string | null): any {
    if (!image) {
      return;
    }
    return this.mediaService.downloadMedia(image, format);
  }

  elementExist(expectationTest: IExpectation): boolean {
    return this.beneficiary.expectations.indexOf(expectationTest) > -1;
  }
  addOrRemove(expectationTest: IExpectation) {
    if (this.elementExist(expectationTest)) {
      this.beneficiary.expectations = this.beneficiary.expectations.filter(expectation => expectation.id !== expectationTest.id);
    } else {
      this.beneficiary.expectations.push(expectationTest);
    }
  }

  public registerWithFb() {
    const tokenFace = this.socialUser.authToken;
    const userFaceBook = new UserFacebook();
    userFaceBook.jwtToken = tokenFace;
    userFaceBook.fcmToken = this.fcmToken;
    userFaceBook.userVM = this.beneficiary;
    this.spinner.show();
    this.loginService
      .loginWithFB(userFaceBook)
      .then(() => {
        this.spinner.hide();
        this.userAccountService.identity().then(account => {
          this.sessionStorage.store('user', account);
          this.userAccountService.setCanPurchase();
        });
        const redirect = this.stateStorageService.getUrl();
        if (
          this.router.url === '/register' ||
          this.router.url === '/login' ||
          /^\/activate\//.test(this.router.url) ||
          /^\/reset\//.test(this.router.url)
        ) {
          redirect ? this.router.navigate([redirect]) : this.router.navigate(['beneficiary-space']);
        }

        if (redirect) {
          this.stateStorageService.storeUrl(null);
          this.router.navigate([redirect]);
        }
      })
      .catch(() => {
        this.spinner.hide();
      });
  }
}
