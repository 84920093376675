<div class="page-title-area item-bg1">
  <div class="container">
    <div class="page-title-content">
      <ul>
        <li>
          <a href="../../../index.html">{{ 'HOME' | translate }}</a>
        </li>
        <li>{{ 'OUR_PARTNERS_ITEM' | translate }}</li>
      </ul>
      <h2>{{ 'CORPORATE_SELLERS' | translate }}</h2>
    </div>
  </div>
</div>

<div class="events-area pt-100 pb-70">
  <div class="container">
    <ng-container>
      <ng-container *ngFor="let corporateSeller of corporateSellers">
        <hro-corporate-seller-block
          *ngIf="corporateSeller.id !== PASSEXCELLECE_ID"
          [currentLocaleId]="currentLocaleId"
          [corporateSellerDetailsUrlPattern]="corporateSellerDetailsPageUrlPattern"
          [corporateSeller]="corporateSeller">
        </hro-corporate-seller-block>
      </ng-container>
    </ng-container>

    <div class="no-data" *ngIf="!isFetchingData && !corporateSellers.length">
      {{ 'NO_CORPORATE_SELLER_FOUND' | translate }}
    </div>
    <div class="row" #showMore>
      <!--            <div class="col-sm-9 col-md-6 col-lg-4 mx-auto" *ngIf="hasMoreSellersToShow && !isFetchingData && false">-->
      <!--                <p class="alert alert-light bg-light-2 text-center radius-1 h6 cursor-pointer" (click)="showMoreSellers()">{{"SHOW_MORE" | translate}}</p>-->
      <!--            </div>-->
      <div class="col-sm-9 col-md-6 col-lg-4 mx-auto" *ngIf="isFetchingData">
        <p class="alert alert-light bg-light-2 text-center radius-1 h6">
          {{ 'LOADING_DATA' | translate }}
        </p>
      </div>
    </div>
    <!--        <div class="row" *ngIf="hasMoreSellersToShow && !isFetchingData">-->
    <!--            <div class="col-sm-9 col-md-6 col-lg-4 mx-auto">-->
    <!--                <p class="alert alert-light bg-light-2 text-center radius-1 h6 cursor-pointer" (click)="showMoreSellers()">{{"SHOW_MORE" | translate}}</p>-->
    <!--            </div>-->
    <!--        </div>-->
    <!--        <div class="row" *ngIf="isFetchingData">-->
    <!--            <div class="col-sm-9 col-md-6 col-lg-4 mx-auto">-->
    <!--                <p class="alert alert-light bg-light-2 text-center radius-1 h6">{{"LOADING_DATA" | translate}}</p>-->
    <!--            </div>-->
    <!--        </div>-->
  </div>
</div>
