import { ICountry, Country } from './country.model';

export interface ICity {
  id?: string;
  code?: string;
  name?: string;
  country?: ICountry;
}

export class City implements ICity {
  constructor(public id?: string, public code?: string, public name?: string, public country?: ICountry) {
    this.country = new Country();
  }
}
