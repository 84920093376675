<div class="instructor-area pt-40 pb-40" *ngFor="let cmsComponentContent of data">
  <div class="container">
    <div class="section-title">
      <h2>{{ cmsComponentContent.title }}</h2>
      <p>{{ cmsComponentContent.description }}</p>
    </div>

    <div class="coache-slides mb-4">
      <owl-carousel-o [options]="coachSlides">
        <ng-template carouselSlide *ngFor="let cmsEntity of cmsComponentContent.elements">
          <div class="mw-90 h-100 pb-4 pt-3">
            <a
              [routerLink]="
                coachDetailsPageUrlPattern ? (coachDetailsPageUrlPattern | seoNameUrl : cmsEntity.seoData : currentLocale) : null
              "
              class="w-100">
              <img
                class="m-auto rounded-circle border border-white"
                [src]="getImage(cmsEntity.user?.photo?.id || cmsEntity.photoId, null)"
                *ngIf="cmsEntity.photoId; else noImage"
                style="height: 120px; width: 120px"
                [alt]="cmsEntity.user?.photo?.alt ?? cmsEntity.name" />
            </a>
            <h5 class="pt-3">
              <a
                [routerLink]="
                  coachDetailsPageUrlPattern ? (coachDetailsPageUrlPattern | seoNameUrl : cmsEntity.seoData : currentLocale) : null
                "
                >{{ cmsEntity.name }}</a
              >
            </h5>
          </div>
        </ng-template>
      </owl-carousel-o>
    </div>
  </div>
</div>
<ng-template #noImage>
  <img
    class="m-auto rounded-circle border border-white"
    style="height: 120px; width: auto"
    src="assets/img/instructor1.webp"
    style="height: 120px; width: 120px"
    alt="image" />
</ng-template>
