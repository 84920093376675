<div class="testimonials-area ptb-100" *ngFor="let cmsComponentContent of data">
  <div class="container">
    <div class="section-title">
      <h2>{{ cmsComponentContent.title }}</h2>
      <p>{{ cmsComponentContent.description }}</p>
    </div>
    <div class="testimonials-slides">
      <owl-carousel-o [options]="testimonialsSlides">
        <ng-template carouselSlide *ngFor="let cmsEntity of cmsComponentContent.elements">
          <div class="single-testimonials-item">
            <p>{{ cmsEntity.content | slice : 0 : 100 }}...</p>
            <div class="info">
              <img [src]="getImage(cmsEntity.photoId, null)" onerror="this.src='assets/img/not-found.png'" [alt]="cmsEntity.name" />
              <h3>{{ cmsEntity.name }}</h3>
              <!--
                            <span>Student</span>
-->
            </div>
          </div>
        </ng-template>
      </owl-carousel-o>
    </div>
  </div>
</div>
