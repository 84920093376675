<ng-container *ngIf="banner && banner.id">
  <ng-container *ngIf="pageCode === pageCodeEntreprise">
    <div
      class="hero-banner bg-success py-5 entreprise-first-section"
      style="background: linear-gradient(to right, var(--optionalColor), var(--mainColor))">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-lg-6">
            <div class="hero-banner-content">
              <h2 class="text-white text-left d-none d-md-block top-title">
                {{ currentLocaleContent.title }}
              </h2>
              <h2 class="text-white text-center d-md-none top-title">
                {{ currentLocaleContent.title }}
              </h2>
            </div>
          </div>

          <div class="col-lg-6">
            <div class="hero-banner-image text-center">
              <img class="d-none d-md-inline" style="max-width: 70%" alt="image" src="assets/img/entreprise.webp" />
              <img class="d-md-none" style="max-width: 85%" alt="image" src="assets/img/entreprise.webp" />
            </div>

            <div class="hero-banner-image text-center">&nbsp;</div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="pageCode === pageCodeParticular">
    <div class="hero-banner" style="background: linear-gradient(to right, var(--optionalColor), var(--mainColor))">
      <div class="d-table">
        <div class="d-table-cell">
          <div class="container-fluid">
            <div class="row align-items-center">
              <div class="col-lg-6">
                <div class="hero-banner-content">
                  <h2 class="text-left d-none d-lg-block top-title">
                    {{ currentLocaleContent.title }}
                  </h2>
                  <h2 class="text-center d-lg-none top-title">
                    {{ currentLocaleContent.title }}
                  </h2>
                  <p>{{ currentLocaleContent.content }}</p>
                  <div class="btn-box">
                    <a class="default-btn" routerLink="/register"
                      ><span class="label">{{ 'GET_STARTED' | translate }}</span></a
                    >
                  </div>
                </div>
              </div>
              <div class="col-lg-6">
                <div class="hero-banner-image text-center">
                  <img alt="image" src="assets/img/particular-img.webp" class="mw-90" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</ng-container>
