<div class="page-title-area item-bg1">
  <div class="container">
    <div class="page-title-content">
      <ul>
        <li>
          <a href="index.html">{{ 'HOME' | translate }}</a>
        </li>
        <li>{{ 'ERROR' | translate }}</li>
      </ul>
      <h2>{{ 'ERROR' | translate }} 404</h2>
    </div>
  </div>
</div>

<div class="error-404-area">
  <div class="container">
    <div class="notfound">
      <div class="notfound-bg">
        <div></div>
        <div></div>
        <div></div>
      </div>
      <h1>Oops!</h1>
      <h3>{{ 'ERROR' | translate }} 404 : {{ 'PAGE_NOT_FOUND' | translate }}</h3>
      <p>{{ 'PAGE_NOT_FOUND_ERROR_MESSAGE' | translate }}.</p>
      <a routerLink="/" class="default-btn"
        ><i class="bx bx-home-circle icon-arrow before"></i><span class="label">{{ 'BACK_HOME' | translate }}</span
        ><i class="bx bx-home-circle icon-arrow after"></i
      ></a>
    </div>
  </div>
</div>
