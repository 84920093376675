import { Component, OnDestroy, OnInit } from '@angular/core';
import { CategoryService } from 'src/app/services/category.service';
import { PRODUCT_CATEGORY } from '../../shared/constants';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { PRESTATION_COMPONENT_NAME } from '../../ComponentsIndex';
import { DynamicPathStorageService } from '../../services/dynamic-path-storage.service';

@Component({
  selector: 'hro-categories',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.scss'],
})
export class CategoryComponent implements OnInit, OnDestroy {
  categories = [];
  categoryId: any;
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  productListByCategoryPageUrl: string;

  constructor(private categoryService: CategoryService, private dynamicPathStorageService: DynamicPathStorageService) {
    this.categoryService.sharedCategoryId.subscribe(value => (this.categoryId = value));
  }

  ngOnInit(): void {
    this.getCategories();

    this.dynamicPathStorageService.urlPatterns$.pipe(takeUntil(this.destroyed$)).subscribe(() => {
      const urlPattern = this.dynamicPathStorageService.getUrlPatternByComponentNameFromCmsPageList(PRESTATION_COMPONENT_NAME);
      if (!urlPattern) {
        this.productListByCategoryPageUrl = null;
      } else {
        this.productListByCategoryPageUrl = urlPattern.startsWith('/') ? urlPattern : '/' + urlPattern;
      }
    });
  }

  getCategories() {
    this.categoryService.getCategoriesByParentId(PRODUCT_CATEGORY).subscribe(res => {
      this.categories = res;
    });
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
