import { Employments } from './employments.model';
import { BeneficiaryDevelopmentAxis } from './BeneficiaryDevelopmentAxisDTO.model';
import { Customer, ICustomer } from './custom-user.model';
import { NovaProfile } from './novaProfile.model';
import { Media } from './media.model';
import { IBadgeWinning } from './badgeWinning.model';

export interface IBeneficiary extends ICustomer {
  passExcellencePassportNumber?: string;
  loyaltyScore?: number;
  employments?: Employments[];
  beneficiaryDevelopmentAxises?: BeneficiaryDevelopmentAxis[];
  novaProfiles?: NovaProfile[];
  medias?: Media[];
  member?: boolean;
  personalInterests?: string[];
  otherExpectations?: string[];
  expectations?: any[];
  badgeWinning?: IBadgeWinning[];
  hasAcceptedTermsOfUse?: boolean;
  deleted?: boolean;
  qrCode?: string;
}

export class Beneficiary extends Customer implements IBeneficiary {
  constructor(
    public passExcellencePassportNumber?: string,
    public loyaltyScore?: number,
    public employments?: Employments[],
    public beneficiaryDevelopmentAxises?: BeneficiaryDevelopmentAxis[],
    public novaProfiles?: NovaProfile[],
    public medias?: Media[],
    public member?: boolean,
    public personalInterests?: string[],
    public otherExpectations?: string[],
    public expectations?: any[],
    public deleted?: boolean,
    public qrCode?: string
  ) {
    super();
    this.personalInterests = [];
    this.otherExpectations = [];
    this.expectations = [];
  }
}
