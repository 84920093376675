<ngx-spinner bdColor="rgba(51,51,51,0.8)" color="#fff" size="medium" type="line-scale"></ngx-spinner>

<div class="page-title-area item-bg1">
  <div class="container">
    <div class="page-title-content">
      <ul>
        <li>
          <a href="index.html"> {{ 'HOME' | translate }} </a>
        </li>
        <li>Checkout</li>
      </ul>
      <h2>Checkout</h2>
    </div>
  </div>
</div>

<div class="checkout-area ptb-100">
  <div class="container" *ngIf="cart">
    <form>
      <div class="row">
        <div class="col-lg-6 col-md-12">
          <div class="billing-details">
            <h3 class="title">{{ 'BILLING_DETAILS' | translate }}</h3>
            <div class="row">
              <div class="col-lg-12 col-md-12">
                <div class="form-group">
                  <label>{{ 'COUNTRY' | translate }} <span class="required">*</span></label>
                  <!--                                    <div class="select-box">-->
                  <!--                                        <select class="form-select" [(ngModel)]="selectedCountry" name="country"-->
                  <!--                                            [ngClass]="{'mandatory': !selectedCountry && hasClickedPlaceOrder}">-->
                  <!--                                            <option *ngFor="let country of countries" [ngValue]="country">{{-->
                  <!--                                                country.name }}</option>-->
                  <!--                                        </select>-->
                  <!--                                    </div>-->
                  <mat-form-field [floatLabel]="'never'">
                    <mat-select
                      panelClass="mat-select-panel-class"
                      [(ngModel)]="selectedCountry"
                      [placeholder]="'COUNTRY' | translate"
                      name="country">
                      <mat-option>
                        <ngx-mat-select-search
                          [formControl]="countryFilterCtrl"
                          [placeholderLabel]="'COUNTRY' | translate"
                          [noEntriesFoundLabel]="'NO_COUNTRY_FOUND' | translate"></ngx-mat-select-search>
                      </mat-option>
                      <mat-option *ngFor="let country of filteredCountries | async" [value]="country">
                        {{ country.name }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>
              <div class="col-lg-12 col-md-6">
                <div class="form-group">
                  <label>{{ 'ADDRESS' | translate }} <span class="required">*</span></label>
                  <input
                    type="text"
                    class="form-control"
                    [(ngModel)]="address.street"
                    name="street"
                    [ngClass]="{
                      mandatory: !address.street && hasClickedPlaceOrder
                    }" />
                </div>
              </div>
              <div class="col-lg-12 col-md-6">
                <div class="form-group">
                  <label>{{ 'CITY' | translate }}</label>
                  <input type="text" class="form-control" />
                </div>
              </div>
              <div class="col-lg-6 col-md-6">
                <div class="form-group">
                  <label>{{ 'STATE' | translate }}</label>
                  <input type="text" class="form-control" />
                </div>
              </div>
              <div class="col-lg-6 col-md-6">
                <div class="form-group">
                  <label>{{ 'ZIP' | translate }} <span class="required">*</span></label>
                  <input
                    type="text"
                    class="form-control"
                    [(ngModel)]="address.zip"
                    name="zipcode"
                    [ngClass]="{
                      mandatory: !address.zip && hasClickedPlaceOrder
                    }" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-md-12">
          <div class="order-details">
            <h3 class="title">{{ 'YOUR_ORDER' | translate }}</h3>
            <div class="order-table table-responsive">
              <table class="table table-bordered">
                <thead>
                  <tr>
                    <th scope="col">{{ 'PRODUCT_NAME' | translate }}</th>
                    <th scope="col">{{ 'TOTAL' | translate }}</th>
                  </tr>
                </thead>
                <tbody style="display: table-row-group">
                  <ng-container *ngFor="let orderLine of cart.cartLines">
                    <tr *ngIf="orderLine.sellerId == PASSEXCELLENCE_ID">
                      <td class="product-name">
                        <a
                          [routerLink]="productDetailsUrlPattern | seoNameUrl : orderLine.seoData : currentLocaleId"
                          *ngIf="orderLine.productType === PRODUCT_PRODUCT_TYPE; else packDetails">
                          {{ orderLine.productName }}
                        </a>
                        <ng-template #packDetails>
                          <a [routerLink]="packDetailsUrlPattern | seoNameUrl : orderLine.seoData : currentLocaleId">
                            {{ orderLine.productName }}
                          </a>
                        </ng-template>
                      </td>
                      <td class="product-total">
                        <span class="subtotal-amount"
                          >{{
                            orderLine.totalPrice
                              | currency : orderLine?.currencyId : '' : '0.' + (orderLine?.numberOfDigistAfterDecimalsign || 3) + '-3'
                          }}
                          {{ orderLine?.currencyId }}</span
                        >
                      </td>
                    </tr>
                  </ng-container>

                  <tr>
                    <td class="order-subtotal">
                      <span> {{ 'SUBTOTAL' | translate }} </span>
                    </td>
                    <td class="order-subtotal-price">
                      <span class="order-subtotal-amount">{{ cart.subTotal | number : '0.3-3' }} TND</span>
                    </td>
                  </tr>
                  <tr>
                    <td class="total-price">
                      <span> {{ 'ORDER_TOTAL' | translate }} </span>
                    </td>
                    <td class="product-subtotal">
                      <span class="subtotal-amount">{{ cart.total | number : '0.3-3' }} TND</span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="payment-box">
              <div class="payment-method" *ngIf="cart?.total > 0">
                <p *ngFor="let paymentMode of filtredPaymentModes">
                  <input
                    type="radio"
                    id="{{ paymentMode.id }}"
                    name="{{ paymentMode.name }}"
                    [value]="paymentMode"
                    [(ngModel)]="selectedPaymentMode" />
                  <label for="{{ paymentMode.id }}">
                    {{ paymentMode.name }}
                  </label>
                </p>
              </div>
              <button type="submit" class="default-btn" (click)="placeOrder()">
                <i class="bx bx-cart icon-arrow before"></i>
                <span class="label"> {{ 'PLACE_ORDER' | translate }} </span>
                <i class="bx bx-cart icon-arrow after"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
