import { Routes } from '@angular/router';

export const blogRoutes: Routes = [
  //     {
  //     path: '',
  //     redirectTo: 'blog',
  //     pathMatch: 'full',
  // },
  //     {
  //         path: '',
  //         component: BlogListComponent
  //     },
  //     {
  //         path: '',
  //         component: BlogListComponent
  //     },
  //     {
  //         path: 'details/:id',
  //         component: BlogDetailsComponent
  //     }
];
