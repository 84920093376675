import { ResponseWrapper } from 'src/app/models/response-wrapper.model';

export const convertResponse = (res: any): ResponseWrapper => {
  const jsonResponse = res.body;
  const result = [];
  for (const iterator of jsonResponse) {
    result.push(convertItemFromServer(iterator));
  }
  return new ResponseWrapper(res.headers, result, res.status);
};

const convertItemFromServer = (json: any): Object => {
  const entity: Object = Object.assign(new Object(), json);
  return entity;
};
