import { IMedia } from './media.model';
import { IAddress } from './address.model';

export interface IIndividualSeller {
  id?: string;
  code?: string;
  userId?: string;
  firstName?: string;
  lastName?: string;
  login?: string;
  email?: string;
  password?: string;
  phoneNumber1?: string;
  phoneNumber2?: string;
  birthDate?: string;
  genderId?: string;
  photo?: IMedia;
  defaultDeliveryAddress?: IAddress;
  defaultPaymentAddress?: IAddress;
  addresses?: IAddress[];
  authorities?: string[];
  activated?: boolean;
  qrCode?: string;
}

export class IndividualSeller implements IIndividualSeller {
  constructor(
    public id?: string,
    public code?: string,
    public firstName?: string,
    public lastName?: string,
    public email?: string,
    public password?: string,
    public phoneNumber1?: string,
    public phoneNumber2?: string,
    public photo?: IMedia,
    public genderId?: string,
    public login?: string,
    public addresses?: IAddress[],
    public authorities?: string[],
    public userId?: string,
    public activated?: boolean,
    public qrCode?: string
  ) {
    this.activated = false;
  }
}
