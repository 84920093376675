import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { CmsService } from '../../cms.service';
import { Store } from '@ngrx/store';
import { AppState } from '../../../store/app.reducer';
import { AppStateService } from 'src/app/services/utils/state.service';
import { LOCALE_STATE, LOCALES } from '../../../shared/constants';
import { DynamicPathRoutingService } from '../../../services/dynamic-path-routing.service';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { PRODUCT_JOURNEY_TO_EXCELLENCE_COMPONENT_NAME } from '../../../ComponentsIndex';
import { DynamicPathStorageService } from '../../../services/dynamic-path-storage.service';

@Component({
  selector: 'app-hro-journey-to-excellence',
  templateUrl: './hro-journey-to-excellence.component.html',
  styleUrls: ['./hro-journey-to-excellence.component.scss'],
})
export class HroJourneyToExcellenceComponent implements OnInit, OnDestroy {
  @Input()
  data: any;
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  journeyToExcellenceProductDetailsPageUrl: string;

  public currentLocale: string;
  constructor(
    private cmsService: CmsService,
    private appStateService: AppStateService,
    private store: Store<AppState>,
    private dynamicPathRoutingService: DynamicPathRoutingService,
    private dynamicPathStorageService: DynamicPathStorageService
  ) {}

  ngOnInit(): void {
    this.store.select(LOCALE_STATE, LOCALES).subscribe(() => {
      this.currentLocale = this.appStateService.getCurrentLocaleId();
    });
    this.dynamicPathStorageService.urlPatterns$.pipe(takeUntil(this.destroyed$)).subscribe(() => {
      this.journeyToExcellenceProductDetailsPageUrl = this.dynamicPathStorageService.getUrlPatternByComponentNameFromCmsPageList(
        PRODUCT_JOURNEY_TO_EXCELLENCE_COMPONENT_NAME
      );
    });
  }

  getImage(image: any, format: string | null): any {
    if (!image) {
      return;
    }
    return this.cmsService.downloadMedia(image, format);
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
