<div class="login-area">
  <div class="row m-0">
    <div class="col-lg-6 col-md-12 p-0">
      <div class="login-image">
        <img class="page-cover" src="assets/img/login-bg.jpg" alt="image" />
      </div>
    </div>
    <div class="col-lg-6 col-md-12 p-0">
      <div class="login-content">
        <div class="d-table">
          <div class="d-table-cell">
            <div class="login-form">
              <div class="logo">
                <a routerLink="/"><img src="../../../../assets/img/black-logo.png" class="imgLogo" alt="image" /></a>
              </div>
              <h4>{{ 'RECOVER_PASSWORD' | translate }}</h4>
              <form>
                <div class="form-group">
                  <input
                    type="email"
                    email
                    required
                    [formControl]="emailFormControl"
                    placeholder="{{ 'EMAIL' | translate }}"
                    class="form-control" />
                  <span class="text-danger" *ngIf="!emailFormControl.valid && hasSubmitted">{{ 'VALID_EMAIL_PLEASE' | translate }}</span>
                </div>
                <button type="submit" (click)="forgetPassword()">
                  {{ 'SEND' | translate }}
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
