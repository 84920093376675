import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { StateStorageService } from './state-storage.service';
import { UserAccountService } from './user-account.service';

@Injectable()
export class UserRouteAccessService implements CanActivate {
  constructor(
    private router: Router,
    private principal: UserAccountService,
    private stateStorageService: StateStorageService,
    @Inject(PLATFORM_ID) private platformId
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Promise<boolean> {
    const authorities = route.data.authorities;
    return this.checkLogin(authorities, state.url);
  }

  checkLogin(authorities: string[], url: string): Promise<boolean> | boolean {
    const principal = this.principal;
    const token = localStorage.getItem('authenticationToken') ;
    const isAuthenticated = this.principal.isAuthenticated() != null ? this.principal.isAuthenticated() : token != null;
    if (isAuthenticated) {
      return Promise.resolve(
        principal.identity().then(account => {
          if (!authorities || authorities.length === 0) {
            if (url.includes('register') && account) {
              this.principal.redirectToSpace();
            }
            return true;
          }
          if (account) {
            if (url.includes('register')) {
              this.router.navigate(['']);
              return true;
            }
            return principal.hasAnyAuthority(authorities).then(response => {
              if (response) {
                return true;
              } else {
                this.router.navigate(['/login']);
                return false;
              }
            });
          }

          this.stateStorageService.storeUrl(url);
          this.router.navigate(['accessdenied']).then(() => {
            if (!account) {
              this.router.navigate(['/login']);
            }
          });
          return false;
        })
      );
    } else if (url.includes('register')) {
      return true;
    }
    this.router.navigate(['/login']);
    return isAuthenticated;
  }
}
