<div class="page-title-area item-bg3">
  <div class="container">
    <div class="page-title-content">
      <ul>
        <li>
          <a href="index.html">{{ 'HOME' | translate }}</a>
        </li>
        <li>{{ 'BLOG' | translate }}</li>
      </ul>
      <h2>{{ blogPost.title }}</h2>
    </div>
  </div>
</div>

<div class="blog-details-area ptb-100 mb-5">
  <div class="container">
    <div class="row">
      <div class="col-lg-8 col-md-12">
        <div class="blog-details-desc">
          <div *ngIf="blogPost.thumbnail && blogPost.thumbnail.id" class="article-image">
            <img
              [src]="getImage(blogPost.thumbnail?.id, null)"
              style="width: 320px; height: 260px"
              onerror="this.src='assets/img/not-found.png'"
              [alt]="blogPost?.thumbnail?.alt ?? blogPost.title" />
          </div>
          <div class="article-content">
            <h3>{{ blogPost.title }}</h3>
            <p [innerHTML]="blogPost.description"></p>
            <div class="d-flex flex-wrap justify-content-around" *ngIf="blogPost.medias">
              <img
                class="flex-1 mt-2"
                style="min-width: 200px; max-width: 250px"
                *ngFor="let val of blogPost.medias"
                [src]="getImage(val?.id, null)"
                onerror="this.src='assets/img/not-found.png'"
                [alt]="val.alt ?? 'image'" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
