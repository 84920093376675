import { Component, OnInit } from '@angular/core';
import { AccountService } from '../../../services/auth/account.service';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PATTERN } from 'src/app/shared/constants';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent implements OnInit {
  showPassword = false;
  submitted = false;
  password = '';
  confirmPassword = '';
  private key = '';
  currentLocale: string;
  pattern = PATTERN;
  resetPasswordForm: FormGroup;

  constructor(
    private authService: AccountService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(params => {
      this.key = params.key;
      if (!this.key || this.key === '') {
        this.router.navigateByUrl('/session/loginone');
      }
    });

    this.resetPasswordForm = this.formBuilder.group(
      {
        password: ['', [Validators.required, Validators.pattern(this.pattern)]],
        confirmPassword: ['', Validators.compose([Validators.required])],
      },
      {
        validator: this.passwordMatchValidator,
      }
    );
  }
  get f() {
    return this.resetPasswordForm?.controls;
  }
  passwordMatchValidator(control: AbstractControl) {
    const password: string = control.get('password')?.value;
    const confirmPassword: string = control.get('confirmPassword')?.value;
    if (password !== confirmPassword) {
      control.get('confirmPassword')?.setErrors({ NoPassswordMatch: true });
    } else {
      control.get('confirmPassword')?.setErrors(null);
    }
  }

  changePassword() {
    this.submitted = true;
    if (this.resetPasswordForm.invalid) {
      return;
    }
    if (this.password === this.confirmPassword) {
      const changePassword: any = {
        key: this.key,
        newPassword: this.password,
      };
      this.authService.changePassword(changePassword).subscribe(
        () => {
          this.router.navigateByUrl('/login');
        },
        error => {
          const errorMessage = this.translate.instant(error.error.errorType);
          this.toastr.error(errorMessage);
        }
      );
    } else {
      this.toastr.error(this.translate.instant('PASSWORD_NOT_MATCH'));
    }
  }
}
