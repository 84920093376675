import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Address, IAddress } from '../../models/address.model';
import { City, ICity } from '../../models/city.model';
import { IState } from '../../models/state.model';
import { Country, ICountry } from '../../models/country.model';
import { EMPTY, Observable, Subject } from 'rxjs';
import { UsersService } from '../../services/users.service';
import { takeUntil } from 'rxjs/operators';
import { Gender, IGender } from '../../models/gender.model';
import { AppStateService } from '../../services/utils/state.service';
import { IUserGroup } from '../../drh-space/models/user-group.model';
import { UserAccountService } from '../../services/auth/user-account.service';
import { CoachSpaceService } from '../../coach-space/coach-space.service';
import { CoachService } from '../../coach/coach.service';
import { Language } from '../../models/language.model';
import { ICoachingDomain } from '../../models/coaching-domain.model';
import { IcfAccreditationLevel, IIcfAccreditationLevel } from '../../models/icf-accreditation-level.model';
import { IndividualCoach } from '../../models/coach.model';
import { AccountService } from '../../services/auth/account.service';
import { PasswordChange } from '../../models/passwordChange.model';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { BeneficiaryService } from '../../beneficiary-space/beneficiary.service';
import { LoginService } from '../../services/auth/loginone.service';
import { IMedia } from '../../models/media.model';
import {
  BENEFICIARY_GROUP,
  COACH_GROUP,
  DRH_GROUP,
  LOCALE_ID,
  LOCALE_STATE,
  LOCALES,
  SERVICE_PROVIDER_GROUP,
  TUNISIA,
  PATTERN,
} from '../constants';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { Store } from '@ngrx/store';
import { AppState } from '../../store/app.reducer';
import * as BeneficiaryActions from '../../store/actions/beneficiary.actions';
import { SocialMediaNetworkService } from '../../services/social-media-network.service';
import { ISocialMediaNetwork } from '../../models/social-media-network.model';
import { ISocialNetworkAccount, SocialNetworkAccount } from '../../models/social-network-account.model';
import { LocaleService } from '../../services/locale.service';
import { Locale } from '../../models/locale.model';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { LocalStorageService, SessionStorageService } from 'ngx-webstorage';

@Component({
  selector: 'app-hro-account',
  templateUrl: './hro-account.component.html',
  styleUrls: ['./hro-account.component.scss'],
})
export class HroAccountComponent implements OnInit, OnDestroy {
  readonly tunisiaCountryId = TUNISIA;
  private isDead$ = new Subject();
  @ViewChild('confirmAction', { static: true }) confirmAction: TemplateRef<any>;

  emailError = false;
  address: IAddress;
  user: any;
  public cities: ICity[];
  public states: IState[];
  public countryT: ICountry = new Country();
  public genders: IGender[];
  public gender: IGender = new Gender();
  userGroups: IUserGroup[] = [];
  groups = [];
  isCoach = false;
  customer = new IndividualCoach();
  languages = [];
  countries = [];
  coachingDomains = [];
  accreditationLevels = [];
  accreditationLevel: IIcfAccreditationLevel;
  coachingDomain: ICoachingDomain[] = [];
  country;
  langue;
  password = '';
  newPassword = '';
  confirmPassword = '';
  media: IMedia;
  currentCurrency;
  dropdownSettings: IDropdownSettings = {};
  currentLocale: string;
  socialMediaNetworks: ISocialMediaNetwork[] = [];
  locales: Locale[] = [];
  confirmDeleteInput = '';
  canSendRequest = false;
  showPassword = false;
  submitted = false;
  passRequirement = {
    passwordMinLowerCase: 1,
    passwordMinNumber: 1,
    passwordMinSymbol: 1,
    passwordMinUpperCase: 1,
    passwordMinCharacters: 8,
  };
  pattern = PATTERN;
  resetPasswordForm: FormGroup;
  isFetchingUserData = false;
  qrCodeImage: string;

  constructor(
    private formBuilder: FormBuilder,
    private usersService: UsersService,
    private userAccountService: UserAccountService,
    private accountService: AccountService,
    private beneficiaryService: BeneficiaryService,
    private authService: AccountService,
    private coachSpaceService: CoachSpaceService,
    private loginService: LoginService,
    private coachService: CoachService,
    private toastr: ToastrService,
    private translationService: TranslateService,
    private router: Router,
    private appStateService: AppStateService,
    private store: Store<AppState>,
    private socialMediaNetworkService: SocialMediaNetworkService,
    private localeService: LocaleService,
    public modal: NgbActiveModal,
    private modalService: NgbModal,
    private spinner: NgxSpinnerService,
    private sessionStorage: LocalStorageService
  ) {}

  ngOnInit(): void {
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'name',
      selectAllText: this.translationService.instant('SELECT_ALL'),
      unSelectAllText: this.translationService.instant('UNSELECT_ALL'),
    };
    this.store.select(LOCALE_STATE, LOCALE_ID).subscribe(localeId => {
      this.getLanguages();
      this.currentLocale = localeId;
      this.localeService.findAll().subscribe(locales => {
        this.locales = locales;
        if (this.locales && this.locales.length) {
          const currentLocaleObject = this.locales.find(locale => locale.id === this.currentLocale);
          const otherLocalesObjects = this.locales.filter(locale => locale.id !== this.currentLocale);
          if (currentLocaleObject && otherLocalesObjects) {
            this.locales = [currentLocaleObject, ...otherLocalesObjects];
          }
        }
      });
      this.getALlCoachingDomains();
      this.getAccreditationLevels();
    });
    this.store.select(LOCALE_STATE, LOCALES).subscribe(() => {
      this.currentCurrency = this.appStateService.getCurrentCurrency();
      this.dropdownSettings = {
        singleSelection: false,
        idField: 'id',
        textField: 'name',
        selectAllText: this.translationService.instant('SELECT_ALL'),
        unSelectAllText: this.translationService.instant('UNSELECT_ALL'),
      };
    });
    this.loadAll();
    this.resetPasswordForm = this.formBuilder.group(
      {
        password: ['', [Validators.required]],
        newPassword: ['', [Validators.required, Validators.pattern(this.pattern)]],
        confirmPassword: ['', Validators.compose([Validators.required])],
      },
      {
        validator: this.passwordMatchValidator,
      }
    );
  }

  passwordMatchValidator(control: AbstractControl) {
    const password: string = control.get('newPassword')?.value;
    const confirmPassword: string = control.get('confirmPassword')?.value;
    if (password !== confirmPassword) {
      control.get('confirmPassword')?.setErrors({ NoPassswordMatch: true });
    } else {
      control.get('confirmPassword')?.setErrors(null);
    }
  }

  deleteAccount() {
    this.canSendRequest = false;
    this.modal = this.modalService.open(this.confirmAction);
  }

  confirmDeleteAction() {
    const request = {
      userId: this.user.id,
      description: this.confirmDeleteInput,
    };

    this.usersService.deleteAccountRequest(request).subscribe(
      res => {
        this.close();
        this.loginService.logout();
      },
      error => {
        this.close();
      },
      () => this.close()
    );
  }

  close() {
    this.confirmDeleteInput = '';
    this.canSendRequest = false;
    this.modal.close();
  }

  validateInput(input: string): void {
    if (input == 'DELETE MY ACCOUNT' || 'SUPPRIMER MON COMPTE') {
      this.canSendRequest = true;
    } else {
      this.canSendRequest = false;
    }
  }

  loadAll() {
    if (!this.address) {
      this.address = new Address();
      this.address.city = new City();
      this.address.city.country = new Country();
      this.address.city.country.name = {};
      this.address.city.country.name.localizedValues = {};
    }
    this.isFetchingUserData = true;
    this.spinner.show();
    this.userAccountService.getCurrentUser().subscribe(
      res => {
        this.user = res;
        if (this.user.userGroups[0].code === DRH_GROUP || this.user.userGroups[0].code === SERVICE_PROVIDER_GROUP) {
          this.initDataForServiceProviderOrDRH();
        }
        if (this.user.userGroups[0].code === BENEFICIARY_GROUP) {
          this.initDataForBeneficiary();
        }
        if (this.user.userGroups[0].code === COACH_GROUP) {
          this.initDataForCoach();
        }
        this.initGenericData();
      },
      () => {
        this.spinner.hide();
        this.isFetchingUserData = false;
      }
    );
  }

  private initGenericData() {
    this.usersService
      .findAllGendersByLocale()
      .pipe(takeUntil(this.isDead$))
      .subscribe(genders => (this.genders = genders));
    this.usersService
      .findAllCities()
      .pipe(takeUntil(this.isDead$))
      .subscribe(cities => (this.cities = cities));
    this.usersService
      .findAllCountries()
      .pipe(takeUntil(this.isDead$))
      .subscribe(countries => (this.countries = countries));
    this.usersService
      .findAllStates()
      .pipe(takeUntil(this.isDead$))
      .subscribe(states => (this.states = states));
    this.usersService
      .findCountry(TUNISIA)
      .pipe(takeUntil(this.isDead$))
      .subscribe(countryT => (this.countryT.name = countryT.name));
  }

  private initDataForServiceProviderOrDRH() {
    this.customer = this.user;
    if (this.customer.addresses[0]) {
      this.address = this.customer.addresses[0];
    }

    if (this.user.gender) {
      this.gender = this.user.gender;
    }
    this.spinner.hide();
    this.isFetchingUserData = false;
  }

  private initDataForBeneficiary() {
    this.store.dispatch(BeneficiaryActions.setBeneficiaryEffect());
    this.store.select('beneficiaryState').subscribe(state => {
      if (state?.beneficiary !== null) {
        this.customer = JSON.parse(JSON.stringify(state.beneficiary));
        if (!this.customer.qrCode) {
          const user = this.sessionStorage.retrieve('user');
          this.generateQRCode(user.login);
        } else {
          this.qrCodeImage = `data:image/png;base64,${this.customer.qrCode}`;
        }
        if (this.customer.addresses && this.customer.addresses[0]) {
          this.address = this.customer.addresses[0];
        }
        if (this.customer.genderId) {
          this.gender.id = this.customer.genderId;
        }
      }
      this.spinner.hide();
      this.isFetchingUserData = false;
    });
  }

  private initDataForCoach() {
    this.isCoach = true;
    this.getAllLocales();
    this.coachSpaceService.getCoachByUserEmail(this.user.email).subscribe(
      coach => {
        this.customer = coach;
        this.initCoachSummary();
        if (this.customer.genderId) {
          this.gender.id = this.customer.genderId;
        }
        if (this.customer.addresses[0]) {
          this.address = this.customer.addresses[0];
        }
        this.getALlCoachingDomains();
        this.getCoutries();
        this.getLanguages();
        this.getAccreditationLevels();
        this.getAllSocialMediaNetworks();
        this.country = new Country();
        this.langue = new Language();
        this.coachingDomain = coach.coachingDomainId.map(domainId => this.coachingDomains.find(domain => domain.id === domainId));
        this.accreditationLevel = new IcfAccreditationLevel();
        this.spinner.hide();
        this.isFetchingUserData = false;
      },
      () => {
        this.spinner.hide();
        this.isFetchingUserData = false;
      }
    );
  }

  initCoachSummary() {
    if (!this.customer.summary) {
      this.customer.summary = {};
      this.customer.summary.localizedValues = {};
    }
    for (const locale of this.locales) {
      if (Object.keys(this.customer.summary.localizedValues).indexOf(locale.id) === -1) {
        this.customer.summary.localizedValues[locale.id] = '';
      }
    }
  }

  getAllLocales() {
    this.localeService.findAll().subscribe(locales => (this.locales = locales));
  }

  getAllSocialMediaNetworks() {
    this.socialMediaNetworkService.getAll().subscribe(socialMediaNetworks => {
      this.socialMediaNetworks = socialMediaNetworks;
      if (!this.customer.socialNetworkAccounts) {
        this.customer.socialNetworkAccounts = [];
      }
      for (const socialMediaNetwork of socialMediaNetworks) {
        if (
          this.customer.socialNetworkAccounts.findIndex(socialAccount => socialAccount.socialMediaNetwork.id === socialMediaNetwork.id) ===
          -1
        ) {
          const socialNetworkAccount: ISocialNetworkAccount = new SocialNetworkAccount();
          socialNetworkAccount.link = '';
          socialNetworkAccount.socialMediaNetwork = socialMediaNetwork;
          this.customer.socialNetworkAccounts.push(socialNetworkAccount);
        }
      }
    });
  }

  getALlCoachingDomains() {
    this.coachService.getAllCoachingDomains().subscribe(coachingDomains => {
      this.coachingDomains = [].concat(coachingDomains);
    });
  }

  getCoutries() {
    this.coachService.getCoutries().subscribe(coutries => {
      this.countries = [].concat(coutries);
    });
  }

  getAccreditationLevels() {
    this.coachService.getAccreditationLevels().subscribe(accreditationLevels => {
      this.accreditationLevels = [].concat(accreditationLevels);
    });
  }

  getLanguages() {
    this.coachService.getLanguages().subscribe(languages => {
      this.languages = [].concat(languages);
      this.updateCustomerLangugesNamesForLocaleUpdate();
    });
  }

  updateCustomerLangugesNamesForLocaleUpdate() {
    if (this.customer.languages && this.languages) {
      const customerLanguages = [];
      if (!this.customer.languages.find(lang => this.languages.find(language => language.id === lang.id && language.name !== lang.name))) {
        return;
      }
      for (const customerLanguage of this.customer.languages) {
        const language = this.languages.find(lang => lang.id === customerLanguage.id);
        if (language && language.name !== customerLanguage.name) {
          customerLanguages.push(language);
        }
      }
      this.customer.languages = [];
      this.customer.languages = customerLanguages;
    }
  }

  save(event?) {
    if ( !this.customer.firstName.length ||
      !this.customer.lastName.length ||
      !this.customer.email.length ||
      !this.customer.phoneNumber1.length
    ) {
      this.toastr.error(this.translationService.instant('FORM_INVALID'));
      return;
    }
    if (
      !/^[0-9]{1,4}[-\s\./0-9]*$/.test(this.customer.phoneNumber1) ||
      this.customer.phoneNumber1.length < 8 ||
      this.customer.phoneNumber1.length > 13
    ) {
      this.toastr.error(this.translationService.instant('PHONE')+ ' ' + this.translationService.instant('NOT_VALID'));
      return;
    }
    if (event) {
      this.customer.photo = event;
    }
    if (this.customer.countryId !== this.tunisiaCountryId) {
      this.address.state = null;
    }
    this.customer.addresses = [...[this.address]];
    if (this.gender.id) {
      this.customer.genderId = this.gender.id;
    }

    const isCoach = this.user.userGroups[0].code === COACH_GROUP;
    const isDrhOrServiceProvider = this.user.userGroups[0].code === DRH_GROUP || this.user.userGroups[0].code === SERVICE_PROVIDER_GROUP;
    const isBeneficiary = this.user.userGroups[0].code === BENEFICIARY_GROUP;

    let save$: Observable<any>;

    if (isCoach) {
      if (this.accreditationLevel) {
        this.customer.icfAccreditationLevelId = this.accreditationLevel.id;
      }
      if (this.coachingDomain) {
        this.customer.coachingDomainId = this.coachingDomain.map(coach => coach.id);
      }
      save$ = this.coachService.updateCoach(this.customer);
    } else if (isDrhOrServiceProvider) {
      save$ = this.accountService.update(this.customer);
    } else if (isBeneficiary) {
      save$ = this.beneficiaryService.update(this.customer);
    }
    this.spinner.show();
    save$.subscribe(
      res => {
        this.spinner.hide();
        this.toastr.success(this.translationService.instant('SUCCESS_COACH_UPDATE'));
      },
      error => {
        this.spinner.hide();
      }
    );
  }

  get f() {
    return this.resetPasswordForm?.controls;
  }

  changePassword(): void {
    this.submitted = true;
    if (this.resetPasswordForm.invalid) {
      return;
    }
    if (this.newPassword === this.confirmPassword) {
      const changePassword = new PasswordChange();
      changePassword.currentPassword = this.password;
      changePassword.newPassword = this.confirmPassword;
      this.authService.changeNewPassword(changePassword).subscribe(res => {
        this.toastr.success(this.translationService.instant('SUCCESS_PASSWORD'));
        this.loginService.logout();
        this.router.navigateByUrl('/login');
      });
    } else {
      this.toastr.error(this.translationService.instant('PASSWORD_NOT_MATCH'));
    }
  }

  getImage(image: any, format: string | null): any {
    if (!image) {
      return;
    }
    return this.coachService.downloadMedia(image, format);
  }

  ngOnDestroy(): void {
    this.isDead$.next();
  }

  generateQRCode(userLogin: string): void {
    this.userAccountService.generateQRCode(userLogin).subscribe(
      res => {
        this.qrCodeImage = `data:image/png;base64,${res}`;
      },
      error => {
      }
    );
  }
}
