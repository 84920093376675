import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { IContext } from '../../../cms/models/context.model';
import { ReplaySubject } from 'rxjs';
import { Router } from '@angular/router';
import { ContextService } from '../../../cms/context.service';
import { CmsService } from '../../../cms/cms.service';
import { takeUntil } from 'rxjs/operators';
import { AppStateService } from 'src/app/services/utils/state.service';
import { LOCALE_ID, LOCALE_STATE, PRIVACY_POLICY, TERMS_OF_USE_AND_PRIVACY_POLICY } from '../../../shared/constants';
import { Store } from '@ngrx/store';
import { AppState } from '../../../store/app.reducer';
import { SeoService } from '../../../services/seo.service';
import { PRIVACY_POLICY_COMPONENT_NAME, TERMS_AND_CONDITIONS_COMPONENT_NAME } from '../../../ComponentsIndex';
import { DynamicPathStorageService } from '../../../services/dynamic-path-storage.service';
import { CurrentLocaleService } from '../../../services/current-locale.service';

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PrivacyPolicyComponent implements OnInit, OnDestroy {
  cmsPage: any;
  currentLocale: string;
  contextItemIds: string[] | undefined;
  context: IContext | null | undefined;
  components: any[] | undefined;
  component: any;
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  constructor(
    private router: Router,
    private contextService: ContextService,
    private cmsService: CmsService,
    appStateService: AppStateService,
    private store: Store<AppState>,
    private seoService: SeoService,
    private dynamicPathStorageService: DynamicPathStorageService,
    private currentLocaleService: CurrentLocaleService
  ) {
    this.currentLocale = currentLocaleService.getCurrentLocale();
  }

  ngOnInit(): void {
    this.currentLocaleService.currentLocaleId$.pipe(takeUntil(this.destroyed$)).subscribe(localeId => {
      this.currentLocale = localeId;
      if (this.cmsPage) {
        this.seoService.setSeoFromSeoDataAndMedia(this.cmsPage.seoData);
      }
      const currentLocaleUrlPattern =
        this.dynamicPathStorageService.getUrlPatternByComponentNameFromCmsPageList(PRIVACY_POLICY_COMPONENT_NAME);
      if (currentLocaleUrlPattern) {
        this.router.navigate([currentLocaleUrlPattern]);
      }
    });
    this.getContext();
    this.getCmsPage();
  }

  getContext(): void {
    this.contextItemIds = [];
    this.context = null;
    this.contextItemIds.push(this.currentLocale);
    this.contextService
      .findContext(this.contextItemIds)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(context => {
        this.context = context;
      });
  }

  getCmsPage(): void {
    this.cmsService
      .getCmsPageByCode(PRIVACY_POLICY)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(res => {
        this.cmsPage = res;
        this.seoService.setSeoFromSeoDataAndMedia(this.cmsPage.seoData);
        this.components = res.cmsComponents;
      });
  }

  getIdComponentByCode(code: string): any {
    if (this.components && this.components.length > 0) {
      const index = this.components.findIndex(component => component.code === code);
      if (index !== -1) {
        return this.components[index].id;
      }
    }
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
    this.seoService.resetSeo();
  }
}
