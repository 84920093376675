import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { GenericService } from './GenericService';
import { IProductCustomerReview } from '../models/product-customer-review.model';
import { ICustomerReviewAverage } from '../models/CustomerReviewAverage.model';
import { IB2BCustomerReviewAverage } from '../shared/models/b-2-b-customer-review-average.model';

@Injectable({
  providedIn: 'root',
})
export class ProductCustomerReviewService extends GenericService {
  constructor() {
    super();
  }

  create(productCustomerReview: IProductCustomerReview): Observable<IProductCustomerReview> {
    return this.api.post('api/product-customer-reviews', productCustomerReview);
  }

  createB2BCustomerReviewAverage(customerReviewAverage: ICustomerReviewAverage): Observable<IProductCustomerReview> {
    return this.api.post('api/b-2-b-customer-review-averages', customerReviewAverage);
  }
  updateB2BCustomerReviewAverage(customerReviewAverage: ICustomerReviewAverage): Observable<IProductCustomerReview> {
    return this.api.put('api/b-2-b-customer-review-averages', customerReviewAverage);
  }

  getB2BCustomerReviewAverageByB2BCustomerAndProduct(b2bCustomerId: string, productId: string): Observable<IB2BCustomerReviewAverage> {
    return this.api.get('api/b-2-b-customer-review-averages/' + b2bCustomerId + '/' + productId);
  }

  update(productCustomerReview: IProductCustomerReview): Observable<IProductCustomerReview> {
    return this.api.put('api/product-customer-reviews', productCustomerReview);
  }

  findAll(): Observable<IProductCustomerReview[]> {
    return this.api.get('api/product-customer-reviews');
  }

  delete(id: string): Observable<any> {
    return this.api.delete('api/product-customer-reviews/' + id);
  }

  findAllByProduct(productId: string): Observable<IProductCustomerReview[]> {
    return this.api.get('api/product-customer-reviews-approved/' + productId);
  }
}
