import { IAddress } from './address.model';
import { IDeliveryMode } from './deliveryMode.model';
import { IPayment } from './payment.model';

export interface IAbstractOrder {
  id?: string;

  code?: string;

  subTotal?: number;

  totalTax?: number;

  total?: number;

  deliveryCost?: number;

  paymentCost?: number;

  siteId?: string;

  deliveryModes?: IDeliveryMode[];

  payments?: IPayment[];

  customerId?: string;

  deliveryAddress?: IAddress;

  currencyId?: string;

  paymentStatusId?: string;

  deliveryStatusId?: string;
}

export class AbstractOrder implements IAbstractOrder {
  constructor(
    public id?: string,

    public code?: string,

    public subTotal?: number,

    public totalTax?: number,

    public total?: number,

    public deliveryCost?: number,

    public paymentCost?: number,

    public siteId?: string,

    public deliveryModes?: IDeliveryMode[],

    public payments?: IPayment[],

    public customerId?: string,

    public deliveryAddressId?: string,

    public currencyId?: string,

    public paymentStatusId?: string,

    public deliveryStatusId?: string
  ) {}
}
