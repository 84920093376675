import { SelectionModel } from '@angular/cdk/collections';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { IProductList } from '../../models/product.model';
import { ProductService } from '../../services/product.service';
import { GenericEntry } from '../product-details/genericPack.model';
import { DynamicPathRoutingService } from '../../services/dynamic-path-routing.service';
import { AppState } from '../../store/app.reducer';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { PRODUCT_DETAILS_COMPONENT_NAME } from '../../ComponentsIndex';
import { takeUntil } from 'rxjs/operators';
import { DynamicPathStorageService } from '../../services/dynamic-path-storage.service';
import { LOCALE_ID, LOCALE_STATE } from '../../shared/constants';

@Component({
  selector: 'app-pack-entries',
  templateUrl: './pack-entries.component.html',
  styleUrls: ['./pack-entries.component.css'],
})
export class PackEntriesComponent implements OnInit, OnDestroy {
  @Input()
  packEntries: GenericEntry[] = [];

  selection: SelectionModel<IProductList>;
  products: IProductList[];
  size: any;
  currentLocaleId: string;
  private destroyed$ = new Subject();
  productDetailsPageUrlPattern: string;

  constructor(
    private prductServcie: ProductService,
    private dynamicPathRoutingService: DynamicPathRoutingService,
    private store: Store<AppState>,
    private dynamicPathStorageService: DynamicPathStorageService
  ) {}

  ngOnInit(): void {
    this.store
      .select(LOCALE_STATE, LOCALE_ID)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(localeId => (this.currentLocaleId = localeId));
    this.products = this.packEntries.map(item => item.productBasicDTO);
    this.dynamicPathStorageService.urlPatterns$.pipe(takeUntil(this.destroyed$)).subscribe(() => {
      this.productDetailsPageUrlPattern =
        this.dynamicPathStorageService.getUrlPatternByComponentNameFromCmsPageList(PRODUCT_DETAILS_COMPONENT_NAME);
    });
  }

  getImage(image, format) {
    if (!image) {
      return;
    }
    return this.prductServcie.downloadMedia(image.id, format);
  }

  ngOnDestroy() {
    this.destroyed$.next();
  }
}
