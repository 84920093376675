import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { IContext } from '../../../cms/models/context.model';
import { Observable, ReplaySubject } from 'rxjs';
import { Router } from '@angular/router';
import { ContextService } from '../../../cms/context.service';
import { CmsService } from '../../../cms/cms.service';
import { map, startWith, take, takeUntil } from 'rxjs/operators';
import { AppStateService } from 'src/app/services/utils/state.service';
import { RequestTypeService } from '../../../services/request-type.service';
import { IWorkItemType, WorkItemType } from '../../../shared/models/request-type.model';
import { IRequest, Request } from '../../../shared/models/request.model';
import { AnonymousUser, IAnonymousUser } from '../../../shared/models/anonymousUser.model';
import { Customer } from '../../../models/custom-user.model';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { CONTACT_US, ENGLISH_UK, HEADER_CONTACT_PAGE } from '../../../shared/constants';
import { NgAutoCompleteComponent } from 'ng-auto-complete';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { Product } from 'src/app/models/product.model';
import { ProductService } from 'src/app/services/product.service';
import { SeoService } from '../../../services/seo.service';
import { CONTACT_US_CMS_COMPONENT_NAME } from '../../../ComponentsIndex';
import { DynamicPathStorageService } from '../../../services/dynamic-path-storage.service';
import { CurrentLocaleService } from '../../../services/current-locale.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-contact-us-cms',
  templateUrl: './contact-us-cms.component.html',
  styleUrls: ['./contact-us-cms.component.scss'],
})
export class ContactUsCmsComponent implements OnInit, OnDestroy {
  cmsPage: any;
  @ViewChild(NgAutoCompleteComponent) public completer: NgAutoCompleteComponent;
  currentLocale: string;
  contextItemIds: string[] | undefined;
  context: IContext | null | undefined;
  components: any[] | undefined;
  component: any;
  user;
  workItemType: IWorkItemType;
  request: IRequest;
  anonymousUser: IAnonymousUser;
  types: WorkItemType[];
  declarativeFormCaptchaValue;
  orders = [];
  products = [];
  orderReference = '';
  myControl = new FormControl('', { validators: [this.validateProduct()] });
  filteredOptions: Observable<Product[]>;
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  public fields: Object = { value: 'code' };
  currencyId: string;
  filterObject = {
    categoryId: '',
    className: 'com.heero.pcm.domain.product.Product',
    searchValue: '',
    sortedField: '',
    queries: {
      online: true,
    },
    fields: [],
    nestedQueries: [],
    nestedFields: [
      // {
      //   path: "textAttributes",
      //   field: "textAttributes.value.keyword",
      //   conditions: { "textAttributes.productAttribute.id.keyword": "NAME" },
      // },
    ],
    onlyProducts: true,
    onlyPacks: true,
    onlyBundles: false,
    aggregations: [],
  };
  submitted = false;
  contactForm: FormGroup;

  recaptchaLocale = 'fr';
  readonly recaptchaSiteKey = environment.recaptchaSiteKey;

  // bind the Query instance to query property
  constructor(
    private router: Router,
    private contextService: ContextService,
    private formBuilder: FormBuilder,
    private cmsService: CmsService,
    private requestTypeService: RequestTypeService,
    private translationService: TranslateService,
    private toastr: ToastrService,
    private appStateService: AppStateService,
    private productService: ProductService,
    private seoService: SeoService,
    private dynamicPathStorageService: DynamicPathStorageService,
    private currentLocaleService: CurrentLocaleService
  ) {}
  ngOnInit(): void {
    this.currentLocaleService.currentLocaleId$
      .pipe(takeUntil(this.destroyed$))
      .pipe(takeUntil(this.destroyed$))
      .subscribe(localeId => {
        this.currentLocale = localeId;
        this.setRecaptchaLocale();
        if (this.cmsPage) {
          this.seoService.setSeoFromSeoDataAndMedia(this.cmsPage.seoData);
        }
        const currentLocaleUrlPattern =
          this.dynamicPathStorageService.getUrlPatternByComponentNameFromCmsPageList(CONTACT_US_CMS_COMPONENT_NAME);
        if (currentLocaleUrlPattern) {
          this.router.navigate([currentLocaleUrlPattern]);
        }
      });
    this.user = this.appStateService.getUser();
    this.currencyId = this.appStateService.getCurrentCurrency();
    this.contactForm = this.formBuilder.group({
      name: Validators.required,
      email: [Validators.required, Validators.email],
      number: Validators.required,
      subject: Validators.required,
      message: Validators.required,
      orderReference: this.validateOrder(),
      captcha: Validators.requiredTrue,
    });
    if (this.user == null) {
      this.user = new Customer();
    }
    this.request = new Request();
    this.getContext();
    this.getCmsPage();
    this.loadAllTypes();
    this.searchProduct();
    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(''),
      map(value => {
        return this.searchProduct(value);
      })
    );
  }

  setRecaptchaLocale() {
    if (this.currentLocale === ENGLISH_UK) {
      this.recaptchaLocale = 'en';
    } else {
      this.recaptchaLocale = 'fr';
    }
  }

  validateOrder(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;
      if (this.workItemType?.id !== 'ORDER_INQUIRIES' || (this.workItemType?.id === 'ORDER_INQUIRIES' && value)) {
        return null;
      }
      return { orderSelected: true };
    };
  }

  validateProduct(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;
      if (this.workItemType?.id !== 'PRODUCT_QUESTIONS' || (this.workItemType?.id === 'PRODUCT_QUESTIONS' && value)) {
        return null;
      }
      return { productSelected: true };
    };
  }

  get f() {
    return this.contactForm?.controls;
  }

  loadAllTypes() {
    this.requestTypeService.findAll().subscribe(res => {
      this.types = res.filter(type => type.id !== 'Task');
    });
  }

  searchProduct(event?) {
    this.filterObject.fields = ['categories.label.localizedValues.' + this.currentLocale];
    this.filterObject.queries['_class.keyword'] = 'com.heero.pcm.domain.product.Product';
    this.filterObject.searchValue = event;
    this.filterObject.nestedFields = [
      {
        path: 'textAttributes',
        field: 'textAttributes.value.keyword',
        conditions: {
          'textAttributes.productAttribute.id.keyword': 'NAME',
          'textAttributes.context.id.keyword': this.currentLocale,
        },
      },
    ];
    this.productService
      .searchAllProducts(this.filterObject, this.currencyId, {
        page: 0,
        size: 10,
      })
      .pipe(take(1))
      .subscribe(res => {
        this.products = res.json;
      });
    return this.products;
  }

  getContext(): void {
    this.contextItemIds = [];
    this.context = null;
    this.contextItemIds.push(this.currentLocale);
    this.contextService
      .findContext(this.contextItemIds)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(context => {
        this.context = context;
      });
  }

  getCmsPage(): void {
    this.cmsService
      .getCmsPageByCode(HEADER_CONTACT_PAGE)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(res => {
        this.components = res.cmsComponents;
      });

    this.cmsService
      .getCmsPageByCode(CONTACT_US)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(res => {
        this.cmsPage = res;
        this.seoService.setSeoFromSeoDataAndMedia(this.cmsPage.seoData);
      });
  }

  getIdComponentByCode(code: string): any {
    if (this.components && this.components.length > 0) {
      const index = this.components.findIndex(component => component.code === code);
      if (index !== -1) {
        return this.components[index].id;
      }
    }
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
    this.seoService.resetSeo();
  }
  selectProduct($event) {
    this.request.product = $event;
  }

  getName(value) {
    return value?.name;
  }

  createRequest() {
    this.submitted = true;
    if (this.contactForm?.invalid || this.myControl?.invalid) {
      return;
    }
    if (!this.user.id) {
      this.anonymousUser = new AnonymousUser();
      this.anonymousUser.firstName = this.user.firstName;
      this.anonymousUser.pseudo = this.user.firstName;
      this.anonymousUser.phone = this.user.phoneNumber1;
      this.anonymousUser.email = this.user.email;
      this.requestTypeService.createAnonynoms(this.anonymousUser).subscribe(reporter => {
        this.request.anonymousReporter = reporter;
        this.request.workItemType = this.workItemType;
        if (this.request.workItemType.id === 'PRODUCT_QUESTIONS') {
          this.requestTypeService.createProductRequest(this.request).subscribe(
            () => {
              this.request = new Request();
              this.workItemType = new WorkItemType();
              const successMsg = this.translationService.instant('SUCCESS_SEND_MESSAGE');
              this.toastr.success(successMsg);
            },
            error => {
              const errorMsg = this.translationService.instant(error.error.errorType);
              this.toastr.error(errorMsg);
            }
          );
        } else if (this.request.workItemType.id === 'ORDER_INQUIRIES') {
          this.request.order = { code: this.orderReference };
          this.requestTypeService.createOrderRequest(this.request).subscribe(
            () => {
              this.request = new Request();
              this.workItemType = new WorkItemType();
              const successMsg = this.translationService.instant('SUCCESS_SEND_MESSAGE');
              this.toastr.success(successMsg);
            },
            error => {
              const errorMsg = this.translationService.instant(error.error.errorType);
              this.toastr.error(errorMsg);
            }
          );
        } else {
          this.requestTypeService.createRequest(this.request).subscribe(
            () => {
              this.request = new Request();
              this.workItemType = new WorkItemType();
              const successMsg = this.translationService.instant('SUCCESS_SEND_MESSAGE');
              this.toastr.success(successMsg);
            },
            error => {
              const errorMsg = this.translationService.instant(error.error.errorType);
              this.toastr.error(errorMsg);
            }
          );
        }
      });
    } else {
      this.request.workItemType = this.workItemType;
      if (this.request.workItemType.id === 'PRODUCT_QUESTIONS') {
        this.requestTypeService.createProductRequest(this.request).subscribe(
          () => {
            this.request = new Request();
            this.workItemType = new WorkItemType();
            const successMsg = this.translationService.instant('SUCCESS_SEND_MESSAGE');
            this.toastr.success(successMsg);
          },
          error => {
            const errorMsg = this.translationService.instant(error.error.errorType);
            this.toastr.error(errorMsg);
          }
        );
      } else if (this.request.workItemType.id === 'ORDER_INQUIRIES') {
        this.requestTypeService.createOrderRequest(this.request).subscribe(
          () => {
            this.request = new Request();
            this.workItemType = new WorkItemType();
            const successMsg = this.translationService.instant('SUCCESS_SEND_MESSAGE');
            this.toastr.success(successMsg);
          },
          error => {
            const errorMsg = this.translationService.instant(error.error.errorType);
            this.toastr.error(errorMsg);
          }
        );
      } else {
        this.requestTypeService.createRequest(this.request).subscribe(
          () => {
            this.request = new Request();
            this.workItemType = new WorkItemType();
            const successMsg = this.translationService.instant('SUCCESS_SEND_MESSAGE');
            this.toastr.success(successMsg);
          },
          error => {
            const errorMsg = this.translationService.instant(error.error.errorType);
            this.toastr.error(errorMsg);
          }
        );
      }
    }
  }
}
