import { Component, ElementRef, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { CorporateSellerService } from '../corporate-seller.service';
import { CORPORATE_SELLER_DETAILS_COMPONENT_NAME, CORPORATE_SELLER_LIST_COMPONENT_NAME } from '../../ComponentsIndex';
import { takeUntil } from 'rxjs/operators';
import { CORPORATE_SELLERS_PAGE } from '../../shared/constants';
import { SeoService } from '../../services/seo.service';
import { CmsService } from '../../cms/cms.service';
import { DynamicPathStorageService } from '../../services/dynamic-path-storage.service';
import { Router } from '@angular/router';
import { CurrentLocaleService } from '../../services/current-locale.service';
import { ViewportService } from '../../services/viewport.service';

@Component({
  selector: 'app-corporate-seller-list',
  templateUrl: './corporate-seller-list.component.html',
  styleUrls: ['./corporate-seller-list.component.scss'],
})
export class CorporateSellerListComponent implements OnInit, OnDestroy {
  cmsPage: any;
  corporateSellers = [];
  subscription: Subscription;
  totalItems: string;
  page = 0;
  size = 5;
  filterObject = {
    categoryId: '',
    className: 'com.softparadigm.passexcellence.domain.CorporateServiceProvider',
    searchValue: '',
    fields: [],
    sortedField: '',
  };
  private destroyed$ = new Subject();
  corporateSellerDetailsPageUrlPattern: string;
  currentLocaleId: string;
  hasMoreSellersToShow = false;

  readonly PASSEXCELLECE_ID = 'PASSEXCELLENCE';
  isFetchingData = false;

  @ViewChild('showMore') private showMore: ElementRef;

  canLoadMore = true;

  constructor(
    private corporateSellerService: CorporateSellerService,
    private dynamicPathStorageService: DynamicPathStorageService,
    private seoService: SeoService,
    private cmsService: CmsService,
    private router: Router,
    private currentLocaleService: CurrentLocaleService,
    private viewportService: ViewportService,
    private renderer: Renderer2
  ) {}

  ngOnInit(): void {
    this.currentLocaleService.currentLocaleId$.pipe(takeUntil(this.destroyed$)).subscribe(localeId => {
      this.currentLocaleId = localeId;
      if (this.cmsPage) {
        this.seoService.setSeoFromSeoDataAndMedia(this.cmsPage.seoData);
      }
      const currentLocaleUrlPattern = this.dynamicPathStorageService.getUrlPatternByComponentNameFromCmsPageList(
        CORPORATE_SELLER_LIST_COMPONENT_NAME
      );
      if (currentLocaleUrlPattern) {
        this.router.navigate([currentLocaleUrlPattern]);
      }
    });
    this.filterCorporateSellers();
    this.dynamicPathStorageService.urlPatterns$.pipe(takeUntil(this.destroyed$)).subscribe(() => {
      this.corporateSellerDetailsPageUrlPattern = this.dynamicPathStorageService.getUrlPatternByComponentNameFromCmsPageList(
        CORPORATE_SELLER_DETAILS_COMPONENT_NAME
      );
    });

    this.cmsService
      .getCmsPageByCode(CORPORATE_SELLERS_PAGE)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(res => {
        this.cmsPage = res;
        this.seoService.setSeoFromSeoDataAndMedia(this.cmsPage.seoData);
      });

    this.renderer.listen('window', 'scroll', this.checkShowMore.bind(this));
    this.renderer.listen('window', 'resize', this.checkShowMore.bind(this));
  }

  checkShowMore() {
    if (!this.showMore) {
      return;
    }
    const elementTop = this.showMore.nativeElement.offsetTop;
    const elementBottom = elementTop + this.showMore.nativeElement.offsetHeight;
    if (!this.viewportService.isInViewport(this.showMore.nativeElement, 250, true)) {
      this.canLoadMore = true;
    } else {
      if (this.canLoadMore) {
        if (this.hasMoreSellersToShow && !this.isFetchingData) {
          this.showMoreSellers();
        }
        this.canLoadMore = false;
      }
    }
  }

  filterCorporateSellers() {
    this.isFetchingData = true;
    this.subscription = this.corporateSellerService
      .searchCorporateSellers(this.filterObject, {
        page: this.page,
        size: this.size,
      })
      .subscribe(
        value => {
          for (const seller of value.json) {
            this.corporateSellers.push(seller);
          }
          this.totalItems = value.headers.get('X-Total-Count');
          this.hasMoreSellersToShow = this.corporateSellers && +this.totalItems !== this.corporateSellers.length;
          this.isFetchingData = false;
        },
        () => (this.isFetchingData = false)
      );
  }

  showMoreSellers() {
    this.page++;
    this.filterCorporateSellers();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.destroyed$.next();
    this.seoService.resetSeo();
  }
}
