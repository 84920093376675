import { AbstractOrder, IAbstractOrder } from './abstractOrder.model';
import { IOrder, Order } from './order.model';
import { IOrderLine } from './orderLine.model';

export type IBookingDTO = IOrder;

export class BookingDTO extends Order implements IBookingDTO {
  constructor() {
    super();
    this.orderLines = this.orderLines ? this.orderLines : [];
  }
}
