import { Routes } from '@angular/router';

export const CorporateSellerRoutes: Routes = [
  //     {
  //   path: '',
  //   redirectTo: 'corporate-sellers',
  //   pathMatch: 'full',
  // },
  //   {
  //     path: '',
  //     component: CorporateSellerListComponent
  //   },
  //   {
  //     path: 'details/:id',
  //     component: CorporateSellerDetailsComponent
  //   }
];
