import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  BLOG_DETAILS_COMPONENT_NAME,
  BLOG_LIST_COMPONENT_NAME,
  COACH_DETAILS_COMPONENT_NAME,
  COACH_LIST_COMPONENT_NAME,
  CONTACT_US_CMS_COMPONENT_NAME,
  CORPORATE_SELLER_DETAILS_COMPONENT_NAME,
  CORPORATE_SELLER_LIST_COMPONENT_NAME,
  ENTREPRISE_HOME_PAGE_COMPONENT_NAME,
  PACK_DETAILS_COMPONENT_NAME,
  PACK_LIST_COMPONENT_NAME,
  PARTICULAR_HOME_PAGE_COMPONENT_NAME,
  PRESTATION_COMPONENT_NAME,
  PRODUCT_COMPONENT_NAME,
  PRODUCT_DETAILS_COMPONENT_NAME,
  PRODUCT_JOURNEY_TO_EXCELLENCE_COMPONENT_NAME,
  TERMS_AND_CONDITIONS_COMPONENT_NAME,
} from '../ComponentsIndex';
import { DynamicPathStorageService } from './dynamic-path-storage.service';

@Injectable({
  providedIn: 'root',
})
export class DynamicPathRoutingService {
  constructor(private router: Router, private dynamicPathStorageService: DynamicPathStorageService) {}

  private navigateToEntityDetailsPage(componentName: string, entityId: string) {
    let newLink = this.dynamicPathStorageService.getUrlPatternByComponentNameFromCmsPageList(componentName);
    if (newLink) {
      const urlFragments: string[] = newLink.split('/');
      const urlVariables = urlFragments.filter((value: string) => value.startsWith(':'));
      for (const url of urlVariables.values()) {
        const key: string = url.substring(1, url.length);
        if (key === 'id') {
          newLink = newLink.replace(url, entityId);
        }
      }
      this.router.navigateByUrl(newLink);
    }
  }

  private navigateToEntityDetailsPageBySeoName(componentName: string, entitySeoName: string) {
    let newLink = this.dynamicPathStorageService.getUrlPatternByComponentNameFromCmsPageList(componentName);
    if (newLink) {
      const urlFragments: string[] = newLink.split('/');
      const urlVariables = urlFragments.filter((value: string) => value.startsWith(':'));
      for (const url of urlVariables.values()) {
        const key: string = url.substring(1, url.length);
        if (key === 'seoName') {
          newLink = newLink.replace(url, entitySeoName);
        }
      }
      this.router.navigateByUrl(newLink);
    }
  }

  private navigateToPage(componentName: string) {
    const newLink = this.dynamicPathStorageService.getUrlPatternByComponentNameFromCmsPageList(componentName);
    if (newLink) {
      this.router.navigate([newLink]);
    }
  }

  // DETAILS PAGES

  navigateToCoachDetailsPage(coachSeoName: string) {
    this.navigateToEntityDetailsPageBySeoName(COACH_DETAILS_COMPONENT_NAME, coachSeoName);
  }

  navigateToBlogDetailsPage(blogId: string) {
    this.navigateToEntityDetailsPageBySeoName(BLOG_DETAILS_COMPONENT_NAME, blogId);
  }

  navigateToCorporateSellerDetailsPage(corporateSellerSeoName: string) {
    this.navigateToEntityDetailsPageBySeoName(CORPORATE_SELLER_DETAILS_COMPONENT_NAME, corporateSellerSeoName);
  }

  navigateToProductDetailsPage(productSeoName: string) {
    this.navigateToEntityDetailsPageBySeoName(PRODUCT_DETAILS_COMPONENT_NAME, productSeoName);
  }

  navigateToPackDetailsPage(packSeoName: string) {
    this.navigateToEntityDetailsPageBySeoName(PACK_DETAILS_COMPONENT_NAME, packSeoName);
  }

  navigateToProductJourneyToExcellenceDetailsPage(productJourneyToExcellenceId: string) {
    this.navigateToEntityDetailsPage(PRODUCT_JOURNEY_TO_EXCELLENCE_COMPONENT_NAME, productJourneyToExcellenceId);
  }

  // LIST PAGES

  navigateToCoachListPage() {
    this.navigateToPage(COACH_LIST_COMPONENT_NAME);
  }

  navigateToBlogListPage() {
    this.navigateToPage(BLOG_LIST_COMPONENT_NAME);
  }

  navigateToCorporateSellerListPage() {
    this.navigateToPage(CORPORATE_SELLER_LIST_COMPONENT_NAME);
  }

  navigateToProductListPage() {
    this.navigateToPage(PRODUCT_COMPONENT_NAME);
  }

  navigateToPackListPage() {
    this.navigateToPage(PACK_LIST_COMPONENT_NAME);
  }

  navigateToProductListByCategoryPage(categoryId?: string) {
    if (categoryId) {
      // pass categoryId as query parameter
      const newLink = this.dynamicPathStorageService.getUrlPatternByComponentNameFromCmsPageList(PRESTATION_COMPONENT_NAME);
      if (newLink) {
        this.router.navigate([newLink], {
          queryParams: { category: categoryId },
        });
      }
    } else {
      this.navigateToPage(PRESTATION_COMPONENT_NAME);
    }
  }

  // CMS CONTENT PAGES

  // navigateToAboutUsPage() {
  //     this.navigateToPage(ABOUT_US_COMPONENT_NAME);
  // }

  navigateToContactUsPage() {
    this.navigateToPage(CONTACT_US_CMS_COMPONENT_NAME);
  }

  navigateToEntrepriseHomePage() {
    this.navigateToPage(ENTREPRISE_HOME_PAGE_COMPONENT_NAME);
  }

  navigateToParticularHomePage() {
    this.navigateToPage(PARTICULAR_HOME_PAGE_COMPONENT_NAME);
  }
}
