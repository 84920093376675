import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthServerProvider } from './auth-jwt.service';
import { UserAccountService } from './user-account.service';
import { IUserFacebook } from '../../models/user-facebook';
import { AppStateService } from '../utils/state.service';
import { InboxService } from 'src/app/inbox/inbox.service';
import { Store } from '@ngrx/store';
import { AppState } from '../../store/app.reducer';
import { setBeneficiaryEffect } from '../../store/actions/beneficiary.actions';
import { BENEFICIARY_GROUP } from 'src/app/shared/constants';

@Injectable({ providedIn: 'root' })
export class LoginService {
  constructor(
    private accountService: UserAccountService,
    private authServerProvider: AuthServerProvider,
    private router: Router,
    private appStateServie: AppStateService,
    private inboxService: InboxService,
    private store: Store<AppState>
  ) {}

  login(credentials: { username: ''; password: ''; rememberMe: ''; fcmToken: '' }, callback?: (() => void) | undefined) {
    const cb = callback || function () {};

    return new Promise((resolve, reject) => {
      this.authServerProvider.login(credentials).subscribe(
        data => {
          this.accountService.identity().then(account => {
            this.appStateServie.setUser(account);
            if (account.userGroups.filter(group => group.id == BENEFICIARY_GROUP).length) {
              this.store.dispatch(setBeneficiaryEffect());
            }
            resolve(data);
          });
          return cb();
        },
        err => {
          // this.logout();
          reject(err);
          return cb();
        }
      );
    });
  }

  logout() {
    this.authServerProvider.logout().subscribe(null, null, () => {
      this.accountService.authenticate(null);
      this.router.navigate(['']);
      this.inboxService.interceptNewMessageReceived(false);
    });
    this.appStateServie.setBeneficiary(null);
  }

  loginWithFB(userFB: IUserFacebook, callback?) {
    const cb = callback || function () {};
    return new Promise((resolve, reject) => {
      this.authServerProvider.facebook(userFB).subscribe(
        dataEngagement => {
          this.accountService.identity().then(account => {
            resolve(dataEngagement);
          });
          return cb();
        },
        err => {
          reject(err);
          return cb(err);
        }
      );
    });
  }
  loginWithGoogle(user, callback?) {
    const cb = callback || function () {};
    return new Promise((resolve, reject) => {
      this.authServerProvider.google(user).subscribe(
        dataEngagement => {
          this.accountService.identity().then(account => {
            resolve(dataEngagement);
          });
          return cb();
        },
        err => {
          reject(err);
          return cb(err);
        }
      );
    });
  }
}
