import { Injectable } from '@angular/core';
import { GenericService } from '../services/GenericService';
import { Observable } from 'rxjs';
import { IndividualCoach } from '../models/coach.model';
import { ResponseWrapper } from '../models/response-wrapper.model';
import { createRequestOption } from '../services/utils/request-util';
import { map } from 'rxjs/operators';
import { convertResponse } from '../services/utils/response-converter';
import { environment } from '../../environments/environment';
import { ICmsMediaContent } from '../models/cms-media-content.model';
import { BannerContentImport } from '../models/BannerContentImport.model';
import { ICmsBannerContent } from '../models/CmsBannerContent.model';
import { Store } from '@ngrx/store';
import { AppState } from '../store/app.reducer';
import { LOCALE_ID, LOCALE_STATE } from '../shared/constants';
import { IB2BCustomer } from '../drh-space/models/B2BCustomer';
import { ICompany } from '../models/company.model';

@Injectable({
  providedIn: 'root',
})
export class ServiceProviderService extends GenericService {
  private currentLocaleId: string;

  constructor(private store: Store<AppState>) {
    super();
    this.store.select(LOCALE_STATE, LOCALE_ID).subscribe(localeId => (this.currentLocaleId = localeId));
  }

  getCorporateSellerEmployeeByUserEmail(email: string): Observable<IndividualCoach> {
    return this.api.get('api/corporate-seller-employees/byemail/' + email);
  }
  getCorporateSellerById(id: string): Observable<IndividualCoach> {
    return this.api.get('api/corporate-service-providers/' + id);
  }
  updateCompany(company: ICompany): Observable<ICompany> {
    return this.api.put('api/corporate-service-provider-companies', company);
  }
  getCompany(id: string): Observable<ICompany> {
    return this.api.get('api/companies/' + id);
  }
  findProductList(id: string, currencyId, req?: any): Observable<ResponseWrapper> {
    const options = createRequestOption(req);
    return this.api
      .get('api/product-list/seller/' + id + '/' + this.currentLocaleId + '/' + currencyId, options)
      .pipe(map(res => convertResponse(res)));
  }
  findAllProductList(id: string, currencyId, req?: any): Observable<ResponseWrapper> {
    const options = createRequestOption(req);
    return this.api
      .get('api/product-list/service-provider/' + id + '/' + this.currentLocaleId + '/' + currencyId, options)
      .pipe(map(res => convertResponse(res)));
  }

  updateBannerContent(cmsComponentBannerContent: ICmsBannerContent): Observable<ICmsBannerContent> {
    return this.api.put('api/cms-banner-contents/', cmsComponentBannerContent);
  }

  saveBanner(bannerContentImport: BannerContentImport): Observable<ICmsMediaContent> {
    const token = localStorage.getItem('authenticationToken');
    const formData = new FormData();
    formData.append('file', bannerContentImport.file);
    formData.append('sellerId', bannerContentImport.sellerId);
    formData.append(
      'cmsComponentBannerContent',
      new Blob([JSON.stringify(bannerContentImport.cmsComponentBannerContent)], {
        type: 'application/json',
      })
    );

    const data$ = Observable.create(observer => {
      fetch(environment.apiUrl + 'api/banner-contents-by-seller/bySeller', {
        method: 'post',
        body: formData,
        headers: {
          Authorization: 'Bearer ' + token,
        },
      })
        .then(response => response.json()) // or text() or blob() etc.
        .then(data => {
          observer.next(data);
          observer.complete();
        })
        .catch(err => observer.error(err));
    });
    return data$;
  }

  findBannerContent(sellerId: string): Observable<ICmsBannerContent> {
    return this.api.get('api/banner-contents-by-seller/By-seller/' + sellerId);
  }

  findValidatedBannerContents(sellerId: string): Observable<ICmsBannerContent[]> {
    return this.api.get('api/validated-banner-contents-by-seller/' + sellerId);
  }
  findAllBannerContentsBySeller(sellerId: string): Observable<ICmsBannerContent[]> {
    return this.api.get('api/banner-contents-by-seller/' + sellerId);
  }

  storeMedia(file: File): Observable<any> {
    const formdata: FormData = new FormData();
    formdata.append('file', file);
    return this.api.post('api/upload-media', formdata);
  }

  setBannerContentOnline(id: string, online: boolean): Observable<any> {
    return this.api.get('api/set-online/' + id + '/' + online);
  }

  deleteBannerContent(id: string): Observable<any> {
    return this.api.delete('api/cms-contents/' + id);
  }

  saveDocumentInServiceProvider(file: File, corporateSellerId: string): Observable<any> {
    const formdata: FormData = new FormData();
    formdata.append('file', file);
    return this.api.post('api/upload-media-for-seller/' + corporateSellerId, formdata);
  }

  downloadMediaToDesktop(mediaId: string, format: string): Observable<any> {
    return this.api.get('api/export-media/' + mediaId + ' /' + format, {
      responseType: 'blob',
    });
  }

  exportAppointmentsToExcel(sellerId: string) {
    const op = createRequestOption({ sellerId });
    let options;
    if (sellerId !== null && sellerId !== undefined) {
      options = {
        params: op.params,
        headers: op.headers,
        responseType: 'blob',
      };
    } else {
      options = {
        responseType: 'blob',
      };
    }
    return this.api.get('api/export-appointments', options);
  }
}
