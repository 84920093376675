import { Injectable } from '@angular/core';
import { GenericService } from '../services/GenericService';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { ResponseWrapper } from '../models/response-wrapper.model';
import { createRequestOption } from '../services/utils/request-util';
import { map } from 'rxjs/operators';
import { convertResponse } from '../services/utils/response-converter';

@Injectable({
  providedIn: 'root',
})
export class CorporateSellerService extends GenericService {
  constructor() {
    super();
  }

  downloadMedia(mediaId: string, format: string | null): any {
    return environment.apiUrl + 'api/export-media/' + mediaId + ' /' + format;
  }

  searchCorporateSellers(filterObject, req): Observable<ResponseWrapper> {
    const options = createRequestOption(req);
    return this.api.post('api/indexed-corporate-seller-pagination/', filterObject, options).pipe(map(res => convertResponse(res)));
  }

  // getCorporateSellerById(corporateSellerId: string): Observable<any> {
  //   return this.api.get('api/corporate-sellers/' + corporateSellerId);
  // }

  getCorporateSellerBySeoName(corporateSellerSeoName: string, localId): Observable<any> {
    return this.api.get('api/corporate-seller-by-seo-name/' + corporateSellerSeoName + '/' + localId);
  }

  // getAdvertisingContentBySellerId(corporateSellerId: string): Observable<any> {
  //   return this.api.get('api/cms-banner-contents-to-display-by-seller/' + corporateSellerId);
  // }
}
