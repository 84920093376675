export interface IAnonymousUser {
  id?: string;
  firstName?: string;
  lastName?: string;
  email?: any;
  pseudo?: string;
  phone?: string;
}
export class AnonymousUser implements IAnonymousUser {
  constructor(
    public id?: string,
    public firstName?: string,
    public lastName?: any,
    public email?: string,
    public pseudo?: string,
    public phone?: string
  ) {}
}
