import { Media } from './media.model';
import { ProductType } from './product-basic.model';
import { ISeoData } from '../shared/models/seo-data.model';

export interface IProduct {
  id?: string;
  code?: string;
  online?: boolean;
  onlineDate?: Date;
  offlineDate?: Date;
  productFamilyId?: string;
  isVariant?: boolean;
  productTaxGroupId?: string;
  sellerId?: string;
  sellerName?: string;
  sellerLogoId?: string;
  tags?: string[];
  brandId?: string;
  media?: Media;
  medias?: Media[];
  customerReviewAverageId?: string;
  priceWithPromotion?: any;
  price?: any;
  label?: string;
  metaDescription?: any;
  categoryName?: any;
  description?: string;
  customerReviewAverage?: any;
  productType?: ProductType;
  mediaPlp?: Media;
  seoData?: ISeoData;
  discountPercentage?: number;
}

export class Product implements IProduct {
  constructor(
    public id?: string,
    public isVariant?: boolean,
    public code?: string,
    public online?: boolean,
    public price?: any,
    public sellerLogoId?: string,
    public sellerName?: string,
    public description?: string,
    public customerReviewAverage?: any,
    public onlineDate?: Date,
    public offlineDate?: Date,
    public productFamilyId?: string,
    public metaDescription?: any,
    public productTaxGroupId?: string,
    public sellerId?: string,
    public media?: Media,
    public priceWithPromotion?: any,
    public label?: string,
    public tags?: string[],
    public brandId?: string,
    public customerReviewAverageId?: string,
    public categoryName?: any,
    public productType?: ProductType,
    public mediaPlp?: Media,
    public medias?: Media[],
    public seoData?: ISeoData,
    public discountPercentage?: number
  ) {}
}

export interface IProductList {
  id?: string;
  code?: string;
  online?: boolean;
  sellerName?: string;
  nameAttributeValue?: string;
}

export class ProductList implements IProductList {
  constructor(id?: string, code?: string, online?: boolean, sellerName?: string, nameAttributeValue?: string) {}
}
