/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, OnInit } from '@angular/core';
import { LoginService } from '../../../services/auth/loginone.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SessionStorageService } from 'ngx-webstorage';
import { UserAccountService } from '../../../services/auth/user-account.service';
import { Router } from '@angular/router';
import { StateStorageService } from '../../../services/auth/state-storage.service';
import { FacebookLoginProvider, GoogleLoginProvider, SocialAuthService } from 'angularx-social-login';
import { COACH_GROUP, DRH_GROUP, SERVICE_PROVIDER_GROUP } from 'src/app/shared/constants';
import { UserFacebook } from '../../../models/user-facebook';
import { AuthServerProvider } from '../../../services/auth/auth-jwt.service';
import { AngularFireMessaging } from '@angular/fire/compat/messaging';
import { SocialUserModel } from '../../../models/social-user.model';

@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss'],
})
export class LoginPageComponent implements OnInit {
  account: any;
  authenticationError: boolean;
  socialUser: SocialUserModel;
  currentUser: any;
  key;
  emailExit = true;
  fcmToken: any;
  submitted = false;
  loginForm: FormGroup;
  userIsDisabled = false;
  showPassword = false;

  constructor(
    private loginService: LoginService,
    private formBuilder: FormBuilder,
    private sessionStorageService: SessionStorageService,
    private userAccountService: UserAccountService,
    private router: Router,
    private stateStorageService: StateStorageService,
    public authService: SocialAuthService,
    public authServerProvider: AuthServerProvider,
    private angularFireMessaging: AngularFireMessaging
  ) {
    const authenticationToken = localStorage.getItem('authenticationToken');
    if (authenticationToken) {
      this.router.navigate(['']);
    }
    this.angularFireMessaging.requestToken.subscribe(token => {
      this.fcmToken = token;
    });
  }

  ngOnInit(): void {
    this.loginForm = this.formBuilder.group({
      username: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]],
      rememberMe: [false],
    });
  }

  login(): void {
    this.submitted = true;
    if (this.loginForm?.invalid) {
      return;
    }
    this.loginService
      .login({
        username: this.loginForm.get('username').value,
        password: this.loginForm.get('password').value,
        rememberMe: this.loginForm.get('rememberMe').value,
        fcmToken: this.fcmToken,
      })
      .then(() => {
        const redirect = this.stateStorageService.getUrl();
        if (
          this.router.url === '/register' ||
          this.router.url === '/login' ||
          /^\/activate\//.test(this.router.url) ||
          /^\/reset\//.test(this.router.url)
        ) {
          this.userAccountService.identity().then(account => {
            this.currentUser = account;
            this.sessionStorageService.store('user', account);
            this.userAccountService.setCanPurchase();
            redirect ? this._redirectToCartPage(redirect) : this._redirectToSpace();
          });
        }
        if (redirect) {
          this.stateStorageService.storeUrl(null);
          this.router.navigate([redirect]);
        }
      })
      .catch(error => {
        const detail: string = error?.error?.detail;
        if (detail.includes('was not activated')) {
          this.userIsDisabled = true;
        }
        this.authenticationError = true;
      });
  }
  private _redirectToSpace() {
    if (this.userAccountService.hasAnyAuthorityDirect([DRH_GROUP])) {
      this.router.navigate(['drh-space']);
    } else if (this.userAccountService.hasAnyAuthorityDirect([COACH_GROUP])) {
      this.router.navigate(['coach-space']);
    } else if (this.userAccountService.hasAnyAuthorityDirect([SERVICE_PROVIDER_GROUP])) {
      this.router.navigate(['service-provider-space']);
    } else {
      this.router.navigate(['beneficiary-space']);
    }
  }

  private _redirectToCartPage(redirect) {
    if (redirect) {
      this.stateStorageService.storeUrl(null);
      this.router.navigate([redirect]);
    }
  }

  get f() {
    return this.loginForm?.controls;
  }

  loginWithGoogle(): void {
    this.authService.signIn(GoogleLoginProvider.PROVIDER_ID).then(data => {
      this.socialUser = data;
      this.socialUser.fcmToken = this.fcmToken;
      this.loginService
        .loginWithGoogle(this.socialUser)
        .then(() => {
          const redirect = this.stateStorageService.getUrl();
          this.authenticationError = false;
          this.userAccountService.identity().then(account => {
            this.currentUser = account;
            this.sessionStorageService.store('user', account);
            this.userAccountService.setCanPurchase();
          });
          if (
            this.router.url === '/register' ||
            this.router.url === '/login' ||
            /^\/activate\//.test(this.router.url) ||
            /^\/reset\//.test(this.router.url)
          ) {
            redirect ? this._redirectToCartPage(redirect) : this._redirectToSpace();
          }

          if (redirect) {
            this.stateStorageService.storeUrl(null);
            this.router.navigate([redirect]);
          }
        })
        .catch(() => {
          this.authenticationError = true;
        });
    });
  }

  signInWithFB(): void {
    const loginOptions: any = {
      enable_profile_selector: true,
      return_scopes: true,
      scope: 'email,public_profile',
      auth_type: 'rerequest',
    };
    this.authService.signIn(FacebookLoginProvider.PROVIDER_ID, loginOptions).then(data => {
      this.socialUser = data;
      this.socialUser.fcmToken = this.fcmToken;
      if (!this.socialUser.email) {
        this.authServerProvider.checkIfUserPresent(this.socialUser.id).subscribe(res => {
          if (res) {
            this.loginFB();
          } else {
            this.emailExit = false;
          }
        });
      } else {
        this.loginFB();
      }
    });
  }

  loginFB(): void {
    const tokenFace = this.socialUser.authToken;
    const userFaceBook = new UserFacebook();
    userFaceBook.jwtToken = tokenFace;
    userFaceBook.fcmToken = this.fcmToken;

    this.loginService
      .loginWithFB(userFaceBook)
      .then(() => {
        this.authenticationError = false;
        this.userAccountService.identity().then(account => {
          this.currentUser = account;
          this.sessionStorageService.store('user', account);
          this.userAccountService.setCanPurchase();
        });
        const redirect = this.stateStorageService.getUrl();
        if (
          this.router.url === '/register' ||
          this.router.url === '/login' ||
          /^\/activate\//.test(this.router.url) ||
          /^\/reset\//.test(this.router.url)
        ) {
          redirect ? this._redirectToCartPage(redirect) : this._redirectToSpace();
        }

        if (redirect) {
          this.stateStorageService.storeUrl(null);
          this.router.navigate([redirect]);
        }
      })
      .catch(() => {
        this.authenticationError = true;
      });
  }
}
