<section *ngIf="data && data[0].cmsComponentContent?.elements.length">
  <div *ngFor="let cmsComponentContent of data">
    <div class="section-title">
      <h2>{{ cmsComponentContent.title }}</h2>
      <p>{{ cmsComponentContent.description }}</p>
    </div>
    <section style="background: #0b0b0b">
      <div class="container-fluid p-0">
        <div class="row m-0">
          <div class="col-lg-3 col-sm-6 col-md-6 p-0" *ngFor="let cmsEntity of cmsComponentContent.elements">
            <div class="single-features-box">
              <div>
                <img
                  [src]="getImage(cmsEntity.mainImageId, null)"
                  onerror="this.src='assets/img/not-found.png'"
                  style="height: 260px"
                  [alt]="cmsEntity.name" />
              </div>
              <div class="inner-content">
                <h3>{{ cmsEntity?.name }}</h3>
                <p>{{ cmsEntity?.description }}</p>
                <a routerLink="/" class="default-btn"
                  ><i class="bx bx-log-in-circle icon-arrow before"></i><span class="label">Learn More</span
                  ><i class="bx bx-log-in-circle icon-arrow after"></i
                ></a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</section>
