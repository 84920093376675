import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { CmsService } from '../../../cms.service';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../store/app.reducer';
import { ReplaySubject } from 'rxjs';
import { LOCALE_ID, LOCALE_STATE } from '../../../../shared/constants';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
})
export class ListComponent implements OnInit, OnDestroy {
  @Input() componentId: any;
  data: any;
  currentLocale;
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  constructor(private cmsService: CmsService, private store: Store<AppState>) {}

  ngOnInit(): void {
    this.store
      .select(LOCALE_STATE, LOCALE_ID)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(localeId => {
        this.currentLocale = localeId;
        this.getComponentData();
      });
  }
  getComponentData() {
    this.cmsService
      .getCmsComponentById(this.componentId)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(res => {
        this.data = res.cmsContents?.filter(element => element?.context.id === this.currentLocale);
      });
  }
  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
