<div class="courses-area ptb-100 position-relative">
  <div class="container">
    <div class="courses-topbar">
      <div class="row align-items-center">
        <div class="col-lg-12 col-md-12">
          <div class="topbar-ordering-and-search">
            <div class="row align-items-center">
              <div class="col-sm-6 col-lg-3 offset-lg-2 mt-2">
                <div class="topbar-ordering">
                  <select [(ngModel)]="selectedSortField" (ngModelChange)="sortProduct($event)" class="form-control">
                    <option hidden [ngValue]="null">
                      {{ 'SORT_BY' | translate }}
                    </option>
                    <option *ngFor="let sortChoice of sortChoices" [ngValue]="sortChoice">
                      {{ sortChoice?.label | translate }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-sm-6 col-lg-4 mt-2">
                <div class="topbar-search w-100">
                  <form>
                    <label><i class="bx bx-search"></i></label>
                    <input
                      name="searchInput"
                      (keyup.enter)="page = 0; filterProducts()"
                      [(ngModel)]="filterObject.searchValue"
                      type="text"
                      class="input-search"
                      [placeholder]="translate.instant('SEARCH_HERE')" />
                  </form>
                </div>
              </div>
              <div class="col-lg-3 mt-2 text-right text-lg-left">
                <button
                  (click)="page = 0; filterProducts()"
                  class="btn text-white mx-2 optional-bg border border-0"
                  style="outline: none; box-shadow: none">
                  {{ 'FILTER' | translate }}
                </button>
                <button class="btn btn-secondary" style="outline: none; box-shadow: none" (click)="resetFilter()">
                  {{ 'RESET' | translate }}
                </button>
              </div>
            </div>
            <div></div>
          </div>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="products && products.length > 0; else noProducts">
      <hro-product-card
        class="col-lg-4 col-md-6"
        [productFamily]="productFamily"
        *ngFor="let product of products"
        [detailsPageUrlPattern]="product.productType === PRODUCT_PRODUCT_TYPE ? productDetailsPageUrlPattern : packDetailsPageUrlPattern"
        [product]="product"></hro-product-card>
      <div class="col-lg-12 col-md-12 col-sm-12">
        <div class="pagination-area text-center">
          <mat-paginator
            (page)="handlePageEvent($event)"
            [length]="totalItems"
            [pageSize]="size"
            [showFirstLastButtons]="false"
            [pageIndex]="page">
          </mat-paginator>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #noProducts>
  <div class="no-data mt-3 mb-5" *ngIf="!onlyPacks && !isFetchingProducts">
    {{ 'NO_PRODUCT_FOUND' | translate }}
  </div>
  <div class="no-data mt-3 mb-5" *ngIf="onlyPacks && !isFetchingProducts">
    {{ 'NO_PACK_FOUND' | translate }}
  </div>
</ng-template>
