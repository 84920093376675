<div class="page-title-area item-bg1">
  <div class="container">
    <div class="page-title-content">
      <ul>
        <li>
          <a href="index.html">{{ 'HOME' | translate }}</a>
        </li>
        <li>{{ 'PRODUCTS' | translate }}</li>
      </ul>
      <h2>{{ 'PRODUCTS' | translate }}</h2>
    </div>
  </div>
</div>
<section class="product-details my-5">
  <div class="container" style="min-height: 300px">
    <div class="row" *ngIf="product && product.id">
      <!-- product image(s) -->
      <div class="col-md-5 text-center">
        <ng-container *appIfBrowserPlatform>
          <owl-carousel-o [options]="productImageSlides" *ngIf="product && product.medias && product.medias.length">
            <ng-template carouselSlide *ngFor="let media of product.medias">
              <img
                class="product-image mw-100"
                style="min-width: 100%; max-height: 500px"
                [alt]="media.alt ?? product.label"
                [src]="media.url ? media.publicUrl : getImage(media.id, 'PDP')"
                onerror="this.src='assets/img/not-found.png'" />
            </ng-template>
          </owl-carousel-o>
        </ng-container>
        <ng-container *appIfServerPlatform>
          <img
            *ngIf="product.id && product.medias.length"
            [src]="product.medias[0].url ? product.medias[0].publicUrl : getImage(product.medias[0].id, 'PDP')"
            [alt]="product.medias[0].alt ?? product.label"
            class="product-image mw-100"
            style="max-height: 500px"
            onerror="this.src='assets/img/not-found.png'" />
        </ng-container>

        <img
          *ngIf="product.id && !product.medias.length"
          src="assets/img/not-found.png"
          [alt]="product.label"
          class="product-image mw-100"
          style="max-height: 500px" />
      </div>
      <!-- product infos -->
      <div class="col-md-7 position-relative">
        <div class="row">
          <div class="col-lg-9 order-last order-lg-first">
            <h3 class="mt-2 theme-color-main">{{ product.label }}</h3>
            <i *ngIf="product.categoryName" class="bx bx-folder-open mr-2" style="font-size: 25px; color: #cfcfcf"></i>
            <a *ngIf="product.categoryName" style="font-size: 23px">{{ product.categoryName.localizedValues[this.currentLocale] }}</a>
          </div>
          <div class="offset-4 offset-sm-8 offset-lg-0 col-8 col-sm-4 col-lg-3 text-right" *ngIf="product.sellerId">
            <img
              class="seller-image shadow-sm"
              style="width: 250px; max-width: 100%"
              [src]="getImage(product.sellerLogoId, null)"
              onerror="this.src='assets/img/not-found.png'"
              [alt]="product.sellerName ?? 'image'" />
          </div>
        </div>
        <!-- <p class="h5 mt-2" style="display: flex;" *ngIf="product?.discountPercentage">
                    <span class="theme-color-optional mr-3" style="flex: 1">{{'DISCOUNT' | translate}}</span>
                    <span class="text-dark" style="flex: 3">{{product?.discountPercentage }}%</span>
                </p> -->
        <div [class.position-absolute]="isOnLargeScreen" style="bottom: 0">
          <ng-container *ngIf="displayPrice">
            <ng-container *ngIf="hasUniquePrice && isPassexcellenceProduct">
              <h6 class="main-color">{{ 'MEMBER_PRICE' | translate }}</h6>
              <span class="d-block text-bold h5 mt-2 text-dark" style="color: var(--optionalColor)"
                >{{
                  product?.priceWithPromotion?.amount
                    | currency
                      : product?.priceWithPromotion?.currency.isoCode
                      : ''
                      : '0.' + product?.priceWithPromotion?.currency.numberOfDigistAfterDecimalsign + '-3'
                }}
                {{ product?.priceWithPromotion?.currency.isoCode }}</span
              >
            </ng-container>

            <ng-container *ngIf="!hasUniquePrice">
              <ng-container *ngIf="product?.price?.amount > 0 && product?.priceWithPromotion?.amount > 0">
                <p class="h5 mt-2" style="display: flex">
                  <span style="flex: 1" class="theme-color-optional mr-3">{{ 'MEMBER_PRICE' | translate }}</span>
                  <span style="flex: 3" class="text-dark"
                    >{{
                      product?.priceWithPromotion?.amount
                        | currency
                          : product?.priceWithPromotion?.currency.isoCode
                          : ''
                          : '0.' + product?.priceWithPromotion?.currency.numberOfDigistAfterDecimalsign + '-3'
                    }}
                    {{ product?.priceWithPromotion?.currency.isoCode }}</span
                  >
                </p>
                <p class="h5" style="display: flex">
                  <span style="flex: 1" class="theme-color-optional mr-3">{{ 'PUBLIC_PRICE' | translate }}</span>
                  <span style="flex: 3" class="text-dark"
                    >{{
                      product?.price?.amount
                        | currency
                          : product?.price?.currency.isoCode
                          : ''
                          : '0.' + product?.price?.currency.numberOfDigistAfterDecimalsign + '-3'
                    }}
                    {{ product?.price?.currency.isoCode }}</span
                  >
                </p>
              </ng-container>
            </ng-container>
            <ng-template #notPrice>
              <div style="display: flex; justify-content: space-between">
                <a routerLink="/beneficiary-space" class="become-member text-break">{{ 'GET_DISCOUNT' | translate }}</a>
              </div>
            </ng-template>
          </ng-container>
          <ng-container>
            <div>
              <ng-container *ngIf="isAuthenticatedAsDRHOrBeneficiary && isPassexcellenceProduct">
                <a style="color: #ffffff" (click)="addToCart()" class="default-btn ml-0 cursor-pointer"
                  ><i class="bx bx-cart icon-arrow before"></i><span class="label">{{ 'BUY' | translate }}</span
                  ><i class="bx bx-cart icon-arrow after"></i
                ></a>
              </ng-container>
              <ng-container *ngIf="isAuthenticatedAsDRHOrBeneficiary && !isPassexcellenceProduct">
                <span class="main-color d-block">
                  <span class="first-letter-lowercase">
                    {{ ('BENEFIT_FROM_REDUCTION_FOR_MEMBERS_OF' | translate) + ' ' + (product.discountPercentage || 0) + ' %' }}
                  </span>
                </span>
                <a style="color: #ffffff" (click)="addToCart()" class="default-btn ml-0 cursor-pointer"
                  ><i class="bx bxs-shopping-bag icon-arrow before"></i><span class="label">{{ 'RESERVE' | translate }}</span
                  ><i class="bx bxs-shopping-bag icon-arrow after"></i
                ></a>
              </ng-container>
              <ng-container *ngIf="!authenticatedUser && !isPassexcellenceProduct">
                <span class="main-color d-block">{{ 'BENEFIT_FROM_REDUCTION_FOR_MEMBERS' | translate }}</span>
              </ng-container>
              <a
                *ngIf="canBecomeMember && !canAddToCart && !authenticatedUser"
                (click)="navigateToUserSpace()"
                style="color: #ffffff"
                class="default-btn ml-0 cursor-pointer"
                ><i class="bx bx-log-in-circle icon-arrow before"></i><span class="label"> {{ 'BECOME_A_MEMBER' | translate }} </span
                ><i class="bx bx-log-in-circle icon-arrow after"></i
              ></a>
            </div>
          </ng-container>
        </div>
      </div>
      <div class="col-12">
        <app-read-wysiwyg *ngIf="product.description" [value]="product.description"></app-read-wysiwyg>
      </div>
      <!-- reviews -->
      <div class="col-12">
        <div class="products-details-tab mt-5">
          <ngx-tabset>
            <ngx-tab tabTitle="Reviews">
              <app-product-reviews [product]="product"></app-product-reviews>
            </ngx-tab>
          </ngx-tabset>
        </div>
      </div>
    </div>
  </div>
</section>
