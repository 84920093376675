<div [ngSwitch]="componentId">
  <app-hro-tarif *ngSwitchCase="'ADD_BANNER'" [data]="data"></app-hro-tarif>
  <app-hro-statistics *ngSwitchCase="'STATISTICS'" [data]="data"></app-hro-statistics>
  <div *ngSwitchDefault style="text-align: justify">
    <div *ngIf="data && data.id">
      <div>
        <div class="html-container" *ngIf="data" [innerHTML]="innerHtml"></div>
      </div>
    </div>
  </div>
</div>
