import { AbstractOrder, IAbstractOrder } from './abstractOrder.model';
import { IOrderLine } from './orderLine.model';

export interface IOrder extends IAbstractOrder {
  orderStatusId?: string;
  createdBy?: string;
  createdDate?: Date;
  lastModifiedBy?: string;
  lastModifiedDate?: Date;
  orderLines?: IOrderLine[];
  totalDiscount?: number;
}

export class Order extends AbstractOrder implements IOrder {
  constructor(
    public orderStatusId?: string,
    public createdBy?: string,
    public createdDate?: Date,
    public lastModifiedBy?: string,
    public lastModifiedDate?: Date,
    public orderLines?: IOrderLine[],
    public totalDiscount?: number
  ) {
    super();
    this.orderLines = this.orderLines ? this.orderLines : [];
  }
}
