import { Component, OnDestroy, OnInit } from '@angular/core';
import { IContext } from '../../../cms/models/context.model';
import { ReplaySubject } from 'rxjs';
import { ContextService } from '../../../cms/context.service';
import { CmsService } from '../../../cms/cms.service';
import { takeUntil } from 'rxjs/operators';
import { AppStateService } from 'src/app/services/utils/state.service';
import { CONTACT } from '../../../shared/constants';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss'],
})
export class ContactComponent implements OnInit, OnDestroy {
  currentLocale: string;
  contextItemIds: string[] | undefined;
  context: IContext | null | undefined;
  components: any[] | undefined;
  component: any;
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  constructor(private contextService: ContextService, private cmsService: CmsService, appStateService: AppStateService) {
    this.currentLocale = appStateService.getCurrentLocaleId();
  }
  ngOnInit(): void {
    this.getContext();
    this.getCmsPage();
  }

  getContext(): void {
    this.contextItemIds = [];
    this.context = null;
    this.contextItemIds.push(this.currentLocale);
    this.contextService
      .findContext(this.contextItemIds)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(context => {
        this.context = context;
      });
  }

  getCmsPage(): void {
    this.cmsService
      .getCmsPageByCode(CONTACT)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(res => {
        this.components = res.cmsComponents;
      });
  }

  getIdComponentByCode(code: string): any {
    if (this.components && this.components.length > 0) {
      const index = this.components.findIndex(component => component.code === code);
      if (index !== -1) {
        return this.components[index].id;
      }
    }
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
