import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../../store/app.reducer';
import { ReplaySubject } from 'rxjs';
import {
  FRENCH_FRANCE,
  LOCALE_ID,
  LOCALE_STATE,
  PARTICULAR_ENTREPRISE_BANNER_CODE,
  PARTICULAR_ENTREPRISE_PAGE_CODE,
} from '../../../shared/constants';
import { takeUntil } from 'rxjs/operators';
import { CmsService } from '../../cms.service';

@Component({
  selector: 'app-home-header',
  templateUrl: './home-header.component.html',
  styleUrls: ['./home-header.component.scss'],
})
export class HomeHeaderComponent implements OnInit, OnDestroy {
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  @Input() componentCode: PARTICULAR_ENTREPRISE_BANNER_CODE;
  currentLocaleId: string = FRENCH_FRANCE;
  banner;
  @Input() pageCode: PARTICULAR_ENTREPRISE_PAGE_CODE;
  currentLocaleContent: any = {};
  imageFileName = '';
  readonly pageCodeEntreprise = PARTICULAR_ENTREPRISE_PAGE_CODE.ENTREPRISE;
  readonly pageCodeParticular = PARTICULAR_ENTREPRISE_PAGE_CODE.PARTICULAR;

  constructor(private store: Store<AppState>, private cmsService: CmsService) {}

  ngOnInit(): void {
    if (this.pageCode === this.pageCodeEntreprise) {
      this.imageFileName = 'entreprise.webp';
    } else {
      this.imageFileName = 'particular-img.webp';
    }
    this.store
      .select(LOCALE_STATE, LOCALE_ID)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(localeId => {
        this.currentLocaleId = localeId;
        this.getBanner();
      });
  }

  getBanner() {
    const banner$ = this.cmsService.getCmsComponentById(this.componentCode);
    // const banner$ = this.cmsService.getCmsComponentsByPageCodeAndCode(this.pageCode, this.componentCode);
    banner$.pipe(takeUntil(this.destroyed$)).subscribe(banner => {
      this.banner = banner;
      this.currentLocaleContent = this.banner.cmsContents.find(cmsContent => cmsContent.context.id === this.currentLocaleId) ?? {};
    });
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
