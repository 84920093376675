import { BehaviorSubject, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { InboxConversation } from './model/InboxConversation.model';
import { environment } from '../../environments/environment';
import { InboxMessage } from './model/InboxMessage.model';
import { InboxMessageToGet } from './model/InboxMessageToGet.model';

@Injectable({ providedIn: 'root' })
export class InboxService {
  private newMessageReceived = new BehaviorSubject(false);
  sharedNewMessage = this.newMessageReceived.asObservable();

  private inboxConversationId = new BehaviorSubject(null);
  sharedInboxConversationId = this.inboxConversationId.asObservable();

  private newCount = new BehaviorSubject(false);
  sharedNewCount = this.newCount.asObservable();

  constructor(private http: HttpClient) {}

  public findAll() {
    return this.http.get<InboxConversation[]>(environment.apiUrl + 'api/' + 'inbox-conversations');
  }

  public findAllByUser(userId) {
    return this.http.get<InboxConversation[]>(environment.apiUrl + 'api/' + 'inbox-conversations/ByUser/' + userId);
  }
  public findAllByUserMarkedAsUnRead(userId): Observable<any> {
    return this.http.get(environment.apiUrl + 'api/' + 'inbox-conversations/markedAsUnRead/' + userId);
  }
  public add(inboxConversation: InboxConversation): Observable<InboxConversation> {
    return this.http.post(environment.apiUrl + 'api/' + 'inbox-conversations', inboxConversation);
  }
  public update(inboxConversation: InboxConversation): Observable<InboxConversation> {
    return this.http.put(environment.apiUrl + 'api/' + 'inbox-conversations', inboxConversation);
  }

  public getMessages(inboxConversationId: string) {
    return this.http.get<InboxMessageToGet[]>(environment.apiUrl + 'api/' + 'inbox-messages-by-conversation/' + inboxConversationId);
  }
  public getParticipantInboxConversationInfo(id: string) {
    return this.http.get<any>(environment.apiUrl + 'api/' + 'participant-inbox-conversation-infos/' + id);
  }

  public sendMessage(inboxMessage: InboxMessage) {
    return this.http.post<InboxMessage>(environment.apiUrl + 'api/' + 'inbox-messages', inboxMessage);
  }

  public interceptNewMessageReceived(value) {
    this.newMessageReceived.next(value);
  }

  public interceptCount() {
    this.newCount.next(true);
  }

  public interceptConversationId(value) {
    this.inboxConversationId.next(value);
  }

  public searchInboxConversation(name: string) {
    return this.http.get<InboxConversation[]>(environment.apiUrl + 'api/' + 'search-inbox-conversations/' + name);
  }

  public getLastMessage(inboxConversationId: string) {
    return this.http.get<any>(environment.apiUrl + 'api/' + 'last-inbox-messages/' + inboxConversationId);
  }
}
