<section class="courses-area pt-40 pb-40">
  <div class="container" *ngFor="let cmsComponentContent of data">
    <div class="section-title">
      <h2>{{ cmsComponentContent.title }}</h2>
      <p>{{ cmsComponentContent.description }}</p>
    </div>
    <ngx-tabset>
      <ngx-tab>
        <div class="text-center mb-3" style="margin-top: -50px">
          <a [routerLink]="productListPageUrl$ | async">
            <u>{{ 'ALL' | translate }}</u>
          </a>
        </div>
        <div class="row">
          <hro-product-card
            class="col-lg-4 col-md-6"
            *ngFor="let cmsEntity of cmsComponentContent.elements"
            [product]="cmsEntity"
            [detailsPageUrlPattern]="
              cmsEntity.productType === PRODUCT_PRODUCT_TYPE ? productDetailsPageUrlPattern : packDetailsPageUrlPattern
            "></hro-product-card>
        </div>
      </ngx-tab>
    </ngx-tabset>
  </div>
</section>
