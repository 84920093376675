import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BlogDetailsComponent } from './blog-details/blog-details.component';
import { RouterModule } from '@angular/router';
import { blogRoutes } from './blog.routing';
import { BlogListComponent } from './blog-list/blog-list.component';
import { SharedModule } from '../shared/shared.module';
import { MatPaginatorModule } from '@angular/material/paginator';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [BlogDetailsComponent, BlogListComponent],
  imports: [CommonModule, RouterModule, RouterModule.forChild(blogRoutes), SharedModule, MatPaginatorModule, TranslateModule],
})
export class BlogModule {}
