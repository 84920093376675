import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { ProductService } from '../../../services/product.service';
import { CmsService } from '../../cms.service';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'hro-popular-packs',
  templateUrl: './hro-popular-packs.component.html',
  styleUrls: ['./hro-popular-packs.component.scss'],
})
export class HroPopularPacksComponent implements OnInit, OnDestroy {
  @Input() componentId: any;
  data: any;
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  constructor(private productService: ProductService, private cmsService: CmsService) {}
  ngOnInit(): void {
    this.cmsService
      .getCmsComponentById(this.componentId)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(res => {
        this.data = res.cmsContents;
      });
  }

  getImage(image: any, format: string | null): any {
    if (!image) {
      return;
    }
    return this.productService.downloadMedia(image, format);
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
