import { Injectable } from '@angular/core';
import { GenericService } from './GenericService';

@Injectable({
  providedIn: 'root',
})
export class SocialMediaNetworkService extends GenericService {
  constructor() {
    super();
  }

  getAll() {
    return this.api.get('api/social-media-networks/');
  }
}
