import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserAccountService } from '../../../services/auth/user-account.service';
import { BeneficiaryProfil } from '../../../models/BeneficiaryProfil';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { LocalStorageService, SessionStorageService } from 'ngx-webstorage';
import { BeneficiaryDevelopmentAxis } from '../../../models/BeneficiaryDevelopmentAxisDTO.model';
import { ProductService } from '../../../services/product.service';
import { BeneficiaryService } from '../../../beneficiary-space/beneficiary.service';
import {ActionStatus} from "../../../models/Action.model";

@Component({
  selector: 'app-beneficiary-profil',
  templateUrl: './beneficiary-profil.component.html',
  styleUrls: ['./beneficiary-profil.component.scss'],
})
export class BeneficiaryProfilComponent implements OnInit {
  beneficiaryCode: string;
  beneficiaryProfil: any;
  beneficiaryDevelopmentAxises = new BeneficiaryDevelopmentAxis();
  condition = new Map<string, number>();


  constructor(
    private route: ActivatedRoute,
    private userAccountService: UserAccountService,
    private toastr: ToastrService,
    private translationService: TranslateService,
    private router: Router,
    private sessionStorage: SessionStorageService,
    private productService: ProductService,
    private localStorage: LocalStorageService,
    private beneficiaryService: BeneficiaryService,
    private translate: TranslateService
  ) {}

    identifyer = (index: number, item: any) => item.id;


  ngOnInit(): void {
    this.beneficiaryCode = this.route.snapshot.params['code'];
    this.userAccountService.getBeneficiaryProfilByCode(this.beneficiaryCode).subscribe(
      res => {
        this.beneficiaryProfil = res;
        const currencyId = this.localStorage.retrieve('currencyId');
        this.beneficiaryService.getBeneficiaryByUserLoginForUserProfil(this.beneficiaryProfil.email, currencyId).subscribe(
          response => {
            let responseData: any;
            responseData = response;
            this.beneficiaryDevelopmentAxises = responseData.body;
          },
          error => {
            this.toastr.error(this.translationService.instant('QR_CODE_NOT_VALID_OR_USER_DOESNT_EXIST'));
            this.router.navigateByUrl('/');
          }
        );
      },
      error => {
        this.toastr.error(this.translationService.instant('QR_CODE_NOT_VALID_OR_USER_DOESNT_EXIST'));
        this.router.navigateByUrl('/');
      }
    );
  }

  getImage(image: any, format: string | null): any {
    if (!image) {
      return;
    }
    return this.productService.downloadMedia(image, format);
  }

    getWidth(width: number): string {
        return (width ? width.toString() : 0) + '%';
    }

    public get actionStatus(): typeof ActionStatus {
        return ActionStatus;
    }

}
