import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { DynamicPathRoutingService } from '../../../services/dynamic-path-routing.service';
import { map, takeUntil } from 'rxjs/operators';
import { ENTREPRISE_HOME_PAGE_COMPONENT_NAME, PARTICULAR_HOME_PAGE_COMPONENT_NAME } from '../../../ComponentsIndex';
import { DynamicPathStorageService } from '../../../services/dynamic-path-storage.service';
@Component({
  selector: 'app-hro-banner',
  templateUrl: './hro-banner.component.html',
  styleUrls: ['./hro-banner.component.scss'],
})
export class HroBannerComponent implements OnInit, OnDestroy {
  @Input()
  data: any;
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  @Input()
  videoComponentCode: string;
  entrepriseHomePageUrl$: Observable<string> = new Observable<string>();
  particularHomePageUrl$: Observable<string> = new Observable<string>();

  constructor(private dynamicPathRoutingService: DynamicPathRoutingService, private dynamicPathStorageService: DynamicPathStorageService) {}

  ngOnInit(): void {
    this.entrepriseHomePageUrl$ = this.getUrlPatternObservable(ENTREPRISE_HOME_PAGE_COMPONENT_NAME);
    this.particularHomePageUrl$ = this.getUrlPatternObservable(PARTICULAR_HOME_PAGE_COMPONENT_NAME);
  }

  getUrlPatternObservable(componentName) {
    return this.dynamicPathStorageService.urlPatterns$.pipe(
      takeUntil(this.destroyed$),
      map(() => this.dynamicPathStorageService.getUrlPatternByComponentNameFromCmsPageList(componentName))
    );
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
