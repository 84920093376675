/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable-next-line @typescript-eslint/no-explicit-any */

import { createReducer, on } from '@ngrx/store';
import { IBeneficiary } from '../../models/Beneficiary.model';
import * as BeneficiaryActions from '../actions/beneficiary.actions';

export interface BeneficiaryState {
  beneficiary: IBeneficiary;
}
export const initialState: BeneficiaryState = {
  beneficiary: null,
};

const beneficiaryReducer = createReducer(
  initialState,
  on(BeneficiaryActions.setBeneficiary, (state, action) => {
    return {
      ...state,
      beneficiary: action.beneficiary,
    };
  }),
  on(BeneficiaryActions.updateDevelopmentAxis, (state, action) => ({
    ...state,
    beneficiary: {
      ...state.beneficiary,
      beneficiaryDevelopmentAxises: state.beneficiary.beneficiaryDevelopmentAxises.map(devAxis =>
        devAxis.id === action.devAxis.id ? action.devAxis : devAxis
      ),
    },
  })),
  on(BeneficiaryActions.addDevelopmentAxis, (state, action) => ({
    ...state,
    beneficiary: {
      ...state.beneficiary,
      beneficiaryDevelopmentAxises: [...state.beneficiary.beneficiaryDevelopmentAxises, action.devAxis],
    },
  })),
  on(BeneficiaryActions.addDevAxisAction, (state, action) => ({
    ...state,
    beneficiary: {
      ...state.beneficiary,
      beneficiaryDevelopmentAxises: state.beneficiary.beneficiaryDevelopmentAxises.map(devAxis =>
        devAxis.id !== action.devAxisId
          ? devAxis
          : {
              ...devAxis,
              actions: [...devAxis.actions, action.devAxisAction],
            }
      ),
    },
  })),
  on(BeneficiaryActions.updateDevAxisAction, (state, action) => ({
    ...state,
    beneficiary: {
      ...state.beneficiary,
      beneficiaryDevelopmentAxises: state.beneficiary.beneficiaryDevelopmentAxises.map(devAxis =>
        devAxis.id !== action.devAxisId
          ? devAxis
          : {
              ...devAxis,
              actions: [
                ...devAxis.actions.map(devAxisAction =>
                  devAxisAction.id !== action.devAxisAction.id ? devAxisAction : action.devAxisAction
                ),
              ],
            }
      ),
    },
  })),
  on(BeneficiaryActions.deleteDevAxisAction, (state, action) => ({
    ...state,
    beneficiary: {
      ...state.beneficiary,
      beneficiaryDevelopmentAxises: state.beneficiary.beneficiaryDevelopmentAxises.map(devAxis =>
        devAxis.id !== action.devAxisId
          ? devAxis
          : {
              ...devAxis,
              actions: [...devAxis.actions.filter(act => act.id !== action.devAxisActionId)],
            }
      ),
    },
  }))
);

export function BeneficiaryReducer(state: any, action: any) {
  return beneficiaryReducer(state, action);
}
