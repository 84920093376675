import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { LocalStorageService } from 'ngx-webstorage';
import { GenericService } from './GenericService';
import { IPayment } from '../models/payment.model';
import { CLICTOPAY_PAGEVIEW_DESKTOP, CLICTOPAY_PAGEVIEW_MOBILE, LANGUAGE } from '../shared/constants';
import { DeviceDetectorService } from 'ngx-device-detector';

@Injectable({ providedIn: 'root' })
export class PaymentService extends GenericService {
  private currentLocale: any;
  private pageView = 'DESKTOP';
  constructor(private localStorage: LocalStorageService, private deviceService: DeviceDetectorService) {
    super();

    const isDesktop = this.deviceService.isDesktop();
    const isTablet = this.deviceService.isTablet();
    const isMobile = this.deviceService.isMobile();

    if (isDesktop) {
      this.pageView = CLICTOPAY_PAGEVIEW_DESKTOP;
    }
    if (isTablet) {
      this.pageView = CLICTOPAY_PAGEVIEW_MOBILE;
    }
    if (isMobile) {
      this.pageView = CLICTOPAY_PAGEVIEW_MOBILE;
    }
  }

  create(payment: IPayment): Observable<IPayment> {
    return this.api.post('api/payments/', payment);
  }

  update(payment: IPayment): Observable<IPayment> {
    return this.api.put('api/payments/', payment);
  }

  find(id: string): Observable<IPayment> {
    return this.api.get('api/payments/' + id);
  }

  findByPspOrderId(pspOrderId: string): Observable<IPayment> {
    return this.api.get('api/payments/psp-order-provider/' + pspOrderId);
  }

  findAll(): Observable<IPayment[]> {
    return this.api.get('api/payments/');
  }

  delete(id: string): Observable<any> {
    return this.api.delete('api/payments/' + id);
  }

  authorizeToPaymentProvider(cartId: string, paymentCode: string): Observable<any> {
    this.currentLocale = this.localStorage.retrieve('languageId');
    return this.api.get(
      'api/payment-provider-authorisation/' + cartId + '/' + paymentCode + '/' + this.currentLocale + '/' + this.pageView
    );
  }
  getPaymentStatus(orderId: string, orderNumber: string): Observable<any> {
    this.currentLocale = this.localStorage.retrieve(LANGUAGE);
    return this.api.get('api/payment-status-response/' + orderId + '/' + orderNumber + '/' + this.currentLocale);
  }
}
