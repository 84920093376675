/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { ILocale, Locale } from 'src/app/models/locale.model';
import { AppStateService } from 'src/app/services/utils/state.service';
import { getSelectedItemById } from 'src/app/services/utils/tools';
import { PromptComponentComponent } from '../../components/pwa/prompt-component/prompt-component.component';
import { UserAccountService } from '../../services/auth/user-account.service';
import { LoginService } from '../../services/auth/loginone.service';
import { CartService } from 'src/app/services/cart.service';
import { ICartLine } from 'src/app/models/cartLine.model';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { WebNotificationService } from '../../services/web-notification.service';
import { IUserWebNotification } from '../../models/user-web-notification.model';
import { BENEFICIARY_GROUP, COACH_GROUP, DRH_GROUP, LOCALE_STATE, SCREEN_SIZE, SERVICE_PROVIDER_GROUP } from '../constants';
import { InboxService } from '../../inbox/inbox.service';
import { Store } from '@ngrx/store';
import { AppState } from '../../store/app.reducer';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../environments/environment';
import { LocalStorageService, SessionStorageService } from 'ngx-webstorage';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import {
  ABOUT_US_COMPONENT_NAME,
  BLOG_LIST_COMPONENT_NAME,
  COACH_LIST_COMPONENT_NAME,
  CORPORATE_SELLER_LIST_COMPONENT_NAME,
  PRESTATION_COMPONENT_NAME,
} from '../../ComponentsIndex';
import { map, takeUntil } from 'rxjs/operators';
import { Observable, ReplaySubject } from 'rxjs';
import { DynamicPathStorageService } from '../../services/dynamic-path-storage.service';
import { ResizeService } from '../../services/resize.service';

@Component({
  selector: 'app-hro-navbar',
  templateUrl: './hro-navbar.component.html',
  styleUrls: ['./hro-navbar.component.scss'],
})
export class HroNavbarComponent implements OnInit, OnDestroy {
  locales: ILocale[];
  currentLocale: ILocale;
  currentLocaleId: string;
  cartItem: ICartLine[] = [];
  totalItems = 0;
  DRH: boolean;
  totalNotificationItems = 0;
  totalMessages = 0;
  webNotifications: IUserWebNotification[] = [];
  show = false;
  currentUser;
  showCartIcon = true;
  previewBaseUrl = environment.apiUrl + 'api/style-sheets-by-theme/';
  previewAvailableThemeBaseUrl = environment.apiUrl + 'api/style-sheet-by-theme-version-variation/';
  styleSheetUrl = '';
  themeId;
  availableThemeId: string;
  user;

  coachListPageUrl$: Observable<string> = new Observable<string>();
  corporateSellerListPageUrl$: Observable<string> = new Observable<string>();
  blogListPageUrl$: Observable<string> = new Observable<string>();
  aboutUsPageUrl$: Observable<string> = new Observable<string>();
  productListByCategoryPageUrl: string;
  spaceLink: string = null;
  showInboxNotification = false;

  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  currentRoute: string;
  screenSize: SCREEN_SIZE;
  isOnSmallScreen = false;

  constructor(
    @Inject(MAT_BOTTOM_SHEET_DATA)
    public data: { mobileType: 'ios' | 'android'; promptEvent?: any },
    private bottomSheetRef: MatBottomSheetRef<PromptComponentComponent>,
    private appStateService: AppStateService,
    private userAccountService: UserAccountService,
    private loginService: LoginService,
    private cartService: CartService,
    private router: Router,
    private inboxService: InboxService,
    private cd: ChangeDetectorRef,
    private webNotificationService: WebNotificationService,
    private store: Store<AppState>,
    public translate: TranslateService,
    private activatedRoute: ActivatedRoute,
    @Inject(DOCUMENT) private document,
    public sessionStorage: LocalStorageService,
    private dynamicPathStorageService: DynamicPathStorageService,
    @Inject(PLATFORM_ID) private platformId,
    private resizeService: ResizeService
  ) {
    userAccountService.canPurchase.subscribe(value => {
      this.showCartIcon = value;
    });
  }

  classApplied = false;

  classApplied2 = false;

  ngOnInit(): void {
    this.currentRoute = this.router.url;
    this.router.events
      .filter(event => event instanceof NavigationEnd)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        this.currentRoute = this.router.url;
      });

    this.activatedRoute.params.subscribe(params => {
      if (params.previewThemeId) {
        this.sessionStorage.clear('availableThemeId');
        this.sessionStorage.store('themeId', params.previewThemeId);
        this.themeId = this.sessionStorage.retrieve('themeId');
        this.styleSheetUrl = this.previewBaseUrl + this.themeId;
        this.document.getElementById('customCss').setAttribute('href', this.styleSheetUrl);
      }
      if (params.previewAvailableThemeId) {
        this.sessionStorage.clear('themeId');
        this.sessionStorage.store('availableThemeId', params.previewAvailableThemeId);
        this.availableThemeId = this.sessionStorage.retrieve('availableThemeId');
        this.styleSheetUrl = this.previewAvailableThemeBaseUrl + this.availableThemeId;
        this.document.getElementById('customCss').setAttribute('href', this.styleSheetUrl);
      }
    });
    if (this.isAuthenticated()) {
      this.getNotReadNotifications();
      this.getNotReadMessages();
    }
    this.store.select(LOCALE_STATE).subscribe(localeState => {
      this.currentLocaleId = localeState.localeId;
      this.locales = localeState.locales;
      if (localeState.locales?.length) {
        this.currentLocale = getSelectedItemById<Locale>(this.currentLocaleId, localeState.locales);
      }
      this.getCart();
    });
    this.getCart();
    this.user = this.sessionStorage.retrieve('user');
    this.setLinkToMySpace();
    if (isPlatformBrowser(this.platformId)) {
      this.sessionStorage.observe('user').subscribe(user => {
        this.user = user;
        this.setLinkToMySpace();
      });
    }

    this.webNotificationService.sharedNewVote.subscribe(res => {
      if (res) {
        this.getNotReadNotifications();
      }
    });
    this.inboxService.sharedNewCount.subscribe(res => {
      if (res) {
        this.getNotReadMessages();
      }
    });

    this.coachListPageUrl$ = this.getUrlPatternObservable(COACH_LIST_COMPONENT_NAME);
    this.corporateSellerListPageUrl$ = this.getUrlPatternObservable(CORPORATE_SELLER_LIST_COMPONENT_NAME);
    this.blogListPageUrl$ = this.getUrlPatternObservable(BLOG_LIST_COMPONENT_NAME);
    this.aboutUsPageUrl$ = this.getUrlPatternObservable(ABOUT_US_COMPONENT_NAME);
    this.getUrlPatternObservable(PRESTATION_COMPONENT_NAME).subscribe(urlPattern => (this.productListByCategoryPageUrl = urlPattern));

    this.resizeService.onResize$.pipe(takeUntil(this.destroyed$)).subscribe(screenSize => {
      this.screenSize = screenSize;
      this.isOnSmallScreen = this.screenSize === SCREEN_SIZE.XS;
    });
  }

  toggleClass(): void {
    this.classApplied = !this.classApplied;
  }

  getUrlPatternObservable(componentName) {
    return this.dynamicPathStorageService.urlPatterns$.pipe(
      takeUntil(this.destroyed$),
      map(() => {
        const urlPattern = this.dynamicPathStorageService.getUrlPatternByComponentNameFromCmsPageList(componentName);
        if (!urlPattern) {
          return null;
        }
        return urlPattern.startsWith('/') ? urlPattern : '/' + urlPattern;
      })
    );
  }

  getCart(): void {
    this.cartService.cart.subscribe(cartData => {
      this.cartItem = cartData.cartLines;
      this.totalItems = cartData.totalItems | 0;
    });
  }

  setCUrrentLocale(locale: ILocale) {
    this.appStateService.setCurrentLocale(locale.id, locale.languageId);
    this.currentLocale = locale;
    this.currentLocaleId = locale.id;
  }

  toggleClass2(): void {
    this.classApplied2 = !this.classApplied2;
  }

  public installPwa(): void {
    this.data.promptEvent.prompt();
    this.close();
  }

  public close(): void {
    this.bottomSheetRef.dismiss();
  }

  isAuthenticated(): boolean {
    return this.userAccountService.isAuthenticated();
  }

  logout(): void {
    this.cartService.clearCart();
    this.appStateService.resetState();
    this.loginService.logout();
    this.userAccountService.setCanPurchase();
  }

  setLinkToMySpace(): void {
    if (this.user && this.user.userGroups && this.user.userGroups.length > 0) {
      if (this.user.userGroups.some(group => group.code === DRH_GROUP)) {
        this.spaceLink = 'drh-space';
      } else if (this.user.userGroups.some(group => group.code === BENEFICIARY_GROUP)) {
        this.spaceLink = 'beneficiary-space';
      } else if (this.user.userGroups.some(group => group.code === COACH_GROUP)) {
        this.spaceLink = 'coach-space';
      } else if (this.user.userGroups.some(group => group.code === SERVICE_PROVIDER_GROUP)) {
        this.spaceLink = 'service-provider-space';
      }
    }
  }

  getNotReadNotifications(): void {
    this.webNotificationService.getMyNotifications().subscribe(res => {
      this.totalNotificationItems = res?.length;
      this.webNotifications = res;
    });
  }

  getNotReadMessages(): void {
    this.userAccountService.getCurrentUser().subscribe(res => {
      this.currentUser = res;
      this.showInboxNotification = this.userAccountService.hasAnyAuthorityDirect([BENEFICIARY_GROUP, COACH_GROUP]);


      this.inboxService.findAllByUser(this.currentUser.id).subscribe(resa => {
        let count = 0;
        resa.forEach( inboxConversation => {
          inboxConversation.participantConversationInfos.forEach(participant =>{
            if(participant.user.email === this.currentUser.email ){
              if(participant.markAsUnRead === true){
                count = count + 1 ;
              }
            }
          })
        });
        this.totalMessages = count ;
      })
      this.cd.detectChanges();
    });
  }

  public reloadData(): void {
    this.getNotReadNotifications();
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
