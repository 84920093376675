import { Media } from './media.model';
import { ProductType } from './product-basic.model';
import { IProduct } from './product.model';
import { ISeoData } from '../shared/models/seo-data.model';

export class Pack implements IProduct {
  constructor(
    public id?: string,
    public isVariant?: boolean,
    public code?: string,
    public online?: boolean,
    public price?: any,
    public description?: string,
    public customerReviewAverage?: any,
    public onlineDate?: Date,
    public offlineDate?: Date,
    public productFamilyId?: string,
    public metaDescription?: any,
    public productTaxGroupId?: string,
    public sellerId?: string,
    public media?: Media,
    public medias?: Media[],
    public priceWithPromotion?: any,
    public label?: string,
    public tags?: string[],
    public brandId?: string,
    public customerReviewAverageId?: string,
    public categoryName?: any,
    public productType?: ProductType,
    public packEntries?: any,
    public seoData?: ISeoData
  ) {}
}
