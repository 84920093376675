import { Component, OnDestroy, OnInit } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { ActivatedRoute, Router } from '@angular/router';
import { ProductService } from '../../../services/product.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {
  PACK_DETAILS_COMPONENT_NAME,
  PRODUCT_DETAILS_COMPONENT_NAME,
  PRODUCT_JOURNEY_TO_EXCELLENCE_COMPONENT_NAME,
} from '../../../ComponentsIndex';
import { DynamicPathStorageService } from '../../../services/dynamic-path-storage.service';
import { Store } from '@ngrx/store';
import { AppState } from '../../../store/app.reducer';
import { LOCALE_ID, LOCALE_STATE } from '../../../shared/constants';
import { IdUrlPipe } from '../../../shared/pipes/id-url.pipe';

@Component({
  selector: 'app-product-journey-to-excellence',
  templateUrl: './product-journey-to-excellence.component.html',
  styleUrls: ['./product-journey-to-excellence.component.scss'],
})
export class ProductJourneyToExcellenceComponent implements OnInit, OnDestroy {
  products;
  page = 1;
  size = 6;
  id: string;
  totalItems: string;
  destroyed$ = new Subject();
  productDetailsPageUrlPattern: string;
  packDetailsPageUrlPattern: string;
  readonly PRODUCT_PRODUCT_TYPE = 'PRODUCT';
  isFetchingProducts = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private productService: ProductService,
    private dynamicPathStorageService: DynamicPathStorageService,
    private store: Store<AppState>,
    private idUrlPipe: IdUrlPipe,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(params => {
      this.id = params.id;

      if (this.id) {
        this.getProducts();
      }
    });
    this.store
      .select(LOCALE_STATE, LOCALE_ID)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        const journeyToExcellenceProductDetailsPageUrlPattern = this.dynamicPathStorageService.getUrlPatternByComponentNameFromCmsPageList(
          PRODUCT_JOURNEY_TO_EXCELLENCE_COMPONENT_NAME
        );
        if (this.id && journeyToExcellenceProductDetailsPageUrlPattern) {
          const url = this.idUrlPipe.transform(journeyToExcellenceProductDetailsPageUrlPattern, this.id);
          this.router.navigateByUrl(url);
        }
      });
    this.dynamicPathStorageService.urlPatterns$.pipe(takeUntil(this.destroyed$)).subscribe(() => {
      this.productDetailsPageUrlPattern =
        this.dynamicPathStorageService.getUrlPatternByComponentNameFromCmsPageList(PRODUCT_DETAILS_COMPONENT_NAME);
      this.packDetailsPageUrlPattern =
        this.dynamicPathStorageService.getUrlPatternByComponentNameFromCmsPageList(PACK_DETAILS_COMPONENT_NAME);
    });
  }

  handlePageEvent($event: PageEvent) {
    this.page = $event.pageIndex;
    this.getProducts();
  }

  getProducts() {
    this.isFetchingProducts = true;
    this.productService
      .getProductsByJourneryToExcellence(this.id, {
        page: this.page - 1,
        size: this.size,
      })
      .subscribe(
        res => {
          this.isFetchingProducts = false;
          this.products = res.body;
          this.totalItems = res.headers.get('X-Total-Count');
        },
        () => (this.isFetchingProducts = false)
      );
  }

  ngOnDestroy() {
    this.destroyed$.next();
  }
}
