import { Component, Input, OnInit } from '@angular/core';
import { CmsService } from '../../cms.service';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-hro-b2b-customer',
  templateUrl: './hro-b2b-customer.component.html',
  styleUrls: ['./hro-b2b-customer.component.scss'],
})
export class HroB2bCustomerComponent implements OnInit {
  @Input()
  data: any;
  public currentLocale: string;

  constructor(private cmsService: CmsService) {}
  partnerSlides: OwlOptions = {
    loop: true,
    nav: false,
    dots: false,
    autoplayHoverPause: true,
    autoplay: true,
    margin: 30,
    navText: ["<i class='bx bx-left-arrow-alt'></i>", "<i class='bx bx-right-arrow-alt'></i>"],
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 2,
      },
      576: {
        items: 3,
      },
      768: {
        items: 4,
      },
      1200: {
        items: 6,
      },
    },
  };
  ngOnInit(): void {}

  getImage(image: any, format: string | null): any {
    if (!image) {
      return;
    }
    return this.cmsService.downloadMedia(image, format);
  }
}
