import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { LocalStorageService } from 'ngx-webstorage';
import { PaymentService } from '../../../services/payment.service';
import { ProductService } from '../../../services/product.service';
import { IProduct } from '../../../models/product.model';
import * as SecureLS from 'secure-ls';
import { LANGUAGE, ORDER_ID_KEY_STORAGE, PAYMENT_ID_KEY_STORAGE } from '../../../shared/constants';

@Component({
  selector: 'app-payment-failed',
  templateUrl: './page-payment-failed.component.html',
  styleUrls: ['./page-payment-failed.component.scss'],
})
export class PagePaymentFailedComponent implements OnInit, OnDestroy {
  errorMessage: any = '';
  public secureLocaStorage: SecureLS;
  constructor(
    private router: Router,
    public localStorage: LocalStorageService,
    public translate: TranslateService,
    public paymentService: PaymentService,
    public productService: ProductService
  ) {
    if (!this.translate.currentLang) {
      this.translate.use(this.localStorage.retrieve(LANGUAGE));
    }
    this.localStorage.observe(LANGUAGE).subscribe(() => {
      this.translate.use(this.localStorage.retrieve(LANGUAGE));
    });
    this.secureLocaStorage = new SecureLS({ encodingType: 'aes' });
  }

  products: IProduct[];

  ngOnDestroy(): void {}

  ngOnInit(): void {
    this.paymentService
      .getPaymentStatus(this.secureLocaStorage.get(ORDER_ID_KEY_STORAGE).data, this.secureLocaStorage.get(PAYMENT_ID_KEY_STORAGE).data)
      .subscribe(data => {
        this.secureLocaStorage.remove(PAYMENT_ID_KEY_STORAGE);
        this.errorMessage = data.actionCodeDescription;
      });
  }

  redirectToCheckout() {
    this.router.navigateByUrl('/checkout');
  }
}
