import { Injectable } from '@angular/core';
import { GenericService } from './GenericService';
import { Observable } from 'rxjs';
import { HttpResponse } from '@angular/common/http';
import { ResponseWrapper } from '../models/response-wrapper.model';
import { createRequestOption } from './utils/request-util';
import { map } from 'rxjs/operators';
import { convertResponse } from './utils/response-converter';
import { IAppointmentStatus } from '../shared/models/appointment-status.model';

@Injectable({
  providedIn: 'root',
})
export class AppointmentStatusService extends GenericService {
  constructor() {
    super();
  }

  update(appointmentStatus: IAppointmentStatus): Observable<HttpResponse<any>> {
    return this.api.put('api/appointment-statuses/', appointmentStatus, {
      observe: 'response',
    });
  }

  create(appointmentStatus: IAppointmentStatus): Observable<any> {
    return this.api.post('api/appointment-statuses/', appointmentStatus);
  }

  delete(id: string): Observable<any> {
    return this.api.delete('api/users/appointment-statuses/' + id);
  }

  find(id: string): Observable<any> {
    return this.api.get('api/appointment-statuses/' + id);
  }

  query(req?: any): Observable<ResponseWrapper> {
    const options = createRequestOption(req);
    return this.api.get('api/appointment-statuses/', options).pipe(map(res => convertResponse(res)));
  }
}
