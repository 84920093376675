import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ICart } from 'src/app/models/cart.model';
import { ICartLine } from 'src/app/models/cartLine.model';
import { StateStorageService } from 'src/app/services/auth/state-storage.service';
import { CartService } from 'src/app/services/cart.service';
import { ProductService } from 'src/app/services/product.service';
import { AppStateService } from 'src/app/services/utils/state.service';
import { ICurrency } from '../../../models/currency.model';
import { CurrencyService } from '../../../services/currency.service';
import { Store } from '@ngrx/store';
import { AppState } from '../../../store/app.reducer';
import { ToastrService } from 'ngx-toastr';
import {
  BENEFICIARY_GROUP,
  COACH_GROUP,
  DRH_GROUP,
  LOCALE_ID,
  LOCALE_STATE,
  LOCALES,
  SERVICE_PROVIDER_GROUP,
  PASSE_EXCELLENCE,
} from 'src/app/shared/constants';
import { UserAccountService } from 'src/app/services/auth/user-account.service';
import { DynamicPathRoutingService } from '../../../services/dynamic-path-routing.service';
import { Observable, ReplaySubject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { PACK_DETAILS_COMPONENT_NAME, PRODUCT_COMPONENT_NAME, PRODUCT_DETAILS_COMPONENT_NAME } from '../../../ComponentsIndex';
import { DynamicPathStorageService } from '../../../services/dynamic-path-storage.service';
import { BookingDTO } from 'src/app/models/bookingDTO.model';
import { IBeneficiary } from 'src/app/models/Beneficiary.model';

@Component({
  selector: 'app-cart-page',
  templateUrl: './cart-page.component.html',
  styleUrls: ['./cart-page.component.scss'],
})
export class CartPageComponent implements OnInit, OnDestroy {
  cart: ICart;
  cartItems: ICartLine[] = [];
  reservationItems: ICartLine[] = [];
  currencyId: string;
  currency: ICurrency;
  isMember: boolean;
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  productListPageUrl$: Observable<string> = new Observable<string>();
  packDetailsUrlPattern: string;
  productDetailsUrlPattern: string;

  readonly PRODUCT_PRODUCT_TYPE = 'PRODUCT';

  currentLocaleId: string;
  customerId: string;
  beneficiary: IBeneficiary;
  isConfirmingReservation = false;

  constructor(
    private cartService: CartService,
    private translationService: TranslateService,
    private appState: AppStateService,
    private productService: ProductService,
    private router: Router,
    public currencyService: CurrencyService,
    private stateStorageService: StateStorageService,
    private toastr: ToastrService,
    private userAccountService: UserAccountService,
    private store: Store<AppState>,
    private dynamicPathRoutingService: DynamicPathRoutingService,
    private dynamicPathStorageService: DynamicPathStorageService
  ) {}

  ngOnInit(): void {
    this.isMember = this.appState.hasMembership();
    this.store
      .select('beneficiaryState', 'beneficiary')
      .pipe(takeUntil(this.destroyed$))
      .subscribe(beneficiary => {
        this.beneficiary = beneficiary;
      });
    if (
      this.appState
        .getUser()
        ?.userGroups.map(group => group.id)
        .includes(DRH_GROUP)
    ) {
      this.isMember = true;
    }
    this.store
      .select(LOCALE_STATE, LOCALE_ID)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(localeId => (this.currentLocaleId = localeId));
    this.store.select(LOCALE_STATE, LOCALES).subscribe(() => {
      this.currencyId = this.appState.getCurrentCurrency();
      this.currencyService.find(this.currencyId).subscribe(res => {
        this.currency = res;
      });
    });
    this.getCart();
    this.productListPageUrl$ = this.dynamicPathStorageService.urlPatterns$.pipe(
      takeUntil(this.destroyed$),
      map(() => {
        const urlPattern = this.dynamicPathStorageService.getUrlPatternByComponentNameFromCmsPageList(PRODUCT_COMPONENT_NAME);
        return urlPattern?.startsWith('/') ? urlPattern : '/' + urlPattern;
      })
    );
    this.dynamicPathStorageService.urlPatterns$.pipe(takeUntil(this.destroyed$)).subscribe(() => {
      this.productDetailsUrlPattern =
        this.dynamicPathStorageService.getUrlPatternByComponentNameFromCmsPageList(PRODUCT_DETAILS_COMPONENT_NAME);
      this.packDetailsUrlPattern = this.dynamicPathStorageService.getUrlPatternByComponentNameFromCmsPageList(PACK_DETAILS_COMPONENT_NAME);
    });
  }

  getCart() {
    this.cartService.cart.subscribe(
      cart => {
        this.cart = cart;
        this.setItemsForCartAndReservations();
      },
      () => {
        // const errorMsg = this.translationService.instant('ERROR_LOADING_CART');
      }
    );
  }

  setItemsForCartAndReservations() {
    this.cartItems = this.cart.cartLines.filter(cartLine => cartLine.sellerId === PASSE_EXCELLENCE);
    this.reservationItems = this.cart.cartLines.filter(cartLine => cartLine.sellerId !== PASSE_EXCELLENCE);
  }

  confirmReservation() {
    if (this.isConfirmingReservation) {
      return;
    }
    this.isConfirmingReservation = true;
    const booking = new BookingDTO();
    booking.customerId = this.beneficiary.id;
    booking.currencyId = this.currencyId;
    booking.orderLines = this.reservationItems;
    this.cartService.createBooking(booking).subscribe(
      () => {
        this.isConfirmingReservation = false;
        this.cartService.removeItems(this.reservationItems, true);
        this.reservationItems = [];
      },
      error => {
        this.isConfirmingReservation = false;
      }
    );
  }

  removeItem(cartLine: ICartLine) {
    this.cartService.removeItem(cartLine);
  }

  getImage(image: any, format: string | null): any {
    if (!image) {
      return;
    }
    return this.productService.downloadMedia(image, format);
  }

  setQuantity(item: ICartLine) {
    if (item.quantity == 0) {
      const msg = this.translationService.instant('QUANTITY_CANNOT_BE_0');
      this.toastr.error(msg);
      item.quantity = 1;
      return;
    }
    if (!Number.isInteger(item.quantity)) {
      item.quantity = Math.floor(item.quantity);
    }
    this.cartService.updateCartLineQuantity(item);
  }

  navigateToUserSpace() {
    const groupeDRH = [DRH_GROUP];
    const groupeBeneficary = [BENEFICIARY_GROUP];
    const groupeCoach = [COACH_GROUP];
    if (this.userAccountService.hasAnyAuthorityDirect(groupeDRH)) {
      this.router.navigate(['drh-space']);
    } else if (this.userAccountService.hasAnyAuthorityDirect(groupeBeneficary)) {
      this.router.navigate(['beneficiary-space']);
    } else if (this.userAccountService.hasAnyAuthorityDirect(groupeCoach)) {
      this.router.navigate(['coach-space']);
    } else if (this.userAccountService.hasAnyAuthorityDirect([SERVICE_PROVIDER_GROUP])) {
      this.router.navigate(['service-provider-space']);
    }
  }

  order() {
    if (this.cart.total == 0 && !this.isMember) {
      const msg = this.translationService.instant('YOU_ARE_NOT_A_MEMBER_YET');
      this.toastr.error(msg);
    } else {
      this.cartService.updateCart();
      this.stateStorageService.storeUrl('/checkout');
      this.cart.customerId ? this.router.navigate(['/checkout']) : this.router.navigate(['/login']);
    }
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
