import { Injectable } from '@angular/core';
import { GenericService } from '../services/GenericService';
import { Observable } from 'rxjs';
import { createRequestOption } from '../services/utils/request-util';
import { HttpResponse } from '@angular/common/http';
import { IB2BCustomer } from './models/B2BCustomer';
import { environment } from '../../environments/environment';
import { IEmployee } from './models/employee-b2b.model';

@Injectable({
  providedIn: 'root',
})
export class DrhSpaceService extends GenericService {
  constructor() {
    super();
  }

  updateb2bEmployee(employee: IEmployee): Observable<HttpResponse<any>> {
    return this.api.put('api/employees-b-2-b/account/', employee, {
      observe: 'response',
    });
  }
  createB2BEmployee(employee: any): Observable<any> {
    return this.api.post('api/employees-b-2-b/register/', employee);
  }

  deleteB2BEmployee(email: string): Observable<any> {
    return this.api.delete('api/users/employees-b-2-b/' + email);
  }

  getCustomerByUserLogin(login: string): Observable<IB2BCustomer> {
    return this.api.get('api/b-2-b-customers/by-login/' + login);
  }

  findB2BEmployeeByEMail(id: string): Observable<any> {
    return this.api.get('api/b-2-b-employees/byemail/' + id);
  }
  createB2B(customerB2b: IB2BCustomer): Observable<any> {
    return this.api.post('api/b-2-b-customers/register/', customerB2b);
  }

  updateB2B(customerB2b: IB2BCustomer): Observable<HttpResponse<any>> {
    return this.api.put('api/b-2-b-customers/account/', customerB2b, {
      observe: 'response',
    });
  }
  deleteB2b(email: IB2BCustomer): Observable<HttpResponse<any>> {
    return this.api.delete('api/b2busers/' + email);
  }
  findByEMailB2b(id: string): Observable<IB2BCustomer> {
    return this.api.get('api/b-2-b-customers/byemail/' + id);
  }

  saveImageInB2BCustomer(file: File, typeId: string): Observable<any> {
    const formdata: FormData = new FormData();
    formdata.append('file', file);
    return this.api.post('api/upload-media-for-b2b-customer/' + typeId, formdata);
  }

  downloadMedia(mediaId: string, format: string): any {
    return environment.apiUrl + 'api/export-media/' + mediaId + ' /' + format;
  }

  downloadMediaToDesktop(mediaId: string, format: string): Observable<any> {
    return this.api.get('api/export-media/' + mediaId + ' /' + format, {
      responseType: 'blob',
    });
  }

  getOrderByCustomerId(id: string, currencyId: string, req?: any): Observable<any> {
    const options = createRequestOption(req);
    return this.api.get('api/orderLines-By-Customer/' + id + '/' + currencyId, options);
  }

  getPurchasedProduct(id: string, currencyId: string, currentLocaleId: string, req?: any): Observable<any> {
    const options = createRequestOption(req);
    return this.api.get('api/get-purchased-product-by-customer/' + id + '/' + currentLocaleId, options);
  }

  getActionStats(receiverId: string): Observable<any> {
    return this.api.get('api/actions-stats/' + receiverId);
  }

  getDashboardDetails(req?): Observable<any> {
    const options = createRequestOption(req);
    return this.api.get('api/dashboard-details', options);
  }
}
