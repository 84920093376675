import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../store/app.reducer';
import { CmsService } from '../../../cms.service';
import { takeUntil } from 'rxjs/operators';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'app-html',
  templateUrl: './html.component.html',
  styleUrls: ['./html.component.scss'],
})
export class HtmlComponent implements OnInit, OnDestroy {
  private destroyed$ = new Subject<void>();
  @Input() componentId: any;
  data: any;
  innerHtml: SafeHtml = '';

  constructor(private cmsService: CmsService, private store: Store<AppState>, public sanitizer: DomSanitizer) {}

  ngOnInit(): void {
    this.store
      .select('context')
      .pipe(takeUntil(this.destroyed$))
      .subscribe(contextId => {
        this.cmsService
          .getCmsComponentHtmlContentsByComponentAndContext(this.componentId, contextId)
          .pipe(takeUntil(this.destroyed$))
          .subscribe(res => {
            this.data = res;
            this.setInnerHtml();
          });
      });
  }

  setInnerHtml() {
    this.innerHtml = this.sanitizer.bypassSecurityTrustHtml(this.data.content);
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
