import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-hro-tarif',
  templateUrl: './hro-tarif.component.html',
  styleUrls: ['./hro-tarif.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class HroTarifComponent implements OnInit {
  @Input()
  data: any;

  constructor(public sanitizer: DomSanitizer) {}

  ngOnInit(): void {}
}
