<div class="row mw-100 m-0">
  <!-- <ng-container *ngFor="let category of categories">
        <div  >
            <li class="nav-item " id="category-item"><a class="nav-link"   >{{ category.label }}</a></li>
            <ul  *ngFor="let child of category.children" >
                <li class="nav-item">
                    <a *ngIf="child.id !== PACKS_CATEGORY_ID"
                       [routerLink]="productListByCategoryPageUrl ? productListByCategoryPageUrl: null" [queryParams]="{category: child.id}" class="nav-link">{{ child.label }}</a>
                </li>
            </ul>
        </div>
    </ng-container> -->
  <div class="col-12 col-lg-6" *ngFor="let category of categories">
    <!-- <li class="nav-item " id="category-item"><a class="nav-link cursor-default">{{ category.label | translate }}</a></li> -->
    <ul *ngFor="let child of category.children" class="pt-2">
      <li class="nav-item hover-right">
        <a *ngIf="child.id === PACKS_CATEGORY_ID" [routerLink]="packListPageUrl$ | async" (click)="onLinkClick.emit()">{{
          child.label | translate
        }}</a>
        <a
          *ngIf="child.id !== PACKS_CATEGORY_ID"
          [routerLink]="productListByCategoryPageUrl ? productListByCategoryPageUrl : null"
          (click)="onLinkClick.emit()"
          [queryParams]="{ category: child.id }"
          class="nav-link"
          >{{ child.label }}</a
        >
      </li>
    </ul>
  </div>
</div>
