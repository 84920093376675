import { Component, OnDestroy, OnInit } from '@angular/core';
import { CoachService } from '../coach.service';
import { ActivatedRoute } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { UserAccountService } from '../../services/auth/user-account.service';
import { BENEFICIARY_GROUP } from '../../shared/constants';
import { SEO, SeoService } from '../../services/seo.service';
import { DynamicPathRoutingService } from '../../services/dynamic-path-routing.service';
import { CurrentLocaleService } from '../../services/current-locale.service';

@Component({
  selector: 'app-coach-details',
  templateUrl: './coach-details.component.html',
  styleUrls: ['./coach-details.component.scss'],
})
export class CoachDetailsComponent implements OnInit, OnDestroy {
  coach: any;
  private coachSeoName: string;
  private _isDead$ = new Subject();
  banner: any;
  displayBookButton = false;
  currentLocaleId: string;

  constructor(
    private coachService: CoachService,
    private activatedRoute: ActivatedRoute,
    private userAccountService: UserAccountService,
    private seoService: SeoService,
    private dynamicPathRoutingService: DynamicPathRoutingService,
    private currentLocaleService: CurrentLocaleService
  ) {}

  hasAtLeastOneProvidedSocialNetwork() {
    return (
      this.coach?.socialNetworkAccounts &&
      this.coach?.socialNetworkAccounts.length &&
      this.coach?.socialNetworkAccounts.some(account => account.link && account.link.length > 0)
    );
  }

  ngOnInit(): void {
    const seo: SEO = {};
    this.seoService.setSEO(seo);
    this.activatedRoute.params.pipe(takeUntil(this._isDead$)).subscribe(params => {
      this.coachSeoName = params.seoName;
      this.getCoach();
    });
    this.currentLocaleId = this.currentLocaleService.getCurrentLocale();
    this.currentLocaleService.currentLocaleId$.pipe(takeUntil(this._isDead$)).subscribe(res => {
      this.currentLocaleId = res;
      if (this.coach) {
        const seoName = this.coach.seoData ? this.coach.seoData.seoName.localizedValues[this.currentLocaleId] : '';
        if (seoName) {
          this.dynamicPathRoutingService.navigateToCoachDetailsPage(seoName);
        }
      } else {
        this.getCoach();
      }
    });
    this.userAccountService.authenticationState.pipe(takeUntil(this._isDead$)).subscribe(() => {
      this.isAuthenticated();
    });
  }

  getCoach() {
    if (this.coach && this.coach.seoData) {
      this.coachSeoName = this.coach.seoData.seoName.localizedValues[this.currentLocaleId];
    }
    const coach$ = this.coachService.getIndividualCoachBySeoName(this.coachSeoName);
    coach$.pipe(takeUntil(this._isDead$)).subscribe(value => {
      this.coach = value;
      this.setCoachSeo();
    });
  }

  setCoachSeo() {
    const seo: SEO = {};
    if (this.coach.seoData) {
      seo.title = this.coach.seoData.metaTitle?.localizedValues[this.currentLocaleId];
      seo.description = this.coach.seoData.metaDescription?.localizedValues[this.currentLocaleId];
    }
    seo.imageUrl = this.coach.photo ? this.getImageSet(this.coach?.photo) : null;
    this.seoService.setSEO(seo);
  }

  getImageSet(image: any): any {
    return this.coachService.downloadMedia(image?.id, null);
  }
  ngOnDestroy(): void {
    this._isDead$.next();
    this._isDead$.complete();
    this.seoService.resetSeo();
  }
  isAuthenticated() {
    const isAuthenticated = this.userAccountService.isAuthenticated();
    if (isAuthenticated && this.userAccountService.hasAnyAuthorityDirect([BENEFICIARY_GROUP])) {
      this.displayBookButton = true;
    }
  }
  getImage(image: any, format: string | null): any {
    if (!image) {
      return;
    }
    return this.coachService.downloadMedia(image, format);
  }
}
