/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { WorkItemType } from './request-type.model';
import { IOrder } from '../../models/order.model';
import { IProduct } from '../../models/product.model';
import { IAnonymousUser } from './anonymousUser.model';

export interface IRequest {
  id?: string;
  code?: string;
  assignee?: any;
  reporter?: any;
  dateCreated?: any;
  dateUpdated?: any;
  workItemType?: WorkItemType;
  description?: string;
  reason?: string;
  enableReply?: boolean;
  anonymousReporter?: IAnonymousUser;
  order?: IOrder;
  product?: IProduct;
}
export class Request implements IRequest {
  constructor(
    public id?: string,
    public code?: string,
    public assignee?: any,
    public reporter?: any,
    public dateCreated?: any,
    public dateUpdated?: any,
    public workItemType?: WorkItemType,
    public anonymousReporter?: IAnonymousUser,
    public description?: string,
    public reason?: string,
    public enableReply?: boolean
  ) {
    this.workItemType = new WorkItemType();
    this.enableReply = false;
  }
}
