import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AppStateService } from '../../services/utils/state.service';
import { IUserWebNotification } from '../../models/user-web-notification.model';
import { WebNotificationService } from '../../services/web-notification.service';
import { LOCALE_ID, LOCALE_STATE } from '../constants';
import { Store } from '@ngrx/store';
import { AppState } from '../../store/app.reducer';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-hro-notification-list',
  templateUrl: './hro-notification-list.component.html',
  styleUrls: ['./hro-notification-list.component.scss'],
})
export class HroNotificationListComponent implements OnInit, OnDestroy {
  webNotifications: IUserWebNotification[] = [];
  currentLocale: string;
  destroyed$ = new Subject();
  pagesNumber: number = 1;

  @Input() fromNavbar: boolean;
  @Output() emitAttributeToDisplay = new EventEmitter<boolean>();
  constructor(appStateService: AppStateService, private webNotificationService: WebNotificationService, private store: Store<AppState>) {
    this.currentLocale = appStateService.getCurrentLocaleId();
  }

  ngOnInit(): void {
    this.webNotificationService.sharedNewVote.subscribe(res => {
      if (res) {
        if(!this.fromNavbar){
          this.getAllReadAndUnreadNotificationsFromNavbar(0);
        }else{
          this.getAllUnreadNotifications();
        }
      }
    });
    this.store
      .select(LOCALE_STATE, LOCALE_ID)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(localeId => {
        this.currentLocale = localeId;
      });
    if(!this.fromNavbar){
      this.getAllReadAndUnreadNotificationsFromNavbar(0);
    }else{
      this.getAllUnreadNotifications();
    }
  }

  getAllReadAndUnreadNotificationsFromNavbar(pageToLoad: number) {
    const queryObject = {
      page: pageToLoad,
      size: 10,
      sort: [],
    };
    this.webNotificationService.query(queryObject).subscribe(res => {
      let totalCount  = Number(res.headers.get('X-Total-Count'));
      this.pagesNumber= this.addOneIfDecimal(totalCount/queryObject.size);
      this.webNotifications = res.json;
    });
  }

  getAllUnreadNotifications() {
    this.webNotificationService.getMyNotifications().subscribe(res => {
      this.webNotifications = res;
      this.webNotifications.sort((a, b) => {
        if (a.readAt && b.readAt) {
          const dateA = new Date(a.readAt);
          const dateB = new Date(b.readAt);
          return dateB.getTime() - dateA.getTime();
        }
      });
    });
  }
  updateOne(notification: IUserWebNotification) {
    notification.read = !notification.read;
    this.webNotificationService.update(notification).subscribe(() => {
      this.emitAttributeToDisplay.emit(true);
      this.ngOnInit();
    });
  }

  updateAll() {
    const notifications = [];
    for (let i = 0; i < this.webNotifications.length; i++) {
      if (!this.webNotifications[i].read) {
        this.webNotifications[i].read = !this.webNotifications[i].read;
        notifications.push(this.webNotifications[i]);
      }
    }
    this.webNotificationService.updateAll(notifications).subscribe(() => {
      this.emitAttributeToDisplay.emit(true);
      this.ngOnInit();
    });
  }

  deleteOne(id: string) {
    this.webNotificationService.delete(id).subscribe(() => {
      this.emitAttributeToDisplay.emit(true);
      this.ngOnInit();
    });
  }
  deleteAll() {
    const ids: string[] = [];
    this.webNotifications.forEach(notification => !notification.readAt);
    for (let i = 0; i < this.webNotifications.length; i++) {
      ids.push(this.webNotifications[i].id);
    }
    this.webNotificationService.deleteAll(ids).subscribe(() => {
      this.emitAttributeToDisplay.emit(true);
      this.ngOnInit();
    });
  }

  fakeArray(length: number): Array<number> {
    if (length >= 0) {
      return new Array(length);
    }
  }

  addOneIfDecimal(number: number): number {
    if (Number.isInteger(number)) {
      return number;
    } else {
      return Math.floor(number) + 1;
    }
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
