<div [ngStyle]="{ 'background-image': 'url(assets/img/page-title/title1.webp)' }" class="page-title-area item-bg1">
  <div class="container">
    <div class="page-title-content">
      <ul>
        <li>
          <a href="index.html">{{ 'HOME' | translate }}</a>
        </li>
        <li>{{ 'PACKS' | translate }}</li>
      </ul>
      <h2>{{ 'PACKS' | translate }}</h2>
    </div>
  </div>
</div>

<app-product-list [onlyPacks]="true" [onlyProducts]="false"></app-product-list>
