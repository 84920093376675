<div *ngIf="videoLoaded" class="video-player-wrapper">
  <div *ngIf="data.medias && data.medias.length > 0">
    <div *ngFor="let mediaContent of data.medias; let j = index">
      <vg-player style="background-color: transparent" *ngIf="videoLoaded && mediaContent && !mediaContent.url">
        <video height="400vh" #media [vgMedia]="$any(media)" id="singleVide9" preload="auto" crossorigin controls>
          <source [src]="getMedia(mediaContent)" type="video/mp4" />
        </video>
      </vg-player>
      <div *ngIf="mediaContent && mediaContent.url" [innerHtml]="iframeHtmls[j]" class="video-style mw-100"></div>
    </div>
  </div>
</div>
