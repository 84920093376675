/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { createReducer, on } from '@ngrx/store';
import { ILocale } from '../../models/locale.model';
import * as LocaleActions from '../actions/locale.actions';
import { FRENCH_FRANCE } from '../../shared/constants';

export interface LocaleState {
  locales: ILocale[];
  localeId: string;
}

export const initialState: LocaleState = {
  locales: null,
  localeId: FRENCH_FRANCE,
};

const localeReducer = createReducer(
  initialState,
  on(LocaleActions.setLocales, (state, { locales }) => ({ ...state, locales })),
  on(LocaleActions.setLocaleId, (state, { localeId }) => ({
    ...state,
    localeId,
  }))
);

export function LocaleReducer(state: any, action: any) {
  return localeReducer(state, action);
}
