import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ImageContentComponent } from './image-content/image-content.component';
import { HtmlContentComponent } from './html-content/html-content.component';
import { ListContentComponent } from './list-content/list-content.component';
import { CmsContentDirective } from './cms-content.directive';
import { RouterModule } from '@angular/router';
import { CmsPageComponentComponent } from './cms-page-component/cms-page-component.component';
import { HroBannerComponent } from './components/hro-banner/hro-banner.component';
import { HroImageSetComponent } from './components/hro-image-set/hro-image-set.component';
import { HroVideoSetComponent } from './components/hro-video-set/hro-video-set.component';
import { VgCoreModule } from '@videogular/ngx-videogular/core';
import { VgOverlayPlayModule } from '@videogular/ngx-videogular/overlay-play';
import { VgBufferingModule } from '@videogular/ngx-videogular/buffering';
import { VgControlsModule } from '@videogular/ngx-videogular/controls';
import { EmbedVideoService } from 'ngx-embed-video';
import { HroCarouselComponent } from './components/hro-carousel/hro-carousel.component';
import { NgbCarouselModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';
import { HroTextComponent } from './components/hro-text/hro-text.component';
import { HroLinkComponent } from './components/hro-link/hro-link.component';
import { HroElementSetComponent } from './components/hro-element-set/hro-element-set.component';
import { HroStatisticsComponent } from './components/hro-statistics/hro-statistics.component';
import { HroPartnerSetComponent } from './components/hro-partner-set/hro-partner-set.component';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { HroJourneyToExcellenceComponent } from './components/hro-journey-to-excellence/hro-journey-to-excellence.component';
import { HroTestimonialComponent } from './components/hro-testimonial/hro-testimonial.component';
import { HroCoachesComponent } from './components/hro-coaches/hro-coaches.component';
import { TranslateModule } from '@ngx-translate/core';
import { HroB2bCustomerComponent } from './components/hro-b2b-customer/hro-b2b-customer.component';
import { HroTarifComponent } from './components/hro-tarif/hro-tarif.component';
import { HroPopularPacksComponent } from './components/hro-popular-packs/hro-popular-packs.component';
import { HroBannerCoachesComponent } from './components/hro-banner-coaches/hro-banner-coaches.component';
import { BannerComponent } from './components/generic/banner/banner.component';
import { ListComponent } from './components/generic/list/list.component';
import { HtmlComponent } from './components/generic/html/html.component';
import { SharedModule } from '../shared/shared.module';
import { HomeHeaderComponent } from './components/home-header/home-header.component';

@NgModule({
  declarations: [
    ImageContentComponent,
    HtmlContentComponent,
    ListContentComponent,
    CmsContentDirective,
    CmsPageComponentComponent,
    HroBannerComponent,
    HroImageSetComponent,
    HroVideoSetComponent,
    HroCarouselComponent,
    HroTextComponent,
    HroLinkComponent,
    HroElementSetComponent,
    HroStatisticsComponent,
    HroPartnerSetComponent,
    HroJourneyToExcellenceComponent,
    HroTestimonialComponent,
    HroCoachesComponent,
    HroB2bCustomerComponent,
    HroTarifComponent,
    HroPopularPacksComponent,
    HroBannerCoachesComponent,
    BannerComponent,
    ListComponent,
    HtmlComponent,
    HomeHeaderComponent,
  ],
  exports: [
    CmsContentDirective,
    CmsPageComponentComponent,
    HroBannerComponent,
    HroImageSetComponent,
    HroVideoSetComponent,
    HroCarouselComponent,
    HroTextComponent,
    HroLinkComponent,
    HroElementSetComponent,
    HroStatisticsComponent,
    HroPartnerSetComponent,
    HroJourneyToExcellenceComponent,
    HroTestimonialComponent,
    HroCoachesComponent,
    HroB2bCustomerComponent,
    HroTarifComponent,
    HroPopularPacksComponent,
    HroBannerCoachesComponent,
    BannerComponent,
    ListComponent,
    HtmlComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    VgCoreModule,
    VgOverlayPlayModule,
    VgBufferingModule,
    VgControlsModule,
    NgbCarouselModule,
    NgbModule,
    FormsModule,
    CarouselModule,
    TranslateModule,
    SharedModule,
  ],
  providers: [EmbedVideoService],
})
export class CMSModule {}
