/* eslint-disable prettier/prettier */
import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import * as BeneficiaryActions from '../actions/beneficiary.actions';
import {catchError, map, mergeMap, tap, withLatestFrom} from 'rxjs/operators';
import {ToastrService} from 'ngx-toastr';
import {TranslateService} from '@ngx-translate/core';
import {BeneficiaryService} from '../../beneficiary-space/beneficiary.service';
import {LocalStorageService, SessionStorageService} from 'ngx-webstorage';
import {Store} from '@ngrx/store';
import {AppState} from '../app.reducer';
import {DatePipe} from "@angular/common";
import {EMPTY} from "rxjs";

@Injectable()
export class BeneficiaryEffects {

    getBeneficiaryByUserLogin$ = createEffect(() => this.actions$.pipe(
            ofType(BeneficiaryActions.setBeneficiaryEffect),
            mergeMap((action) => {
                    const user = this.sessionStorage.retrieve('user');
                    const currencyId = this.localStorage.retrieve('currencyId');
                    if (!user || !user.login) {
                        return EMPTY;
                    }
                    return this.beneficiaryService.getBeneficiaryByUserLogin(user.login, currencyId).pipe(
                        map((res: any) => {
                                this.sessionStorage.store('beneficiary', res.body);
                                return BeneficiaryActions.setBeneficiary({beneficiary: res.body});
                            }
                        ),
                        catchError(error => {
                            this.toastr.error(this.translate.instant('GENERIC_ERROR_MSG'));
                            throw error;
                        })
                    );
                }
            )
        )
    );

    addBeneficiaryDevelopmentAxis$ = createEffect(() => this.actions$.pipe(
            ofType(BeneficiaryActions.addDevelopmentAxisEffect),
            withLatestFrom(this.store.select('beneficiaryState', 'beneficiary')),
            mergeMap(([action, beneficiary]) => this.beneficiaryService.addDevelopmentAxeToBeneficiary(beneficiary.id, action.devAxis).pipe(
                    map(res => {
                        action
                        action.onFinish();
                        action.onSuccess(res);
                        return BeneficiaryActions.addDevelopmentAxis({devAxis: res});
                        }, () => action.onFinish()
                    ),
                    catchError(error => {
                        this.toastr.error(this.translate.instant('GENERIC_ERROR_MSG'));
                        throw error;
                    })
                )
            )
        )
    );

    updateBeneficiaryDevelopmentAxis$ = createEffect(() => this.actions$.pipe(
            ofType(BeneficiaryActions.updateDevelopmentAxisEffect),
            mergeMap((action) => this.beneficiaryService.updateDevelopementAxis(action.devAxis).pipe(
                    map(res => {
                            action.onSuccess(res);
                            action.onFinish();
                            return BeneficiaryActions.updateDevelopmentAxis({devAxis: res});
                        }, ()=> action.onFinish()
                    ),
                    catchError(error => {
                        this.toastr.error(this.translate.instant('GENERIC_ERROR_MSG'));
                        throw error;
                    })
                )
            )
        )
    );

    addBeneficiaryDevAxisAction$ = createEffect(() => this.actions$.pipe(
            ofType(BeneficiaryActions.addDevAxisActionEffect),
            withLatestFrom(this.store.select('beneficiaryState', 'beneficiary', 'employments')),
            mergeMap(([action, employments]) => this.beneficiaryService.addActionToAxe(action.devAxisId, action.devAxisAction).pipe(
                    map(res => {
                            if (res.actionVisibility === 'ALSO_MY_MANAGER') {
                                    action.onFinish(res);
                                    // const b2bCustomerId =  employments.filter(emloyement => this.datepipe.transform(emloyement.startDate, 'yyyy-MM-dd') <= this.datepipe.transform(new Date(), 'yyyy-MM-dd')
                                    //     && this.datepipe.transform(emloyement.endDate, 'yyyy-MM-dd')  >= this.datepipe.transform(new Date(), 'yyyy-MM-dd') )[0].b2BCustomerId;
                                        return BeneficiaryActions.addDevAxisAction({devAxisId: action.devAxisId, devAxisAction: res});

                            }
                        action.onFinish(res);
                        return BeneficiaryActions.addDevAxisAction({devAxisId: action.devAxisId, devAxisAction: res});
                        }, ()=> action.onFinish()
                    ),
                    catchError(error => {
                        this.toastr.error(this.translate.instant('GENERIC_ERROR_MSG'));
                        action.onFinish();
                        throw error;
                    })
                )
            )
        )
    );

    updateBeneficiaryDevAxisAction$ = createEffect(() => this.actions$.pipe(
            ofType(BeneficiaryActions.updateDevAxisActionEffect),
            mergeMap((action) => this.beneficiaryService.updateAction(action.devAxisAction).pipe(
                    map(res => {
                            action.onFinish();
                            return BeneficiaryActions.setBeneficiaryEffect();
                        }
                    ),
                    catchError(error => {
                        this.toastr.error(this.translate.instant('GENERIC_ERROR_MSG'));
                        action.onFinish();
                        throw error;
                    })
                )
            )
        )
    );

    getActionDetails$ = createEffect(() => this.actions$.pipe(
        ofType(BeneficiaryActions.getActionDetailsEffect),
        mergeMap((action) => this.beneficiaryService.getActionDetails(action.actionId, action.login, action.currencyId).pipe(
                map(res => {
                    action.onFinish();
                        return BeneficiaryActions.setBeneficiaryEffect();
                    }
                ),
                catchError(error => {
                    this.toastr.error(this.translate.instant('GENERIC_ERROR_MSG'));
                    action.onFinish();
                    throw error;
                })
            )
        )
    )
);

    bindProductToDevAxisAction$ = createEffect(() => this.actions$.pipe(
            ofType(BeneficiaryActions.bindProductToDevAxisActionEffect),
            withLatestFrom(this.store.select('beneficiaryState', 'beneficiary', 'beneficiaryDevelopmentAxises')),
            mergeMap(([action, devAxises]) => this.beneficiaryService
                    .bindProductToAction(action.userId, action.b2bCustomerId, action.visibility, action.devAxisAction).pipe(
                    map(res => {
                        action.onFinish();
                        return BeneficiaryActions.setBeneficiaryEffect();
                        }
                    ),
                    catchError(error => {
                        this.toastr.error(this.translate.instant('GENERIC_ERROR_MSG'));
                        action.onFinish();
                        throw error;
                    })
                )
            )
        )
    );

    updateDevAxisActionStatus$ = createEffect(() => this.actions$.pipe(
            ofType(BeneficiaryActions.updateDevAxisActionStatusEffect),
            withLatestFrom(this.store.select('beneficiaryState', 'beneficiary')),
            mergeMap(([action, beneficiary]) => this.beneficiaryService
                .updateActionStatus(action.devAxisActionId, action.devAxisActionStatus, action.devAxisId, beneficiary.id)
                .pipe(
                    map(res => {
                        return BeneficiaryActions.setBeneficiaryEffect();
                        }
                    ),
                    catchError(error => {
                        this.toastr.error(this.translate.instant('GENERIC_ERROR_MSG'));
                        throw error;
                    })
                )
            )
        )
    );

    deleteBeneficiaryDevAxisAction$ = createEffect(() => this.actions$.pipe(
            ofType(BeneficiaryActions.deleteDevAxisActionEffect),
            mergeMap((action) => this.beneficiaryService.deleteAction(action.devAxisAction).pipe(
                    map(res => {
                        action.onSuccess();
                        action.onFinish();
                            return BeneficiaryActions.deleteDevAxisAction({
                                devAxisActionId: action.devAxisAction.id,
                                devAxisId: action.devAxisId
                            });
                        }, ()=> action.onFinish()
                    ),
                    catchError(error => {
                        this.toastr.error(this.translate.instant(error));
                        action.onFinish();
                        throw error;
                    })
                )
            )
        )
    );


    deleteBeneficiaryDevAxis$ = createEffect(() => this.actions$.pipe(
        ofType(BeneficiaryActions.deleteDevAxisEffect),
        mergeMap((action) => this.beneficiaryService.deleteDevAxis(action.devAxisId).pipe(
                map(res => {
                    action.onSuccess();
                    action.onFinish();
                        return BeneficiaryActions.setBeneficiaryEffect();
                    }, ()=> action.onFinish()
                ),
                catchError(error => {
                    action.onFinish();
                    this.toastr.error(this.translate.instant(error));
                    throw error;
                })
            )
        )
    )
);



    updateBeneficiaryInBrowserStorage$ = createEffect(() => this.actions$.pipe(
            ofType(
                BeneficiaryActions.updateDevelopmentAxis,
                BeneficiaryActions.addDevelopmentAxis,
                BeneficiaryActions.updateDevAxisAction,
                BeneficiaryActions.deleteDevAxisAction,
                BeneficiaryActions.deleteDevAxis,
                BeneficiaryActions.setBeneficiary
            ),
            withLatestFrom(this.store.select('beneficiaryState', 'beneficiary')),
            tap(([action, beneficiary]) => {
                    this.sessionStorage.store('beneficiary', beneficiary);
            }),
        ), {
        dispatch: false
        }
    );


    constructor(
        private actions$: Actions,
        private beneficiaryService: BeneficiaryService,
        private toastr: ToastrService,
        private translate: TranslateService,
        private sessionStorage: LocalStorageService,
        private store: Store<AppState>,
        public datepipe: DatePipe,
        private localStorage: LocalStorageService
    ) {
    }
}
