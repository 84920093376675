<!-- <app-hro-navbar *ngIf="!fromNavbar"></app-hro-navbar> -->
<div class="page-title-area item-bg1" *ngIf="!fromNavbar">
  <div class="container">
    <div class="page-title-content">
      <ul>
        <li>
          <a href="index.html">{{ 'HOME' | translate }}</a>
        </li>
        <li>Notifications</li>
      </ul>
      <h2>Notifications</h2>
    </div>
  </div>
</div>

<div
  class="row col-12"
  style="text-align: left; padding: 20px 0 20px 20px"
  [ngStyle]="!fromNavbar ? { 'margin-left': '0%' } : null"
  [ngClass]="{ 'not-navbar': !fromNavbar }"
  *ngIf="webNotifications?.length > 0">
  <div *ngIf="fromNavbar">
    <div style="margin-bottom: 30px">
      <a routerLink="/notifications" style="float: right"> view all</a>
    </div>
    <hr />
  </div>
  <div>
    <ul style="float: right; display: inline-flex" class="list-inline action-icon icon-drag trash-icon-center">
      <li class="list-inline-item; delete-icon" style="justify-content: center; align-items: center">
        <a style="border-radius: 50%; overflow: hidden; font-size: 1rem" (click)="deleteAll()">
          <i class="bx bx-trash icon-color" aria-hidden="true"></i
        ></a>
      </li>
      <li class="list-inline-item; delete-icon" style="justify-content: center; align-items: center">
        <a (click)="updateAll()" style="border-radius: 50%; overflow: hidden; font-size: 1rem">
          <i class="bx bx-check icon-color" aria-hidden="true"></i
        ></a>
      </li>
    </ul>
  </div>
  <ul>
    <li
      class="nav-item"
      *ngFor="let category of webNotifications; let i = index"
      [ngClass]="{
        'element-not-read': !category.read,
        'element-read': category.read
      }">
      <div style="display: flex; align-items: center">
        <a class="nav-link"><p [innerHTML]="category?.inAppNotification?.message?.localizedValues[currentLocale]"></p></a>
        <div>
          <span *ngIf="!category.read" style="float: right" class="not-read"></span>
        </div>
      </div>
      <ul style="float: right; display: inline-flex" class="list-inline action-icon icon-drag trash-icon-center">
        <li class="list-inline-item; delete-icon" style="justify-content: center; align-items: center">
          <a style="border-radius: 50%; overflow: hidden; font-size: 1rem" (click)="deleteOne(category.id)">
            <i class="bx bx-trash icon-color" aria-hidden="true"></i
          ></a>
        </li>
        <li class="list-inline-item; delete-icon" style="justify-content: center; align-items: center">
          <a (click)="updateOne(category)" style="border-radius: 50%; overflow: hidden; font-size: 1rem">
            <i class="bx bx-check icon-color" aria-hidden="true"></i
          ></a>
        </li>
      </ul>

      <span style="font-size: 10px; color: #969494; padding-left: 10px"> {{ category?.readAt | date : 'dd/MM/yyyy HH:mm:ss' }}</span>
      <hr *ngIf="!fromNavbar" />
    </li>

    <!-- <li class="nav-item"><a routerLink="/course-portal-online" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Soft Skills</a></li> -->
  </ul>
  <nav aria-label="Page navigation example" *ngIf="pagesNumber > 1 && !fromNavbar">
    <ul class="pagination justify-content">
      <li class="page-item">
        <a class="page-link" (click)="getAllReadAndUnreadNotificationsFromNavbar(0)">&laquo;</a>
      </li>
      <li class="page-item" *ngFor="let page of fakeArray(pagesNumber); let i = index">
        <a class="page-link" (click)="getAllReadAndUnreadNotificationsFromNavbar(i)">{{i+1}}</a>
      </li>
      <li class="page-item">
        <a class="page-link" (click)="getAllReadAndUnreadNotificationsFromNavbar(pagesNumber - 1)">&raquo;</a>
      </li>
    </ul>
  </nav>
</div>

<div class="row col-12" style="text-align: left; padding: 20px 0 20px 20px" *ngIf="!webNotifications.length">
  <p>{{ 'NO_NOTIFICATION_FOUND' | translate }}</p>
</div>
