<div style="background-position-y: 25%" class="page-title-area item-coach item-bg1">
  <div class="container">
    <div class="page-title-content">
      <ul>
        <li>
          <a href="index.html">{{ 'HOME' | translate }}</a>
        </li>
        <li>{{ 'COACHS' | translate }}</li>
        <li>{{ 'COACH_DETAILS' | translate }}</li>
      </ul>
      <h2 style="color: white">{{ coach?.firstName }} {{ coach?.lastName }}</h2>
    </div>
  </div>
</div>
<div class="instructor-details-area pt-100 pb-40">
  <div class="container">
    <div class="instructor-details-desc">
      <div class="row">
        <div class="col-lg-4 col-md-4">
          <div class="instructor-details-sidebar" *ngIf="coach">
            <img
              class="coach-image img-shadow"
              *ngIf="coach?.photo; else noImage"
              [src]="getImageSet(coach?.photo)"
              onerror="this.src='assets/img/not-found.png'"
              [alt]="coach.photo?.alt ?? coach.firstName + ' ' + coach.lastName" />
            <div class="instructor-details-meta"></div>
            <a
              *ngIf="displayBookButton"
              [routerLink]="'/coach/book/' + coach?.seoData?.seoName.localizedValues[currentLocaleId] + ''"
              class="default-btn"
              ><i class="bx bx-plus-circle icon-arrow before"></i><span class="label">{{ 'BOOK' | translate }}</span
              ><i class="bx bx-plus-circle icon-arrow after"></i
            ></a>
            <ul class="social-link" *ngIf="hasAtLeastOneProvidedSocialNetwork()">
              <ng-container *ngFor="let socialNetworkAccount of coach.socialNetworkAccounts">
                <li *ngIf="socialNetworkAccount.link">
                  <a [href]="socialNetworkAccount.link" class="d-block" target="_blank">
                    <!-- desktop social network icon -->
                    <img
                      class="d-none d-sm-inline"
                      [src]="getImage(socialNetworkAccount.socialMediaNetwork.icon.id, null)"
                      [alt]="socialNetworkAccount.socialMediaNetwork.icon.alt ?? socialNetworkAccount.socialMediaNetwork.name"
                      [title]="socialNetworkAccount.socialMediaNetwork.name"
                      width="30"
                      height="50" />
                    <!-- mobile social network icon -->
                    <img
                      class="d-inline d-sm-none"
                      [src]="getImage(socialNetworkAccount.socialMediaNetwork.icon.id, null)"
                      [alt]="socialNetworkAccount.socialMediaNetwork.icon.alt ?? socialNetworkAccount.socialMediaNetwork.name"
                      [title]="socialNetworkAccount.socialMediaNetwork.name"
                      width="25"
                      height="40" />
                  </a>
                </li>
              </ng-container>
            </ul>
          </div>
        </div>
        <div class="col-lg-8 col-md-8">
          <div class="instructor-details" *ngIf="coach">
            <h3>{{ coach?.firstName }} {{ coach?.lastName }}</h3>
            <!-- <div class="row">
                            <span class="mr-2 badge badge-light" *ngFor="let domain of coach?.coachingDomainName">{{domain }}</span>
                        </div> -->
            <ng-container *ngIf="coach?.summary && coach?.summary.localizedValues && coach.summary.localizedValues[currentLocaleId]">
              <p>{{ coach.summary.localizedValues[currentLocaleId] }}</p>
            </ng-container>
            <div class="instructor-details-info">
              <div class="row">
                <div class="col-lg-6 col-md-12" *ngIf="coach && coach.languages">
                  <h3>{{ 'LANGUAGES' | translate }}</h3>
                  <div>
                    <span *ngFor="let language of coach.languages" class="badge badge-info mr-2">{{ language }}</span>
                  </div>
                </div>
                <div class="col-lg-6 col-md-12" *ngIf="coach?.icfAccreditationLevelName">
                  <h3>{{ 'ACCREDITATION_LEVEL' | translate }}</h3>
                  <span class="badge badge-info">{{ coach?.icfAccreditationLevelName }}</span>
                </div>
              </div>
              <div class="mt-5" *ngIf="coach && coach.coachingDomainName">
                <h3>{{ 'COACHING_DOMAIN' | translate }}</h3>
                <div>
                  <span *ngFor="let domain of coach.coachingDomainName" class="badge badge-info badge-pill mr-2 mt-2">{{ domain }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <app-hro-user-banner *ngIf="coach && coach.id" [sellerId]="coach.id"></app-hro-user-banner>
  </div>
</div>
<ng-template #noImage>
  <img class="coach-image img-shadow" src="assets/img/instructor1.webp" alt="image" />
</ng-template>
