import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { CmsService } from '../../cms.service';
import { takeUntil } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { AppState } from '../../../store/app.reducer';
import { CONTEXT } from '../../../shared/constants';

@Component({
  selector: 'app-hro-image-set',
  templateUrl: './hro-image-set.component.html',
  styleUrls: ['./hro-image-set.component.scss'],
})
export class HroImageSetComponent implements OnInit, OnDestroy {
  @Input() width: string;
  @Input() height: string;
  @Input() componentId: any;
  data: any;
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  constructor(private cmsService: CmsService, private store: Store<AppState>) {}

  ngOnInit(): void {
    this.store
      .select(CONTEXT)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(contextId => {
        this.cmsService
          .getCmsComponentMediaContentsByComponentAndContext(this.componentId, contextId)
          .pipe(takeUntil(this.destroyed$))
          .subscribe(res => {
            this.data = res;
          });
      });
  }

  getImageSet(image: any): any {
    return this.cmsService.downloadMedia(image.id, null);
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
