<div class="page-title-area item-bg1">
  <div class="container">
    <div class="page-title-content">
      <ul>
        <li>
          <a href="index.html"> {{ 'HOME' | translate }} </a>
        </li>
        <li>{{ 'PAYMENT' | translate }}</li>
      </ul>
      <h2>{{ 'PAYMENT' | translate }}</h2>
    </div>
  </div>
</div>
<div class="checkout-area ptb-100">
  <div class="container">
    <div class="not-found">
      <h3>{{ 'PAYMENT_FAILED' | translate }}</h3>

      <div class="not-found__content">
        <!--<h4 class="not-found__title">{{errorMessage | translate}}</h4>-->

        <p class="not-found__text">
          <br />
        </p>
        <button
          style="background-color: #d9534f; color: white"
          type="submit"
          (click)="redirectToCheckout()"
          class="not-found__search-button btn">
          {{ 'Go back to checkout page' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
