import { ICustomer } from './custom-user.model';

export interface IUserFacebook {
  userVM?: ICustomer;
  jwtToken?: string;
  fcmToken?: string;
}

export class UserFacebook implements IUserFacebook {
  constructor(public userVM?: ICustomer, public jwtToken?: string, public fcmToken?: string) {}
}
