<header class="header-area p-relative">
  <div class="top-header top-header-style-four">
    <div class="container">
      <div class="row align-items-center" *ngIf="!isAuthenticated()">
        <div class="col-lg-8 col-md-8"></div>
        <div class="col-lg-4 col-md-4">
          <ul class="top-header-login-register">
            <li>
              <a routerLink="/login"><i class="bx bx-log-in"></i> {{ 'LOGIN' | translate }}</a>
            </li>
            <li>
              <a routerLink="/register"><i class="bx bx-log-in-circle"></i> {{ 'REGISTER' | translate }}</a>
            </li>
            <li *ngIf="data.mobileType === 'android'">
              <a (click)="installPwa()" routerLink="/login"><i class="bx bxl-android"></i> install</a>
            </li>
          </ul>
        </div>
      </div>
      <div class="row align-items-center" *ngIf="isAuthenticated()">
        <div class="col-lg-5 col-md-3"></div>
        <div class="col-lg-7 col-md-9">
          <ul class="top-header-login-register text-center" [ngClass]="{ 'float-right': !isOnSmallScreen }">
            <li>
              <a class="nav-link" routerLink="/inbox" *ngIf="showInboxNotification">
                <i class="bx bxs-inbox" [ngClass]="{ 'icon-size-mobile': isOnSmallScreen }"></i
                ><span class="notification-number"> {{ totalMessages }}</span>
              </a>
            </li>
            <li class="nav-item ">
              <a class="nav-link" style="cursor: pointer;">
                <i class="bx bx-bell" [ngClass]="{ 'icon-size-mobile': isOnSmallScreen }"></i
                ><span class="notification-number"> {{ totalNotificationItems }}</span>
              </a>
              <app-hro-notification-list
                class="dropdown-menu"
                id="notification"
                [fromNavbar]="true"
                (emitAttributeToDisplay)="reloadData()"></app-hro-notification-list>
            </li>
            <li>
              <a [routerLink]="spaceLink" class="my-sm-0" [ngClass]="{ 'nav-link pr-2': isOnSmallScreen }"
                ><i class="bx bx-home" [ngClass]="{ 'icon-size-mobile': isOnSmallScreen }"></i>
                <span class="d-none d-sm-inline">{{ 'MY_SPACE' | translate }}</span></a
              >
            </li>
            <li>
              <a routerLink="/account" class="my-sm-0" [ngClass]="{ 'nav-link pr-2': isOnSmallScreen }"
                ><i class="bx bx-user-circle" [ngClass]="{ 'icon-size-mobile': isOnSmallScreen }"></i>
                <span class="d-none d-sm-inline">{{ 'ACCOUNT' | translate }}</span></a
              >
            </li>
            <li>
              <a (click)="logout()" routerLink="/" class="my-sm-0" [ngClass]="{ 'nav-link pr-2': isOnSmallScreen }"
                ><i class="bx bx-log-out" [ngClass]="{ 'icon-size-mobile': isOnSmallScreen }"></i>
                <span class="d-none d-sm-inline">{{ 'LOGOUT' | translate }}</span></a
              >
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <div class="navbar-area navbar-style-three" ngStickyNav stickyClass="sticky-box-shadow" ngStickyNav>
    <div class="container">
      <nav class="navbar navbar-expand-lg navbar-light bg-light" [class.active]="classApplied">
        <a class="navbar-brand" routerLink="/"
          ><img src="../../../assets/img/pass-excellence.png" class="imgLogo" alt="logo" width="525" height="119"
        /></a>
        <button class="navbar-toggler" type="button" (click)="toggleClass()" aria-label="Menu">
          <span class="burger-menu">
            <span class="top-bar"></span>
            <span class="middle-bar"></span>
            <span class="bottom-bar"></span>
          </span>
        </button>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav">
            <li class="nav-item">
              <a class="nav-link">{{ 'OUR_SERVICES' | translate }}<i class="bx bx-chevron-down"></i></a>
              <app-mega-menu (onLinkClick)="toggleClass()" class="dropdown-menu" id="mega-menu"></app-mega-menu>
            </li>
            <li class="nav-item">
              <a
                [routerLink]="coachListPageUrl$ | async"
                (click)="toggleClass()"
                class="nav-link"
                routerLinkActive="false"
                [routerLinkActiveOptions]="{ exact: true }">
                {{ 'COACHS' | translate }}</a
              >
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                routerLinkActive="active"
                (click)="toggleClass()"
                [routerLinkActiveOptions]="{ exact: true }"
                [routerLink]="corporateSellerListPageUrl$ | async">
                {{ 'OUR_PARTNERS_ITEM' | translate }}</a
              >
            </li>
            <li class="nav-item">
              <a
                [routerLink]="blogListPageUrl$ | async"
                (click)="toggleClass()"
                class="nav-link"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }">
                {{ 'Blog' | translate }}</a
              >
            </li>
            <li class="nav-item">
              <a
                [routerLink]="aboutUsPageUrl$ | async"
                (click)="toggleClass()"
                class="nav-link"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }">
                {{ 'WHO_WE_ARE' | translate }}</a
              >
            </li>
          </ul>
        </div>
        <div class="others-option">
          <div class="dropdown language-switcher d-inline-block" [class.active]="classApplied2">
            <button class="dropdown-toggle" #localeDropdownToggler type="button" (click)="toggleClass2()">
              <img
                *ngIf="currentLocale?.languageId"
                src="assets/img/{{ currentLocale?.languageId }}-flag.jpg"
                class="shadow"
                width="64"
                height="42"
                [alt]="currentLocale?.name" />
              <span>{{ currentLocale?.name | slice : 0 : 2 }}<i class="bx bx-chevron-down"></i></span>
            </button>
            <div class="dropdown-menu">
              <a
                style="cursor: pointer"
                (click)="setCUrrentLocale(locale); localeDropdownToggler.click()"
                class="dropdown-item d-flex align-items-center"
                *ngFor="let locale of locales">
                <img
                  *ngIf="currentLocale?.languageId"
                  src="assets/img/{{ locale.languageId }}-flag.jpg"
                  class="shadow-sm"
                  [alt]="locale.name"
                  width="64"
                  height="42" />
                <span>{{ locale.name }}</span>
              </a>
            </div>
          </div>
          <a routerLink="/cart" class="cart-wrapper-btn d-inline-block" *ngIf="showCartIcon && isAuthenticated()">
            <i class="bx bx-cart-alt"></i>
            <span> {{ totalItems }}</span>
          </a>
        </div>
      </nav>
    </div>
  </div>
</header>
