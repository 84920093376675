import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { GenericService } from './GenericService';
import { Store } from '@ngrx/store';
import { AppState } from '../store/app.reducer';
import { LOCALE_ID, LOCALE_STATE } from '../shared/constants';

@Injectable({
  providedIn: 'root',
})
export class CategoryService extends GenericService {
  private emirCategorryId = new BehaviorSubject('');
  sharedCategoryId = this.emirCategorryId.asObservable();
  private currentLocaleId: string;

  constructor(private store: Store<AppState>) {
    super();
    this.store.select(LOCALE_STATE, LOCALE_ID).subscribe(localeId => (this.currentLocaleId = localeId));
  }

  getCategoriesByParentId(parentId): Observable<any> {
    return this.api.get('api/categories/' + parentId + '/' + this.currentLocaleId);
  }
  next(categoryId: string) {
    this.emirCategorryId.next(categoryId);
  }
}
